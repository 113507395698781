import {
  DEV_DOMAIN_URL,
  QA_DOMAIN_URL,
  DEV_ANALYTICS_DOMAIN_URL,
  QA_ANALYTICS_DOMAIN_URL,
  PREVIEW_DOMAIN_URL,
  PREVIEW_ANALYTICS_DOMAIN_URL,
  PRODUCTION_DOMAIN_URL,
  PRODUCTION_ANALYTICS_DOMAIN_URL,
  PREVIEW_DEPLOYED_URL,
  DEV_DEPLOYED_URL,
  QA_DEPLOYED_URL,
  PRODUCTION_DEPLOYED_URL,
} from "./../config.js";
import {
  ENVIRONMENT,
  SPECIFIC_DOMAIN_URL,
  SPECIFIC_ANALYTICS_DOMAIN_URL,
  SPECIFIC_DEPLOYED_URL,
} from "./../environment.js";

export const getDeployedURL = function () {
  let url = "";
  switch (ENVIRONMENT) {
    case "preview":
      url = PREVIEW_DEPLOYED_URL;
      break;
    case "development":
      url = DEV_DEPLOYED_URL;
      break;
    case "qa":
      url = QA_DEPLOYED_URL;
      break;
    case "production":
      url = PRODUCTION_DEPLOYED_URL;
      break;
    case "specific":
      url = SPECIFIC_DEPLOYED_URL;
      break;
    default:
      break;
  }
  return url;
};

export const getDomainURL = function (type = "platform") {
  let url = "";
  switch (ENVIRONMENT) {
    case "preview":
      url =
        type === "platform" ? PREVIEW_DOMAIN_URL : PREVIEW_ANALYTICS_DOMAIN_URL;
      break;
    case "development":
      url = type === "platform" ? DEV_DOMAIN_URL : DEV_ANALYTICS_DOMAIN_URL;
      break;
    case "qa":
      url = type === "platform" ? QA_DOMAIN_URL : QA_ANALYTICS_DOMAIN_URL;
      break;
    case "production":
      url =
        type === "platform"
          ? PRODUCTION_DOMAIN_URL
          : PRODUCTION_ANALYTICS_DOMAIN_URL;
      break;
    case "specific":
      url =
        type === "platform"
          ? SPECIFIC_DOMAIN_URL
          : SPECIFIC_ANALYTICS_DOMAIN_URL;
      break;
    default:
      break;
  }
  return url;
};

export const invokeAPI = function (
  method,
  endpoint,
  onsuccess,
  onerror,
  payload,
  accessOrigin,
  extraHeaders = {},
  type = "platform"
) {
  async function getResponse() {
    let domainURL = getDomainURL(type);
    let headers = {};
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");
    if (!(accessToken && companyId && applicationId && accessOrigin)) return;
    switch (type) {
      case "platform":
        headers = {
          ...extraHeaders,
          companyid: companyId,
          applicationid: applicationId,
          "access-origin": accessOrigin,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };
        break;
      case "analytics":
        headers = {
          ...extraHeaders,
          cid: companyId,
          aid: applicationId,
          "access-origin": accessOrigin,
          Authorization: `Bearer ${accessToken}`,
        };
        break;
      default:
        console.error("Wrong type of API");
        return;
    }
    let options = { method, headers };
    if (payload) {
      options.body = JSON.stringify(payload);
    }
    const response = await fetch(`${domainURL}/${endpoint}`, options);
    if (!response?.ok) {
      throw new Error(`HTTP error! status: ${response?.status}`);
    }
    const data = await response.json();
    return data;
  }

  getResponse()
    .then((data) => {
      onsuccess && onsuccess(data);
    })
    .catch((error) => {
      onerror && onerror(error);
      console.error("API call failed", error);
    });
};
