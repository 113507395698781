import { writable, readable, derived } from "svelte/store";
import { ENVIRONMENT } from "../environment";
import setLocalStorageValues from "./setLocalStorageVariables.js";

if (ENVIRONMENT === "preview") setLocalStorageValues();

export const selectedNode = writable(null);
export const selectedDate = writable(null);
export const currentPage = writable("");
export const endTimeOffSet = writable(0);
export const startTimeOffSet = writable(0);
export const parentNodeType = writable("");
export const plantNodeType = writable("");
export const subPlantNodeType = writable("");
export const childNodeType = writable("");
export const defaultNodeId = writable(sessionStorage.getItem("saved-node-id"));
export const applicationId = writable(sessionStorage.getItem("appId"));
export const companyId = writable(localStorage.getItem("companyId"));
export const companyName = writable(localStorage.getItem("companyName"));
export const applicationName = writable(sessionStorage.getItem("appName"));
export const userId = writable(localStorage.getItem("sub"));
export const userName = writable(localStorage.getItem("uname"));
export const realmName = writable(localStorage.getItem("realmName"));
export const infoBoardSelectedTimePeriod = writable(
  sessionStorage.getItem("del-infoboard-selected-timeperiod") ?? null
);
export const isPageTitleDelpheon = writable(
  localStorage.getItem("IncludeDelpheonLogo")
);
export const applicationsList = writable(
  localStorage.getItem("applicationList")
    ? JSON.parse(localStorage.getItem("applicationList"))
    : []
);
export const allocatedScreenNames = derived(
  [applicationsList, applicationId],
  ([$applicationsList, $applicationId], set) => {
    if ($applicationsList) {
      const match = $applicationsList.find(
        (item) => item.ApplicationId === $applicationId
      );

      if (match) {
        set(match.Screens.map((item) => item.Name));
      }
    }
  }
);
export const availableScreens = derived(
  [applicationsList, applicationId],
  ([$applicationsList, $applicationId], set) => {
    if ($applicationsList) {
      const match = $applicationsList.find(
        (item) => item.ApplicationId === $applicationId
      );

      if (match) {
        set(match.Screens);
      }
    }
  }
);
export const analyticsVersion = writable("");
export const companyDetails = writable(null);
export const applicationError = writable(
  sessionStorage.getItem("application-error")
    ? JSON.parse(sessionStorage.getItem("application-error"))
    : { error: false }
);
export const analyticsSelectedTab = writable("");
export const isReportGenerated = writable(false);
export const rootNode = writable(null);
export const disableScroll = writable(false);
export const screenWidth = writable(0);
export const allNodes = writable([]);
