<script>
  import { Router, Route, Link } from "svelte-routing";
  import Dashboard from "./routes/Dashboard.svelte";
  import Infoboard from "./routes/Infoboard.svelte";
  import Reports from "./routes/Reports.svelte";
  import Events from "./routes/Events.svelte";
  import DigitalTwin from "./routes/DigitalTwin.svelte";
  import PlanInput from "./routes/PlanInput.svelte";
  import Analytics from "./routes/Analytics.svelte";
  import Trend from "./routes/Trend.svelte";
  import About from "./routes/About.svelte";
  import Error from "./routes/Error.svelte";

  import { onMount, onDestroy } from "svelte";
  import {
    selectedNode,
    defaultNodeId,
    allocatedScreenNames,
    selectedDate,
    currentPage,
    analyticsVersion,
    companyDetails,
    startTimeOffSet,
    endTimeOffSet,
    parentNodeType,
    plantNodeType,
    subPlantNodeType,
    childNodeType,
    applicationError,
    applicationId,
    companyId,
    companyName,
    applicationName,
    userId,
    userName,
    realmName,
    isPageTitleDelpheon,
    applicationsList,
    analyticsSelectedTab,
    isReportGenerated,
    rootNode,
    disableScroll,
    screenWidth,
    allNodes,
  } from "./stores.js";
  import { getDomainURL, invokeAPI } from "./invokeAPI.js";
  import DelNavigationTree from "./components/DelNavigationTree/DelNavigationTree.svelte";
  import DelDatePicker from "./components/DelDatePicker/DelDatePicker.svelte";
  import InfoboardDropDown from "./components/InfoboardDropDown/InfoboardDropDown.svelte";
  import {
    pagesWithDatePicker,
    screensToShow,
    infoboardScreenDetails,
    pathNameSSPA,
  } from "./../config";
  import getLocalStorageValues from "./getLocalStorageValues";
  import { ENVIRONMENT } from "./../environment";
  import moment from "moment";

  const IS_STANDALONE = window.singleSpaNavigate ? false : true;

  let defaultStartDate = sessionStorage.getItem("saved-start-date")
    ? sessionStorage.getItem("saved-start-date")
    : new Date(moment().subtract(6, "day").startOf("day"));
  let defaultEndDate = sessionStorage.getItem("saved-end-date")
    ? sessionStorage.getItem("saved-end-date")
    : new Date(moment().endOf("day"));
  let dateFromSessionStorage =
    sessionStorage.getItem("saved-start-date") ?? null;
  defaultNodeId.set(sessionStorage.getItem("saved-node-id") ?? null);

  let domainURL = getDomainURL();
  let initialScreenPath = "";
  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;

  const invokeGetAnalyticsVersionAPI = function () {
    const endpoint = "get-version";
    const analyticsVersionAPInSuccess = function (data) {
      analyticsVersion.set(data);
    };
    invokeAPI(
      "GET",
      endpoint,
      analyticsVersionAPInSuccess,
      null,
      null,
      screensToShow[initialScreenPath]
        ? `${screensToShow[initialScreenPath].screen}/R`
        : null,
      {},
      "analytics"
    );
  };

  const checkIfTodayHasStarted = function (offSet, endTime) {
    const currentTime = moment().valueOf();
    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }
    return moment().startOf("day").valueOf() + offSet <= currentTime;
  };

  const invokeGetCompanyDetailsAPI = function () {
    const companyName = localStorage.getItem("companyName");
    const appName = sessionStorage.getItem("appName");
    const endpoint = `twin/api/Hierarchy/${companyName}/${appName}`;
    const companyDetailsAPIOnSuccess = function (data) {
      companyDetails.set(data);
      startTimeOffSet.set(data?.StartTimeOffset ?? 0);
      endTimeOffSet.set(data?.EndTimeOffset ?? 0);
      parentNodeType.set(data?.metadata?.length ? data.metadata[0].type : "");
      plantNodeType.set(
        data?.metadata?.length > 1 ? data.metadata[1].type : ""
      );
      subPlantNodeType.set(
        data?.metadata?.length > 2 ? data.metadata[2].type : ""
      );
      childNodeType.set(
        data?.metadata?.length > 3 ? data.metadata[3].type : ""
      );
      localStorage.setItem("startTimeOffset", $startTimeOffSet);
      localStorage.setItem("endTimeOffset", $endTimeOffSet);

      if (!checkIfTodayHasStarted($startTimeOffSet)) {
        if (!dateFromSessionStorage) {
          defaultStartDate = new Date(
            moment(defaultStartDate).subtract(1, "day")
          );
          defaultEndDate = new Date(moment(defaultEndDate).subtract(1, "day"));
        }
      }
    };

    invokeAPI(
      "GET",
      endpoint,
      companyDetailsAPIOnSuccess,
      null,
      null,
      screensToShow[initialScreenPath]
        ? `${screensToShow[initialScreenPath].screen}/R`
        : null
    );
  };

  const onURLChanged = function () {
    console.log("URL changed:", window.location.pathname);
    const currentPath = window.location.pathname;

    let newPath =
      ENVIRONMENT === "preview"
        ? currentPath
        : currentPath.replace(pathNameSSPA, "");
    newPath = newPath || "/";
    handleLinkClick(newPath);
  };

  onMount(() => {
    const currentPath = window.location.pathname;
    const localStorageValues = getLocalStorageValues();
    console.log("Values from local storage", localStorageValues);

    applicationId.set(localStorageValues.applicationId);
    applicationName.set(localStorageValues.applicationName);
    applicationsList.set(localStorageValues.applicationsList);
    companyId.set(localStorageValues.companyId);
    companyName.set(localStorageValues.companyName);
    isPageTitleDelpheon.set(localStorageValues.isPageTitleDelpheon);
    realmName.set(localStorageValues.realmName);
    userId.set(localStorageValues.userId);
    userName.set(localStorageValues.userName);

    window.addEventListener("popstate", onURLChanged);

    initialScreenPath =
      ENVIRONMENT === "preview"
        ? currentPath
        : currentPath.replace(pathNameSSPA, "");
    initialScreenPath = initialScreenPath || "/";
    currentPage.set(initialScreenPath);

    if (initialScreenPath === "/") {
      const firstLinkToHome = document.querySelector(
        `a[href="${pathNameSSPA}/"]`
      );
      if (firstLinkToHome) {
        firstLinkToHome?.click();
      }
    } else if (initialScreenPath === "/Events") {
      const urlParams = new URLSearchParams(window.location.search);
      const nodeIdFromURL = urlParams.get("nodeId");

      if (nodeIdFromURL) {
        defaultNodeId.set(nodeIdFromURL);
        const linkToEvents = document.querySelector(
          `a[href="${pathNameSSPA}/Events"]`
        );
        if (linkToEvents) {
          linkToEvents?.click();
        }
      }
    }

    if (initialScreenPath !== "/Reports") {
      clearReportRelatedSessionItems();
    }
    if (initialScreenPath !== "/Analytics") {
      clearAnalyticsRelatedSessionItems();
    }
    if (initialScreenPath !== "/PlanInput") {
      clearPlanInputRelatedSessionItems();
    }
    if (initialScreenPath !== "/Events") {
      clearEventsRelatedSessionItems();
    }
    if (initialScreenPath !== "/") {
      disableScroll.set(false);
    }
    invokeGetAnalyticsVersionAPI();
    invokeGetCompanyDetailsAPI();
    onWindowResize();
    window.addEventListener("resize", onWindowResize);
  });

  const onWindowResize = function () {
    const currentScreenWidth =
      window?.innerWidth ||
      document?.documentElement?.clientWidth ||
      document?.body?.clientWidth;
    screenWidth.set(currentScreenWidth);
  };

  onDestroy(() => {
    window.removeEventListener("popstate", onURLChanged);
  });

  const dateRangesToShow = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
    "Current Year",
  ];

  const onDateChange = function (newDate) {
    selectedDate.set(newDate);
    sessionStorage.setItem("saved-start-date", newDate?.start);
    sessionStorage.setItem("saved-end-date", newDate?.end);
  };

  const onNavTreeNodeSelected = function (node) {
    if (node && $selectedNode?.id !== node.id) {
      selectedNode.set(node);
      sessionStorage.setItem("saved-node-id", node?.id);
      if (node?.id) {
        defaultNodeId.set(node.id);
      }
    }
  };

  const checkIfScreenIsNotAllocated = function (screen) {
    if ($allocatedScreenNames?.includes(screen)) return false;
    return true;
  };

  const clearReportRelatedSessionItems = function () {
    sessionStorage.removeItem("report-to-be-shown");
    sessionStorage.removeItem("report-selected-start-time");
    sessionStorage.removeItem("report-selected-end-time");
    sessionStorage.removeItem("report-selected-options");
    sessionStorage.removeItem("report-selected-summary-options");
    sessionStorage.removeItem("report-default-selected-options");
    sessionStorage.removeItem("show-feeder-descriptions");
    isReportGenerated.set(false);
  };

  const clearAnalyticsRelatedSessionItems = function () {
    sessionStorage.removeItem("del-analytics-selected-tab");
    sessionStorage.removeItem("del-analytics-saved-dates");
    sessionStorage.removeItem("del-analytics-selected-nodeid");
    sessionStorage.removeItem("del-analytics-selected-group-name");
    sessionStorage.removeItem("del-analytics-selected-parameter-id");
    sessionStorage.removeItem("del-analytics-selected-resolution");
    sessionStorage.removeItem("del-analytics-selected-feature");
    sessionStorage.removeItem("del-dynamic-report-selected-parameters");
    sessionStorage.removeItem("del-dynamic-report-aggregation");
    sessionStorage.removeItem("del-dynamic-report-selected-dates");
    sessionStorage.removeItem("del-comments-table-filter-selected-parameters");
  };

  const clearPlanInputRelatedSessionItems = function () {
    sessionStorage.removeItem("plan-input-saved-end-date");
    sessionStorage.removeItem("plan-input-saved-start-date");
  };

  const clearEventsRelatedSessionItems = function () {
    sessionStorage.removeItem("eventViewerFilterColumns");
    sessionStorage.removeItem("eventViewerSelectedFilters");
    sessionStorage.removeItem("isChildNodeIncluded");
  };

  const handleLinkClick = function (screenName) {
    currentPage.set(screenName);
    if (screenName !== "/Reports") {
      clearReportRelatedSessionItems();
    }
    if (screenName !== "/Analytics") {
      clearAnalyticsRelatedSessionItems();
    }
    if (screenName !== "/PlanInput") {
      clearPlanInputRelatedSessionItems();
    }
    if (screenName !== "/Events") {
      clearEventsRelatedSessionItems();
    }
    if (screenName !== "/") {
      disableScroll.set(false);
    }
    if (!pagesWithDatePicker.includes(screensToShow[screenName]?.displayName)) {
      defaultStartDate = sessionStorage.getItem("saved-start-date")
        ? sessionStorage.getItem("saved-start-date")
        : new Date(moment().subtract(6, "day").startOf("day"));
      defaultEndDate = sessionStorage.getItem("saved-end-date")
        ? sessionStorage.getItem("saved-end-date")
        : new Date(moment().endOf("day"));
    }
  };

  const onNoNodesAssigned = function () {
    applicationError.set({
      error: true,
      message:
        "No user nodes assigned for this user. Please contact your admin.",
      disable: true,
    });
    sessionStorage.setItem(
      "application-error",
      JSON.stringify($applicationError)
    );
    if ($currentPage !== "/Error") window.location.href = `${pathPrefix}/Error`;
  };

  const onNavTreeAPISucess = function (data) {
    applicationError.set({ error: false });
    sessionStorage.setItem(
      "application-error",
      JSON.stringify({ error: false })
    );
    if (data?.length) {
      rootNode.set(data[0]);
      allNodes.set(data);
    } else {
      allNodes.set([]);
    }
  };

  const onNavTreeAPIError = function () {
    onNoNodesAssigned();
    allNodes.set([]);
  };

  const hideNavItem = function (screen, nodeType, parentNodeType) {
    if (screen === "/Trend" && nodeType !== parentNodeType) {
      return true;
    }
    return false;
  };
</script>

<svelte:head>
  <title>{$isPageTitleDelpheon === "true" ? "DelphEon" : $companyName}</title>
</svelte:head>

<div
  class={`del-application-container ${$disableScroll ? "disable-scroll" : ""}`}
  id="del-application-container"
>
  <div class="del-navigation-bar">
    <div class="del-nav-bar-left-container">
      <div class="del-nav-tree-container">
        <div
          class={`del-nav-tree ${
            $currentPage === "/PlanInput" ? "del-hidden-item" : ""
          }`}
        >
          <DelNavigationTree
            onNodeSelected={onNavTreeNodeSelected}
            baseURL={domainURL}
            defaultNodeId={$defaultNodeId}
            currentScreen={screensToShow[initialScreenPath]?.screen ?? null}
            isDisabled={$currentPage === "/Infoboard" ||
              $currentPage === "/Trend" ||
              ($currentPage === "/Analytics" &&
                $analyticsSelectedTab === "dynamic-report") ||
              $isReportGenerated}
            {onNoNodesAssigned}
            onAPISuccess={onNavTreeAPISucess}
            onAPIFailed={onNavTreeAPIError}
            withAncestors={false}
          />
        </div>
        <div class="del-node-name">
          {`${
            $currentPage === "/Analytics" &&
            $analyticsSelectedTab === "dynamic-report"
              ? "Dynamic Report"
              : $currentPage === "/PlanInput"
                ? "Plan Input"
                : $selectedNode?.name ?? ""
          }`}
        </div>
      </div>
      {#if pagesWithDatePicker.includes(screensToShow[$currentPage]?.displayName)}
        <div
          class={`del-date-picker-container ${
            $currentPage === "/PlanInput" ? "del-hidden-item" : ""
          }`}
        >
          <DelDatePicker
            customRangesToShow={dateRangesToShow}
            onDateChanged={onDateChange}
            autoApply={true}
            {defaultStartDate}
            {defaultEndDate}
            zIndex={11}
            firstDay={1}
            isDisabled={$isReportGenerated}
            startTimeOffSet={$startTimeOffSet}
            endTimeOffSet={$endTimeOffSet}
          />
        </div>
      {/if}
    </div>
    <div class="del-nav-item-container">
      {#if infoboardScreenDetails?.show && !$applicationError?.error}
        <InfoboardDropDown />
      {/if}
      <Router>
        {#each Object.keys(screensToShow) as screenName (screenName)}
          {#if screenName !== "/Infoboard" && screenName !== "/Error"}
            <Link
              to={`${pathPrefix}${screenName}`}
              class="del-nav-item {checkIfScreenIsNotAllocated(
                screensToShow[screenName].screen
              ) ||
              $applicationError?.error ||
              (screenName === '/PlanInput' &&
                $rootNode?.type !== $parentNodeType)
                ? 'disabled'
                : ''} {$currentPage === screenName
                ? 'del-active-nav-item'
                : ''} {hideNavItem(
                screenName,
                $selectedNode?.type,
                $parentNodeType
              )
                ? 'del-hidden-item'
                : ''}"
              on:click={() => handleLinkClick(screenName)}
              >{screensToShow[screenName].displayName}</Link
            >
          {/if}
        {/each}
      </Router>
      <About />
    </div>
  </div>

  <Router basepath={IS_STANDALONE ? "/" : pathNameSSPA}>
    <Route path="/" component={Dashboard} />
    <Route path="/Infoboard" component={Infoboard} />
    <Route path="/Trend" component={Trend} />
    <Route path="/Reports" component={Reports} />
    <Route path="/Events" component={Events} />
    <Route path="/DigitalTwin" component={DigitalTwin} />
    <Route path="/PlanInput" component={PlanInput} />
    <Route path="/Analytics" component={Analytics} />
    <Route path="/Error" component={Error} />
  </Router>
</div>

<style>
  :global(body) {
    margin: 0 auto;
    padding: 0;
  }
  :global(.del-application-container) {
    margin: 0 auto;
    padding: 0;
    /* max-width: 1550px; */
    width: 100%;
    background-color: #ffffff;
    height: 100%;
  }

  .disable-scroll {
    overflow-y: hidden;
  }

  .del-navigation-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 28px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #0000001f;
    opacity: 1;
    border-top: 1px solid #ccd3e7;
    min-height: 40px;
  }

  .del-nav-bar-left-container {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
  }

  .del-date-picker-container {
    align-self: center;
  }
  .del-nav-tree-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .del-node-name,
  .del-nav-tree {
    align-self: center;
  }

  .del-nav-tree {
    height: 32px;
  }

  :global(.tree-cascader-button),
  :global(.tree-cascader-button:enabled:hover),
  :global(.tree-cascader-button:enabled:active),
  :global(.tree-cascader-button:enabled:focus) {
    background-color: #16558f !important;
    color: white !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 12px !important;
    border: none !important;
    padding: 5px !important;
  }

  :global(.tree-cascader-button:disabled) {
    background-color: #ddddddbd !important;
    color: white !important;
  }

  .del-node-name {
    text-align: left;
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    opacity: 1;
  }

  .del-nav-item-container {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  :global(.del-hidden-item) {
    display: none;
  }

  :global(a.del-nav-item) {
    text-decoration: none;
    color: #585859;
  }

  :global(.del-nav-item:hover) {
    color: #1f419b;
  }
  :global(.del-nav-item) {
    text-align: left;
    font: normal normal 500 15px/20px Roboto;
    letter-spacing: 0px;
    color: #585859;
    opacity: 1;
    cursor: pointer;
    text-decoration: none;
  }

  :global(.del-nav-item.disabled) {
    cursor: not-allowed;
    pointer-events: none;
    color: #59585875;
  }

  :global(a.del-active-nav-item) {
    text-align: left;
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #1f419b;
    opacity: 1;
  }

  :global(.del-active-nav-item) {
    text-align: left;
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #1f419b;
    opacity: 1;
  }

  :global(.del-button) {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  :global(.del-button:hover),
  :global(.del-button:focus),
  :global(.del-button:active) {
    background-color: transparent;
  }

  :global(.del-page-container) {
    padding-bottom: 16px;
  }

  :global(.del-container) {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
  }
</style>
