<script>
  export let tableColumns = [];
  export let tableData = [];
  export let entriesPerPage = 10;
  export let hideNoDataMessage = false;

  let paginatedData = [];
  let currentPage = 1;

  $: {
    if (tableData) {
      updatePagination();
    }
  }
  $: totalPages = Math.ceil(tableData?.length / entriesPerPage) ?? 0;

  const updatePagination = function () {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    paginatedData = tableData.slice(startIndex, endIndex);
  };

  const handleFirstPageClick = function () {
    currentPage = 1;
    updatePagination();
  };
  const handleLastPageClick = function () {
    currentPage = totalPages;
    updatePagination();
  };
  const handleNextPageClick = function () {
    currentPage++;
    updatePagination();
  };
  const handlePreviousPageClick = function () {
    currentPage--;
    updatePagination();
  };
</script>

<div class="del-table-container">
  {#if tableData?.length}
    <div class="del-table-inner-container">
      <table class="del-table">
        <thead>
          {#if tableColumns?.length}
            <tr class="del-table-header-row">
              {#each tableColumns as item (item)}
                <th class="del-table-cell del-table-header-cell">{item}</th>
              {/each}
            </tr>
          {/if}
        </thead>
        <tbody class="table-body">
          {#each paginatedData as row}
            <tr class="del-table-row">
              {#each tableColumns as item (item)}
                <td class={`del-table-cell`}>{row[item] ?? ""}</td>
              {/each}
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  {:else if !hideNoDataMessage}
    <div class="no-data-message">No Data Available</div>
  {/if}
  <!-- Pagination -->
  {#if tableData.length > entriesPerPage}
    <div class="pagination-container">
      <div class="entries-display">
        {`Showing ${(currentPage - 1) * entriesPerPage + 1} to ${Math.min(
          currentPage * entriesPerPage,
          tableData.length
        )} of ${tableData.length} entries`}
      </div>
      <div class="pagination-buttons">
        <button
          class="first-page"
          disabled={currentPage === 1}
          on:click={handleFirstPageClick}>First</button
        >
        <button
          class="previous-page"
          disabled={currentPage === 1}
          on:click={handlePreviousPageClick}>Previous</button
        >
        <span class="current-page"
          >{`Page ${currentPage} of ${totalPages}`}</span
        >
        <button
          class="next-page"
          disabled={currentPage === totalPages}
          on:click={handleNextPageClick}>Next</button
        >
        <button
          class="last-page"
          disabled={currentPage === totalPages}
          on:click={handleLastPageClick}>Last</button
        >
      </div>
    </div>
  {/if}
</div>

<style>
  .del-table-container {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    margin: 20px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .del-table-inner-container {
    overflow-x: auto;
    border: 1px solid #d3d3d3;
    margin: 0;
    padding: 0;
  }
  .del-table {
    width: 100%;
    border-spacing: 0;
    position: relative;
  }

  .del-table td:first-child,
  .del-table th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    border-right: 1px solid #d3d3d3;
    min-width: 250px;
  }

  .del-table th:last-child {
    border-right-color: #79919a;
  }

  .del-table-cell {
    padding: 10px;
    text-align: center;
    font: normal normal normal 15px/21px Roboto;
    color: #000000;
    opacity: 0.77;
    border: 1px solid #d3d3d3;
    background-color: #ffffff;
    opacity: 1;
    min-width: 250px;
  }

  .del-table-no-data-cell {
    text-align: center;
  }

  .del-table-header-row {
    border: 1px solid #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }

  .del-table-filter-row {
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #beccd1;
    border: 1px solid #d3d3d3;
  }

  .del-table-header-cell {
    border-color: #79919a;
    border-right-color: #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }
  .del-table-date {
    margin-left: 1ch;
    color: #798b91;
  }

  .no-data-message {
    width: 100%;
    text-align: center;
    font: italic normal normal 15px Roboto;
  }

  /* For Pagination */
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .pagination-buttons button {
    margin: 0;
    background: #7293d1 0 0 no-repeat padding-box;
    border: none;
    color: #ffffff;
    opacity: 1;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
  }

  .pagination-buttons button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .pagination-buttons button:enabled:hover {
    background: #4b72b9;
  }

  .current-page {
    font-weight: bold;
    margin: 0 10px;
  }

  .entries-display {
    font-style: italic;
    margin-right: 10px;
  }

  /* End of Pagination styles */
</style>
