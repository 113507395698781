import singleSpaSvelte from "single-spa-svelte";
import App from "./App.svelte";
import singleSpaCss from "single-spa-css";
import { getDeployedURL } from "./invokeAPI";
const IS_STANDALONE = window.singleSpaNavigate ? false : true; // checks if app is loaded as singlespa
console.log("Load Mode:", IS_STANDALONE ? "Standalone" : "Single Spa");

let cssLifecycles;
let svelteInstance;
let svelteLifecycles;
const DEPLOYED_URL = getDeployedURL();

if (IS_STANDALONE) {
  // For standalone mode, instantiate the Svelte app directly
  svelteInstance = new App({
    target: document.body,
    // You can pass in props if needed
  });
} else {
  svelteLifecycles = singleSpaSvelte({
    component: App,
    // domElementGetter: () => document.getElementById("svelte-app"),
  });
  cssLifecycles = singleSpaCss({
    // required: a list of CSS URLs to load
    // can be omitted if webpackExtractedCss is set to true, do not specify Webpack extracted css files here
    cssUrls: [`${DEPLOYED_URL}/build/bundle.css`],

    // optional: defaults to false. This controls whether extracted CSS files from Webpack
    // will automatically be loaded. This requires using the ExposeRuntimeCssAssetsPlugin,
    // which is documented below.
    webpackExtractedCss: false,

    // optional: defaults to true. Indicates whether the <link> element for the CSS will be
    // unmounted when the single-spa microfrontend is unmounted.
    shouldUnmount: true,

    // optional: defaults to 5000. The number of milliseconds to wait on the <link> to load
    // before failing the mount lifecycle.
    timeout: 5000,

    // optional: defaults to a standard <link rel="stylesheet" href="/main.css"> element
    // Customize the creation of the link element that is used by single-spa-css by providing a
    // function. For example, for setting the cross-origin or other HTML attributes on the <link>
    createLink(url) {
      const linkEl = document.createElement("link");
      linkEl.rel = "stylesheet";
      linkEl.href = url;
      return linkEl;
    },
  });
}

export const bootstrap = svelteLifecycles
  ? [cssLifecycles.bootstrap, svelteLifecycles.bootstrap]
  : null;
export const mount = svelteLifecycles
  ? [cssLifecycles.mount, svelteLifecycles.mount]
  : null;
export const unmount = svelteLifecycles
  ? [cssLifecycles.unmount, svelteLifecycles.unmount]
  : null;
