<script>
  import {
    selectedNode,
    selectedDate,
    currentPage,
    availableScreens,
    parentNodeType,
    plantNodeType,
    subPlantNodeType,
    childNodeType,
    isReportGenerated,
    endTimeOffSet,
  } from "./../stores";
  import { getDomainURL } from "./../invokeAPI";
  import {
    electricalReportConfigurations,
    eventReportConfigurations,
    costReportConfigurations,
    userReportConfigurations,
    screensToShow,
    ksebBillConfigurations,
    varianceReportConfigurations,
    feederReportConfigurations,
    energyReportConfigurations,
    summaryReportConfigurations,
    connectedEquipmentDetails,
    rawReportConfigurations,
  } from "./../../config";
  import { addOffSet, epoch13to10 } from "./../utilityFunctions";
  import DelEventReport from "./../components/DelEventReport/DelEventReport.svelte";
  import DelDropDownCheckBox from "./../components/DelDropDownCheckBox/DelDropDownCheckBox.svelte";
  import DelUserReport from "./../components/DelUserReport/DelUserReport.svelte";
  import DelTimePickerPopUp from "./../components/DelTimePickerPopUp/DelTimePickerPopUp.svelte";
  import DelElectricalReport from "./../components/DelElectricalReport/DelElectricalReport.svelte";
  import DelKSEBBillReport from "./../components/DelKSEBBillReport/DelKSEBBillReport.svelte";
  import DelSummaryReport from "./../components/DelSummaryReport/DelSummaryReport.svelte";
  import DelCardsList from "./../components/DelCardsList/DelCardsList.svelte";
  import Toastify from "toastify-js";
  import "toastify-js/src/toastify.css";
  import moment from "moment";

  let selectedReport = null;
  let isEventReportDropDownOpen = false;
  let isElectricalReportDropDownOpen = false;
  let isHourlyElectricalReportDropDownOpen = false;
  let isCostReportDropDownOpen = false;
  let isVarianceReportDropDownOpen = false;
  let isFeederReportDropDownOpen = false;
  let isSummaryReportDropDownOpen = false;
  let isRawReportDropDownOpen = false;

  let electricalDefaultOptions = [];
  let costDefaultOptions = [];
  let hourlyElectricalDefaultOptions = [];
  let eventDefaultOptions = [];
  let varianceDefaultOptions = [];
  let feederDefaultOptions = [];
  let summaryDefaultOptions = [];
  let rawReportDefaultOptions = [];

  let reportToBeShown = sessionStorage.getItem("report-to-be-shown");
  let reportStartTime = sessionStorage.getItem("report-selected-start-time");
  let reportEndTime = sessionStorage.getItem("report-selected-end-time");
  let reportSelectNodeId = sessionStorage.getItem("report-selected-node-id");
  let reportSelectNode = sessionStorage.getItem("report-selected-node")
    ? JSON.parse(sessionStorage.getItem("report-selected-node"))
    : null;
  let reportSelectedNodeName = sessionStorage.getItem(
    "report-selected-node-name"
  );
  let selectedOptions = sessionStorage.getItem("report-selected-options")
    ? JSON.parse(sessionStorage.getItem("report-selected-options"))
    : [];
  let defaultOptions = sessionStorage.getItem("report-default-selected-options")
    ? JSON.parse(sessionStorage.getItem("report-default-selected-options"))
    : [];
  let selectedSummaryOptions = sessionStorage.getItem(
    "report-selected-summary-options"
  )
    ? JSON.parse(sessionStorage.getItem("report-selected-summary-options"))
    : [];

  let popUpStartTime = null;
  let popUpEndTime = null;
  let showPopUp = false;
  let ksebBillRealTimeParameters = null;
  let costOptions = [];
  let varianceOptions = [];
  let showFeederDescriptions = sessionStorage.getItem(
    "show-feeder-descriptions"
  )
    ? JSON.parse(sessionStorage.getItem("show-feeder-descriptions"))
    : false;

  const setDefaultOptions = function () {
    switch (reportToBeShown) {
      case "Electrical":
        electricalDefaultOptions = [...defaultOptions];
        break;
      case "Hourly Electrical":
        hourlyElectricalDefaultOptions = [...defaultOptions];
        break;
      case "Cost":
        costDefaultOptions = [...defaultOptions];
        break;
      case "Events":
        eventDefaultOptions = [...defaultOptions];
        break;
      case "Feeder":
        feederDefaultOptions = [...defaultOptions];
        break;
      case "Variance":
        varianceDefaultOptions = [...defaultOptions];
        break;
      case "Summary":
        summaryDefaultOptions = [...defaultOptions];
        break;
      case "Raw Report":
        rawReportDefaultOptions = [...defaultOptions];
        break;
      default:
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
    }
  };

  setDefaultOptions();

  isReportGenerated.set(reportToBeShown);

  $: selectedNodeType = $selectedNode?.type;

  $: {
    if ($plantNodeType && ksebBillConfigurations?.realTimeParameters) {
      ksebBillRealTimeParameters = {
        ...ksebBillConfigurations.realTimeParameters,
        levels: [$plantNodeType],
      };
    }
  }

  $: {
    setReportGeneratedFlag(reportToBeShown);
  }

  $: {
    if ($selectedNode || $selectedDate) {
      closeAllDropDowns();
    }
  }

  $: {
    if ($selectedNode) {
      clearDefaultOptions();
    }
  }

  const closeAllDropDowns = function () {
    isEventReportDropDownOpen = false;
    isCostReportDropDownOpen = false;
    isElectricalReportDropDownOpen = false;
    isHourlyElectricalReportDropDownOpen = false;
    isVarianceReportDropDownOpen = false;
    isFeederReportDropDownOpen = false;
    isSummaryReportDropDownOpen = false;
    isRawReportDropDownOpen = false;
  };

  const clearDefaultOptions = function (type) {
    switch (type) {
      case "Electrical":
        hourlyElectricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
      case "Hourly Electrical":
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
      case "Cost":
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
      case "Events":
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
      case "Feeder":
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
      case "Variance":
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
      case "Summary":
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
      case "Raw Report":
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        break;
      default:
        hourlyElectricalDefaultOptions = [];
        electricalDefaultOptions = [];
        costDefaultOptions = [];
        eventDefaultOptions = [];
        varianceDefaultOptions = [];
        feederDefaultOptions = [];
        summaryDefaultOptions = [];
        rawReportDefaultOptions = [];
        break;
    }
  };

  const setReportGeneratedFlag = function () {
    isReportGenerated.set(reportToBeShown);
  };
  const onEventReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = selected.map((item) => item.value);
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selectedOptions)
    );
    if (selected.length) selectedReport = "Events";
  };

  const onRawReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = [...selected];
    selectedSummaryOptions = selected
      .map((item) => {
        return rawReportConfigurations?.summaryOptions?.find(
          (element) => element.name === item.name
        );
      })
      .filter((item) => item);
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-selected-summary-options",
      JSON.stringify(selectedSummaryOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selected.map((item) => item.name))
    );
    if (selected.length) selectedReport = "Raw Report";
  };

  const updateCostReportOptions = function (options) {
    let filteredOptions = [];
    let _match = null;
    let match = options.find(
      (item) =>
        item.name === costReportConfigurations?.discomDetails?.currentTitle
    );

    if (match) {
      _match = { ...match };
      filteredOptions.push(_match);
      filteredOptions = [
        ...filteredOptions,
        ...costReportConfigurations.discomOptions,
      ];
    }

    match = options.find(
      (item) => item.name === costReportConfigurations?.dgDetails?.currentTitle
    );

    if (match) {
      _match = { ...match };
      filteredOptions.push(_match);
      filteredOptions = [
        ...filteredOptions,
        ...costReportConfigurations.dgOptions,
      ];
    }
    filteredOptions = [
      ...filteredOptions,
      ...options.filter(
        (item) =>
          item.name !== costReportConfigurations?.discomDetails?.currentTitle &&
          item.name !== costReportConfigurations?.dgDetails?.currentTitle
      ),
    ];
    return filteredOptions;
  };

  const updateCostReportSummaryOptions = function (options) {
    let filteredOptions = [];
    let _match = null;
    let match = options.find(
      (item) =>
        item.name === costReportConfigurations?.discomDetails?.currentTitle
    );

    if (match) {
      _match = { ...match };
      filteredOptions.push(_match);
      filteredOptions = [
        ...filteredOptions,
        ...costReportConfigurations.discomSummaryOptions,
      ];
    }

    match = options.find(
      (item) => item.name === costReportConfigurations?.dgDetails?.currentTitle
    );

    if (match) {
      _match = { ...match };
      filteredOptions.push(_match);
      filteredOptions = [
        ...filteredOptions,
        ...costReportConfigurations.dgSummaryOptions,
      ];
    }
    filteredOptions = [
      ...filteredOptions,
      ...options.filter(
        (item) =>
          item.name !== costReportConfigurations?.discomDetails?.currentTitle &&
          item.name !== costReportConfigurations?.dgDetails?.currentTitle
      ),
    ];
    if (costReportConfigurations?.commonSummaryOptions) {
      filteredOptions = [
        ...filteredOptions,
        ...costReportConfigurations?.commonSummaryOptions,
      ];
    }
    return filteredOptions;
  };

  const onCostReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = updateCostReportOptions([...selected]);
    const _selectedSummaryOptions = selected
      .map((item) => {
        return costReportConfigurations?.summaryOptions?.find(
          (element) => element.name === item.name
        );
      })
      .filter((item) => item);
    selectedSummaryOptions = updateCostReportSummaryOptions(
      _selectedSummaryOptions
    );
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-selected-summary-options",
      JSON.stringify(selectedSummaryOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selected.map((item) => item.name))
    );
    if (selected.length) selectedReport = "Cost";
  };

  const onVarianceReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = [...selected];
    selectedSummaryOptions = selectedOptions
      .map((item) => {
        return varianceReportConfigurations?.reportSummaryConfig?.find(
          (element) => element.columnTitle === item.columnTitle
        );
      })
      .filter((item) => item);
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-selected-summary-options",
      JSON.stringify(selectedSummaryOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selected.map((item) => item.name))
    );
    if (selected.length) selectedReport = "Variance";
  };

  const onFeederReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = [...selected];
    selectedSummaryOptions = selectedOptions
      .map((item) => {
        return feederReportConfigurations?.reportSummaryConfig?.find(
          (element) => element.name === item.name
        );
      })
      .filter((item) => item);
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-selected-summary-options",
      JSON.stringify(selectedSummaryOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selected.map((item) => item.name))
    );
    if (selected.length) selectedReport = "Feeder";
  };

  const updateElectricalOptions = function (options) {
    let filteredOptions = [];
    for (let item of options) {
      let newItem = { ...item };
      if (selectedNodeType === $childNodeType) {
        newItem.parameterType = item.parameterType1;
        newItem.api = item.api1 ?? item.api;
      } else {
        newItem.parameterType = item.parameterType2;
        newItem.api = item.api2 ?? item.api;
      }
      if (selectedNodeType === $subPlantNodeType) {
        if (item.extraDeviceCategories) {
          newItem.deviceCategories = [
            ...item.extraDeviceCategories,
            ...item.deviceCategories,
          ];
        }
        if (item.extraParameterCategories) {
          newItem.parameterCategories = [
            ...item.extraParameterCategories,
            ...item.parameterCategories,
          ];
        }
      }
      filteredOptions.push({ ...newItem });
    }
    return filteredOptions;
  };

  const updateOptions = function () {
    if (selectedReport === "Electrical") {
      selectedOptions = updateElectricalOptions(selectedOptions);
      selectedSummaryOptions = updateElectricalOptions(selectedSummaryOptions);
    }
  };

  const onElectricalReportOptionsChange = function (selected, type) {
    if (!selected || !selectedNodeType) return;
    selectedOptions = updateElectricalOptions(selected);
    let _summaryOptions = selectedOptions
      .map((item) => {
        return electricalReportConfigurations?.reportSummaryConfig?.find(
          (element) => element.columnTitle === item.columnTitle
        );
      })
      .filter((item) => item);
    selectedSummaryOptions = updateElectricalOptions(_summaryOptions);
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-selected-summary-options",
      JSON.stringify(selectedSummaryOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selected.map((item) => item.name))
    );
    if (selected.length) selectedReport = type ?? "Electrical";
  };

  const onSummaryReportOptionsChange = function (selected) {
    if (!selected) return;
    selectedOptions = [...selected];
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(selectedOptions)
    );
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(selected.map((item) => item.name))
    );
    if (selected.length) selectedReport = "Summary";
  };

  const onUserReportButtonClick = function () {
    if (selectedReport === "User" || reportToBeShown === "User") {
      selectedReport = null;
      hideReport();
    } else {
      selectedReport = "User";
      sessionStorage.removeItem("report-selected-options");
      sessionStorage.removeItem("report-default-selected-options");
      closeAllDropDowns();
      hideReport();
    }
    clearDefaultOptions("User");
  };

  const onEnergyReportButtonClick = function () {
    if (selectedReport === "Energy" || reportToBeShown === "Energy") {
      selectedReport = null;
      hideReport();
    } else {
      selectedReport = "Energy";
      sessionStorage.removeItem("report-selected-options");
      sessionStorage.removeItem("report-default-selected-options");
      closeAllDropDowns();
      hideReport();
    }
    clearDefaultOptions("Energy");
  };

  const onKSEBBillReportButtonClick = function () {
    if (selectedReport === "KSEB Bill" || reportToBeShown === "KSEB Bill") {
      selectedReport = null;
      hideReport();
    } else {
      selectedReport = "KSEB Bill";
      sessionStorage.removeItem("report-selected-options");
      sessionStorage.removeItem("report-default-selected-options");
      closeAllDropDowns();
      hideReport();
    }
    clearDefaultOptions("KSEB Bill");
  };

  const hideReport = function () {
    reportToBeShown = null;
    clearDefaultOptions();
    sessionStorage.removeItem("report-to-be-shown");
    sessionStorage.removeItem("report-default-selected-options");
    sessionStorage.removeItem("report-selected-options");
    sessionStorage.removeItem("report-default-selected-options");
  };

  const onPopUpSubmitClick = function (start, end) {
    reportSelectNodeId = $selectedNode?.id || "";
    reportSelectedNodeName = $selectedNode?.name || "";
    reportSelectNode = { ...$selectedNode };

    sessionStorage.setItem("report-selected-node-id", reportSelectNodeId);
    sessionStorage.setItem("report-selected-node-name", reportSelectedNodeName);
    sessionStorage.setItem(
      "report-selected-node",
      JSON.stringify(reportSelectNode)
    );

    reportToBeShown = selectedReport;
    reportStartTime = start || 0;
    reportEndTime = end || 0;

    showPopUp = false;
    popUpEndTime = null;
    popUpStartTime = null;

    sessionStorage.setItem("report-to-be-shown", reportToBeShown);
    sessionStorage.setItem("report-selected-start-time", reportStartTime);
    sessionStorage.setItem("report-selected-end-time", reportEndTime);
  };

  const onPopUpCancelClick = function () {
    showPopUp = false;
    popUpEndTime = null;
    popUpStartTime = null;
  };

  const onSubmitButtonClick = function () {
    closeAllDropDowns();
    switch (selectedReport) {
      case "Hourly Electrical":
      case "Feeder":
      case "Raw Report":
        popUpStartTime = $selectedDate?.dayDefinedStartDateInEpoch || 0;
        popUpEndTime =
          $selectedDate?.dayDefinedEndDateInEpochNotGreaterThanNow || 0;
        if (popUpEndTime - popUpStartTime > 86400000 * 3 + 999) {
          popUpEndTime = popUpStartTime + 86400000 * 3 + 999;
        }
        showPopUp = true;
        break;
      case "Events":
      case "Summary":
        popUpStartTime = $selectedDate?.dayDefinedStartDate || 0;
        popUpEndTime =
          $selectedDate?.dayDefinedEndDateInEpochNotGreaterThanNow || 0;
        showPopUp = true;
        break;
      case "Electrical":
      case "Cost":
      case "Variance":
      case "Energy":
        reportSelectNodeId = $selectedNode?.id || "";
        reportSelectedNodeName = $selectedNode?.name || "";
        reportSelectNode = { ...$selectedNode };
        reportStartTime = $selectedDate?.dayDefinedStartDateInSeconds || 0;
        reportEndTime = $selectedDate?.dayDefinedEndDateInSeconds || 0;
        if (
          $selectedDate?.dayDefinedEndDateInSeconds >
          epoch13to10(addOffSet(moment(new Date()).startOf("day").valueOf()))
        ) {
          reportEndTime = parseInt(
            moment(new Date($selectedDate?.dayDefinedEndDateInEpoch))
              .subtract(1, "day")
              .valueOf() / 1000
          );
          if (reportEndTime === reportStartTime) {
            Toastify({
              text: `Report for today will be geneated at the end of the day, please try again later`,
              style: {
                background: "orange",
              },
              duration: 3000,
              close: false,
            }).showToast();
            hideReport();
            return;
          } else {
            Toastify({
              text: `This report does not support today, report generated will be till yesterday`,
              style: {
                background: "orange",
              },
              duration: 3000,
              close: false,
            }).showToast();
          }
        }

        sessionStorage.setItem("report-selected-node-id", reportSelectNodeId);
        sessionStorage.setItem(
          "report-selected-node-name",
          reportSelectedNodeName
        );
        sessionStorage.setItem(
          "report-selected-node",
          JSON.stringify(reportSelectNode)
        );
        sessionStorage.setItem("report-selected-start-time", reportStartTime);
        sessionStorage.setItem("report-selected-end-time", reportEndTime);

        reportToBeShown = selectedReport;
        sessionStorage.setItem("report-to-be-shown", reportToBeShown);
        break;
      case "User":
      case "KSEB Bill":
        reportSelectNodeId = $selectedNode?.id || "";
        reportSelectedNodeName = $selectedNode?.name || "";
        reportSelectNode = { ...$selectedNode };
        sessionStorage.setItem("report-selected-node-id", reportSelectNodeId);
        sessionStorage.setItem(
          "report-selected-node-name",
          reportSelectedNodeName
        );
        sessionStorage.setItem(
          "report-selected-node",
          JSON.stringify(reportSelectNode)
        );
        reportToBeShown = selectedReport;
        sessionStorage.setItem("report-to-be-shown", reportToBeShown);
        break;
      default:
        break;
    }
  };

  const checkIfNotGreaterThan3DaysSelected = function (start, end) {
    let error = {
      status: false,
    };
    if (end * 1000 - start * 1000 > 86400000 * 3 + 999) {
      error = {
        status: true,
        message: "From and To Time can have only atmost 3 days difference",
      };
    }
    return error;
  };

  const getPermission = function (currentScreen) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = $availableScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };

  const setDropDownOptions = function (nodeType) {
    if (
      nodeType &&
      costReportConfigurations?.dropDownOptions &&
      varianceReportConfigurations?.dropDownOptions &&
      energyReportConfigurations?.columnOptions &&
      energyReportConfigurations?.reportSummaryConfig
    ) {
      if (nodeType !== $parentNodeType) {
        costOptions = costReportConfigurations.dropDownOptions.filter(
          (item) => item.columnTitle !== "Briquette Cost"
        );
        varianceOptions = varianceReportConfigurations.dropDownOptions.filter(
          (item) => item.columnTitle !== "Briquette"
        );
      } else {
        costOptions = [...costReportConfigurations.dropDownOptions];
        varianceOptions = [...varianceReportConfigurations.dropDownOptions];
      }
    } else {
      costOptions = [...costReportConfigurations.dropDownOptions];
      varianceOptions = [...varianceReportConfigurations.dropDownOptions];
    }
  };

  const onBackButtonClick = function () {
    hideReport();
    selectedReport = null;
  };

  $: {
    setDropDownOptions(selectedNodeType);
  }

  $: {
    if (selectedNodeType) {
      updateOptions();
    }
  }

  $: disableSubmitButton =
    !$selectedNode ||
    (selectedReport === "Summary" && $selectedNode?.type !== $parentNodeType) ||
    ((selectedReport === "Cost" || selectedReport === "Variance") &&
      $selectedNode?.type !== $parentNodeType &&
      $selectedNode?.type !== $plantNodeType) ||
    ((selectedReport === "KSEB Bill" || selectedReport === "User") &&
      $selectedNode?.type !== $parentNodeType) ||
    (selectedReport === "Feeder" && $selectedNode?.type !== $childNodeType) ||
    (selectedReport === "Energy" &&
      $selectedNode?.type !== $subPlantNodeType &&
      $selectedNode?.type !== $childNodeType) ||
    $isReportGenerated ||
    !selectedReport;

  $: screenPermission = getPermission($currentPage);
</script>

<div class="del-page-container del-report-page-container">
  {#if !reportToBeShown}
    <div class="del-report-title">Select Report</div>
    <div class="del-reports-main-container">
      <div class="del-report-items-container">
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={electricalReportConfigurations.buttonLabel}
            showSubmitButton={electricalReportConfigurations.showSubmitButton}
            options={electricalReportConfigurations.dropDownOptions}
            onSelectionUpdated={onElectricalReportOptionsChange}
            bind:isDropDownOpen={isElectricalReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Electrical");
                isEventReportDropDownOpen = false;
                isHourlyElectricalReportDropDownOpen = false;
                isCostReportDropDownOpen = false;
                isVarianceReportDropDownOpen = false;
                isFeederReportDropDownOpen = false;
                isRawReportDropDownOpen = false;
              }
            }}
            defaultSelection={electricalDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Electrical" ||
                reportToBeShown === "Electrical")}
            isDisabled={$isReportGenerated}
          />
        </div>
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={costReportConfigurations.buttonLabel}
            showSubmitButton={costReportConfigurations.showSubmitButton}
            options={costOptions}
            onSelectionUpdated={onCostReportOptionsChange}
            bind:isDropDownOpen={isCostReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Cost");
                isElectricalReportDropDownOpen = false;
                isEventReportDropDownOpen = false;
                isHourlyElectricalReportDropDownOpen = false;
                isFeederReportDropDownOpen = false;
                isVarianceReportDropDownOpen = false;
                isRawReportDropDownOpen = false;
              }
            }}
            defaultSelection={costDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Cost" || reportToBeShown === "Cost")}
            isDisabled={($selectedNode?.type !== $parentNodeType &&
              $selectedNode?.type !== $plantNodeType) ||
              $isReportGenerated}
          />
        </div>
        <!-- <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={varianceReportConfigurations.buttonLabel}
            showSubmitButton={varianceReportConfigurations.showSubmitButton}
            options={varianceOptions}
            onSelectionUpdated={onVarianceReportOptionsChange}
            bind:isDropDownOpen={isVarianceReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Variance");
                isElectricalReportDropDownOpen = false;
                isCostReportDropDownOpen = false;
                isEventReportDropDownOpen = false;
                isHourlyElectricalReportDropDownOpen = false;
                isFeederReportDropDownOpen = false;
              }
            }}
            defaultSelection={varianceDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Variance" || reportToBeShown === "Variance")}
            isDisabled={($selectedNode?.type !== $parentNodeType &&
              $selectedNode?.type !== $plantNodeType) ||
              $isReportGenerated}
          />
        </div> -->
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={rawReportConfigurations.buttonLabel}
            showSubmitButton={rawReportConfigurations.showSubmitButton}
            options={rawReportConfigurations.dropDownOptions}
            onSelectionUpdated={onRawReportOptionsChange}
            bind:isDropDownOpen={isRawReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Raw Report");
                isElectricalReportDropDownOpen = false;
                isEventReportDropDownOpen = false;
                isHourlyElectricalReportDropDownOpen = false;
                isFeederReportDropDownOpen = false;
                isVarianceReportDropDownOpen = false;
                isCostReportDropDownOpen = false;
              }
            }}
            defaultSelection={rawReportDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Raw Report" ||
                reportToBeShown === "Raw Report")}
            isDisabled={$selectedNode?.type !== $parentNodeType ||
              $isReportGenerated}
          />
        </div>
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={feederReportConfigurations.buttonLabel}
            showSubmitButton={feederReportConfigurations.showSubmitButton}
            options={feederReportConfigurations?.dropDownOptions}
            onSelectionUpdated={onFeederReportOptionsChange}
            bind:isDropDownOpen={isFeederReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Feeder");
                isElectricalReportDropDownOpen = false;
                isCostReportDropDownOpen = false;
                isEventReportDropDownOpen = false;
                isHourlyElectricalReportDropDownOpen = false;
                isVarianceReportDropDownOpen = false;
                isRawReportDropDownOpen = false;
              }
            }}
            defaultSelection={feederDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Feeder" || reportToBeShown === "Feeder")}
            isDisabled={$selectedNode?.type !== $childNodeType ||
              $isReportGenerated}
          />
        </div>
        <!-- <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={electricalReportConfigurations.hourlyButtonLabel}
            showSubmitButton={electricalReportConfigurations.showSubmitButton}
            options={electricalReportConfigurations.dropDownOptions}
            onSelectionUpdated={(selected) => {
              onElectricalReportOptionsChange(selected, "Hourly Electrical");
            }}
            bind:isDropDownOpen={isHourlyElectricalReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Hourly Electrical");
                isCostReportDropDownOpen = false;
                isEventReportDropDownOpen = false;
                isElectricalReportDropDownOpen = false;
                isVarianceReportDropDownOpen = false;
                isFeederReportDropDownOpen = false;
              }
            }}
            defaultSelection={hourlyElectricalDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Hourly Electrical" ||
                reportToBeShown === "Hourly Electrical")}
            isDisabled={$isReportGenerated}
          />
        </div> -->
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={eventReportConfigurations.buttonLabel}
            showSubmitButton={eventReportConfigurations.showSubmitButton}
            options={eventReportConfigurations.options}
            onSelectionUpdated={onEventReportOptionsChange}
            bind:isDropDownOpen={isEventReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Events");
                isCostReportDropDownOpen = false;
                isElectricalReportDropDownOpen = false;
                isHourlyElectricalReportDropDownOpen = false;
                isVarianceReportDropDownOpen = false;
                isFeederReportDropDownOpen = false;
                isRawReportDropDownOpen = false;
              }
            }}
            defaultSelection={eventDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Events" || reportToBeShown === "Events")}
            isDisabled={$isReportGenerated}
          />
        </div>
        <div class="del-report-item">
          <button
            class={`del-user-report-button ${
              selectedReport === "Energy" || reportToBeShown === "Energy"
                ? "selected"
                : ""
            }`}
            on:click={onEnergyReportButtonClick}
            disabled={!(selectedNodeType && $subPlantNodeType) ||
              ($childNodeType !== selectedNodeType &&
                selectedNodeType !== $subPlantNodeType) ||
              $isReportGenerated}
            >{energyReportConfigurations.buttonLabel ||
              "Daily Energy Report"}</button
          >
        </div>
        <div class="del-report-item">
          <DelDropDownCheckBox
            buttonLabel={summaryReportConfigurations?.buttonLabel}
            showSubmitButton={summaryReportConfigurations?.showSubmitButton}
            options={summaryReportConfigurations?.dropDownOptions}
            onSelectionUpdated={onSummaryReportOptionsChange}
            bind:isDropDownOpen={isSummaryReportDropDownOpen}
            onShowDropDownChange={(status) => {
              if (status) {
                hideReport();
                clearDefaultOptions("Summary");
                isCostReportDropDownOpen = false;
                isElectricalReportDropDownOpen = false;
                isHourlyElectricalReportDropDownOpen = false;
                isVarianceReportDropDownOpen = false;
                isFeederReportDropDownOpen = false;
                isEventReportDropDownOpen = false;
                isRawReportDropDownOpen = false;
              }
            }}
            defaultSelection={summaryDefaultOptions}
            isSelected={selectedOptions?.length &&
              (selectedReport === "Summary" || reportToBeShown === "Summary")}
            isDisabled={!(selectedNodeType && $parentNodeType) ||
              $parentNodeType !== selectedNodeType ||
              $isReportGenerated}
          />
        </div>
        <div class="del-report-item">
          <button
            class={`del-user-report-button ${
              selectedReport === "User" || reportToBeShown === "User"
                ? "selected"
                : ""
            }`}
            on:click={onUserReportButtonClick}
            disabled={!(selectedNodeType && $parentNodeType) ||
              $parentNodeType !== selectedNodeType ||
              $isReportGenerated}
            >{userReportConfigurations.buttonLabel || "User Report"}</button
          >
        </div>
        <div class="del-report-item">
          <button
            class={`del-user-report-button ${
              selectedReport === "KSEB Bill" || reportToBeShown === "KSEB Bill"
                ? "selected"
                : ""
            }`}
            on:click={onKSEBBillReportButtonClick}
            disabled={!(selectedNodeType && $parentNodeType) ||
              $parentNodeType !== selectedNodeType ||
              $isReportGenerated}
            >{ksebBillConfigurations?.buttonLabel ||
              "KSEB Bill Cost Components"}</button
          >
        </div>
        <div class="del-reports-submit-button-container">
          <button
            class="del-reports-submit-button"
            on:click={onSubmitButtonClick}
            disabled={(selectedReport !== "User" &&
              selectedReport !== "KSEB Bill" &&
              selectedReport !== "Energy" &&
              !selectedOptions?.length) ||
              disableSubmitButton}>Generate Report</button
          >
        </div>
      </div>
    </div>
  {/if}

  {#if showPopUp}
    <div class="overlay-container"></div>
    <DelTimePickerPopUp
      defaultStartTime={popUpStartTime}
      defaultEndTime={popUpEndTime}
      onSubmit={onPopUpSubmitClick}
      onCancel={onPopUpCancelClick}
      submitButtonLabel={"Generate Report"}
      showLatestTimeAsEndTimeForToday={true}
      extraValidations={selectedReport === "Electrical" ||
      selectedReport === "Hourly Electrical" ||
      selectedReport === "Feeder" ||
      selectedReport === "Raw Report"
        ? checkIfNotGreaterThan3DaysSelected
        : null}
    />
  {/if}
  {#if reportToBeShown === "Events"}
    <div class="del-report-container">
      <DelEventReport
        baseURL={getDomainURL()}
        nodeId={reportSelectNodeId || null}
        nodeName={reportSelectedNodeName || ""}
        startTime={reportStartTime}
        endTime={reportEndTime}
        currentScreen={"Reports"}
        writePermission={screenPermission === "RW"}
        possibleColumns={eventReportConfigurations.columnsToShow}
        columnDetails={eventReportConfigurations.columnDetails}
        showBackButton={true}
        {onBackButtonClick}
        possibleAlertCategories={selectedOptions}
      />
    </div>
  {:else if reportToBeShown === "User"}
    <div class="del-report-container">
      <DelUserReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        currentScreen={"Reports"}
        columnsOrder={userReportConfigurations.columnsToShow}
        columnDetails={userReportConfigurations.columnDetails}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
      />
    </div>
  {:else if reportToBeShown === "KSEB Bill"}
    <div class="del-report-container">
      <DelKSEBBillReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        reportTitle={ksebBillConfigurations?.reportTitle}
        deviceCategories={ksebBillConfigurations?.deviceCategories}
        derivedParameters={ksebBillConfigurations?.derivedParameters}
        constantParameters={ksebBillConfigurations?.constantParameters}
        realTimeParameters={ksebBillRealTimeParameters}
      ></DelKSEBBillReport>
    </div>
  {:else if reportToBeShown === "Electrical"}
    <div class="del-report-container">
      <DelElectricalReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        reportTitle={electricalReportConfigurations.buttonLabel}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
        columnDetails={selectedOptions}
        summaryDetails={selectedSummaryOptions}
        startTime={reportStartTime}
        endTime={reportEndTime}
        groupBy={electricalReportConfigurations.groupBy}
        dateTimeFormat={electricalReportConfigurations.dateTimeFormat}
        dateColumnFormat={electricalReportConfigurations?.electricalColumnDateFormat}
      />
    </div>
  {:else if reportToBeShown === "Hourly Electrical"}
    <div class="del-report-container">
      <DelElectricalReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        reportTitle={electricalReportConfigurations.hourlyButtonLabel}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
        columnDetails={selectedOptions}
        summaryDetails={selectedSummaryOptions}
        startTime={reportStartTime}
        endTime={reportEndTime}
        groupBy={electricalReportConfigurations.groupByForHourly}
        dateTimeFormat={electricalReportConfigurations.dateTimeFormat}
      />
    </div>
  {:else if reportToBeShown === "Cost"}
    <div class="del-report-container">
      <DelElectricalReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        reportTitle={costReportConfigurations.buttonLabel}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
        columnDetails={selectedOptions}
        summaryDetails={selectedSummaryOptions}
        startTime={reportStartTime}
        endTime={reportEndTime}
        groupBy={costReportConfigurations.groupBy}
        includeAllDays={costReportConfigurations.includeAllDays}
        dateTimeFormat={costReportConfigurations.dateTimeFormat}
        dateColumnFormat={costReportConfigurations?.columnDateFormat}
      />
    </div>
  {:else if reportToBeShown === "Variance"}
    <div class="del-report-container">
      <DelElectricalReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        reportTitle={varianceReportConfigurations?.reportTitle}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
        columnDetails={selectedOptions}
        summaryDetails={selectedSummaryOptions}
        startTime={reportStartTime}
        endTime={reportEndTime}
        groupBy={varianceReportConfigurations?.groupBy}
        includeAllDays={varianceReportConfigurations?.includeAllDays}
        dateTimeFormat={varianceReportConfigurations?.dateTimeFormat}
        showColors={true}
        dateColumnFormat={varianceReportConfigurations?.columnDateFormat}
      />
    </div>
  {:else if reportToBeShown === "Raw Report"}
    <div class="del-report-container">
      <DelElectricalReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        reportTitle={rawReportConfigurations.reportTitle}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
        columnDetails={selectedOptions}
        summaryDetails={selectedSummaryOptions}
        startTime={reportStartTime}
        endTime={reportEndTime}
        groupBy={rawReportConfigurations.groupBy}
        dateTimeFormat={rawReportConfigurations.dateTimeFormat}
        dateColumnFormat={rawReportConfigurations.columnDateFormat}
      />
    </div>
  {:else if reportToBeShown === "Feeder"}
    <div class="del-report-container">
      <DelElectricalReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        reportTitle={feederReportConfigurations?.reportTitle}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
        columnDetails={selectedOptions}
        summaryDetails={selectedSummaryOptions}
        startTime={reportStartTime}
        endTime={reportEndTime}
        groupBy={feederReportConfigurations?.groupBy}
        dateTimeFormat={feederReportConfigurations?.dateTimeFormat}
        showLoadsTable={true}
        dateColumnFormat={feederReportConfigurations?.columnDateFormat}
      />
    </div>
  {:else if reportToBeShown === "Energy"}
    <div class="del-report-container">
      <DelElectricalReport
        baseURL={getDomainURL()}
        nodeDetails={reportSelectNode}
        reportTitle={energyReportConfigurations?.reportTitle}
        currentScreen={"Reports"}
        showBackButton={true}
        {onBackButtonClick}
        showSummary={true}
        columnDetails={energyReportConfigurations?.columnOptions}
        summaryDetails={energyReportConfigurations?.reportSummaryConfig}
        startTime={reportStartTime}
        endTime={reportEndTime}
        groupBy={energyReportConfigurations?.groupBy}
        dateTimeFormat={energyReportConfigurations?.dateTimeFormat}
        includeAllDays={energyReportConfigurations?.includeAllDays}
        dateColumnFormat={energyReportConfigurations?.columnDateFormat}
        showOnlyColumnsWithParameters={true}
      />
    </div>
  {:else if reportToBeShown === "Summary"}
    {#if showFeederDescriptions}
      <div>
        <DelCardsList
          dataList={connectedEquipmentDetails}
          title={"Main Panel - Feeder Descriptions"}
          onBackButtonClick={() => {
            showFeederDescriptions = false;
            sessionStorage.setItem(
              "show-feeder-descriptions",
              JSON.stringify(showFeederDescriptions)
            );
          }}
        ></DelCardsList>
      </div>
    {:else}
      <div class="del-connected-equipment-button-container">
        <button
          class="del-connected-equipment-button"
          on:click={() => {
            showFeederDescriptions = true;
            sessionStorage.setItem(
              "show-feeder-descriptions",
              JSON.stringify(showFeederDescriptions)
            );
          }}>{`View connected equipment details >`}</button
        >
      </div>
      <div class="del-report-container">
        <DelSummaryReport
          baseURL={getDomainURL()}
          nodeDetails={reportSelectNode}
          reportTitle={summaryReportConfigurations?.reportTitle}
          currentScreen={"Reports"}
          showBackButton={true}
          {onBackButtonClick}
          showSummary={true}
          columnDetails={selectedOptions}
          summaryDetails={summaryReportConfigurations?.summaryOptions}
          startTime={reportStartTime}
          endTime={reportEndTime}
          parentLevelType={$subPlantNodeType}
          showChildNodeSummary={summaryReportConfigurations?.showChildNodeSummary}
          groupBy={summaryReportConfigurations?.groupBy}
          excludeStartTime={summaryReportConfigurations?.excludeStartTime}
          endTimeOffSet={$endTimeOffSet}
        />
      </div>
    {/if}
  {/if}
</div>

<style>
  .del-report-page-container {
    padding: 24px 28px;
  }
  .del-report-title {
    padding-bottom: 18px;
    text-align: left;
    font: normal normal 600 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }
  .del-reports-main-container,
  .del-report-items-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 22px;
    flex-wrap: wrap;
  }

  .del-report-items-container .del-report-item {
    flex: 0 0 auto;
  }

  .del-reports-submit-button-container {
    margin-left: auto;
  }

  .del-reports-submit-button,
  .del-user-report-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-reports-submit-button:enabled:active,
  .del-user-report-button:enabled:active {
    border: 1px solid #79a4e9;
  }

  .del-reports-submit-button:enabled:hover,
  .del-user-report-button:enabled:hover {
    border-color: #79a4e9;
  }

  .del-reports-submit-button:disabled,
  .del-reports-submit-button:disabled:hover,
  .del-user-report-button:disabled,
  .del-user-report-button:disabled:hover {
    cursor: not-allowed;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eee;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    letter-spacing: 0px;
    color: #eee;
    /* opacity: 0.8; */
  }

  .del-user-report-button.selected {
    border: none;
    background-color: #79a4e9;
    color: white;
  }

  .del-connected-equipment-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .del-connected-equipment-button {
    background-color: transparent;
    cursor: pointer;
    color: rgb(33, 65, 155);
    padding: 0;
    margin: 0;
    font: normal normal bold 14px/19px Roboto;
    text-decoration: underline;
    border: none;
  }

  .overlay-container {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }
</style>
