<script>
  import { onMount, onDestroy } from "svelte";
  import ApexCharts from "apexcharts";
  import Loader from "./Loader.svelte";

  export let baseURL = "";
  export let graphConfig = [];
  export let nodeDetails;
  export let startTime = 0;
  export let endTime = 0;
  export let currentScreen = "Dashboard";
  export let cutOut = "45%";
  export let legendPosition = "bottom";
  export let legendAlignment = "center";
  export let showLegend = true;
  export let chartHeight = "200px";
  export let labelOffSet = 40;

  let paramID = [];
  let data = [];
  let parameterIdNameMap = {};
  let chartElement = null;
  let chart = null;
  let apiExecutedOnce = false;
  let isNoData = false;
  let isAPIAwaiting = false;

  $: {
    if (nodeDetails && graphConfig && startTime && endTime && baseURL) {
      parseParametersFromConfig();
    }
  }

  let payload = {
    parameters: paramID,
    startTime: startTime,
    endTime: endTime,
  };

  async function fetchData() {
    try {
      const url = `${baseURL}/dpe/api/evaluator`;
      const companyId = localStorage.getItem("companyId");
      const appId = sessionStorage.getItem("appId");
      const access_token = "Bearer " + localStorage.getItem("access_token");
      const headers = {
        "Content-Type": "application/json",
        companyid: companyId,
        applicationid: appId,
        Authorization: access_token,
        "access-origin": `${currentScreen}/R`,
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      };
      if (!(payload?.parameters?.length && startTime && endTime && baseURL)) {
        isNoData = true;
        return;
      }
      isAPIAwaiting = true;
      const response = await fetch(url, options);
      isAPIAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      data = await response.json();

      drawDonut(data);
    } catch (error) {
      console.error(error);
    }
  }

  onMount(() => {
    drawDonut(data);
  });

  onDestroy(() => {
    if (chart) chart.destroy();
  });

  function convertTo2Points(num) {
    if (isNaN(parseFloat(num))) {
      return null;
    } else {
      return parseFloat(num).toFixed(2);
    }
  }

  function parameterIdResolver(dc, pc) {
    if (nodeDetails) {
      if (nodeDetails.parameters) {
        let id = nodeDetails.parameters.find((item) => {
          if (item.DeviceCategory === dc && item.ParameterCategory === pc) {
            return true;
          }
        });
        if (id) return id.ParameterID;
      }
      if (nodeDetails.derivedparameters) {
        let id = nodeDetails.derivedparameters.find((item) => {
          if (item.DeviceCategory === dc && item.ParameterCategory === pc) {
            return true;
          }
        });

        if (id) return id.DerivedParameterId;
      }
    }
  }

  function parseParametersFromConfig() {
    let parameters = [];
    graphConfig.forEach((item) => {
      let resolvedParameterId = parameterIdResolver(
        item.DeviceCategory,
        item.ParameterCategory
      );
      parameterIdNameMap[resolvedParameterId] = item.Name;
      parameters.push(resolvedParameterId);
    });
    payload.startTime = startTime;
    payload.endTime = endTime;
    payload.parameters = parameters;
    fetchData();
  }

  function drawDonut(data) {
    let Names = graphConfig.map((item) => {
      return item.Name;
    });
    let Colors = graphConfig.map((item) => {
      return item.Color;
    });
    let percData = [];
    Names.forEach((item) => {
      data.forEach((item2) => {
        if (parameterIdNameMap[item2.Id] === item) {
          let temp = parseFloat(convertTo2Points(item2.Result));
          percData.push(temp);
        }
      });
    });
    if (percData.length === 0 || percData.every(isNaN)) {
      isNoData = true;
    } else {
      isNoData = false;
    }

    percData = percData.map((item) => (isNaN(item) ? 0 : item));
    let options = {
      plotOptions: {
        pie: {
          customScale: 0.8,
          dataLabels: {
            offset: labelOffSet,
          },
          donut: {
            size: cutOut,
            percentageInnerCutout: 90,
          },
        },
      },
      labels: Names,
      colors: Colors,
      series: percData,
      dataLabels: {
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
          colors: ["#202020"],
        },
        dropShadow: {
          enabled: false,
        },
      },
      chart: {
        type: "donut",
        height: chartHeight,
      },
      legend: {
        show: showLegend,
        horizontalAlign: legendAlignment,
        position: legendPosition,
      },
    };

    if (!chart) {
      chart = new ApexCharts(chartElement, options);
      chart.render();
      apiExecutedOnce = true;
    } else {
      chart.updateOptions(options);
    }
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-donut-chart-container">
  {#if isAPIAwaiting}
    <Loader />
  {/if}
  <div
    style={`${chartHeight ? `height:${chartHeight}` : ""}`}
    bind:this={chartElement}
  />
  {#if isNoData && !isAPIAwaiting}
    <div class="no-data">No data available</div>
  {/if}
</div>

<style>
  .del-donut-chart-container {
    width: 100%;
    position: relative;
  }

  .no-data {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    font: italic normal normal 16px/21px Roboto;
  }

  :global(.apexcharts-legend-marker) {
    border-radius: 2px !important;
  }
</style>
