<script>
  import { onMount } from "svelte";
  import moment from "moment";
  import Chart from "chart.js/auto";
  import Loader from "./Loader.svelte";

  export let baseURL = "";
  export let nodeDetails = null;
  export let config = null;
  export let currentScreen = "Dashboard";
  export let currentStartTime = "";
  export let currentEndTime = "";
  export let previousStartTime = "";
  export let previousEndTime = "";
  export let operation = "sum";
  export let groupBy = "month";
  export let graphConfig = {
    currGraphColor: "#1746A2",
    prevGraphColor: "#A4B7DE",
    targGraphColor: "#EB1D36",
  };
  export let title = "Energy Consumption Cumulative Graph";
  export let xAxisTitle = "time";
  export let yAxisTitle = "kwh";
  export let chartHeight = "500px";
  export let legendPosition = "top";
  export let legendAlignment = "end";
  export let chartType = "line";
  export let dateTimeFormat = "MMM";

  let chartElement = null;
  let chart = null;
  let parameterIdNameMap = {};
  let currData = {};
  let prevData = {};
  let currReadings = [];
  let prevReadings = [];
  let currentXAxisData = [];
  let previousXAxisData = [];
  let xAxisData = [];
  let currParameters = [];
  let prevParameters = [];
  let previousDataAwaiting = false;
  let currentDataAwaiting = false;
  let showNoDataMessage = false;

  $: {
    if (nodeDetails && config) {
      clearChartData();
      filterParameterDetails();
    }
  }

  $: {
    if (baseURL && operation && groupBy && currentScreen) {
      clearChartData();
      handleCurrentDataAPIInvokation();
      handlePreviousDataAPIInvokation();
    }
  }

  $: {
    if (currentStartTime && currentEndTime) {
      currReadings = [];
      currentXAxisData = [];
      updateChart();
      handleCurrentDataAPIInvokation();
    }
  }

  $: {
    if (previousStartTime && previousEndTime) {
      prevReadings = [];
      previousXAxisData = [];
      updateChart();
      handlePreviousDataAPIInvokation();
    }
  }

  const clearChartData = function () {
    prevReadings = [];
    currReadings = [];
    currentXAxisData = [];
    previousXAxisData = [];
    xAxisData = [];

    if (chart) {
      updateChartData();
    } else {
      drawChart();
    }
  };

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  async function fetchCurrentData() {
    try {
      if (!(baseURL && currentScreen)) return;

      const headers = getHeader();

      const url = `${baseURL}/parameters/telemetry/aggregation/derived/dailymultiple?includeAllDays=true`;

      const currPayload = {
        operation: operation,
        parameters: currParameters,
        starttime: currentStartTime,
        endtime: currentEndTime,
        groupby: groupBy,
      };

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(currPayload),
      };

      if (
        !(
          currPayload &&
          currPayload.operation &&
          currPayload.parameters?.length &&
          currPayload.starttime &&
          currPayload.endtime &&
          currPayload.groupby
        )
      )
        return;

      currentDataAwaiting = true;
      const response = await fetch(url, options);
      currentDataAwaiting = false;

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      currData = await response.json();
      xAxisData = [];
      currentXAxisData = [];
      let readings = {};
      currData.forEach((data) => {
        const formattedTimeStamp = formatXLabel(data.unixtime, dateTimeFormat);
        // xAxisData.push(formatXLabel(data.unixtime, dateTimeFormat));
        currentXAxisData.push(data.unixtime);
        if (data.unixtime <= moment().unix()) {
          data.parameters.forEach((item) => {
            readings[formattedTimeStamp] = item.reading;
          });
        }
      });
      currReadings = { ...readings };
      // currReadings = cumulateReadings(readings);
      updateChart();
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchPrevData() {
    try {
      if (!(baseURL && currentScreen)) return;

      const headers = getHeader();

      const url = `${baseURL}/parameters/telemetry/aggregation/derived/dailymultiple?includeAllDays=true`;

      const prevPayload = {
        operation: operation,
        parameters: prevParameters,
        starttime: previousStartTime,
        endtime: previousEndTime,
        groupby: groupBy,
      };

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(prevPayload),
      };

      if (
        !(
          prevPayload &&
          prevPayload.operation &&
          prevPayload.parameters?.length &&
          prevPayload.starttime &&
          prevPayload.endtime &&
          prevPayload.groupby
        )
      )
        return;

      previousDataAwaiting = true;
      const response = await fetch(url, options);
      previousDataAwaiting = false;

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      prevData = await response.json();
      let readings = {};
      previousXAxisData = [];
      prevData.forEach((data) => {
        previousXAxisData.push(data.unixtime);
        const formattedTimeStamp = formatXLabel(data.unixtime, dateTimeFormat);
        data.parameters.forEach((item) => {
          readings[formattedTimeStamp] = item.reading;
        });
      });
      prevReadings = { ...readings };
      // prevReadings = cumulateReadings(readings);
      updateChart();
    } catch (error) {
      console.error(error);
    }
  }

  const debounce = function (func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(func, timeout);
    };
  };

  const handleCurrentDataAPIInvokation = debounce(fetchCurrentData);
  const handlePreviousDataAPIInvokation = debounce(fetchPrevData);

  onMount(() => {
    drawChart();
  });

  function filterParameterDetails() {
    let basicParameters = [];
    let derivedParameters = [];
    if (nodeDetails && config) {
      config.forEach((r) => {
        if (r.ParameterType === "Basic") {
          let parameterFound = nodeDetails.parameters.find(
            (p) =>
              p.ParameterCategory === r.ParameterCategory &&
              p.DeviceCategory === r.DeviceCategory
          );
          if (parameterFound) {
            basicParameters.push(parameterFound.ParameterID);
            parameterIdNameMap[parameterFound.ParameterID] =
              parameterFound.ParameterFriendlyName;
          }
        } else if (
          r.ParameterType === "Derived" &&
          nodeDetails.derivedparameters
        ) {
          let parameterFound = nodeDetails.derivedparameters.find(
            (p) =>
              p.ParameterCategory === r.ParameterCategory &&
              p.DeviceCategory === r.DeviceCategory
          );
          if (parameterFound) {
            parameterIdNameMap[parameterFound.DerivedParameterId] =
              parameterFound.DerivedParameterName;
            derivedParameters.push(parameterFound.DerivedParameterId);
          }
        }
      });
    }
    currParameters = derivedParameters;
    prevParameters = derivedParameters;
    xAxisData = [];
    if (baseURL && operation && groupBy && currentScreen) {
      if (derivedParameters?.length) {
        if (currentStartTime && currentEndTime)
          handleCurrentDataAPIInvokation();
        if (previousStartTime && previousEndTime)
          handlePreviousDataAPIInvokation();
      }
    }
  }

  function cumulateReadings(inputArray) {
    let cumulatedSum = 0;

    return inputArray.map((value) => {
      cumulatedSum += value;
      return cumulatedSum;
    });
  }

  function formatXLabel(time, format) {
    if (time && format) {
      return moment(new Date(time * 1000)).format(format);
    }
    return null;
  }

  function drawChart() {
    if (chartElement) {
      const { currentData, previousData } = combineCurrentAndPreviousDataSets();
      const datasets = [
        {
          label: "Previous",
          data: previousData,
          borderColor: graphConfig.prevGraphColor || "#A4B7DE",
          pointRadius: 2,
          backgroundColor: graphConfig.prevGraphColor || "#A4B7DE",
        },
        {
          label: "Current",
          data: currentData,
          borderColor: graphConfig.currGraphColor || "#1746A2",
          pointRadius: 2,
          backgroundColor: graphConfig.currGraphColor || "#1746A2",
        },
      ];

      showNoDataMessage = !xAxisData?.length;

      chart = new Chart(chartElement, {
        type: chartType,
        data: {
          datasets: datasets,
          labels: xAxisData,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: legendPosition,
              align: legendAlignment,
              labels: {
                boxHeight: 10,
                boxWidth: 10,
                pointStyle: true,
                backgroundColor: "#FFC93C",
                borderColor: "#FFC93C",
              },
            },
            title: {
              text: title,
              display: false,
              position: "top",
              align: "start",
              font: {
                weight: "bold",
              },
              color: "#222222",
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: xAxisTitle || "",
                color: "#3C3C3C",
              },
              grid: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
                text: yAxisTitle || "",
                color: "#3C3C3C",
                align: "end",
              },
              grid: {
                display: false,
              },
            },
          },
          elements: {
            point: {
              // radius: 0.1,
              // pointStyle: 'circle',
              // pointRadius: 0.1,
            },
            line: {
              borderWidth: 2,
              borderJoinStyle: "miter",
              borderCapStyle: "butt",
            },
          },
        },
      });
    }
  }

  const updateChart = function () {
    if (chart) {
      updateChartData();
    } else {
      drawChart();
    }
  };

  const combineCurrentAndPreviousDataSets = function () {
    const set1 = new Set(
      currentXAxisData?.map((timestamp) =>
        formatXLabel(timestamp, dateTimeFormat)
      ) ?? []
    );
    const set2 = new Set(
      previousXAxisData?.map((timestamp) =>
        formatXLabel(timestamp, dateTimeFormat)
      ) ?? []
    );
    const unionSet = new Set([...set1, ...set2]);
    xAxisData = [...unionSet];
    let currentData = [];
    let previousData = [];
    if (currReadings && prevReadings) {
      for (const timestamp of xAxisData) {
        previousData.push(prevReadings[timestamp] ?? 0);
      }
      for (const timestamp of currentXAxisData) {
        if (timestamp <= moment().unix()) {
          currentData.push(
            currReadings[formatXLabel(timestamp, dateTimeFormat)] ?? 0
          );
        }
      }
    }
    currentData = cumulateReadings(currentData);
    previousData = cumulateReadings(previousData);
    return { currentData, previousData };
  };

  function updateChartData() {
    const { currentData, previousData } = combineCurrentAndPreviousDataSets();
    const datasets = [
      {
        label: "Previous",
        data: previousData,
        borderColor: graphConfig.prevGraphColor || "#A4B7DE",
        pointRadius: 2,
        backgroundColor: graphConfig.prevGraphColor || "#A4B7DE",
      },
      {
        label: "Current",
        data: currentData,
        borderColor: graphConfig.currGraphColor || "#1746A2",
        pointRadius: 2,
        backgroundColor: graphConfig.currGraphColor || "#1746A2",
      },
    ];

    showNoDataMessage = !xAxisData?.length;

    chart.data.labels = xAxisData;
    chart.data.datasets = datasets;
    chart.update();
  }
</script>

<div class="del-cumulative-chart-container">
  <div class="del-cumulative-chart-title">{title}</div>
  <div style={`${chartHeight ? `height:${chartHeight}` : ""}`}>
    <canvas id="del-cumulative-chart" bind:this={chartElement} />
  </div>
  {#if showNoDataMessage && !currentDataAwaiting && !previousDataAwaiting}
    <div class="del-cumulative-chart-no-data-message">No Data Available</div>
  {/if}
  {#if currentDataAwaiting || previousDataAwaiting}
    <Loader />
  {/if}
</div>

<style>
  .del-cumulative-chart-container {
    position: relative;
  }
  .del-cumulative-chart-title {
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }
  .del-cumulative-chart-no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
  }
</style>
