<script>
  import Loader from "./../Loader/Loader.svelte";

  export let currentScreen = "Dashboard";
  export let baseURL = null;
  export let nodeDetails = null;
  export let parameterCategories = null;
  export let deviceCategories = null;
  export let startTime = null;
  export let endTime = null;
  export let title = null;
  export let unit = null;

  let parameterIdsMap = {};
  let feedersMap = {};
  let isAPIAwaiting = false;
  let showNoDataMessage = false;
  const apiEndPoint = "dpe/api/evaluator";

  $: {
    if (parameterCategories && deviceCategories && nodeDetails) {
      getFeederDetails();
    }
  }

  $: {
    if (baseURL && currentScreen && startTime && endTime) {
      invokeGetFeedersDataAPI();
    }
  }

  const filterParameterId = function (
    nodeDetails,
    parameterCategories,
    deviceCategories,
    type = "any"
  ) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;

        case "Derived":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          break;

        case "any":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;
        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  };

  const getFeederDetails = function () {
    feedersMap = {};
    parameterIdsMap = {};
    showNoDataMessage = false;
    let _feedersMap = { ...feedersMap };
    if (nodeDetails?.children?.length) {
      for (const node of nodeDetails?.children) {
        if (node?.name) {
          _feedersMap[node.name] = null;
          const parameterId = filterParameterId(
            node,
            parameterCategories,
            deviceCategories
          );
          if (parameterId) {
            parameterIdsMap[parameterId] = node.name;
          }
        }
      }
    } else {
      showNoDataMessage = true;
    }
    feedersMap = { ..._feedersMap };
    if (Object.keys(parameterIdsMap)?.length) {
      invokeGetFeedersDataAPI();
    }
  };

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const invokeGetFeedersDataAPI = function () {
    const parameters = Object.keys(parameterIdsMap);
    if (
      startTime &&
      endTime &&
      baseURL &&
      currentScreen &&
      parameters?.length
    ) {
      const payload = {
        startTime,
        endTime,
        parameters,
      };
      isAPIAwaiting = true;
      invokeAPI(
        "POST",
        apiEndPoint,
        getFeedersAPIOnSuccess,
        getFeedersAPIOnError,
        payload,
        `${currentScreen}/R`
      );
    }
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  function getValueWithUnit(value, unit) {
    if (isNaN(value)) {
      return "No Data";
    }
    if (unit) {
      return `${convertToTwoDigits(value)} ${unit}`;
    } else {
      return convertToTwoDigits(value);
    }
  }

  const getFeedersAPIOnSuccess = function (data) {
    let _feedersMap = { ...feedersMap };
    isAPIAwaiting = false;
    for (const item of data) {
      if (parameterIdsMap[item?.Id]) {
        _feedersMap[parameterIdsMap[item?.Id]] = getValueWithUnit(
          item?.Result,
          unit
        );
      }
    }
    feedersMap = { ..._feedersMap };
  };
  const getFeedersAPIOnError = function () {
    isAPIAwaiting = false;
    showNoDataMessage = true;
  };
</script>

<div class="feeder-break-up-container">
  {#if isAPIAwaiting}
    <Loader />
  {/if}
  {#if title}
    <div class="feeder-break-up-title">{title}</div>
  {/if}
  <div class="feeder-table">
    {#each Object.keys(feedersMap) as node (node)}
      <div class="feeder-table-item">
        <div class="feeder-table-item-name">{node}</div>
        <div class="feeder-table-item-value">
          {feedersMap[node] ?? "No Data"}
        </div>
      </div>
    {/each}
    {#if showNoDataMessage}
      <div class="no-data-message">No Data Available</div>
    {/if}
  </div>
</div>

<style>
  .feeder-break-up-container {
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    opacity: 1;
    box-sizing: border-box;
  }

  .feeder-table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    border-top: 1px solid #ccd3e7;
    border-collapse: collapse;
    box-sizing: border-box;
    min-height: 50px;
  }

  .feeder-break-up-title {
    text-align: left;
    font: normal normal bold 14px Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    padding: 12px 22px;
  }

  .feeder-table-item {
    width: 25%;
    display: flex;
    align-content: space-between;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d3d3d3;
    padding: 10px 25px;
    position: relative;
    box-sizing: border-box;
  }
  .feeder-table-item-name {
    text-align: left;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    /*color: #4B72B9;*/
  }
  .feeder-table-item-value {
    text-align: right;
    font: normal normal 500 14px Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    align-self: center;
    position: absolute;
    right: 25px;
  }

  .no-data-message {
    position: absolute;
    top: 65%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font: normal normal 500 14px Roboto;
  }

  /*Responsive Styles*/

  @media (max-width: 1024px) {
    .feeder-table-item {
      width: 33.33%;
    }
  }

  @media (max-width: 875px) {
    .feeder-table-item {
      width: 50%;
    }
  }

  @media (max-width: 600px) {
    .feeder-table-item {
      width: 100%;
    }
  }
</style>
