import * as XLSX from "xlsx";
import moment from "moment/moment";

export function exportCSVExcel(
  fileType,
  reportTitle,
  date,
  derivedParameterReadings,
  presentRealTimeReadings,
  previousRealTimeReadings,
  constantReadings,
  roundOffCharge,
  netCharge
) {
  let formattedDate = moment(date).format("MMMM YYYY");
  let today = moment(new Date()).format("DD MMM YYYY");

  let workbook = XLSX.utils.book_new();

  let headerArray = [
    [reportTitle.toUpperCase()],
    [`KSEB MONTHLY REPORT FOR ${formattedDate.toUpperCase()}`],
    ["", "", "", "", "", "", `Bill Date: ${today}`],
    [""],
    ["", "EoU Plant", "", "Domestic Plant"],
    [
      "kWh",
      "Previous",
      "Present",
      "Previous",
      "Present",
      "Consumption",
      "Rate",
      "Amount",
    ],
    [
      "Zone 1",
      `${previousRealTimeReadings["eou-zone-1-energy"] ?? ""}`,
      `${presentRealTimeReadings["eou-zone-1-energy"] ?? ""}`,
      `${previousRealTimeReadings["domestic-zone-1-energy"] ?? ""}`,
      `${presentRealTimeReadings["domestic-zone-1-energy"] ?? ""}`,
      `${derivedParameterReadings["zone-1-consumption"] ?? ""}`,
      `${constantReadings["zone-1-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["zone-1-amount"] ?? ""}`,
    ],
    [
      "Zone 2",
      `${previousRealTimeReadings["eou-zone-2-energy"] ?? ""}`,
      `${presentRealTimeReadings["eou-zone-2-energy"] ?? ""}`,
      `${previousRealTimeReadings["domestic-zone-2-energy"] ?? ""}`,
      `${presentRealTimeReadings["domestic-zone-2-energy"] ?? ""}`,
      `${derivedParameterReadings["zone-2-consumption"] ?? ""}`,
      `${constantReadings["zone-2-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["zone-2-amount"] ?? ""}`,
    ],
    [
      "Zone 3",
      `${previousRealTimeReadings["eou-zone-3-energy"] ?? ""}`,
      `${presentRealTimeReadings["eou-zone-3-energy"] ?? ""}`,
      `${previousRealTimeReadings["domestic-zone-3-energy"] ?? ""}`,
      `${presentRealTimeReadings["domestic-zone-3-energy"] ?? ""}`,
      `${derivedParameterReadings["zone-3-consumption"] ?? ""}`,
      `${constantReadings["zone-3-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["zone-3-amount"] ?? ""}`,
    ],
    [
      "",
      "",
      "",
      "",
      "",
      `${derivedParameterReadings["total-consumption"] ?? ""}`,
      "",
      `${derivedParameterReadings["total-amount"] ?? ""}`,
    ],
    [""],
    ["Statutory Charges"],
    [""],
    [
      "Electricity Duty",
      "",
      "",
      "",
      "",
      "",
      `${constantReadings["electric-duty-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["electric-duty-amount"] ?? ""}`,
    ],
    [
      "Electricity Surcharge",
      "",
      "",
      "",
      "",
      "",
      `${constantReadings["electric-surcharge-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["electric-surcharge-amount"] ?? ""}`,
    ],
    [
      "Fuel Surcharge",
      "",
      "",
      "",
      "",
      "",
      `${constantReadings["fuel-surcharge-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["fuel-surcharge-amount"] ?? ""}`,
    ],
    [
      "Monthly Fuel Surcharge",
      "",
      "",
      "",
      "",
      "",
      `${constantReadings["monthly-fuel-surcharge-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["monthly-fuel-surcharge-amount"] ?? ""}`,
    ],
    [
      "Duty on Self generated energy",
      "",
      "",
      "",
      "",
      `${constantReadings["self-generated-duty-consumption"]?.Value ?? ""}`,
      `${constantReadings["self-generated-duty-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["self-generated-duty"] ?? ""}`,
    ],
    [
      "Belated payment charges",
      "",
      "",
      "",
      "",
      "",
      "",
      `${constantReadings["belated-payment-charges"]?.Value ?? ""}`,
    ],
    [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `${derivedParameterReadings["duty-charge-total-amount"] ?? ""}`,
    ],
    [
      "PF incentive",
      "",
      "",
      "",
      "",
      `${constantReadings["pf-incentive-consumption"]?.Value ?? ""}`,
      "",
      `${constantReadings["pf-incentive-amount"]?.Value ?? ""}`,
    ],
    [
      "Demand KVA Z1",
      "",
      "",
      "",
      "",
      `${derivedParameterReadings["demand-kva-zone1"] ?? ""}`,
      "",
      "",
    ],
    [
      "Demand KVA Z2",
      "",
      "",
      "",
      "",
      `${derivedParameterReadings["demand-kva-zone2"] ?? ""}`,
      "",
      "",
    ],
    [
      "Demand KVA Z3",
      "",
      "",
      "",
      "",
      `${derivedParameterReadings["demand-kva-zone3"] ?? ""}`,
      "",
      "",
    ],
    [
      "Demand KVA - Normal",
      "",
      "",
      "",
      "",
      `${derivedParameterReadings["demand-kva-normal"] ?? ""}`,
      `${constantReadings["demand-kva-normal-rate"]?.Value ?? ""}`,
      `${derivedParameterReadings["demand-kva-charge-normal"] ?? ""}`,
    ],
    [""],
    [
      "Total Charges",
      "",
      "",
      "",
      "",
      "",
      "",
      `${derivedParameterReadings["total-charges"] ?? ""}`,
    ],
    ["Plus/Minus round off", "", "", "", "", "", "", `${roundOffCharge ?? ""}`],
    ["Net payable Amount", "", "", "", "", "", "", `${netCharge ?? ""}`],
  ];

  let worksheet = XLSX.utils.aoa_to_sheet(headerArray);

  const merge = [
    {
      s: {
        r: 0,
        c: 0,
      },
      e: {
        r: 0,
        c: 7,
      },
    },
    {
      s: {
        r: 1,
        c: 0,
      },
      e: {
        r: 1,
        c: 7,
      },
    },
    {
      s: {
        r: 2,
        c: 0,
      },
      e: {
        r: 2,
        c: 5,
      },
    },
    {
      s: {
        r: 2,
        c: 6,
      },
      e: {
        r: 2,
        c: 7,
      },
    },
    {
      s: {
        r: 3,
        c: 0,
      },
      e: {
        r: 3,
        c: 7,
      },
    },
    {
      s: {
        r: 4,
        c: 1,
      },
      e: {
        r: 4,
        c: 2,
      },
    },
    {
      s: {
        r: 4,
        c: 3,
      },
      e: {
        r: 4,
        c: 4,
      },
    },
    {
      s: {
        r: 9,
        c: 0,
      },
      e: {
        r: 9,
        c: 4,
      },
    },
    {
      s: {
        r: 10,
        c: 0,
      },
      e: {
        r: 10,
        c: 7,
      },
    },
    {
      s: {
        r: 11,
        c: 0,
      },
      e: {
        r: 11,
        c: 7,
      },
    },
    {
      s: {
        r: 12,
        c: 0,
      },
      e: {
        r: 12,
        c: 7,
      },
    },
    {
      s: {
        r: 13,
        c: 0,
      },
      e: {
        r: 13,
        c: 5,
      },
    },
    {
      s: {
        r: 14,
        c: 0,
      },
      e: {
        r: 14,
        c: 5,
      },
    },
    {
      s: {
        r: 15,
        c: 0,
      },
      e: {
        r: 15,
        c: 5,
      },
    },
    {
      s: {
        r: 16,
        c: 0,
      },
      e: {
        r: 16,
        c: 5,
      },
    },
    {
      s: {
        r: 17,
        c: 0,
      },
      e: {
        r: 17,
        c: 4,
      },
    },
    {
      s: {
        r: 18,
        c: 0,
      },
      e: {
        r: 18,
        c: 6,
      },
    },
    {
      s: {
        r: 19,
        c: 0,
      },
      e: {
        r: 19,
        c: 6,
      },
    },
    {
      s: {
        r: 20,
        c: 0,
      },
      e: {
        r: 20,
        c: 4,
      },
    },
    {
      s: {
        r: 21,
        c: 0,
      },
      e: {
        r: 21,
        c: 4,
      },
    },
    {
      s: {
        r: 22,
        c: 0,
      },
      e: {
        r: 22,
        c: 4,
      },
    },
    {
      s: {
        r: 23,
        c: 0,
      },
      e: {
        r: 23,
        c: 4,
      },
    },
    {
      s: {
        r: 24,
        c: 0,
      },
      e: {
        r: 24,
        c: 4,
      },
    },
    {
      s: {
        r: 25,
        c: 0,
      },
      e: {
        r: 25,
        c: 7,
      },
    },
    {
      s: {
        r: 26,
        c: 0,
      },
      e: {
        r: 26,
        c: 6,
      },
    },
    {
      s: {
        r: 27,
        c: 0,
      },
      e: {
        r: 27,
        c: 6,
      },
    },
    {
      s: {
        r: 28,
        c: 0,
      },
      e: {
        r: 28,
        c: 6,
      },
    },
  ];

  worksheet["!merges"] = merge;

  const exportFileName = `Report of ${reportTitle} for ${formattedDate} (${today}).${fileType}`; //
  XLSX.utils.book_append_sheet(workbook, worksheet, reportTitle);
  return XLSX.writeFile(workbook, exportFileName);
}

export function generatePDF(
  reportTitle,
  date,
  derivedParameterReadings,
  presentRealTimeReadings,
  previousRealTimeReadings,
  constantReadings,
  roundOffCharge,
  netCharge
) {
  let formattedDate = moment(date).format("MMMM YYYY");
  let today = moment(new Date()).format("DD MMM YYYY");

  let htmlCode = `<style>
	.del-bill-report-table-container {
		margin: 0;
		padding: 0;
	}
	.del-bill-report-table {
		width: 100%;
		border-spacing: 0;
		position: relative;
		text-align: center;
		border-collapse: collapse;
	}
	.del-bill-report-table-header-row {
		background: #ececec;
    background-color: #ececec;
		opacity: 1;
		text-align: center;
		font: normal normal 500 12px/16px Roboto;
		letter-spacing: 0px;
		color: #202020;
		opacity: 1;
	}
	.del-bill-report-table th,
	.del-bill-report-table td {
		padding: 8px 0;
		cursor: default;
		text-align: left;
		padding-left: 5px;
	}
	.del-bill-report-table th {
		text-align: center;
	}
	th.first-row-heading {
		text-align: center;
	}
	tbody.top-table:before {
		content: '@';
		display: block;
		line-height: 10px;
		text-indent: -99999px;
	}
	.del-bill-report-table td,
	.del-bill-report-input {
		text-align: center;
		font: normal normal normal 12px/16px Roboto;
		letter-spacing: 0px;
		color: #202020;
		opacity: 1;
		border: 1px solid #b2b2b2;
	}
	.del-bill-report-table td.dashed-border {
		border-right: 1px dashed #b2b2b2;
	}
	.del-bill-report-table td.no-left-border {
		border-left: none;
	}
	.del-bill-report-table-row.summary-row {
		position: relative;
	}
	.del-bill-report-table-row.summary-row td {
		text-align: center;
		font: normal normal normal 10px/13px Roboto;
		letter-spacing: 0px;
		color: #202020;
		opacity: 1;
	}
	.del-bill-report-table-row.summary-row td:before,
	.del-bill-report-table-row.summary-row td:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: black;
	}
	.del-bill-report-table-row.summary-row td:before {
		top: 0;
		left: 0;
	}
	.del-bill-report-table-row.summary-row td:after {
		bottom: -1px;
		left: 0;
	}
	.del-bill-report-table-row.summary-row td:first-child {
		border-left: 1px solid black;
	}
	.del-bill-report-table-row.summary-row td:last-child {
		border-right: 1px solid black;
	}
	td .del-bill-report-input {
		height: inherit;
		width: 100%;
		padding: 0;
		border: none;
		outline: transparent;
	}
	td .del-bill-report-input:disabled {
		background: transparent;
		cursor: not-allowed;
	}
	.del-bill-report-inner-title {
		text-align: left;
		font: normal normal bold 14px/19px Roboto;
		letter-spacing: 0px;
		color: #145aa8;
		opacity: 1;
		padding: 14px 0;
	}
	td.del-bill-report-title-cell {
		text-align: left;
		font: normal normal 600 12px/16px Roboto;
		letter-spacing: 0px;
		color: #202020;
		opacity: 1;
		padding-left: 5px;
	}
	td.bold-cell {
		font: normal normal bold 12px/16px Roboto;
		letter-spacing: 0px;
		color: #202020;
		opacity: 1;
	}
	td.empty-row {
		padding: 1px;
	}
	td.separator-row{
		padding:16px;
	}
	.table-inner-container {
		border: none;
	}
	.dell-report-total-table {
		padding-top: 25px;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
	td.top-right-cell {
		border-radius: 0 4px 0 0;
	}
	td.top-left-cell {
		border-radius: 4px 0 0 0;
	}
	td.bottom-right-cell {
		border-radius: 0 0 4px 0;
	}
	td.bottom-left-cell {
		border-radius: 0 0 0 4px;
	}
	tr.row-with-border {
		border: 1px solid #b2b2b2;
	}
	</style>`;

  htmlCode += `
	<body>
	<div style="margin: 50px 25px">
		<div style="width: 100%">
			<div
				style="
					margin: 0 auto;
					text-align: center;
					font: normal normal bold 16px/24px Bodoni Moda;
					letter-spacing: 1px;
					color: #0f57a8;
					text-transform: uppercase;
					opacity: 1;
					padding: 8px 0;
				"
			>
				${reportTitle}
			</div>
			<div style="margin: 0 auto; position: relative; display: flex; padding-bottom: 36px">
				<div
					style="
						text-align: center;
						font: normal normal 600 13px/24px Roboto;
						letter-spacing: 0.18px;
						color: #0f0f0f;
						text-transform: uppercase;
						opacity: 1;
						margin: 0 auto;
					"
				>
					KSEB MONTHLY REPORT FOR ${formattedDate}
				</div>
				<div
					style="
						float: right;
						font: normal normal normal 10px/17px Roboto;
						letter-spacing: 0.14px;
						color: #0f0f0f;
						text-transform: capitalize;
						position: absolute;
						right: 0;
					"
				>
					Bill Date: ${today}
				</div>
			</div>
		</div>`;

  htmlCode += `
	<div class="del-bill-report-table-container">
			<table class="del-bill-report-table">
				<thead style="background: #ececec;background-color: #ececec;">
					<tr class="del-bill-report-table-header-row" style="background: #ececec;background-color: #ececec;">
						<th></th>
						<th colspan="2" class="first-row-heading">EoU Plant</th>
						<th colspan="2" class="first-row-heading">Domestic Plant</th>
						<th></th>
						<th></th>
						<th></th>
					</tr>
					<tr class="del-bill-report-table-header-row" style="background: #ececec;background-color: #ececec;">
						<th>kWh</th>
						<th>Previous</th>
						<th>Present</th>
						<th>Previous</th>
						<th>Present</th>
						<th>Consumption</th>
						<th>Rate</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody class="top-table">
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell top-left-cell">Zone 1</td>
						<td class="dashed-border">${
              previousRealTimeReadings["eou-zone-1-energy"] ?? "No Data"
            }</td>
						<td class="no-left-border">${
              presentRealTimeReadings["eou-zone-1-energy"] ?? "No Data"
            }</td>
						<td class="dashed-border">${
              previousRealTimeReadings["domestic-zone-1-energy"] ?? "No Data"
            }</td>
						<td class="no-left-border">${
              presentRealTimeReadings["domestic-zone-1-energy"] ?? "No Data"
            }</td>
						<td class="bold-cell">${
              derivedParameterReadings["zone-1-consumption"] ?? "No Data"
            }</td>
						<td>${constantReadings["zone-1-rate"]?.Value ?? "No Data"}</td>
						<td class="top-right-cell">${
              derivedParameterReadings["zone-1-amount"] ?? "No Data"
            }</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell">Zone 2</td>
						<td class="dashed-border">${
              previousRealTimeReadings["eou-zone-2-energy"] ?? "No Data"
            }</td>
						<td class="no-left-border">${
              presentRealTimeReadings["eou-zone-2-energy"] ?? "No Data"
            }</td>
						<td class="dashed-border">${
              previousRealTimeReadings["domestic-zone-2-energy"] ?? "No Data"
            }</td>
						<td class="no-left-border">${
              presentRealTimeReadings["domestic-zone-2-energy"] ?? "No Data"
            }</td>
						<td class="bold-cell">${
              derivedParameterReadings["zone-2-consumption"] ?? "No Data"
            }</td>
						<td>${constantReadings["zone-2-rate"]?.Value ?? "No Data"}</td>
						<td>${derivedParameterReadings["zone-2-amount"] ?? "No Data"}</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell">Zone 3</td>
						<td class="dashed-border">${
              previousRealTimeReadings["eou-zone-3-energy"] ?? "No Data"
            }</td>
						<td class="no-left-border">${
              presentRealTimeReadings["eou-zone-3-energy"] ?? "No Data"
            }</td>
						<td class="dashed-border">${
              previousRealTimeReadings["domestic-zone-3-energy"] ?? "No Data"
            }</td>
						<td class="no-left-border">${
              presentRealTimeReadings["domestic-zone-3-energy"] ?? "No Data"
            }</td>
						<td class="bold-cell">${
              derivedParameterReadings["zone-3-amount"] ?? "No Data"
            }</td>
						<td>${constantReadings["zone-3-rate"]?.Value ?? "No Data"}</td>
						<td>${derivedParameterReadings["zone-3-amount"] ?? "No Data"}</td>
					</tr>
					<tr>
						<td colspan="8" class="empty-row"></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell bottom-left-cell" colspan="5"></td>
						<td class="bold-cell">${
              derivedParameterReadings["total-consumption"] ?? "No Data"
            }</td>
						<td></td>
						<td class="bold-cell bottom-right-cell">${
              derivedParameterReadings["total-amount"] ?? "No Data"
            }</td>
					</tr>
				</tbody>
			</table>
			<div class="del-bill-report-inner-title">Statutory Charges</div>
			<table class="del-bill-report-table">
				<tbody>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell top-left-cell" colspan="6">Electricity Duty</td>
						<td>${constantReadings["electric-duty-rate"]?.Value ?? "No Data"}</td>
						<td class="top-right-cell">${
              derivedParameterReadings["electric-duty-amount"] ?? "No Data"
            }</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="6">Electricity Surcharge</td>
						<td>${constantReadings["electric-surcharge-rate"]?.Value ?? "No Data"}</td>
						<td>${derivedParameterReadings["electric-surcharge-amount"] ?? "No Data"}</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="6">Fuel Surcharge</td>
						<td>${constantReadings["fuel-surcharge-rate"]?.Value ?? "No Data"}</td>
						<td>${derivedParameterReadings["fuel-surcharge-amount"] ?? "No Data"}</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="6">Monthly Fuel Surcharge</td>
						<td>${constantReadings["monthly-fuel-surcharge-rate"]?.Value ?? "No Data"}</td>
						<td>${
              derivedParameterReadings["monthly-fuel-surcharge-amount"] ??
              "No Data"
            }</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="5">Duty on Self generated energy</td>
						<td>${
              constantReadings["self-generated-duty-consumption"]?.Value ??
              "No Data"
            }</td>
						<td>${constantReadings["self-generated-duty-rate"]?.Value ?? "No Data"}</td>
						<td>${derivedParameterReadings["self-generated-duty"] ?? "No Data"}</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="7">Belated payment charges</td>
						<td>${constantReadings["belated-payment-charges"]?.Value ?? "No Data"}</td>
					</tr>
					<tr>
						<td colspan="8" class="empty-row"></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td colspan="7"></td>
						<td class="bold-cell">${
              derivedParameterReadings["duty-charge-total-amount"] ?? "No Data"
            }</td>
					</tr>
					<tr>
						<td colspan="8" class="empty-row"></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="5">PF incentive</td>
						<td>${constantReadings["pf-incentive-consumption"]?.Value ?? "No Data"}</td>
						<td></td>
						<td>${constantReadings["pf-incentive-amount"]?.Value ?? "No Data"}</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="5">Demand KVA Z1</td>
						<td>${derivedParameterReadings["demand-kva-zone1"] ?? "No Data"}</td>
						<td></td>
						<td></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="5">Demand KVA Z2</td>
						<td>${derivedParameterReadings["demand-kva-zone2"] ?? "No Data"}</td>
						<td></td>
						<td></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="5">Demand KVA Z3</td>
						<td>${derivedParameterReadings["demand-kva-zone3"] ?? "No Data"}</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td colspan="8" class="empty-row"></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="5">Demand KVA - Normal</td>
						<td>${derivedParameterReadings["demand-kva-normal"] ?? "No Data"}</td>
						<td>${constantReadings["demand-kva-normal-rate"]?.Value ?? "No Data"}</td>
						<td>${derivedParameterReadings["demand-kva-charge-normal"] ?? "No Data"}</td>
					</tr>
					<tr>
						<td colspan="8" class="empty-row"></td>
					</tr>
					<tr>
						<td colspan="8" class="separator-row"></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="7">Total Charges</td>
						<td class="bold-cell">${
              derivedParameterReadings["total-charges"] ?? "No Data"
            }</td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="7">Plus/Minus round off</td>
						<td class="bold-cell">${roundOffCharge ?? "No Data"}</td>
					</tr>
					<tr>
						<td colspan="8" class="empty-row"></td>
					</tr>
					<tr class="del-bill-report-table-row">
						<td class="del-bill-report-title-cell" colspan="7">Net payable Amount</td>
						<td class="bold-cell">${netCharge ?? "No Data"}</td>
					</tr>
					<tr>
						<td colspan="8" class="empty-row"></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</body>
	`;

  printPDF(htmlCode);

  //   const config = {
  //     html2canvas: {
  //       dpi: 75,
  //       scale: 2,
  //       letterRendering: true,
  //     },
  //     pagebreak: {
  //       mode: ["avoid-all", "css", "legacy"],
  //     },
  //     jsPDF: {
  //       orientation: "portrait",
  //       unit: "in",
  //       format: "a4",
  //       compressPDF: true,
  //     },
  //     filename: `Report of ${reportTitle} for ${formattedDate} (${today})`,
  //   };

  //   html2pdf()
  //     .from(htmlCode)
  //     .set(config)
  //     .toPdf()
  //     .get("pdf")
  //     .then((pdf) => {
  //       let totalPages = pdf.internal.getNumberOfPages();
  //       for (let i = 1; i <= totalPages; i++) {
  //         pdf.setPage(i);
  //         pdf.text(
  //           pdf.internal.pageSize.getWidth() - 30,
  //           pdf.internal.pageSize.getHeight() - 10,
  //           ""
  //         );
  //       }
  //     })
  //     .save();
}

function printPDF(contents) {
  let frame1 = document.createElement("iframe");
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);
  let frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(contents);
  frameDoc.document.close();
  setTimeout(function () {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(frame1);
  }, 500);
  return false;
}
