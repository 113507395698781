<script>
  import { selectedNode } from "./../stores";
  import Spinner from "./../components/Spinner/Spinner.svelte";
  import { onMount, onDestroy } from "svelte";

  let showSpinner = true;
  let selectedNodeId = "";
  let footerElements = [];

  $: {
    selectedNodeId = $selectedNode?.id;
    showSpinner = true;
  }

  const currentLocationOrigin = window.location.origin;
  const encodeURI = function (item) {
    const encoded = encodeURIComponent(item);
    return encoded;
  };

  $: iframeURL = `${currentLocationOrigin}/platform/DigitalTwinMonitoring/${selectedNodeId}?source=wm&screen=${encodeURI(
    "Digital Twin/R"
  )}`;

  const onIframeLoaded = function () {
    showSpinner = false;
  };

  onMount(() => {
    footerElements = document.getElementsByTagName("footer");
    if (footerElements?.length > 0) {
      footerElements[0].style.display = "none";
    }
  });

  onDestroy(() => {
    if (footerElements?.length > 0) {
      footerElements[0].style.display = "block";
    }
  });
</script>

<div class="del-digital-twin-page">
  <object
    data={iframeURL}
    type={"text/html"}
    title={`Digital Twin Image of ${$selectedNode?.name ?? ""}`}
    class="del-digital-twin-iframe"
    on:load={onIframeLoaded}
  />
  {#if showSpinner}
    <Spinner />
  {/if}
</div>

<style>
  .del-digital-twin-page {
    padding: 18px 28px 0;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    position: relative;
  }

  .del-digital-twin-iframe {
    flex-grow: 1;
    min-height: 500px;
    overflow-x: auto;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
  }
</style>
