<script>
  import { selectedNode } from "./../stores";
  import DelFillChart from "../components/DelFillChart/DelFillChart.svelte";
  import { getDomainURL } from "./../invokeAPI";
  import {
    monthlyPeakConfigurations,
    yearlyPeakConfigurations,
    dayAggregationConfigurations,
    monthAggregationConfigurations,
    dayPeakIdentificationConfigurations,
    generationAndConsumptionChart,
  } from "./../../config";
  import moment from "moment";
  import DelStackedColumns from "../components/DelStackedColumns/DelStackedColumns.svelte";

  let selectedTab =
    sessionStorage.getItem("trends-selected-tab") ?? "trend-view";

  const onTabChanged = function (selected) {
    selectedTab = selected;
    sessionStorage.setItem("trends-selected-tab", selected);
  };

  const manualStartDate = Math.floor(
    moment().subtract(11, "months").startOf("month").valueOf() / 1000
  );
  const manualEndDate = Math.floor(moment().endOf("month").valueOf() / 1000);
</script>

<div class="del-page-container del-trends-page-container">
  <div class="trends-tab-container">
    <button
      class={`del-trends-tab-button ${
        selectedTab === "trend-view" ? "selected" : ""
      }`}
      on:click={() => {
        onTabChanged("trend-view");
      }}>Trend View</button
    >
    <button
      class={`del-trends-tab-button ${
        selectedTab === "aggregation-view" ? "selected" : ""
      }`}
      on:click={() => {
        onTabChanged("aggregation-view");
      }}>Aggregated Consumption</button
    >
  </div>
  <div
    class={`del-trend-charts-main-container ${
      selectedTab === "trend-view" ? "" : "hide-container"
    }`}
  >
    <div
      class="del-container del-trend-chart-container generation-vs-consumption-chart"
    >
      <DelStackedColumns
        nodeDetails={$selectedNode}
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        chartTitle={generationAndConsumptionChart.chartTitle}
        datePickerType={generationAndConsumptionChart.datePickerType}
        config={generationAndConsumptionChart.staticConfig}
        dynamicConfig={generationAndConsumptionChart.dynamicConfig}
        annotationConfig={generationAndConsumptionChart.annotationConfig}
        groupBy={generationAndConsumptionChart.groupBy}
        uniqueId={generationAndConsumptionChart.uniqueId}
        chartHeight={generationAndConsumptionChart.chartHeight}
      />
    </div>
    <div
      class="del-container del-trend-chart-container day-peak-identification-chart"
    >
      <DelStackedColumns
        nodeDetails={$selectedNode}
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        chartTitle={dayPeakIdentificationConfigurations.chartTitle}
        datePickerType={dayPeakIdentificationConfigurations.datePickerType}
        config={dayPeakIdentificationConfigurations.staticConfig}
        dynamicConfig={dayPeakIdentificationConfigurations.dynamicConfig}
        annotationConfig={dayPeakIdentificationConfigurations.annotationConfig}
        groupBy={dayPeakIdentificationConfigurations.groupBy}
        uniqueId={dayPeakIdentificationConfigurations.uniqueId}
        chartHeight={dayPeakIdentificationConfigurations.chartHeight}
      />
    </div>
    <div class="del-container del-trend-chart-container del-monthly-peak-chart">
      <DelFillChart
        nodeDetails={$selectedNode}
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        chartTitle={monthlyPeakConfigurations.chartTitle}
        config={monthlyPeakConfigurations.chartConfig}
        chartHeight={monthlyPeakConfigurations.chartHeight}
        datePickerType={monthlyPeakConfigurations.datePickerType}
        groupBy={monthlyPeakConfigurations.groupBy}
        operation={monthlyPeakConfigurations.operation}
        dayFormat={monthlyPeakConfigurations.dayFormat}
        monthFormat={monthlyPeakConfigurations.monthFormat}
        xAxisLabel={monthlyPeakConfigurations.xAxisLabel}
        monthHoverFormat={monthlyPeakConfigurations.monthHoverFormat}
        dayHoverFormat={monthlyPeakConfigurations.dayHoverFormat}
        showRangePicker={monthlyPeakConfigurations.showRangePicker}
      />
    </div>
    <div class="del-container del-trend-chart-container del-yearly-peak-chart">
      <DelFillChart
        nodeDetails={$selectedNode}
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        chartTitle={yearlyPeakConfigurations.chartTitle}
        config={yearlyPeakConfigurations.chartConfig}
        chartHeight={yearlyPeakConfigurations.chartHeight}
        datePickerType={yearlyPeakConfigurations.datePickerType}
        groupBy={yearlyPeakConfigurations.groupBy}
        operation={yearlyPeakConfigurations.operation}
        dayFormat={yearlyPeakConfigurations.dayFormat}
        monthFormat={yearlyPeakConfigurations.monthFormat}
        xAxisLabel={yearlyPeakConfigurations.xAxisLabel}
        monthHoverFormat={yearlyPeakConfigurations.monthHoverFormat}
        dayHoverFormat={yearlyPeakConfigurations.dayHoverFormat}
        showRangePicker={yearlyPeakConfigurations.showRangePicker}
      />
    </div>
  </div>
  <div
    class={`del-trend-charts-main-container ${
      selectedTab === "aggregation-view" ? "" : "hide-container"
    }`}
  >
    <div class="del-container del-trend-chart-container del-monthly-peak-chart">
      <DelFillChart
        nodeDetails={$selectedNode}
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        chartTitle={dayAggregationConfigurations.chartTitle}
        config={dayAggregationConfigurations.chartConfig}
        chartHeight={dayAggregationConfigurations.chartHeight}
        datePickerType={dayAggregationConfigurations.datePickerType}
        groupBy={dayAggregationConfigurations.groupBy}
        operation={dayAggregationConfigurations.operation}
        dayFormat={dayAggregationConfigurations.dayFormat}
        monthFormat={dayAggregationConfigurations.monthFormat}
        xAxisLabel={dayAggregationConfigurations.xAxisLabel}
        monthHoverFormat={dayAggregationConfigurations.monthHoverFormat}
        dayHoverFormat={dayAggregationConfigurations.dayHoverFormat}
        showRangePicker={dayAggregationConfigurations.showRangePicker}
      />
    </div>
    <div class="del-container del-trend-chart-container del-yearly-peak-chart">
      <DelFillChart
        nodeDetails={$selectedNode}
        baseURL={getDomainURL()}
        currentScreen={"Dashboard"}
        chartTitle={monthAggregationConfigurations.chartTitle}
        config={monthAggregationConfigurations.chartConfig}
        chartHeight={monthAggregationConfigurations.chartHeight}
        datePickerType={monthAggregationConfigurations.datePickerType}
        groupBy={monthAggregationConfigurations.groupBy}
        operation={monthAggregationConfigurations.operation}
        dayFormat={monthAggregationConfigurations.dayFormat}
        monthFormat={monthAggregationConfigurations.monthFormat}
        xAxisLabel={monthAggregationConfigurations.xAxisLabel}
        monthHoverFormat={monthAggregationConfigurations.monthHoverFormat}
        dayHoverFormat={monthAggregationConfigurations.dayHoverFormat}
        showRangePicker={monthAggregationConfigurations.showRangePicker}
        manualStartTime={manualStartDate}
        manualEndTime={manualEndDate}
      />
    </div>
  </div>
</div>

<style>
  .del-trends-page-container {
    padding: 20px;
    padding-bottom: 16px;
  }

  .trends-tab-container {
    display: flex;
    gap: 15px;
  }
  .del-trends-tab-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-trends-tab-button.selected {
    background-color: #16558f;
    border: 1px solid #16558f;
    color: white;
  }

  .del-trend-chart-container {
    margin-top: 20px;
    padding: 20px;
    min-height: 400px;
  }

  .hide-container {
    display: none;
  }
</style>
