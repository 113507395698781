<script>
  import Chart from "chart.js/auto";
  import Loader from "./Loader.svelte";
  import { onMount } from "svelte";

  export let nodeDetails;
  export let currentScreen = "Dashboard";
  export let baseURL = "";
  export let levelType;
  export let parameterCategories;
  export let deviceCategories;
  export let count = 5;
  export let sortingOrder = "Descending";
  export let parameterType = "Derived";
  export let currentStartTime;
  export let currentEndTime;
  export let previousStartTime;
  export let previousEndTime;
  export let chartConfigurations = {};
  export let chartTitle = "";
  export let chartHeight;
  export let xAxisTitle = "";
  export let yAxisTitle = "";
  export let legendPosition = "top";
  export let legendAlignment = "end";
  export let showLegends = true;
  export let barThickness = 10;
  export let isHorizontal = true;
  export let showViewAllButton = false;
  export let onViewAllButtonClick = null;

  let chart = null;
  let chartElement = null;
  let currentDataAwaiting = false;
  let previousDataAwaiting = false;
  let showNoDataMessage = false;
  let selectedNodeDetails = [];

  $: {
    if (
      nodeDetails &&
      deviceCategories &&
      parameterCategories &&
      parameterType
    ) {
      getParameters();
    }
  }
  $: {
    if (baseURL && currentScreen) {
      clearData();
      updateChartData();
      invokeAPIs();
    }
  }
  $: {
    if (chartConfigurations) {
      updateChartData();
    }
  }
  $: {
    if (currentStartTime && currentEndTime) {
      clearCurrentData();
      updateChartData();
      invokeGetCurrentDataAPI();
    }
  }
  $: {
    if (previousStartTime && previousEndTime) {
      clearPreviousData();
      updateChartData();
      invokeGetPreviousDataAPI();
    }
  }
  $: {
    updateChartOptions("yaxistitle", yAxisTitle);
  }
  $: {
    updateChartOptions("xaxistitle", xAxisTitle);
  }
  $: {
    updateChartOptions("showlegends", showLegends);
  }
  $: {
    updateChartOptions("legendposition", legendPosition);
  }
  $: {
    updateChartOptions("legendalignment", legendAlignment);
  }
  $: {
    updateChartOptions("barthickness", barThickness);
  }
  $: {
    updateChartOptions("ishorizontal", isHorizontal);
  }

  onMount(() => {
    drawChart();
  });

  const clearData = function (type) {
    selectedNodeDetails = selectedNodeDetails.map((node) => {
      node.previousData = null;
      node.currentData = null;
      return node;
    });
  };

  const clearCurrentData = function () {
    selectedNodeDetails = selectedNodeDetails.map((node) => {
      node.currentData = null;
      return node;
    });
  };

  const clearPreviousData = function () {
    selectedNodeDetails = selectedNodeDetails.map((node) => {
      node.previousData = null;
      return node;
    });
  };

  const getParameters = function () {
    if (
      nodeDetails &&
      parameterType &&
      parameterCategories &&
      deviceCategories &&
      levelType
    ) {
      selectedNodeDetails = [];
      updateChartData();
      filterParameterIDs([nodeDetails]);
      if (selectedNodeDetails.length) {
        showNoDataMessage = false;
        invokeAPIs();
      } else {
        showNoDataMessage = true;
      }
    }
  };

  const filterParameterIDs = function (hierarchy) {
    hierarchy.forEach((node) => {
      if (node.type === levelType) {
        switch (parameterType) {
          case "Basic":
            if (node.parameters && node.parameters.length) {
              for (const basicParameter of node.parameters) {
                if (
                  deviceCategories.includes(basicParameter.DeviceCategory) &&
                  parameterCategories.includes(basicParameter.ParameterCategory)
                ) {
                  selectedNodeDetails.push({
                    name: node.name,
                    parameterId: basicParameter.ParameterID,
                  });
                }
              }
            } else if (node.name) {
              selectedNodeDetails.push({
                name: node.name,
                parameterId: null,
              });
            }
            break;
          case "Derived":
            if (node.derivedparameters && node.derivedparameters.length) {
              for (const derivedParameter of node.derivedparameters) {
                if (
                  deviceCategories.includes(derivedParameter.DeviceCategory) &&
                  parameterCategories.includes(
                    derivedParameter.ParameterCategory
                  )
                ) {
                  selectedNodeDetails.push({
                    name: node.name,
                    parameterId: derivedParameter.DerivedParameterId,
                  });
                }
              }
            } else if (node.name) {
              selectedNodeDetails.push({
                name: node.name,
                parameterId: null,
              });
            }
            break;
          default:
            console.error("Unspecified Parameter Type!!!");
            break;
        }
      }
      if (node.children.length) {
        filterParameterIDs(node.children);
      }
    });
  };

  async function getResponse(endpoint, payload, method = "POST") {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const invokeGetPreviousDataAPI = function () {
    const selectedParameterIDs = selectedNodeDetails.map(
      (item) => item.parameterId
    );
    const endpoint = "dpe/api/evaluator";
    if (
      baseURL &&
      previousStartTime &&
      previousEndTime &&
      selectedParameterIDs.length &&
      !previousDataAwaiting
    ) {
      const payload = {
        parameters: selectedParameterIDs,
        startTime: parseInt(previousStartTime),
        endTime: parseInt(previousEndTime),
      };
      previousDataAwaiting = true;
      getResponse(endpoint, payload)
        .then((data) => {
          onPreviousDataReceived(data);
        })
        .catch((error) => {
          previousDataAwaiting = false;
          onPreviousChartDataError();
          console.error("API call failed", error);
        });
    }
  };

  const invokeGetCurrentDataAPI = function () {
    const selectedParameterIDs = selectedNodeDetails.map(
      (item) => item.parameterId
    );
    const endpoint = "dpe/api/evaluator";
    if (
      baseURL &&
      currentStartTime &&
      currentEndTime &&
      selectedParameterIDs.length &&
      !currentDataAwaiting
    ) {
      const payload = {
        parameters: selectedParameterIDs,
        startTime: parseInt(currentStartTime),
        endTime: parseInt(currentEndTime),
      };
      currentDataAwaiting = true;
      getResponse(endpoint, payload)
        .then((data) => {
          onCurrentDataReceived(data);
        })
        .catch((error) => {
          currentDataAwaiting = false;
          onCurrentChartDataError();
          console.error("API call failed", error);
        });
    }
  };

  const onCurrentDataReceived = function (data) {
    currentDataAwaiting = false;
    if (data && data.length) {
      const findItem = function (parameterId) {
        return data.find((item) => item.Id === parameterId);
      };
      selectedNodeDetails = selectedNodeDetails.map((node) => {
        const match = findItem(node.parameterId);
        if (match) {
          node.currentData = convertToTwoDigits(match.Result);
        } else {
          node.currentData = null;
        }
        return node;
      });
    }
    if (!previousDataAwaiting) {
      sortData();
      if (chart) {
        updateChartData();
      } else {
        drawChart();
      }
      checkForNoData();
    }
  };
  const onPreviousDataReceived = function (data) {
    previousDataAwaiting = false;
    if (data && data.length) {
      const findItem = function (parameterId) {
        return data.find((item) => item.Id === parameterId);
      };

      selectedNodeDetails = selectedNodeDetails.map((node) => {
        const match = findItem(node.parameterId);
        if (match) {
          node.previousData = convertToTwoDigits(match.Result);
        } else {
          node.previousData = null;
        }
        return node;
      });
    }
    if (!currentDataAwaiting) {
      sortData();
      if (chart) {
        updateChartData();
      } else {
        drawChart();
      }
      checkForNoData();
    }
  };

  const onCurrentChartDataError = function () {
    currentDataAwaiting = false;
    if (!previousDataAwaiting) {
      if (chart) {
        updateChartData();
      } else {
        drawChart();
      }
      checkForNoData();
    }
  };

  const onPreviousChartDataError = function () {
    previousDataAwaiting = false;
    if (!currentDataAwaiting) {
      if (chart) {
        updateChartData();
      } else {
        drawChart();
      }
      checkForNoData();
    }
  };

  const invokeAPIs = function () {
    invokeGetPreviousDataAPI();
    invokeGetCurrentDataAPI();
  };

  const sortData = function () {
    switch (sortingOrder) {
      case "Ascending":
        selectedNodeDetails.sort((a, b) => {
          const dataA =
            a.currentData === null ? Infinity : parseFloat(a.currentData);
          const dataB =
            b.currentData === null ? Infinity : parseFloat(b.currentData);

          if (dataA === dataB) {
            const prevDataA =
              a.previousData === null ? Infinity : parseFloat(a.previousData);
            const prevDataB =
              b.previousData === null ? Infinity : parseFloat(b.previousData);

            return prevDataA - prevDataB;
          }

          return dataA - dataB;
        });
        break;
      case "Descending":
        selectedNodeDetails.sort((a, b) => {
          const dataA =
            a.currentData === null ? -Infinity : parseFloat(a.currentData);
          const dataB =
            b.currentData === null ? -Infinity : parseFloat(b.currentData);

          if (dataA === dataB) {
            const prevDataA =
              a.previousData === null ? -Infinity : parseFloat(a.previousData);
            const prevDataB =
              b.previousData === null ? -Infinity : parseFloat(b.previousData);

            return prevDataB - prevDataA;
          }

          return dataB - dataA;
        });
        break;
      default:
        console.error("Unspecified ordering!");
        break;
    }
  };

  const getChartConfigurations = function () {
    const _count = count || 5;
    const chartData = selectedNodeDetails.slice(0, _count);
    const xAxisData = chartData.map((item) => item.name);
    const currentDataObj = {
      label: chartConfigurations?.currentDisplayName ?? "Current",
      data: chartData.map((item) =>
        item.currentData === undefined ? null : item.currentData
      ),
      parameterIds: chartData.map((item) => item.parameterId),
      backgroundColor: chartConfigurations?.currentChartColor ?? "#3F4C84",
    };
    const previousDataObj = {
      label: chartConfigurations?.previousDisplayName ?? "Previous",
      data: chartData.map((item) =>
        item.previousData === undefined ? null : item.previousData
      ),
      parameterIds: chartData.map((item) => item.parameterId),
      backgroundColor: chartConfigurations?.previousChartColor ?? "#BFDDE5",
    };
    return {
      xAxisData,
      currentDataObj,
      previousDataObj,
    };
  };

  const drawChart = function () {
    if (chartElement) {
      let dataset = [];

      const chartData = getChartConfigurations();
      dataset = [chartData.currentDataObj, chartData.previousDataObj];

      chart = new Chart(chartElement, {
        // The type of chart we want to create
        type: "bar",
        // The data for our dataset
        data: {
          labels: chartData.xAxisData,
          datasets: dataset,
        },
        // configuration options
        options: {
          responsive: true,
          barThickness: barThickness || 10,
          maintainAspectRatio: false,
          indexAxis: isHorizontal ? "y" : "x",
          plugins: {
            legend: {
              display: showLegends,
              position: legendPosition || "top",
              align: legendAlignment || "end",
              labels: {
                boxHeight: 10,
                boxWidth: 10,
                curser: "pointer",
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: xAxisTitle || "",
                color: "#3C3C3C",
                align: "start",
                font: {
                  weight: "bold",
                },
              },
              grid: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
                text: yAxisTitle || "",
                color: "#3C3C3C",
              },
              grid: {
                display: false,
              },
              categoryPercentage: 0.8,
              barPercentage: 0.5,
            },
          },
        },
      });
    }
  };

  const updateChartOptions = function (key, newVal) {
    if (chart && key) {
      switch (key) {
        case "yaxistitle":
          chart.options.scales.y.title.text = newVal || "";
          chart.update();
          break;
        case "xaxistitle":
          chart.options.scales.x.title.text = newVal || "";
          chart.update();
          break;
        case "showlegends":
          chart.options.plugins.legend.display = newVal;
          chart.update();
          break;
        case "legendposition":
          chart.options.plugins.legend.position = newVal || "top";
          chart.update();
          break;
        case "legendalignment":
          chart.options.plugins.legend.align = newVal || "end";
          chart.update();
          break;
        case "barthickness":
          chart.options.barThickness = newVal || 10;
          chart.update();
          break;
        case "ishorizontal":
          chart.destroy();
          drawChart();
        default:
          break;
      }
    }
  };

  const checkForNoData = function () {
    const _count = count || 5;
    const chartData = selectedNodeDetails.slice(0, _count);
    if (chartData.length) {
      const isEmpty = chartData.every(
        (item) => item.previousData == null && item.currentData == null
      );
      if (isEmpty) {
        showNoDataMessage = true;
      } else {
        showNoDataMessage = false;
      }
    } else {
      showNoDataMessage = true;
    }
  };

  const updateChartData = function () {
    if (chart) {
      const chartData = getChartConfigurations();
      const dataset = [chartData.currentDataObj, chartData.previousDataObj];
      chart.data.labels = chartData.xAxisData;
      chart.data.datasets = dataset;
      chart.update();
    }
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };
</script>

<div class="del-sorted-chart-container">
  <div class="chart-header">
    <div class="del-sorted-chart-title">{chartTitle}</div>
    {#if showViewAllButton && selectedNodeDetails?.length > count}
      <div>
        <button
          class="view-all-button"
          on:click={() => {
            onViewAllButtonClick && onViewAllButtonClick();
          }}>View All</button
        >
      </div>
    {/if}
  </div>
  <div style={`${chartHeight ? `height:${chartHeight}` : ""}`}>
    <canvas id="del-sorted-chart" bind:this={chartElement} />
  </div>
  {#if showNoDataMessage && !currentDataAwaiting && !previousDataAwaiting}
    <div class="del-sorted-chart-no-data-message">No Data Available</div>
  {/if}
  {#if currentDataAwaiting || previousDataAwaiting}
    <Loader />
  {/if}
</div>

<style>
  .del-sorted-chart-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    padding: 0 1.5em;
  }

  .chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .view-all-button {
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 0.9;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }

  .del-sorted-chart-no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
  }

  .del-sorted-chart-container {
    position: relative;
  }
</style>
