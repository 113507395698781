<script>
  import ApexCharts from "apexcharts";
  import Loader from "./Loader.svelte";
  import moment from "moment";
  import Toastify from "toastify-js";
  import "toastify-js/src/toastify.css";

  import DelDatePicker from "./DelDatePicker/DelDatePicker.svelte";
  import DelDropDownCheckBox from "./DelDropDownCheckBox/DelDropDownCheckBox.svelte";

  import { onMount, onDestroy } from "svelte";

  export let baseURL = "";
  export let currentScreen = "Analytics";
  export let maxLimit = 10;
  export let onGraphClicked = null;

  let dynamicReportAPIEndPoint =
    "analyticsapi/api/DynamicReport?searchtext=&pagecount=0&limit=10";
  let rawDataAPIEndPoint = "parameters/telemetry/rawdata/all";

  const dateRangesToShow = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
  ];

  let dynamicReportParameters = [];
  let selectedParameters = [];
  let dynamicReportAPIAwaiting = false;
  let rawDataAwaiting = false;
  let defaultSelectedParameters = sessionStorage.getItem(
    "del-dynamic-report-selected-parameters"
  )
    ? JSON.parse(
        sessionStorage.getItem("del-dynamic-report-selected-parameters")
      )
    : [];
  let defaultSelectedDates = sessionStorage.getItem(
    "del-dynamic-report-selected-dates"
  )
    ? JSON.parse(sessionStorage.getItem("del-dynamic-report-selected-dates"))
    : [];
  let selectedDates = [];
  let aggregationType =
    sessionStorage.getItem("del-dynamic-report-aggregation") ?? "";
  let firstLoad = true;
  let chartElements = [];
  let chartContainer = null;
  let chartTitle = "";
  const colors = [
    "#ebac23",
    "#b80058",
    "#008cf9",
    "#006e00",
    "#00bbad",
    "#d163e6",
    "#b24502",
    "#ff9287",
    "#5954d6",
    "#00c6f8",
    "#878500",
    "#00a76c",
    "#bdbdbd",
  ];
  let buttonVisibility = {
    realTime: false,
    hour: false,
    day: false,
    week: false,
    month: false,
  };
  let showNoDataMessage = false;
  let isDropDownOpen = false;
  let noDataParameters = [];
  let defaultDate = new Date();
  let legendStatus = {};

  const checkIfTodayHasStarted = function (offSet, endTime) {
    const currentTime = moment().valueOf();
    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }
    return moment().startOf("day").valueOf() + offSet <= currentTime;
  };
  if (!checkIfTodayHasStarted()) {
    if (defaultSelectedDates?.length !== 2) {
      defaultDate = new Date(moment().subtract(1, "day"));
    }
  }

  $: {
    if (baseURL && currentScreen) {
      invokeGetDynamicReportDataAPI();
    }
  }

  const invokeGetDynamicReportDataAPI = function () {
    dynamicReportParameters = [];
    dynamicReportAPIAwaiting = true;
    invokeAPI(
      "GET",
      dynamicReportAPIEndPoint,
      onDynamicReportAPISuccess,
      onDynamicReportAPIError,
      null,
      `${currentScreen}/R`
    );
  };

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const onDynamicReportAPISuccess = function (data) {
    let _dynamicReportParameters = [];
    dynamicReportAPIAwaiting = false;
    if (data) {
      data.DynamicReportConfig.forEach((item) => {
        item.Configurations.forEach((parameter) => {
          _dynamicReportParameters.push({
            nodeId: item.NodeId,
            nodeName: item.NodeName,
            name: parameter.AliasName
              ? parameter.AliasName
              : parameter.ParameterName,
            id: parameter.ParameterId,
            ...parameter,
          });
        });
      });
    }
    dynamicReportParameters = [..._dynamicReportParameters];
  };

  const onDynamicReportAPIError = function () {
    dynamicReportAPIAwaiting = false;
  };

  const invokeRawDataAPI = function () {
    if (
      typeof localStorage === "undefined" ||
      !(
        selectedDates?.length === 2 &&
        selectedParameters?.length &&
        currentScreen &&
        baseURL
      )
    ) {
      return;
    }
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    clearCharts();

    let operationsMap = {};
    let payloads = [];
    let parametersStatus = {};
    let colorIndex = 0;
    showNoDataMessage = false;
    for (const parameter of selectedParameters) {
      parametersStatus[parameter.ParameterId] = {
        ...parameter,
        status: false,
        data: [],
        color: colors[colorIndex],
      };
      colorIndex = (colorIndex + 1) % colors.length;
      if (parameter?.Aggregation && operationsMap[parameter.Aggregation]) {
        if (operationsMap[parameter.Aggregation][parameter.ParamType]) {
          operationsMap[parameter.Aggregation][parameter.ParamType].push(
            parameter.ParameterId
          );
        } else {
          operationsMap[parameter.Aggregation][parameter.ParamType] = [
            parameter.ParameterId,
          ];
        }
      } else {
        operationsMap[parameter.Aggregation] = {};
        operationsMap[parameter.Aggregation][parameter.ParamType] = [
          parameter.ParameterId,
        ];
      }
    }
    for (const operation in operationsMap) {
      payloads.push({
        derivedParameters: operationsMap[operation].DP ?? [],
        basicParameters: operationsMap[operation].BP ?? [],
        starttime: selectedDates[0],
        endtime: selectedDates[1],
        groupby: aggregationType,
        operation,
      });
    }

    rawDataAwaiting = true;
    Promise.all(
      payloads.map((payload) => {
        return fetch(`${baseURL}/${rawDataAPIEndPoint}`, {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        });
      })
    )
      .then((responses) =>
        Promise.all(
          responses.map((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.warn("API call failed", response);
              return [];
            }
          })
        )
      )
      .then((dataList) => {
        onRawDataAPISuccess(dataList, parametersStatus);
        rawDataAwaiting = false;
      })
      .catch((error) => {
        rawDataAwaiting = false;
        console.error("API call failed due to Error ", error);
        onRawDataAPIError();
      });
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const onRawDataAPISuccess = function (dataList, parameters) {
    for (const data of dataList) {
      if (data) {
        for (const item of data) {
          for (const parameter of item?.parameters) {
            if (parameters[parameter.parameterId]) {
              parameters[parameter.parameterId].data.push([
                item.unixtime * 1000,
                convertToTwoDigits(parameter.reading),
              ]);
              parameters[parameter.parameterId].status = true;
            }
          }
        }
      }
    }
    const _parameters = Object.values(parameters).filter((item) => item.status);
    const _noDataParameters = Object.values(parameters)
      .filter((item) => !item.status)
      .map((item) => item.name);
    const newNoDataParameters = _noDataParameters.filter(
      (item) => !noDataParameters.includes(item)
    );

    if (newNoDataParameters?.length) {
      Toastify({
        text: `No data found for parameters ${newNoDataParameters.join(
          ", "
        )} for the selected duration`,
        style: {
          background: "red",
        },
        duration: 3000,
        close: false,
      }).showToast();
    }
    noDataParameters = [..._noDataParameters];
    if (_parameters?.length) {
      drawChart(_parameters);
    } else {
      showNoDataMessage = true;
    }
  };

  const onRawDataAPIError = function () {};

  const debounce = function (func, timeout = 500) {
    let timer = null;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  };

  const onParametersSelected = function (selected) {
    if (selected?.length) {
      sessionStorage.setItem(
        "del-dynamic-report-selected-parameters",
        JSON.stringify(selected.map((item) => item.name))
      );
      selectedParameters = [...selected];
      invokeRawDataAPI();
    } else {
      sessionStorage.setItem("del-dynamic-report-selected-parameters", []);
      selectedParameters = [];
      clearCharts();
      noDataParameters = [];
    }
  };

  const handleParameterSelectionChange = debounce(onParametersSelected);

  const getDaysRange = (date1, date2) => {
    let singleDayDuration = 24 * 60 * 60 * 1000;
    return Math.ceil((date2 - date1) / singleDayDuration);
  };

  const updateButtonVisibilty = function (start, end) {
    if (!(start && end)) return;
    const range = getDaysRange(start, end);
    if (range <= 1) {
      buttonVisibility = {
        realTime: true,
        hour: true,
        day: false,
        week: false,
        month: false,
      };
      if (!(firstLoad || buttonVisibility[aggregationType])) {
        aggregationType = "";
        sessionStorage.setItem(
          "del-dynamic-report-aggregation",
          aggregationType
        );
      }
    } else if (range > 1 && range <= 7) {
      buttonVisibility = {
        realTime: true,
        hour: true,
        day: true,
        week: false,
        month: false,
      };
      if (!(firstLoad || buttonVisibility[aggregationType])) {
        aggregationType = "";
        sessionStorage.setItem(
          "del-dynamic-report-aggregation",
          aggregationType
        );
      }
    } else if (range >= 7 && range <= 31) {
      buttonVisibility = {
        realTime: false,
        hour: true,
        day: true,
        week: true,
        month: false,
      };
      if (!(firstLoad || buttonVisibility[aggregationType])) {
        aggregationType = "hour";
        sessionStorage.setItem(
          "del-dynamic-report-aggregation",
          aggregationType
        );
      }
    } else if (range >= 32 && range <= 60) {
      buttonVisibility = {
        realTime: false,
        hour: false,
        day: true,
        week: true,
        month: false,
      };
      if (!(firstLoad || buttonVisibility[aggregationType])) {
        aggregationType = "day";
        sessionStorage.setItem(
          "del-dynamic-report-aggregation",
          aggregationType
        );
      }
    } else {
      if (!(firstLoad || buttonVisibility[aggregationType])) {
        buttonVisibility = {
          realTime: false,
          hour: false,
          day: true,
          week: true,
          month: true,
        };
        aggregationType = "day";
        sessionStorage.setItem(
          "del-dynamic-report-aggregation",
          aggregationType
        );
      }
    }
  };

  const onDateChanged = function (date) {
    if (date) {
      selectedDates = [
        date?.dayDefinedStartDateInSeconds,
        date?.dayDefinedEndDateInSecondsNotGreaterThanNow,
      ];
      sessionStorage.setItem(
        "del-dynamic-report-selected-dates",
        JSON.stringify([date?.start, date?.end])
      );
      updateButtonVisibilty(
        date?.dayDefinedStartDateInEpoch,
        date?.dayDefinedEndDateInEpochNotGreaterThanNow
      );
      firstLoad = false;
      invokeRawDataAPI();
    }
  };

  const setAggregationType = function (type) {
    aggregationType = type;
    sessionStorage.setItem("del-dynamic-report-aggregation", type);
    invokeRawDataAPI();
  };

  const splitSeries = function (series) {
    const chunkSize = maxLimit || 5;
    let seriesGroup = [];
    for (let i = 0; i < series.length; i += chunkSize) {
      const chunk = series.slice(i, i + chunkSize);
      seriesGroup.push(chunk);
    }
    return seriesGroup;
  };

  const getYAxisObject = function (data) {
    let yAxisObjects = [];
    let colors = [];
    let show = true;
    let opposite = true;
    for (const item of data) {
      const isExisting = yAxisObjects.find(
        (axis) => axis.parameterUnit === item.Unit
      );
      if (isExisting) {
        show = false;
      } else {
        show = true;
        opposite = !opposite;
      }
      let obj = {
        show: show,
        opposite: opposite,
        showAlways: true,
        seriesName: isExisting ? isExisting.seriesName : item.name,
        axisTicks: {
          show: true,
          borderType: "solid",
          color: item.color,
          width: 8,
        },
        axisBorder: {
          show: true,
          color: item.color,
        },
        labels: {
          style: {
            fontSize: "14px",
            fontFamily: "Arial",
            fontWeight: 700,
            align: "left",
          },
        },
        title: {
          text: item.Unit ? `(${item.Unit})` : "",
          style: {},
          fontSize: "14px",
          fontWeight: 600,
        },
        parameterUnit: item.Unit,
      };
      colors.push(item.color);
      yAxisObjects.push(obj);
    }
    return {
      yAxisObjects,
      colors,
    };
  };
  const drawCustomToolTip = function ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }) {
    const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
    const hoverIndexes = w.globals.seriesX.map((seriesX) => {
      return seriesX.findIndex((xData) => xData === hoverXaxis);
    });
    let hoverList = "";
    hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
      let unit = w.globals.initialSeries[seriesEachIndex].Unit ?? "";
      if (unit?.toLowerCase() === "number" || unit?.toLowerCase() === "count") {
        unit = "";
      }
      const seriesName = w.globals.initialSeries[seriesEachIndex].name;
      if (hoverIndex >= 0 && legendStatus[seriesName]) {
        hoverList += `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: ${
                              w.globals.markers.colors[seriesEachIndex]
                            };"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">${
                                      w.globals.seriesNames[seriesEachIndex]
                                    }: </span>
                                    <span class="apexcharts-tooltip-text-y-value">${w.globals.yLabelFormatters[0](
                                      series[seriesEachIndex][hoverIndex] ||
                                        series[seriesEachIndex][hoverIndex] ===
                                          0
                                        ? series[seriesEachIndex][hoverIndex] +
                                            " " +
                                            unit
                                        : "No Data"
                                    )}</span>
                                </div>
                            </div>
                        </div>`;
      }
    });
    const format = `DD MMM YYYY${
      aggregationType === "hour" || aggregationType === "" ? " hh:mm A" : ""
    }`;
    const formattedDate =
      aggregationType === "week"
        ? `${moment(hoverXaxis).format(format)} to ${moment(hoverXaxis).add(7, "day").format(format)}`
        : `${moment(hoverXaxis).format(format)}`;
    const parsed = moment(new Date(hoverXaxis)).format(
      "DD-MM-YYYY " +
        (aggregationType === "hour" || aggregationType === "" ? "hh:mm A" : "")
    );
    return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${formattedDate}</div>${hoverList}`;
  };

  const drawChart = function (data) {
    if (chartContainer) {
      let chartCount = 0;
      chartTitle = `Dynamic Report <br> Duration: From ${moment(
        selectedDates[0] * 1000
      ).format("DD-MM-YYYY hh:mm A")} to ${moment(
        selectedDates[1] * 1000
      ).format("DD-MM-YYYY hh:mm A")} <br> Resolution: ${
        aggregationType || "Base Frequency"
      }`;
      let firstDraw = true;
      const seriesList = splitSeries(data);
      if (
        chartElements?.length &&
        seriesList?.length === chartElements.length
      ) {
        firstDraw = false;
      } else {
        clearCharts();
      }

      const fileName = `Dynamic Report from ${moment(
        selectedDates[0] * 1000
      ).format("DD-MM-YYYY hh:mm A")} to ${moment(
        selectedDates[1] * 1000
      ).format("DD-MM-YYYY hh:mm A")}`;
      legendStatus = {};
      for (const series of seriesList) {
        const { yAxisObjects, colors } = getYAxisObject(series);
        for (const seriesObj of series) {
          if (seriesObj.name) legendStatus[seriesObj.name] = true;
        }
        let options = {
          chart: {
            type: "line",
            stacked: false,
            animations: {
              enabled: false,
            },
            toolbar: {
              export: {
                csv: {
                  filename: fileName,
                  headerCategory: "Time",
                  dateFormatter: function (timestamp) {
                    const format = `DD MMM YY${
                      aggregationType === "hour" || aggregationType === ""
                        ? " hh:mm A"
                        : ""
                    }`;
                    return moment(timestamp).format(format);
                  },
                },
                svg: {
                  filename: fileName,
                },
                png: {
                  filename: fileName,
                },
              },
            },
            events: {
              dataPointSelection: function (event, chartContext, config) {
                const selected = {
                  series: series[config.seriesIndex],
                  dataPointIndex: config.dataPointIndex,
                  resolution: aggregationType,
                };
                onGraphClicked(selected);
              },
              legendClick: function (chartContext, seriesIndex, config) {
                const seriesName = series[seriesIndex]?.name;
                if (legendStatus[seriesName] !== undefined) {
                  legendStatus[seriesName] = !legendStatus[seriesName];
                }
              },
            },
            height: 600,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 4,
          },
          colors: colors,
          stroke: {
            show: true,
            curve: "straight",
            lineCap: "butt",
            width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
            dashArray: 0,
          },

          series: series,
          xaxis: {
            type: "datetime",
            title: {
              text: "TIME",
              fontSize: "13px",
              fontWeight: "bold",
            },
            tooltip: {
              enabled: false,
            },
            labels: {
              format:
                "dd MMM yy " +
                (aggregationType === "hour" || aggregationType === ""
                  ? "HH:mm"
                  : ""),
              datetimeUTC: false,
              style: {
                fontSize: "13px",
                fontFamily: "Arial",
                fontWeight: "bold",
              },
              rotate: -45,
              rotateAlways: false,
              showDuplicates: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: yAxisObjects,
          grid: {
            padding: {
              left: 13,
              right: 13,
            },
          },
          tooltip: {
            intersect: true,
            shared: false,
            custom: drawCustomToolTip,
            y: {
              formatter: function (
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) {
                const unit = w?.globals?.initialSeries[seriesIndex]?.Unit;
                const formattedUnit =
                  (value || value === 0) &&
                  unit !== "Number" &&
                  unit !== "count"
                    ? unit ?? ""
                    : "";
                return `${value ?? "No Data"} ${formattedUnit}`;
              },
            },
            x: {
              formatter: function (timestamp) {
                const format = `DD MMM YYYY${
                  aggregationType === "hour" || aggregationType === ""
                    ? " hh:mm A"
                    : ""
                }`;
                return aggregationType === "week"
                  ? `${moment(timestamp).format(format)} to ${moment(timestamp).add(7, "day").format(format)}`
                  : `${moment(timestamp).format(format)}`;
              },
            },
          },
          legend: {
            showForSingleSeries: true,
            position: "bottom",
            fontSize: "13px",
            fontFamily: "Arial",
            fontWeight: 700,
            offSetX: 30,
          },
          fill: {
            type: "solid",
            fillOpacity: 0.7,
          },
        };

        if (firstDraw) {
          const element = document.createElement("div");
          element.classList.add("del-dynamic-report-inner-chart-container");
          chartContainer.appendChild(element);
          chartElements.push(new ApexCharts(element, options));
          chartElements[chartCount].render();
        } else {
          chartElements[chartCount].updateOptions(options);
        }
        chartCount++;
      }
    }
  };

  const clearCharts = function () {
    if (chartElements?.length) {
      for (const chart of chartElements) {
        chart.destroy();
      }
    }
    chartElements = [];
    legendStatus = {};
    if (chartContainer) {
      chartContainer.innerHTML = "";
    }
  };

  const onClick = function (e) {
    const dropDownElement = document.getElementById(
      "del-dynamic-report-dropdown"
    );
    if (
      dropDownElement &&
      !(
        dropDownElement?.contains(e.target) ||
        e.target.matches("svg") ||
        e.target.matches("path")
      )
    ) {
      isDropDownOpen = false;
    }
  };

  onMount(() => {
    window.addEventListener("click", onClick);
  });

  onDestroy(() => {
    window.removeEventListener("click", onClick);
  });
</script>

<div class="del-dynamic-report-container">
  {#if dynamicReportAPIAwaiting || rawDataAwaiting}
    <Loader />
  {/if}
  <div class="del-dynamic-report-header-container">
    <div id="del-dynamic-report-dropdown">
      <DelDropDownCheckBox
        options={dynamicReportParameters}
        checkboxTitle={"Parameters"}
        onSelectionUpdated={(selected) => {
          handleParameterSelectionChange(selected);
        }}
        buttonLabel={"Select Parameters"}
        defaultSelection={defaultSelectedParameters}
        bind:isDropDownOpen
        showSearchBox={true}
      />
    </div>
    <DelDatePicker
      showRangePicker={true}
      autoApply={true}
      customRangesToShow={dateRangesToShow}
      firstDay={1}
      {onDateChanged}
      defaultStartDate={defaultSelectedDates?.length === 2
        ? defaultSelectedDates[0]
        : null}
      defaultEndDate={defaultSelectedDates?.length === 2
        ? defaultSelectedDates[1]
        : null}
      {defaultDate}
    />
    <div class="del-dynamic-report-buttons-container">
      {#if buttonVisibility.realTime}
        <button
          class={`del-dynamic-report-button ${
            aggregationType === "" ? "selected" : ""
          }`}
          on:click={() => {
            setAggregationType("");
          }}>Base Frequency</button
        >
      {/if}
      {#if buttonVisibility.hour}
        <button
          class={`del-dynamic-report-button ${
            aggregationType === "hour" ? "selected" : ""
          }`}
          on:click={() => {
            setAggregationType("hour");
          }}>Hour</button
        >
      {/if}
      {#if buttonVisibility.day}
        <button
          class={`del-dynamic-report-button ${
            aggregationType === "day" ? "selected" : ""
          }`}
          on:click={() => {
            setAggregationType("day");
          }}>Day</button
        >{/if}
      {#if buttonVisibility.week}
        <button
          class={`del-dynamic-report-button ${
            aggregationType === "week" ? "selected" : ""
          }`}
          on:click={() => {
            setAggregationType("week");
          }}>Week</button
        >{/if}
      {#if buttonVisibility.month}
        <button
          class={`del-dynamic-report-button ${
            aggregationType === "month" ? "selected" : ""
          }`}
          on:click={() => {
            setAggregationType("month");
          }}>Month</button
        >{/if}
    </div>
  </div>
  <div class="del-dynamic-report-chart-container">
    {#if showNoDataMessage && !(rawDataAwaiting || dynamicReportAPIAwaiting)}
      <div class="no-data-class">No data available</div>
    {:else if selectedParameters?.length}
      <div class="del-dynamic-report-title">{@html chartTitle}</div>
    {/if}
    <div bind:this={chartContainer} />
  </div>
</div>

<style>
  .del-dynamic-report-container {
    position: relative;
    min-height: 500px;
  }
  .del-dynamic-report-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button.del-dynamic-report-button {
    color: #2c3049;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #222639;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }

  button.del-dynamic-report-button:enabled:hover {
    color: #fff;
    background-color: #2c3049;
    opacity: 0.8;
  }

  button.del-dynamic-report-button.selected,
  button.del-dynamic-report-button.selected:enabled:hover {
    border: none;
    color: #fff;
    background-color: #2c3049;
    opacity: 1;
  }

  button.del-dynamic-report-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .del-dynamic-report-chart-container {
    padding: 15px 0;
  }

  .del-dynamic-report-title {
    text-align: center;
    font: normal normal bold 16px Roboto;
    color: #222222;
    opacity: 1;
    padding: 5px;
    line-height: 30px;
    text-transform: capitalize;
  }

  .no-data-class {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    font: italic normal normal 16px/21px Roboto;
  }
</style>
