<script>
  import moment from "moment";
  import Toastify from "toastify-js";
  import DelHeatMap from "./DelHeatMap/DelHeatMap.svelte";
  import DelAnomalyDetection from "./DelAnomalyDetection/DelAnomalyDetection.svelte";
  import DelForecast from "./DelForecast/DelForecast.svelte";
  import DelDropDownCheckBox from "./DelDropDownCheckBox/DelDropDownCheckBox.svelte";
  import DelSelect from "./DelSelect/DelSelect.svelte";
  import DelDynamicReport from "./DelDynamicReport/DelDynamicReport.svelte";
  import DelDatePicker from "./DelDatePicker/DelDatePicker.svelte";
  import Loader from "./Loader.svelte";
  import { onMount, onDestroy } from "svelte";

  export let platformBaseURL = "";
  export let analyticsBaseURL = "";
  export let currentScreen = "Analytics";
  export let selectedNode = null;
  export let parameterMatches = [];
  export let entriesPerPage = 5;
  export let showTrainingButton = true;
  export let duration = 5000;
  export let onTabChanged = null;
  export let isWritePermission = false;
  export let allNodesList = [];
  export let updateSelectedNode = null;
  export let nodeTypeToUpdate = null;

  const analyticsConfigDataEndPoint = "analyticsapi/api/AnalyticsConfigData";
  const dynamicReportEndPoint =
    "analyticsapi/api/DynamicReport?pagecount=0&limit=1000";
  const getCommentsEndPoint = "analyticsapi/api/AnalyticsData/getcomments";
  const saveCommentsEndPoint = "analyticsapi/api/AnalyticsData/comments";
  const trainingStatusEndPoint = "get_training_status";
  const updateTrainingDateEndPoint = "update_training_date";

  let manualDateChange = true;
  let configDataReceived = false;
  let computationInterval = null;
  let computationTimeOut = null;
  let anomalyTimeOut = null;
  let isFirstTimeCheckingStatus = true;
  let wasLastResponseEmpty = false;
  let currentPage = 1;
  let totalPages = 0;
  let isAfterSave = false;
  let firstDayOfWeek = 1;

  let childNodeIds = [];
  let nodeOptions = [];
  let groupOptions = [];
  let featureOptions = [];
  let parameterOptions = [];
  let resolutionOptions = [];
  let timePeriodOptions = [];
  let defaultTimePeriod = [];
  let selectedNodeName = "";
  let selectedNodeId = "";
  let commentsTableData = [];
  let paginatedData = [];
  let isTrainingInProgress = false;
  let featureDetails = [];
  let nodesDetails = [];
  let dynamicReportConfigData = [];
  let defaultSelectedGroup = null;
  let defaultSelectedFeature = null;
  let defaultSelectedParameter = null;
  let defaultSelectedResolution = null;
  let defaultSelectedNodeId = null;
  let allCommentsData = [];
  let noDataMessage = "";
  let currentTrainingPeriod = "";
  let plotAPIFailed = false;
  let showOverlay = false;
  let showAnalyticsDropDowns = true;
  let featureValue = null;
  let parameterValue = null;
  let resolutionValue = null;
  let timePeriodValue = null;
  let timePeriodLabel = "";
  let groupValue = null;
  let showAddCommentModal = false;
  let forceClearHeatMap = null;
  let forceClearAnomalyDetection = null;
  let forceClearForecast = null;
  let selectedDate = null;
  let forecastDetails = null;
  let anomalyDetectionDetails = null;
  let heatMapDetails = null;
  let chartToShow = null;
  let commentTextArea = null;
  let nodesList = [];
  let defaultValuesForNodesFilter = [];
  let nodesFilterSelected = [];
  let disableCommentsSubmit = true;
  let commentsTimeStamp = null;
  let analyticsConfigAPIAwaiting = false;
  let commentsAPIAwaiting = false;
  let isDropDownOpen = false;
  let isSubmitButtonClicked = false;

  const checkIfTodayHasStarted = function (offSet, endTime) {
    const currentTime = moment().valueOf();
    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }
    return moment().startOf("day").valueOf() + offSet <= currentTime;
  };

  let remainingCharCount = "100  characters left out of 100";
  let selectedTab =
    sessionStorage.getItem("del-analytics-selected-tab") ?? "analytics";
  let datePickerRanges = [
    "Today",
    "Yesterday",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
  ];
  let datePickerMaxDate = moment().endOf("day").valueOf();
  const savedDates = sessionStorage.getItem("del-analytics-saved-dates")
    ? JSON.parse(sessionStorage.getItem("del-analytics-saved-dates"))
    : [];
  let defaultStartDate = savedDates.length === 2 ? savedDates[0] : null;
  let defaultEndDate = savedDates.length === 2 ? savedDates[1] : null;
  let defaultDate = new Date();
  if (!checkIfTodayHasStarted()) {
    if (!defaultStartDate) {
      defaultDate = new Date(moment().subtract(1, "day"));
    }
  }
  const commentsTableKeys = [
    "SlNo",
    "Comment",
    "Timestamp",
    "User",
    "NodeName",
    "CommentedTime",
    "ParameterName",
  ];
  const commentsTableColumnHeadings = {
    SlNo: "Sl.No",
    Comment: "Comment",
    Timestamp: "Timestamp",
    User: "User",
    NodeName: "Node",
    CommentedTime: "Commented Time",
    ParameterName: "Parameter Name",
  };

  $: {
    if (analyticsBaseURL && currentScreen && duration) {
      setInterValsForTrainingStatus();
    }
  }

  $: {
    if (platformBaseURL && currentScreen) {
      getAnalyticsConfigData();
      getDynamicReportConfig();
    }
  }

  $: {
    if (selectedTab) {
      triggerOnTabChangedEvent();
    }
  }

  $: {
    selectedNode && onNodeChange(selectedNode);
  }

  $: {
    if (featureValue || !featureValue) {
      const timePeriodPlaceholder = `Select a time period for ${
        featureValue === "Forecast" ? "training" : "computation"
      }`;
      timePeriodOptions = [
        {
          name: timePeriodPlaceholder,
          placeholder: true,
        },
        {
          name: "Last 3 Months",
          value: 3,
        },
        {
          name: "Last 6 Months",
          value: 6,
        },
        {
          name: "Last 1 Year",
          value: 12,
        },
      ];
    }
  }

  onDestroy(() => {
    if (computationInterval) {
      clearInterval(computationInterval);
      computationInterval = null;
    }

    if (computationTimeOut) {
      clearTimeout(computationTimeOut);
      computationTimeOut = null;
    }

    if (anomalyTimeOut) {
      clearTimeout(anomalyTimeOut);
      anomalyTimeOut = null;
    }

    window.removeEventListener("click", onOutsideClick);
  });

  const triggerOnTabChangedEvent = function () {
    onTabChanged && onTabChanged(selectedTab);
  };

  const setInterValsForTrainingStatus = function () {
    isTrainingInProgress = true;
    computationInterval = setInterval(invokeCheckTrainingStatusAPI, duration);
    computationTimeOut = setTimeout(handleComputationTimedOut, 120000);
  };

  const getAnalyticsConfigData = function () {
    analyticsConfigAPIAwaiting = true;
    invokeAPI(
      "GET",
      platformBaseURL,
      analyticsConfigDataEndPoint,
      getAnalyticsConfigDataonSuccess,
      getAnalyticsConfigDataonError,
      null,
      `${currentScreen}/R`
    );
  };
  const getDynamicReportConfig = function () {
    invokeAPI(
      "GET",
      platformBaseURL,
      dynamicReportEndPoint,
      getDynamicReportConfigonSuccess,
      null,
      null,
      `${currentScreen}/R`
    );
  };

  const invokeAPI = function (
    method,
    baseURL,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const onNodeChange = function (newVal) {
    clearCharts();
    childNodeIds = [];
    nodeOptions = [];
    if (newVal) {
      getChildNodes([newVal]);
      getNodeOptions([newVal]);
      const oldNodeId = sessionStorage.getItem("del-analytics-selected-nodeid");
      const savedNode = nodeOptions.find((node) => node.id === oldNodeId);
      if (savedNode) {
        selectedNodeName = savedNode.name;
        selectedNodeId = savedNode.id;
      } else {
        selectedNodeName = newVal.name;
        selectedNodeId = newVal.id;
      }
    } else {
      selectedNodeName = null;
      selectedNodeId = null;
    }
    defaultSelectedNodeId = selectedNodeId;
    if (configDataReceived) {
      setDropDownOptions();
    }
  };

  const getAllRanges = function () {
    const lastDayOfWeek =
      firstDayOfWeek + 6 > 6 ? firstDayOfWeek + 6 : (firstDayOfWeek + 6) % 7;
    let maxDateEndOfDay = new Date(moment(datePickerMaxDate).endOf("day"));
    const hasTodayStarted = checkIfTodayHasStarted();
    let endOfToday =
      maxDateEndOfDay < new Date(moment().endOf("day"))
        ? maxDateEndOfDay
        : new Date(moment().endOf("day"));
    let endOfYesterday =
      maxDateEndOfDay < new Date(moment().subtract(1, "day").endOf("day"))
        ? maxDateEndOfDay
        : new Date(moment().subtract(1, "day").endOf("day"));
    let endOfLastWeek =
      maxDateEndOfDay <
      new Date(
        moment()
          .startOf("week")
          .weekday(firstDayOfWeek)
          .subtract(1, "week")
          .endOf("week")
          .weekday(lastDayOfWeek)
      )
        ? maxDateEndOfDay
        : new Date(
            moment()
              .startOf("week")
              .weekday(firstDayOfWeek)
              .subtract(1, "week")
              .endOf("week")
              .weekday(lastDayOfWeek)
          );
    let endOfWeek =
      maxDateEndOfDay < new Date(moment().endOf("week").weekday(lastDayOfWeek))
        ? maxDateEndOfDay
        : new Date(moment().endOf("week").weekday(lastDayOfWeek));
    let endOfMonth =
      maxDateEndOfDay < new Date(moment().endOf("month"))
        ? maxDateEndOfDay
        : new Date(moment().endOf("month"));
    let endOfLastMonth =
      maxDateEndOfDay <
      new Date(moment().startOf("month").subtract(1, "month").endOf("month"))
        ? maxDateEndOfDay
        : new Date(
            moment().startOf("month").subtract(1, "month").endOf("month")
          );
    let endOfYear =
      maxDateEndOfDay < new Date(moment().endOf("year"))
        ? maxDateEndOfDay
        : new Date(moment().endOf("year"));

    if (!hasTodayStarted) {
      endOfToday = new Date(moment(endOfToday).subtract(1, "day"));
      endOfYesterday = new Date(moment(endOfYesterday).subtract(1, "day"));
      endOfWeek = new Date(moment(endOfWeek).subtract(1, "day"));
      endOfLastWeek = new Date(moment(endOfLastWeek).subtract(1, "day"));
      endOfMonth = new Date(moment(endOfMonth).subtract(1, "day"));
      endOfLastMonth = new Date(moment(endOfLastMonth).subtract(1, "day"));
      endOfYear = new Date(moment(endOfYear).subtract(1, "day"));
    }

    let customRanges = {
      Today: hasTodayStarted
        ? [new Date(moment().startOf("day")), endOfToday]
        : [new Date(moment().subtract(1, "day").startOf("day")), endOfToday],
      Yesterday: hasTodayStarted
        ? [new Date(moment().subtract(1, "day").startOf("day")), endOfYesterday]
        : [
            new Date(moment().subtract(2, "day").startOf("day")),
            endOfYesterday,
          ],
      "Current Week": [
        new Date(moment().startOf("week").weekday(firstDayOfWeek)),
        endOfWeek,
      ],
      "Last Week": [
        new Date(
          moment().startOf("week").weekday(firstDayOfWeek).subtract(1, "week")
        ),
        endOfLastWeek,
      ],
      "Current Month": [new Date(moment().startOf("month")), endOfMonth],
      "Last Month": [
        new Date(moment().startOf("month").subtract(1, "month")),
        endOfLastMonth,
      ],
      "Current Year": [new Date(moment().startOf("year")), endOfYear],
    };
    return customRanges;
  };

  const getAnalyticsConfigDataonSuccess = function (data) {
    analyticsConfigAPIAwaiting = false;
    if (data && data.AnalyticsFeatures && data.nodes) {
      featureDetails = data.AnalyticsFeatures;
      nodesDetails = data.nodes;
    } else {
      featureDetails = {};
      nodesDetails = [];
    }
    configDataReceived = true;
    setDropDownOptions();
  };

  const getAnalyticsConfigDataonError = function () {
    analyticsConfigAPIAwaiting = false;
  };

  const getDynamicReportConfigonSuccess = function (data) {
    if (data && data.DynamicReportConfig) {
      dynamicReportConfigData = data.DynamicReportConfig;
    }
  };

  const nodeSelectChange = function (newVal) {
    clearCharts();
    childNodeIds = [];
    if (newVal) {
      selectedNodeName = newVal.name;
      selectedNodeId = newVal.id;
      getChildNodes([newVal]);
      sessionStorage.setItem("del-analytics-selected-nodeid", newVal.id);
    } else {
      selectedNodeName = null;
      selectedNodeId = null;
    }
    if (configDataReceived) {
      setDropDownOptions();
    }
  };

  const findInParameters = function (id) {
    if (selectedNodeId && nodesDetails) {
      const _selectedNode = nodesDetails.find(
        (item) => item.nodeid === selectedNodeId
      );
      if (
        _selectedNode &&
        _selectedNode.parameters &&
        _selectedNode.parameters.length
      ) {
        for (const group of _selectedNode.parameters) {
          if (group.parameterlist) {
            const match = group.parameterlist.find(
              (parameter) => parameter.parameterid === id
            );
            if (match) {
              return group;
            }
          }
        }
      }
    }
    return null;
  };

  const setDropDownOptions = function () {
    const _featureDetails = featureDetails;
    const _nodesDetails = nodesDetails;
    let _groupOptions = [];
    let _featureOptions = [];
    let _parameterOptions = [];

    const previouslySelectedGroup = sessionStorage.getItem(
      "del-analytics-selected-group-name"
    );
    const previouslySelectedParameterId = sessionStorage.getItem(
      "del-analytics-selected-parameter-id"
    );
    const previouslySelectedFeature = sessionStorage.getItem(
      "del-analytics-selected-feature"
    );
    if (selectedNodeId && _featureDetails && _nodesDetails) {
      const _selectedNode = _nodesDetails.find(
        (item) => item.nodeid === selectedNodeId
      );
      if (
        _selectedNode &&
        _selectedNode.parameters &&
        _selectedNode.parameters.length
      ) {
        let oldGroupFound = null;
        for (const group of _selectedNode.parameters) {
          let newGroup = {
            name: group.labelname,
            parameters: [],
            features: [],
          };
          if (group.labelname === previouslySelectedGroup) {
            oldGroupFound = newGroup;
          }
          if (_featureDetails[group.labelname]) {
            newGroup.features = _featureDetails[group.labelname]
              .filter((item) => item.Enabled)
              .map((item) => item.Name);
          }
          if (group.parameterlist) {
            newGroup.parameters = group.parameterlist.map((parameter) => ({
              name: parameter.parametername,
              id: parameter.parameterid,
              threshold: parameter.ratedthreshold,
              parametername: parameter.parametername,
              type: parameter.type,
              aggregation: group.Aggregation,
              unit: group.Unit,
              ParameterCategory: parameter.ParameterCategory,
              DeviceCategory: parameter.DeviceCategory,
              groupName: group.labelname,
            }));
          }
          _groupOptions.push(newGroup);
        }

        if (_groupOptions.length) {
          let selectedGroup = oldGroupFound ? oldGroupFound : _groupOptions[0];
          defaultSelectedGroup = selectedGroup;

          _featureOptions = selectedGroup.features;
          if (_featureOptions.length) {
            if (_featureOptions.includes(previouslySelectedFeature)) {
              defaultSelectedFeature = previouslySelectedFeature;
            } else {
              defaultSelectedFeature = _featureOptions[0];
            }
          }

          _parameterOptions = selectedGroup.parameters;
          if (_parameterOptions.length) {
            let oldParameterFound = _parameterOptions.find(
              (parameter) => parameter.id === previouslySelectedParameterId
            );
            defaultSelectedParameter = oldParameterFound
              ? oldParameterFound
              : _parameterOptions[0];
          }
        }
      }
      checkForNoData(_groupOptions, _featureOptions, _parameterOptions);
    }

    groupOptions = [..._groupOptions];
    featureOptions = [..._featureOptions];
    parameterOptions = [..._parameterOptions];
  };

  const getNodeOptions = function (hierarchy) {
    hierarchy.forEach((node) => {
      nodeOptions.push(node);
      if (node.children && node.children.length) {
        getNodeOptions(node.children);
      }
    });
  };

  const getChildNodes = function (hierarchy) {
    hierarchy.forEach((node) => {
      childNodeIds.push(node.id);
      if (node.children && node.children.length) {
        getChildNodes(node.children);
      }
    });
  };

  const findNodeItem = function (nodeId) {
    return nodeOptions.find((node) => node.id === nodeId);
  };

  const findRootNodeId = function (node, id, path = []) {
    let found = null;
    if (node) {
      path.push({
        id: node.id,
        name: node.name,
        type: node.type,
      });
    }
    if (node?.id === id) {
      node.nodesInPath = path;
      return node;
    } else if (node?.children?.length) {
      node.children.some(
        (item) => (found = findRootNodeId(item, id, path.slice()))
      );
    }
    return found;
  };

  const findNodeItemNotInList = function (id) {
    let found = null;
    for (const node of allNodesList) {
      found = findRootNodeId(node, id);
      if (found) {
        const nodesInPath = found.nodesInPath;
        found = nodesInPath?.find((item) => item.type === nodeTypeToUpdate);
        break;
      }
    }
    return found?.id || null;
  };

  const checkForNoData = function (
    _groupOptions,
    _featureOptions,
    _parameterOptions
  ) {
    if (
      !_groupOptions.length ||
      !_featureOptions.length ||
      !_parameterOptions.length
    ) {
      showAnalyticsDropDowns = false;
      featureValue = null;
      if (!_groupOptions.length) {
        noDataMessage = "No groups configured for this node";
      } else if (!_parameterOptions.length) {
        noDataMessage = "No parameters configured for this node";
      } else {
        noDataMessage = "No features configured for this node";
      }
    } else {
      showAnalyticsDropDowns = true;
      noDataMessage = "";
    }
  };

  const groupSelectChange = function (newVal) {
    clearCharts();
    groupValue = { ...newVal };
    if (newVal) {
      featureOptions = newVal.features;
      parameterOptions = newVal.parameters;
      if (newVal.parameters && newVal.parameters.length) {
        const parameterIds = newVal.parameters.map((item) => item.id);
        if (!parameterIds.includes(defaultSelectedParameter?.id)) {
          defaultSelectedParameter = newVal.parameters[0];
        }
      }
      sessionStorage.setItem("del-analytics-selected-group-name", newVal.name);
    }
  };

  const parameterSelectChange = function (newVal) {
    clearCharts();
    if (newVal) {
      parameterValue = { ...newVal };
      sessionStorage.setItem("del-analytics-selected-parameter-id", newVal.id);
    }
  };

  const updateDatePickerOptions = function () {
    switch (featureValue) {
      case "Heatmap":
        datePickerMaxDate = moment().subtract(1, "day").endOf("day").valueOf();
        if (!checkIfTodayHasStarted()) {
          datePickerMaxDate = moment(datePickerMaxDate)
            .subtract(1, "day")
            .valueOf();
        }
        datePickerRanges = [
          "Current Week",
          "Last Week",
          "Current Month",
          "Last Month",
        ];
        break;
      case "Anomaly Detection":
        datePickerMaxDate = moment().endOf("day").valueOf();
        if (!checkIfTodayHasStarted()) {
          datePickerMaxDate = moment(datePickerMaxDate)
            .subtract(1, "day")
            .valueOf();
        }
        datePickerRanges = [
          "Today",
          "Yesterday",
          "Current Week",
          "Last Week",
          "Current Month",
          "Last Month",
        ];
        break;
      case "Forecast":
        datePickerMaxDate = moment().add(5000, "day").valueOf();
        datePickerRanges = [
          "Today",
          "Yesterday",
          "Current Week",
          "Last Week",
          "Current Month",
          "Last Month",
        ];
        break;
      default:
        break;
    }
  };

  const setResolutionForHeatMap = function () {
    resolutionOptions = ["Hourly"];
    const previouslySelectedResolution = sessionStorage.getItem(
      "del-analytics-selected-resolution"
    );
    if (
      previouslySelectedResolution &&
      resolutionOptions.includes(previouslySelectedResolution)
    ) {
      defaultSelectedResolution = previouslySelectedResolution;
    } else {
      defaultSelectedResolution = resolutionOptions[0];
    }
  };

  const featureSelectChange = function (newVal) {
    clearCharts();
    const oldFeatureValue = featureValue;
    featureValue = newVal?.name ?? "";
    if (featureValue) {
      sessionStorage.setItem("del-analytics-selected-feature", featureValue);
    }
    defaultSelectedFeature = featureValue;
    updateDatePickerOptions();
    manualDateChange = false;
    if (oldFeatureValue !== featureValue && selectedDate) {
      if (featureValue === "Heatmap") {
        setResolutionForHeatMap();
      } else {
        setResolutionOptions();
      }
    }
  };

  const durationFinder = function (start, end, ranges) {
    let duration = null;
    if (ranges) {
      duration = Object.keys(ranges).find((key) => {
        if (
          ranges[key][0].valueOf() === start &&
          ranges[key][1].valueOf() === end
        ) {
          return true;
        }
      });
    }
    if (duration && datePickerRanges.includes(duration)) {
      return duration;
    }
    return "Custom";
  };

  const checkIfGreaterThanMaxDate = function (date) {
    if (!date || isNaN(date)) return;

    date = parseInt(date);
    if (date > datePickerMaxDate) {
      return datePickerMaxDate;
    }
    return date;
  };

  const showToastMessage = function (message, color = "red", duration = 3000) {
    Toastify({
      text: message,
      style: {
        background: color,
      },
      duration: duration,
      close: false,
    }).showToast();
  };

  const drawForecastGraph = function () {
    forecastDetails = {
      node: selectedNodeName,
      parameter: parameterValue.name,
      parameterId: parameterValue.id,
      unit: parameterValue.unit,
      type: parameterValue.type,
      duration: durationFinder(
        selectedDate.startDateInEpoch,
        selectedDate.endDateInEpoch,
        getAllRanges()
      ),
      customStartTime: moment(selectedDate.start).format("YYYY-MM-DD"),
      customEndTime: moment(selectedDate.end).format("YYYY-MM-DD"),
      resolution: resolutionValue,
    };
    forceClearForecast = false;
    chartToShow = "forecast";
  };

  const drawAnomalyGraph = function (selectedParameter) {
    if (!selectedParameter) {
      selectedParameter = parameterValue;
    }
    anomalyDetectionDetails = {
      node: selectedNodeName,
      parameter: selectedParameter.name,
      parameterId: selectedParameter.id,
      unit: selectedParameter.unit,
      type: selectedParameter.type,
      duration: durationFinder(
        selectedDate.startDateInEpoch,
        selectedDate.endDateInEpoch,
        getAllRanges()
      ),
      customStartTime: moment(
        checkIfGreaterThanMaxDate(selectedDate.startDateInEpoch)
      ).format("YYYY-MM-DD"),
      customEndTime: moment(
        checkIfGreaterThanMaxDate(selectedDate.endDateInEpoch)
      ).format("YYYY-MM-DD"),
      resolution: resolutionValue,
    };

    forceClearAnomalyDetection = false;
    chartToShow = "anomaly";
  };

  const drawHeatMap = function () {
    heatMapDetails = {
      node: selectedNodeName,
      parameter: parameterValue.name,
      parameterId: parameterValue.id,
      unit: parameterValue.unit,
      type: parameterValue.type,
      duration: durationFinder(
        selectedDate.startDateInEpoch,
        selectedDate.endDateInEpoch,
        getAllRanges()
      ),
      customStartTime: moment(
        checkIfGreaterThanMaxDate(selectedDate.startDateInEpoch)
      ).format("YYYY-MM-DD"),
      customEndTime: moment(
        checkIfGreaterThanMaxDate(selectedDate.endDateInEpoch)
      ).format("YYYY-MM-DD"),
      resolution: resolutionValue,
    };
    forceClearHeatMap = false;
    chartToShow = "heatmap";
  };

  const invokeCommentsAPI = function () {
    allCommentsData = [];
    commentsTableData = [];
    paginatedData = [];
    nodesList = [];
    const starttime = selectedDate.startDateInSeconds;
    const endtime = selectedDate.endDateInSeconds;
    const payload = {
      starttime,
      endtime,
      nodeid: selectedNodeId,
    };
    commentsAPIAwaiting = true;
    invokeAPI(
      "POST",
      platformBaseURL,
      getCommentsEndPoint,
      getCommentsonSuccess,
      getCommentsOnError,
      payload,
      `${currentScreen}/R`
    );
  };

  const onSubmitButtonClick = function () {
    isSubmitButtonClicked = true;
    switch (featureValue) {
      case "Heatmap":
        drawHeatMap();
        break;
      case "Anomaly Detection":
        invokeCommentsAPI();
        drawAnomalyGraph();
        break;
      case "Forecast":
        drawForecastGraph();
        break;
      default:
        break;
    }
  };

  const switchToDynamicReport = function () {
    let selectedParametersList = [];
    if (
      parameterMatches &&
      parameterValue &&
      parameterValue.ParameterCategory
    ) {
      const matchingSubString = parameterMatches.find((item) =>
        parameterValue.ParameterCategory.includes(item)
      );
      selectedParametersList = getSelectedParameterNames(matchingSubString);
    }
    if (selectedParametersList.length) {
      clearCharts();
      sessionStorage.setItem(
        "del-dynamic-report-selected-parameters",
        JSON.stringify(selectedParametersList)
      );
      sessionStorage.setItem("del-dynamic-report-aggregation", "");
      selectedTab = "dynamic-report";
      sessionStorage.setItem("del-analytics-selected-tab", selectedTab);
    } else {
      showToastMessage(
        "No matching parameters configured for the selected node in Dynamic Report"
      );
      // redrawCharts();
    }
  };

  const onHeatMapClick = function (data) {
    const savedDates = [
      moment(data.name).startOf("day").valueOf(),
      moment(data.name).endOf("day").valueOf(),
    ];
    sessionStorage.setItem(
      "del-dynamic-report-selected-dates",
      JSON.stringify(savedDates)
    );
    switchToDynamicReport();
  };

  const getSelectedParameterNames = function (subStringToMatch) {
    let selectedParameterNames = [];
    if (
      subStringToMatch &&
      dynamicReportConfigData &&
      dynamicReportConfigData.length &&
      childNodeIds &&
      childNodeIds.length
    ) {
      for (const nodeId of childNodeIds) {
        const selectedNodeDetails = dynamicReportConfigData.find(
          (node) => node.NodeId === nodeId
        );
        if (selectedNodeDetails && selectedNodeDetails.Configurations) {
          for (const parameter of selectedNodeDetails.Configurations) {
            if (parameter.ParameterCategory) {
              const parameterCategory = parameter.ParameterCategory;
              if (parameterCategory.includes(subStringToMatch)) {
                selectedParameterNames.push(
                  parameter.AliasName
                    ? parameter.AliasName
                    : parameter.ParameterName
                );
              }
            }
          }
        }
      }
    }
    return selectedParameterNames;
  };

  const redrawCharts = function () {
    if (chartToShow === "heatmap") {
      forceClearHeatMap = false;
    } else if (chartToShow === "anomaly") {
      forceClearAnomalyDetection = false;
    } else if (chartToShow === "forecast") {
      forceClearForecast = false;
    }
    updatePagination();
  };

  const clearCharts = function () {
    forceClearHeatMap = forceClearHeatMap === true ? 1 : true;
    forceClearAnomalyDetection = forceClearAnomalyDetection === true ? 1 : true;
    forceClearForecast = forceClearForecast === true ? 1 : true;
    showAddCommentModal = false;
    currentTrainingPeriod = "";
    plotAPIFailed = false;
    paginatedData = [];
    isSubmitButtonClicked = false;
  };

  const resetToOldNode = function (nodeId, nodeName) {
    selectedNodeId = nodeId;
    selectedNodeName = nodeName;
    childNodeIds = [];
    const _selectedNodeItem = findNodeItem(nodeId);
    if (_selectedNodeItem) {
      getChildNodes([_selectedNodeItem]);
    }
    setDropDownOptions();
  };

  const onDynamicReportClick = function (data) {
    let _parameterOptions = [...parameterOptions];
    let changeTreeCascader = false;
    if (data) {
      const series = data.series;
      anomalyDetectionDetails = null;
      forceClearAnomalyDetection = true;
      let selectedParameter = _parameterOptions.find(
        (item) => item.id === series.ParameterId
      );
      let _selectedGroupName = null;
      let _selectedGroup = null;
      if (!selectedParameter) {
        const oldNodeName = selectedNodeName;
        const oldNodeId = selectedNodeId;
        const oldParameter = parameterValue;
        selectedNodeId = series.nodeId;
        selectedNodeName = series.nodeName.split("-").pop();
        childNodeIds = [];
        const _selectedNodeItem = findNodeItem(series.nodeId);
        if (_selectedNodeItem) {
          getChildNodes([_selectedNodeItem]);
        }
        const group = findInParameters(series.ParameterId);
        if (group) {
          _selectedGroupName = group.labelname;
          _selectedGroup = { ...group };
        }
        setDropDownOptions();
        _parameterOptions = [...parameterOptions];
        selectedParameter = _parameterOptions.find(
          (item) => item.id === series.ParameterId
        );
        if (!_selectedNodeItem) {
          changeTreeCascader = findNodeItemNotInList(series.nodeId);
        }
        if (!selectedParameter || (!_selectedNodeItem && !changeTreeCascader)) {
          sessionStorage.setItem(
            "del-analytics-selected-parameter-id",
            oldParameter?.id
          );
          sessionStorage.setItem(
            "del-analytics-selected-group-name",
            oldParameter?.groupName
          );
          resetToOldNode(oldNodeId, oldNodeName);
          if (!selectedParameter) {
            showToastMessage(
              "This parameter is not available in anomaly detection"
            );
          } else {
            showToastMessage(
              "This parameter is not available in anomaly detection for the selected node"
            );
          }
          return;
        } else {
          if (changeTreeCascader) {
            updateSelectedNode && updateSelectedNode(changeTreeCascader);
          }
          sessionStorage.removeItem(
            `del-comments-table-filter-selected-parameters`
          );
        }
      }
      defaultSelectedNodeId = "";
      defaultSelectedNodeId = selectedNodeId;
      defaultSelectedParameter = null;
      defaultSelectedParameter = selectedParameter;
      defaultSelectedGroup = null;
      defaultSelectedGroup = _selectedGroup;
      sessionStorage.setItem(
        "del-analytics-selected-group-name",
        _selectedGroupName
      );
      sessionStorage.setItem(
        "del-analytics-selected-parameter-id",
        series.ParameterId
      );
      sessionStorage.setItem("del-analytics-selected-nodeid", selectedNodeId);
      defaultSelectedFeature = "";
      defaultSelectedFeature = "Anomaly Detection";
      featureValue = "Anomaly Detection";
      sessionStorage.setItem(
        "del-analytics-selected-feature",
        "Anomaly Detection"
      );
      datePickerMaxDate = moment().endOf("day").valueOf();
      datePickerRanges = [
        "Today",
        "Yesterday",
        "Current Week",
        "Last Week",
        "Current Month",
        "Last Month",
      ];
      clearCharts();
      if (series.data) {
        const selectedItem = series.data[data.dataPointIndex];
        const selectedDate = moment(new Date(selectedItem[0]));
        const _defaultStartDate = selectedDate.startOf("day").valueOf();
        const _defaultEndDate = selectedDate.endOf("day").valueOf();
        if (_defaultStartDate === defaultStartDate) {
          defaultStartDate = new Date(_defaultStartDate);
        } else {
          defaultStartDate = _defaultStartDate;
        }
        if (_defaultEndDate === defaultEndDate) {
          defaultEndDate = new Date(_defaultEndDate);
        } else {
          defaultEndDate = _defaultEndDate;
        }
        manualDateChange = false;
      }
      setResolutionOptions();
      defaultSelectedResolution = "Base Frequency";
      selectedTab = "analytics";
      sessionStorage.setItem("del-analytics-selected-tab", selectedTab);
      anomalyTimeOut = setTimeout(switchToAnomalyDetection, 1000);
    }
  };

  const switchToAnomalyDetection = function () {
    isSubmitButtonClicked = true;
    drawAnomalyGraph();
    invokeCommentsAPI();
  };

  const onAnomalyDetectionClick = function (data) {
    const startDate = selectedDate.startDateInEpoch;
    const endDate = selectedDate.endDateInEpoch;
    if (data && data.length) {
      const timestamp = data[0].x;
      if (moment(startDate).isSame(endDate, "day")) {
        if (timestamp && isWritePermission) {
          const timestamp = moment(new Date(data[0].x)).valueOf();
          commentsTimeStamp = timestamp;
          showModal(timestamp);
        }
      } else {
        const savedDates = [
          moment(timestamp).startOf("day").valueOf(),
          moment(timestamp).endOf("day").valueOf(),
        ];
        sessionStorage.setItem(
          "del-dynamic-report-selected-dates",
          JSON.stringify(savedDates)
        );
        switchToDynamicReport();
      }
    }
  };

  const getDayNumber = function (date1, date2) {
    let milliSeconds = 24 * 60 * 60 * 1000;
    return Math.ceil((date2 - date1) / milliSeconds);
  };

  const setResolutionOptions = function () {
    let duration = durationFinder(
      selectedDate.startDateInEpoch,
      selectedDate.endDateInEpoch,
      getAllRanges()
    );
    if (featureValue === "Heatmap") {
      resolutionOptions = ["Hourly"];
    } else if (duration === "Today" || duration === "Yesterday") {
      resolutionOptions = ["Base Frequency", "Hourly"];
    } else if (duration === "Current Week" || duration === "Last Week") {
      resolutionOptions = ["Hourly"];
    } else if (duration === "Current Month" || duration === "Last Month") {
      resolutionOptions = ["Hourly", "Daily"];
    } else if (duration === "Custom") {
      let daysRange = getDayNumber(
        selectedDate.startDateInEpoch,
        selectedDate.endDateInEpoch
      );
      if (daysRange < 4) {
        resolutionOptions = ["Base Frequency"];
      } else if (daysRange >= 4 && daysRange <= 14) {
        resolutionOptions = ["Hourly"];
      } else if (daysRange > 14) {
        resolutionOptions = ["Daily"];
      }
    }
    const previouslySelectedResolution = sessionStorage.getItem(
      "del-analytics-selected-resolution"
    );
    if (
      previouslySelectedResolution &&
      resolutionOptions.includes(previouslySelectedResolution)
    ) {
      defaultSelectedResolution = previouslySelectedResolution;
    } else {
      defaultSelectedResolution = resolutionOptions[0];
    }
  };

  const datePickerChange = function () {
    const savedDates = [
      selectedDate.startDateInEpoch,
      selectedDate.endDateInEpoch,
    ];
    sessionStorage.setItem(
      "del-analytics-saved-dates",
      JSON.stringify(savedDates)
    );
    setResolutionOptions();
    if (manualDateChange) {
      clearCharts();
    } else {
      manualDateChange = true;
    }
  };

  const resolutionSelectChange = function (newVal) {
    clearCharts();
    resolutionValue = newVal?.name ?? null;
    resolutionValue &&
      sessionStorage.setItem(
        "del-analytics-selected-resolution",
        resolutionValue
      );
    defaultSelectedResolution = resolutionValue;
  };

  const onTrainingPeriodUpdated = function (data) {
    currentTrainingPeriod = data;
  };

  const updatePagination = function () {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    let entryNo = startIndex + 1;
    paginatedData = commentsTableData.slice(startIndex, endIndex).map((row) => {
      row.SlNo = entryNo++;
      return row;
    });
  };

  const handleFirstPageClick = function () {
    currentPage = 1;
    updatePagination();
  };
  const handleLastPageClick = function () {
    currentPage = totalPages;
    updatePagination();
  };
  const handleNextPageClick = function () {
    currentPage++;
    updatePagination();
  };
  const handlePreviousPageClick = function () {
    currentPage--;
    updatePagination();
  };

  const showModal = function () {
    showOverlay = true;
    showAddCommentModal = true;
    remainingCharCount = "100  characters left out of 100";
    disableCommentsSubmit = true;
  };

  const onSubmitCommentButtonClick = function () {
    if (commentTextArea) {
      const comment = commentTextArea.value ?? "";
      const payload = {
        Comment: comment,
        Timestamp: parseInt(commentsTimeStamp / 1000),
        User: localStorage.getItem("uname"),
        NodeId: selectedNodeId,
        NodeName: selectedNodeName,
        CommentedTime: parseInt(moment().valueOf() / 1000),
        ParameterName: parameterValue?.name ?? "",
        ParameterId: parameterValue?.id ?? "",
      };
      invokeAPI(
        "POST",
        platformBaseURL,
        saveCommentsEndPoint,
        saveCommentonSuccess,
        saveCommentonError,
        payload,
        `${currentScreen}/RW`
      );
      isAfterSave = true;
      showAddCommentModal = false;
      showOverlay = false;
      commentsTimeStamp = null;
    }
  };

  const saveCommentonSuccess = function (data) {
    invokeCommentsAPI();
    const message =
      data && data.message ? data.message : "Comment saved successfully";
    showToastMessage(message, "green");
  };

  const saveCommentonError = function (data) {
    isAfterSave = false;
    const message =
      data && data.message ? data.message : "Unable to save comment";
    showToastMessage(message);
  };

  const formatTimeStamp = function (timestamp) {
    if (!timestamp || isNaN(timestamp)) return "";
    const formattedTimeStamp = moment(new Date(timestamp * 1000)).format(
      "DD-MMM-YYYY HH:mm:ss"
    );
    return formattedTimeStamp;
  };

  const getCommentsonSuccess = function (data) {
    commentsAPIAwaiting = false;
    if (data) {
      data.sort((b, a) => {
        const difference = parseInt(a.Timestamp) - parseInt(b.Timestamp);
        if (difference === 0) {
          return parseInt(a.CommentedTime) - parseInt(b.CommentedTime);
        }
        return difference;
      });
      nodesList = [];
      currentPage = 1;
      allCommentsData = [...data];
      commentsTableData = [...allCommentsData];
      let _nodesList = [];
      let nodesIdList = [];
      for (const item of data) {
        item.Timestamp = formatTimeStamp(item.Timestamp);
        item.CommentedTime = item.CommentedTime
          ? formatTimeStamp(item.CommentedTime)
          : "";
        if (!nodesIdList.includes(item.NodeId)) {
          nodesIdList.push(item.NodeId);
          _nodesList.push({
            name: item.NodeName,
            id: item.NodeId,
          });
        }
      }
      nodesList = _nodesList;
      updatePagination();
      if (!isAfterSave || wasLastResponseEmpty) {
        defaultValuesForNodesFilter = _nodesList.map((item) => item.name);
        isAfterSave = false;
      }

      wasLastResponseEmpty = !data.length;
    } else {
      updatePagination();
    }
  };

  const getCommentsOnError = function () {
    commentsAPIAwaiting = false;
  };

  const filterTableData = function () {
    if (nodesFilterSelected) {
      const nodeIdList = nodesFilterSelected.map((item) => item.id);
      commentsTableData = allCommentsData.filter((item) =>
        nodeIdList.includes(item.NodeId)
      );
      totalPages = Math.ceil(commentsTableData.length / entriesPerPage);
      if (currentPage > totalPages) {
        currentPage = Math.ceil(commentsTableData.length / entriesPerPage) || 1;
      }
      updatePagination();
    }
  };

  const updateCharCount = function () {
    if (!commentTextArea?.value) return;
    const charCount = commentTextArea.value.length;
    const charsLeft = 100 - charCount;
    if (charCount > 0) {
      disableCommentsSubmit = false;
    } else {
      disableCommentsSubmit = true;
    }
    remainingCharCount = charsLeft + " characters left out of 100";
  };

  const onNodesFilterSelectionUpdated = function (selected) {
    nodesFilterSelected = [...selected];
    filterTableData();
  };

  const onTimePeriodUpdated = function (newVal) {
    timePeriodValue = newVal?.value;
    timePeriodLabel = newVal?.name ?? "";
  };

  const trainingSubmitButtonClick = function () {
    if (timePeriodValue && parameterValue) {
      const endDate = moment().endOf("day").subtract(1, "day").valueOf();
      let payload = {
        cId: localStorage.getItem("companyId"),
        aId: sessionStorage.getItem("appId"),
        start_date: 0,
        end_date: parseInt(endDate / 1000),
        param_id: parameterValue.id,
        node_id: selectedNodeId,
      };
      let startDate = 0;
      switch (timePeriodValue) {
        case 3:
          startDate = moment(new Date(endDate)).subtract(3, "month").valueOf();
          break;
        case 6:
          startDate = moment(new Date(endDate)).subtract(6, "month").valueOf();
          break;
        case 12:
          startDate = moment(new Date(endDate)).subtract(1, "year").valueOf();
          break;
        default:
          break;
      }
      payload.start_date = parseInt(startDate / 1000);
      isTrainingInProgress = true;
      clearCharts();

      invokeUpdateTrainingDateAPI(payload);
    }
  };

  const invokeUpdateTrainingDateAPI = function (payload) {
    invokeAPI(
      "POST",
      analyticsBaseURL,
      updateTrainingDateEndPoint,
      updatingTrainingDateonSuccess,
      updatingTrainingDateonError,
      payload,
      `${currentScreen}/R`,
      null,
      "analytics"
    );
  };

  const trainingAPIOnSuccess = function (data) {
    if (data && data.analyticsSyncStatus) {
      switch (data.analyticsSyncStatus) {
        case "TrainingCompleted":
          if (computationInterval) {
            handleTrainingSuccess();
          }
          break;
        case "TrainingStarted":
          break;
        case "TrainingFailed":
        default:
          throw new Error("Training failed");
          break;
      }
    }
  };
  const trainingAPIOnError = function (error) {
    const messageLabel =
      featureValue === "Forecast" ? "Training" : "Computation";
    console.error(`${messageLabel} failed!`, error);
    clearComputationTimeOut();
    handleComputationTimedOut();
  };
  const invokeCheckTrainingStatusAPI = async function () {
    if (!analyticsBaseURL || !trainingStatusEndPoint) {
      console.error("Domain URL missing, get status api call canceled");
      clearComputationTimeOut();
      handleComputationTimedOut();
      return;
    }

    invokeAPI(
      "GET",
      analyticsBaseURL,
      trainingStatusEndPoint,
      trainingAPIOnSuccess,
      trainingAPIOnError,
      null,
      `${currentScreen}/R`,
      null,
      "analytics"
    );
  };

  const handleTrainingSuccess = function () {
    const messageLabel =
      featureValue === "Forecast" ? "Training" : "Computation";
    isTrainingInProgress = false;
    if (!isFirstTimeCheckingStatus) {
      showToastMessage(
        `${messageLabel} completed successfully ${
          timePeriodLabel ? `for ${timePeriodLabel}` : ""
        }`,
        "green"
      );
    } else {
      onSubmitButtonClick();
    }
    isFirstTimeCheckingStatus = false;
    clearComputationTimeOut();
    clearComputationInterval();
  };

  const handleComputationTimedOut = function () {
    clearComputationInterval();
    if (!isFirstTimeCheckingStatus) {
      const messageLabel =
        featureValue === "Forecast" ? "Training" : "Computation";
      showToastMessage(`${messageLabel} failed, try again after some time`);
    } else {
      onSubmitButtonClick();
    }
    isFirstTimeCheckingStatus = false;
  };

  const clearComputationTimeOut = function () {
    if (computationTimeOut) {
      clearTimeout(computationTimeOut);
      computationTimeOut = null;
    }
  };

  const clearComputationInterval = function () {
    if (computationInterval) {
      clearInterval(computationInterval);
      computationInterval = null;
    }
    isTrainingInProgress = false;
    defaultTimePeriod = [];
    timePeriodValue = null;
    timePeriodLabel = "";
  };

  const updatingTrainingDateonSuccess = function () {
    const messageLabel =
      featureValue === "Forecast" ? "Training" : "Computation";

    showToastMessage(
      `${messageLabel} started ${
        timePeriodLabel ? `for ${timePeriodLabel}` : ""
      }`,
      "green"
    );
    computationInterval = setInterval(invokeCheckTrainingStatusAPI, duration);
    computationTimeOut = setTimeout(handleComputationTimedOut, 120000);
  };

  const updatingTrainingDateonError = function () {
    showToastMessage("Unable to update training data");
    defaultTimePeriod = [];
    timePeriodValue = null;
    timePeriodLabel = "";
    isTrainingInProgress = false;
  };

  const foreCastPlotAPIOnSuccess = function (_, data) {
    if (data?.error_message) {
      showToastMessage(data.error_message);
    }
    plotAPIFailed = false;
  };

  const foreCastPlotAPIOnError = function () {
    plotAPIFailed = true;
  };

  const anomalyDetectionPlotAPIOnSuccess = function () {
    plotAPIFailed = false;
  };

  const anomalyDetectionPlotAPIOnError = function () {
    plotAPIFailed = true;
  };

  const heatMapPlotAPIOnSuccess = function () {
    plotAPIFailed = false;
  };

  const heatMapPlotAPIOnError = function () {
    plotAPIFailed = true;
  };

  const onSelectedTabChange = function (type) {
    selectedTab = type;
    sessionStorage.setItem("del-analytics-selected-tab", selectedTab);
    if (type === "analytics") {
      redrawCharts();
    } else {
      clearCharts();
    }
  };

  const onOverLayClick = function () {
    showAddCommentModal = false;
    showOverlay = false;
    commentsTimeStamp = null;
  };

  const onOutsideClick = function (e) {
    const dropDownElement = document.getElementById(
      "del-dropdown-filter-container"
    );
    if (
      dropDownElement &&
      !(
        dropDownElement?.contains(e.target) ||
        e.target.matches("svg") ||
        e.target.matches("path")
      )
    ) {
      isDropDownOpen = false;
    }
  };

  onMount(() => {
    window.addEventListener("click", onOutsideClick);
  });

  $: isSubmitDisabled = !(
    groupValue &&
    featureValue &&
    resolutionValue &&
    parameterValue &&
    selectedDate &&
    !isTrainingInProgress
  );
</script>

<div class="del-analytics-component">
  {#if (analyticsConfigAPIAwaiting || isTrainingInProgress) && selectedTab === "analytics"}
    <Loader />
  {/if}
  {#if showOverlay}<div
      class="del-analytics-prefab-overlay-container"
      on:click={() => {
        onOverLayClick();
      }}
      on:keypress={() => {
        onOverLayClick();
      }}
    />{/if}
  <div class="del-analytics-tab-container">
    <div class="tab-header-container">
      <button
        class={`tab-button ${selectedTab === "analytics" ? "selected" : ""}`}
        on:click={() => {
          onSelectedTabChange("analytics");
        }}>Analytics</button
      >
      <button
        class={`tab-button ${
          selectedTab === "dynamic-report" ? "selected" : ""
        }`}
        on:click={() => {
          onSelectedTabChange("dynamic-report");
        }}>Dynamic Report</button
      >
    </div>
    <div class="tab-content">
      <div
        class={`analytics-container ${
          selectedTab === "analytics" ? "" : "del-hide-chart"
        }`}
      >
        <div class="del-analytics-top-container">
          <div class="del-dropdown-container">
            <DelSelect
              options={nodeOptions}
              defaultSelection={defaultSelectedNodeId
                ? [defaultSelectedNodeId]
                : []}
              onSelectionChange={(newVal) => {
                if (newVal?.length) {
                  nodeSelectChange(newVal[0]);
                } else {
                  nodeSelectChange(null);
                }
              }}
            />
          </div>
          {#if showTrainingButton && featureValue !== "Heatmap"}
            <div
              class="del-dropdown-parent-container del-dropdown-training-container"
            >
              <div class="del-training-period-container">
                {currentTrainingPeriod}
              </div>
              <div class="del-dropdown-container">
                <DelSelect
                  options={timePeriodOptions}
                  defaultSelection={defaultTimePeriod}
                  onSelectionChange={(newVal) => {
                    if (newVal?.length) {
                      onTimePeriodUpdated(newVal[0]);
                    } else {
                      onTimePeriodUpdated(null);
                    }
                  }}
                />
              </div>
              <div>
                <button
                  class="del-analytics-submit-button"
                  disabled={isTrainingInProgress ||
                    !timePeriodValue ||
                    noDataMessage ||
                    plotAPIFailed}
                  on:click={trainingSubmitButtonClick}
                  >{featureValue === "Forecast" ? "Train" : "Compute"}</button
                >
              </div>
            </div>
          {/if}
        </div>
        {#if showAnalyticsDropDowns}
          <div
            class="del-analytics-inner-containers del-dropdown-parent-container"
          >
            <div class="del-dropdown-container">
              <DelSelect
                options={groupOptions}
                defaultSelection={defaultSelectedGroup
                  ? [defaultSelectedGroup.name]
                  : []}
                onSelectionChange={(newVal) => {
                  if (newVal?.length) {
                    groupSelectChange(newVal[0]);
                  } else {
                    groupSelectChange(null);
                  }
                }}
              />
            </div>
            <div class="del-dropdown-container">
              <DelSelect
                options={parameterOptions}
                defaultSelection={defaultSelectedParameter
                  ? [defaultSelectedParameter.id]
                  : []}
                onSelectionChange={(newVal) => {
                  if (newVal?.length) {
                    parameterSelectChange(newVal[0]);
                  } else {
                    parameterSelectChange(null);
                  }
                }}
              />
            </div>
            <div class="del-dropdown-container">
              <DelSelect
                options={featureOptions.map((name) => ({ name }))}
                defaultSelection={defaultSelectedFeature
                  ? [defaultSelectedFeature]
                  : []}
                onSelectionChange={(newVal) => {
                  if (newVal?.length) {
                    featureSelectChange(newVal[0]);
                  } else {
                    featureSelectChange(null);
                  }
                }}
              />
            </div>
            <div class="del-dropdown-container">
              <DelDatePicker
                {defaultStartDate}
                {defaultEndDate}
                {defaultDate}
                showRangePicker={true}
                autoApply={true}
                customRangesToShow={datePickerRanges}
                onDateChanged={(date) => {
                  selectedDate = date;
                  datePickerChange();
                }}
                maxDate={datePickerMaxDate}
                maxDays={200}
                firstDay={firstDayOfWeek}
              />
            </div>
            <div class="del-dropdown-container">
              <DelSelect
                options={resolutionOptions?.length
                  ? resolutionOptions.map((name) => ({ name }))
                  : []}
                defaultSelection={defaultSelectedResolution
                  ? [defaultSelectedResolution]
                  : []}
                onSelectionChange={(newVal) => {
                  if (newVal?.length) {
                    resolutionSelectChange(newVal[0]);
                  } else {
                    resolutionSelectChange(null);
                  }
                }}
              />
            </div>
            <div>
              <button
                class="del-analytics-submit-button"
                disabled={isSubmitDisabled}
                on:click={onSubmitButtonClick}>Submit</button
              >
            </div>
          </div>
        {/if}
        {#if featureValue === "Heatmap" && isSubmitButtonClicked}
          <div
            class={`del-chart-container ${
              chartToShow === "heatmap" ? "" : "del-hide-chart"
            }`}
          >
            <DelHeatMap
              {platformBaseURL}
              {analyticsBaseURL}
              screenName={currentScreen}
              {heatMapDetails}
              {onHeatMapClick}
              onPlotAPIError={heatMapPlotAPIOnError}
              onPlotAPISuccess={heatMapPlotAPIOnSuccess}
              forceClearChart={forceClearHeatMap}
            />
          </div>
        {:else if featureValue === "Anomaly Detection" && isSubmitButtonClicked}
          <div
            class={`del-chart-container ${
              chartToShow === "anomaly" ? "" : "del-hide-chart"
            }`}
          >
            <DelAnomalyDetection
              baseURL={analyticsBaseURL}
              screenName={currentScreen}
              {anomalyDetectionDetails}
              {onAnomalyDetectionClick}
              onPlotAPIError={anomalyDetectionPlotAPIOnError}
              onPlotAPISuccess={anomalyDetectionPlotAPIOnSuccess}
              forceClearChart={forceClearAnomalyDetection}
              {onTrainingPeriodUpdated}
            />

            {#if !forceClearAnomalyDetection}
              <div class="del-comments-table-main-container">
                {#if commentsAPIAwaiting}
                  <Loader />{/if}
                <div
                  class="del-dropdown-parent-container del-dropdown-filter-container"
                >
                  <div id="del-dropdown-filter-container">
                    <DelDropDownCheckBox
                      buttonLabel={"Filter"}
                      options={nodesList}
                      defaultSelection={defaultValuesForNodesFilter}
                      onSelectionUpdated={onNodesFilterSelectionUpdated}
                      bind:isDropDownOpen
                      alignLeft={false}
                    />
                  </div>
                </div>
                <div
                  class="del-anomaly-detection-inner-container del-analytics-comment-table-container"
                >
                  <!-- Report Table -->
                  {#if commentsTableData?.length}
                    <div class="del-analytics-comment-table-inner-container">
                      <table class="del-analytics-comment-table">
                        <thead>
                          {#if commentsTableKeys?.length}
                            <tr class="del-analytics-comment-table-header-row">
                              {#each commentsTableKeys as item (item)}
                                <th
                                  class="del-analytics-comment-table-cell del-analytics-comment-table-header-cell"
                                  >{commentsTableColumnHeadings[item]}</th
                                >
                              {/each}
                            </tr>
                          {/if}
                        </thead>
                        <tbody class="table-body">
                          {#each paginatedData as row}
                            <tr class="del-analytics-comment-table-row">
                              {#each commentsTableKeys as item (item)}
                                <td class={`del-analytics-comment-table-cell`}
                                  >{row[item]}</td
                                >
                              {/each}
                            </tr>
                          {/each}
                        </tbody>
                      </table>
                    </div>
                  {:else if !commentsAPIAwaiting}
                    <div class="no-data-text-centered no-data-message">
                      No Comments Available
                    </div>
                  {/if}
                  <!-- Pagination -->
                  {#if commentsTableData.length > entriesPerPage}
                    <div class="pagination-container">
                      <div class="entries-display">
                        {`Showing ${
                          (currentPage - 1) * entriesPerPage + 1
                        } to ${Math.min(
                          currentPage * entriesPerPage,
                          commentsTableData.length
                        )} of ${commentsTableData.length} entries`}
                      </div>
                      <div class="pagination-buttons">
                        <button
                          class="first-page"
                          disabled={currentPage === 1}
                          on:click={handleFirstPageClick}>First</button
                        >
                        <button
                          class="previous-page"
                          disabled={currentPage === 1}
                          on:click={handlePreviousPageClick}>Previous</button
                        >
                        <span class="current-page"
                          >{`Page ${currentPage} of ${totalPages}`}</span
                        >
                        <button
                          class="next-page"
                          disabled={currentPage === totalPages}
                          on:click={handleNextPageClick}>Next</button
                        >
                        <button
                          class="last-page"
                          disabled={currentPage === totalPages}
                          on:click={handleLastPageClick}>Last</button
                        >
                      </div>
                    </div>
                  {/if}
                </div>
              </div>
            {/if}
          </div>
        {:else if featureValue === "Forecast" && isSubmitButtonClicked}
          <div
            class={`del-chart-container ${
              chartToShow === "forecast" ? "" : "del-hide-chart"
            }`}
          >
            <DelForecast
              baseURL={analyticsBaseURL}
              screenName={currentScreen}
              {forecastDetails}
              forceClearChart={forceClearForecast}
              {onTrainingPeriodUpdated}
              onPlotAPIError={foreCastPlotAPIOnError}
              onPlotAPISuccess={foreCastPlotAPIOnSuccess}
            />
          </div>
        {/if}
        {#if noDataMessage}
          <div class="no-data-text-centered">{noDataMessage}</div>
        {/if}
      </div>
      {#if selectedTab === "dynamic-report"}
        <div
          class={`del-dynamic-report-tab-container ${
            selectedTab === "dynamic-report" ? "" : "del-hide-chart"
          }`}
        >
          <DelDynamicReport
            baseURL={platformBaseURL}
            {currentScreen}
            onGraphClicked={onDynamicReportClick}
          />
        </div>
      {/if}
    </div>
  </div>

  {#if showAddCommentModal}
    <div class="del-analytics-prefab-modal">
      <div class="del-analytics-prefab-modal-header" modal-type="acknowledge">
        <div class="del-analytics-prefab-modal-title">Add Comment</div>
      </div>

      <div class="del-analytics-prefab-modal-comment-container">
        <textarea
          bind:this={commentTextArea}
          on:input={updateCharCount}
          id="del-analytics-prefab-modal-textarea"
          name="del-analytics-prefab-modal-comment"
          class="del-analytics-prefab-modal-comment"
          maxlength="100"
          rows="6"
          cols="300"
        />
      </div>
      <div
        class="del-analytics-prefab-modal-comment-count"
        id="del-analytics-prefab-modal-comment-count"
      >
        {remainingCharCount}
      </div>

      <div class="del-analytics-prefab-modal-buttons-container">
        <button
          class="del-analytics-prefab-modal-cancel-button"
          id="del-analytics-prefab-modal-cancel-button"
          on:click={() => {
            showAddCommentModal = false;
            showOverlay = false;
            commentsTimeStamp = null;
          }}>Cancel</button
        >
        <button
          class="del-analytics-prefab-modal-submit-button"
          id="del-analytics-prefab-modal-submit-button"
          on:click={onSubmitCommentButtonClick}
          disabled={disableCommentsSubmit}>Submit</button
        >
      </div>
    </div>
  {/if}
</div>

<style>
  button {
    cursor: pointer;
  }

  .del-analytics-component {
    position: relative;
  }
  .del-analytics-prefab-overlay-container {
    position: fixed;
    z-index: 108;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }

  .del-analytics-tab-container {
    border-radius: 8px;
    margin: 15px auto;
    border: 1px solid #f0eff7;
    min-height: 500px;
    /*width: 95vw;*/
    font: normal normal normal 14px Roboto;
    background: white;
  }

  .del-chart-container {
    padding: 10px 20px;
  }

  .del-hide-chart {
    display: none;
  }

  .tab-header-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
  }

  .tab-button {
    background: transparent;
    padding: 15px;
    border: none;
    color: #585859;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }

  .tab-button:hover {
    border-bottom: 2px solid #585859;
  }

  .tab-button.selected {
    border-bottom: 2px solid #4b72b9;
  }

  .del-analytics-inner-containers {
    margin: 10px;
  }

  .del-anomaly-detection-inner-container {
    min-height: 200px;
  }

  .del-analytics-title {
    font-size: 20px;
    color: #000;
  }

  .del-analytics-top-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 10px;
  }

  .del-comments-table-main-container {
    position: relative;
    padding: 20px 0;
  }

  .del-dropdown-filter-container {
    justify-content: right;
    position: relative;
    z-index: 2;
  }

  .del-filter-checkbox-container {
    position: absolute;
    right: 0;
    top: 40px;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000010;
    border-radius: 5px;
    padding: 5px;
  }
  .del-dropdown-training-container {
    justify-content: right;
  }

  .del-dropdown-parent-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
  }

  .del-dropdown-container {
    min-width: 180px;
  }

  .del-dropdown-container .form-control {
    border-radius: 10px;
    width: 100%;
    height: 35px;
    padding: 8px 12px;
    font: normal normal normal 14px Roboto;
    line-height: 1.42857143;
    color: #3b3b3b;
    background-color: #fff;
    background-image: none;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    border: 1px solid #e0e0e0;
    padding-right: 25px;
  }

  .del-analytics-submit-button {
    background: #ffffff 0 0 no-repeat padding-box;
    color: #4b72b9;
    border: 1px solid #e3e2e2;
    border-radius: 10px;
    font: normal normal normal 14px Roboto;
    font-weight: 700;
    text-transform: none;
    padding: 8px 12px;
    cursor: pointer;
  }

  .del-analytics-submit-button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  .del-analytics-submit-button:enabled:hover,
  .del-analytics-submit-button:enabled:focus {
    background: #ffffff 0 0 no-repeat padding-box;
    color: #4b72b9;
    border: 1px solid #4b72b9;
  }

  .no-data-text-centered {
    margin-top: 5%;
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 16px;
  }

  .no-data-message {
    font-style: normal;
    margin-top: 10%;
  }

  .del-training-period-container {
    align-self: center;
    font-style: italic;
    padding: 0 2px;
  }

  .del-dynamic-report-tab-container {
    padding: 20px;
  }

  /* Table related style*/

  .del-analytics-comment-table-container {
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000010;
    border-radius: 5px;
    margin: 20px 0;
    padding: 5px;
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #aaaeb4;
  }

  .del-analytics-comment-table-inner-container {
    margin: 0;
    padding: 0;
    overflow-x: auto;
  }

  .del-analytics-comment-table {
    width: 100%;
    position: relative;
    border-spacing: 0;
  }

  .del-analytics-comment-table td:first-child,
  .del-analytics-comment-table th:first-child,
  .del-analytics-comment-table-first-column {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
  }

  .del-analytics-comment-table-cell.del-analytics-comment-table-first-column {
    min-width: 100px;
  }

  .del-analytics-comment-table th:first-child {
    border-radius: 6px 0 0 0;
  }

  .del-analytics-comment-table th:last-child {
    border-radius: 0 6px 0 0;
  }

  .del-analytics-comment-table th:not(:last-child) {
    border-right: 1px solid #aaaeb4;
  }

  .del-analytics-comment-table-cell:not(:last-child) {
    border-right: 1px solid #aaaeb4;
  }

  .del-analytics-comment-table-cell {
    padding: 10px 15px;
    text-align: center;
    font: normal normal normal 15px/21px Roboto;
    color: #636c7b;
    min-width: 225px;
    background-color: #ffffff;
    opacity: 1;
  }

  .del-analytics-comment-table-no-data-cell {
    text-align: center;
    height: 45px;
    position: relative;
  }

  .del-analytics-comment-table-no-data-message {
    position: absolute;
    top: 0;
    left: 40vw;
    padding: 10px 5px;
  }

  .del-analytics-comment-table-header-row {
    text-align: center;
    font: normal normal medium 16px/21px Roboto;
    color: #292727;
    background: #f2f4f5 0 0 no-repeat padding-box;
    border-radius: 5px;
  }

  .del-analytics-comment-table-header-cell {
    text-align: center;
    font: normal normal medium 16px/21px Roboto;
    color: #292727;
    background: #f2f4f5 0 0 no-repeat padding-box;
  }

  .del-analytics-comment-table-row:not(:last-child) {
    border-bottom: 1px solid #aaaeb4;
  }

  /* For Pagination */

  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    padding: 0 5px;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .pagination-buttons button {
    margin: 0;
    background: #7293d1 0 0 no-repeat padding-box;
    border: none;
    color: #ffffff;
    opacity: 1;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
  }

  .pagination-buttons button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .pagination-buttons button:enabled:hover {
    background: #4b72b9;
  }

  .current-page {
    font-weight: bold;
    margin: 0 10px;
  }

  .entries-display {
    font-style: italic;
    margin-right: 10px;
  }

  /* End of Pagination styles */

  /* End of table related styles */

  /* For Modal */

  .del-analytics-prefab-modal {
    position: fixed;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 600;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 160px;
    top: 25%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  .del-analytics-prefab-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    font: normal normal bold 16px/21px Roboto;
    color: #000000;
  }

  .del-analytics-prefab-modal-no-data-message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font: normal normal normal 15px Roboto;
    color: grey;
    padding: 30px 0;
  }

  .del-analytics-prefab-modal-comment-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 0;
  }

  .del-analytics-prefab-modal-comment {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #bababa;
    border-radius: 7px;
    font-size: 14px !important;
    max-width: 100%;
  }

  .del-analytics-prefab-modal-comment-count {
    font: normal normal normal 14px Roboto;
    color: #798b91;
    text-align: right;
  }

  .del-analytics-prefab-modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
    margin-top: 10px;
  }

  button.del-analytics-prefab-modal-submit-button {
    color: #fff;
    background-color: #2c3049;
    border-color: #222639;
    border-radius: 5px;
    padding: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }

  button.del-analytics-prefab-modal-cancel-button {
    color: #e51f1f;
    background: transparent;
    border: none;
    font: normal normal bold 14px Roboto;
    cursor: pointer;
  }

  button.del-analytics-prefab-modal-submit-button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  button.del-analytics-prefab-modal-submit-button:enabled:hover {
    background: #222639;
  }

  @media screen and (min-width: 700px) {
    .del-analytics-prefab-modal {
      width: 450px;
      left: calc(50% - 225px);
      top: 25%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-analytics-prefab-modal {
      width: 68%;
      left: calc(50% - 34%);
      top: 25%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-analytics-prefab-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-analytics-prefab-modal {
      width: 80%;
      left: calc(50% - 40%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-analytics-prefab-modal {
      top: 25%;
    }
  }
</style>
