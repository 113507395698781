<script>
  import Loader from "./Loader.svelte";

  export let currentScreen = "Dashboard";
  export let baseURL = "";
  export let title = "Connected Loads";
  export let nodeId = null;
  export let columnHeadings = {};

  let tableData = [];
  let isAwaitingTableDataAPI = false;

  $: {
    if (currentScreen && baseURL && nodeId) {
      invokeGetTableDataAPI();
    }
  }

  async function getResponse(endpoint) {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const onGetTableDataAPISuccess = function (data) {
    let _tableData = [];
    if (data.ExternalParameters && nodeId) {
      data.ExternalParameters.forEach((item) => {
        if (item.ParameterDetails.NodeID === nodeId) {
          let count = 0;
          item.ParameterDetails.Processes.forEach((process) => {
            for (const constant of process.Constants) {
              constant.Id = ++count;
              _tableData.push(constant);
            }
          });
        }
      });
    }
    tableData = _tableData;
  };

  const invokeGetTableDataAPI = function () {
    tableData = [];
    if (baseURL && currentScreen) {
      const endpoint = "parameters/api/external";
      isAwaitingTableDataAPI = true;
      getResponse(endpoint)
        .then((data) => {
          isAwaitingTableDataAPI = false;
          onGetTableDataAPISuccess(data);
        })
        .catch((error) => {
          isAwaitingTableDataAPI = false;
          console.error("API call failed", error);
        });
    }
  };
</script>

<div class="del-loads-table-container">
  {#if isAwaitingTableDataAPI}
    <Loader />
  {/if}
  <div class="del-loads-table-title">{title}</div>
  <table class="del-loads-table">
    <tr class="del-loads-table-header-row">
      <th class="del-loads-table-cell del-loads-table-header-cell"
        >{columnHeadings?.Id ?? "Sl.No"}</th
      >
      <th class="del-loads-table-cell del-loads-table-header-cell"
        >{columnHeadings?.Name ?? "Load Name"}</th
      >
      <th class="del-loads-table-cell del-loads-table-header-cell"
        >{columnHeadings?.Description ?? "Description"}</th
      >
      <th class="del-loads-table-cell del-loads-table-header-cell"
        >{columnHeadings?.Value ?? "Rated Power"}</th
      >
    </tr>
    {#if tableData?.length}
      {#each tableData as row (row.Id)}
        <tr class="del-loads-table-row">
          <td class="del-loads-table-cell">{row.Id ?? ""}</td>
          <td class="del-loads-table-cell">{row.ConstantName ?? ""}</td>
          <td class="del-loads-table-cell">{row.ConstantDescription ?? ""}</td>
          <td class="del-loads-table-cell">{row.ConstantValue ?? ""}</td>
        </tr>
      {/each}
    {:else}
      <tr class="del-loads-table-row"
        ><td
          class="del-loads-table-cell del-loads-table-no-data-cell"
          colspan="4">No Data Available</td
        ></tr
      >
    {/if}
  </table>
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-loads-table-container {
    overflow-x: auto;
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    position: relative;
  }

  .del-loads-table-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    padding: 10px 15px;
  }

  .del-loads-table {
    width: 100%;
    border-spacing: 0;
  }

  .del-loads-table-cell {
    padding: 10px 15px;
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    color: #222222;
    opacity: 0.77;
    min-width: 100px;
  }

  .del-loads-table-no-data-cell {
    text-align: center;
  }

  .del-loads-table-cell:not(:last-child) {
    border-right: 1px solid #ccd3e7;
  }

  .del-loads-table-header-row {
    border-top: 1px solid #ccd3e7;
    text-align: left;
    font: normal normal bold 12px/16px Roboto;
    color: #202020;
  }

  .del-loads-table-header-cell {
    border-top: 1px solid #ccd3e7;
    border-bottom: 1px solid #ccd3e7;
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    color: #202020;
  }

  .del-loads-table-row:not(:last-child) {
    border-bottom: 1px solid #ccd3e7;
  }

  .del-loads-table-row:nth-child(even) {
    background: #f0f0f0 0 0 no-repeat padding-box;
  }
</style>
