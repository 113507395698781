<script>
  import { onMount } from "svelte";
  import Icon from "@iconify/svelte";

  export let options = [];
  export let buttonLabel = "Show";
  export let checkboxTitle = "";
  export let showSubmitButton = false;
  export let isDropDownOpen = false;
  export let onShowDropDownChange = null;
  export let onSelectionUpdated = null;
  export let defaultSelection = [];
  export let isSelected = false;
  export let alignLeft = true;
  export let showSearchBox = false;
  export let searchBoxPlaceholder = "";

  onMount(() => {
    selectedOptions = [...defaultSelection];
    oldSelection = options.filter((item) =>
      selectedOptions.includes(item.name)
    );
  });

  let selectedOptions = [];
  let isAllOptionChecked = false;
  let previousSelection = [];
  let allOptions = [];
  let allOptionsIds = [];
  let searchQuery = "";
  let oldSelection = [];
  let isFirstLoad = true;

  $: {
    if (options) {
      allOptions = options.map((item) => item.name);
      allOptionsIds = options.map((item) => item.id ?? item.name);
    }
  }

  $: {
    if (defaultSelection) {
      updateDefaultSelection();
    }
  }

  $: {
    if (options?.length && selectedOptions) {
      checkIfFirstLoad();
      handleCheckBoxSelectionChange();
    }
  }

  const debounce = function (func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(func, timeout);
    };
  };

  const checkIfFirstLoad = function () {
    if (isFirstLoad) {
      oldSelection = options.filter((item) =>
        selectedOptions.includes(item.name)
      );
    }
    isFirstLoad = false;
  };

  const updateSelectedValues = function () {
    setAllOptions();
    if (!showSubmitButton) {
      const newSelection = options.filter((item) =>
        selectedOptions.includes(item.name)
      );
      onSelectionUpdated && onSelectionUpdated(newSelection);
    }
  };

  const handleCheckBoxSelectionChange = debounce(updateSelectedValues);

  const onCheckBoxChange = function () {
    const newSelection = getNewSelection();
    oldSelection = [...newSelection];
    selectedOptions = newSelection.map((item) => item.name);
  };

  const getNewSelection = function () {
    let finalSelected = [];
    let newVal = options.filter((item) => selectedOptions.includes(item.name));
    const allFilteredValues = options.filter((item) =>
      allOptions.includes(item.name)
    );
    if (showSearchBox) {
      const commonSelected = allFilteredValues
        .filter((item) =>
          oldSelection.find((element) => element.name === item.name)
        )
        .filter((item) => newVal.find((element) => element.name === item.name));
      const oldValues = oldSelection.filter(
        (item) =>
          !allFilteredValues.find((element) => element.name === item.name)
      );
      const combinedList = newVal.concat(commonSelected, oldValues);

      for (const item of combinedList) {
        if (!finalSelected.find((element) => element.name === item.name)) {
          finalSelected.push(item);
        }
      }
    } else {
      finalSelected = options.filter((item) =>
        selectedOptions.includes(item.name)
      );
    }
    return finalSelected;
  };

  const updateDefaultSelection = function () {
    selectedOptions = [...defaultSelection];
    oldSelection = options.filter((item) =>
      selectedOptions.includes(item.name)
    );
  };

  const setAllOptions = function () {
    if (selectedOptions?.length === options?.length) {
      isAllOptionChecked = true;
    } else {
      isAllOptionChecked = false;
    }
  };

  const onAllOptionChange = function () {
    if (isAllOptionChecked) {
      selectedOptions = options.map((item) => item.name);
    } else {
      selectedOptions = [];
    }
    oldSelection = getNewSelection();
    handleCheckBoxSelectionChange();
  };

  const onButtonClick = function () {
    isDropDownOpen = !isDropDownOpen;
    if (isDropDownOpen) {
      searchQuery = "";
      handleSearchQueryChange();
      previousSelection = [...selectedOptions];
    } else if (showSubmitButton) {
      selectedOptions = [...previousSelection];
      handleCheckBoxSelectionChange();
    }
    onShowDropDownChange && onShowDropDownChange(isDropDownOpen);
  };

  const onSubmitButtonClick = function () {
    onSelectionUpdated && onSelectionUpdated(selectedOptions);
    isDropDownOpen = false;
  };

  const onSearchKeyPress = function (event) {
    if (event.key === "Enter") {
      handleSearchQueryChange();
    }
  };

  const handleSearchQueryChange = function () {
    if (searchQuery) {
      const filteredOptions = options.filter(
        (item) =>
          item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
      );
      allOptions = filteredOptions.map((item) => item.name);
      allOptionsIds = filteredOptions.map((item) => item.id ?? item.name);
    } else {
      allOptions = options.map((item) => item.name);
      allOptionsIds = options.map((item) => item.id ?? item.name);
    }
  };

  const onSearchQueryUpdated = debounce(handleSearchQueryChange);
</script>

<div class="del-dropdown-checkbox-container">
  <div class="del-dropdown-button-container">
    <button
      class={`del-dropdown-button ${isDropDownOpen ? "opened" : ""} ${
        isSelected ? "selected" : ""
      }`}
      on:click={onButtonClick}
      disabled={!options.length}
      >{buttonLabel}
      {#if isDropDownOpen}
        <Icon icon="ep:arrow-up-bold" />
      {:else}
        <Icon icon="ep:arrow-down-bold" />
      {/if}
    </button>
  </div>
  {#if isDropDownOpen}
    <div class="del-checkbox-outer-container">
      <div
        class="del-checkbox-container"
        style={alignLeft ? "left: 0;" : "right:0;"}
      >
        <div class="del-checkbox-inner-container">
          {#if showSearchBox}
            <div class="del-checkbox-searchbox-container">
              <input
                class="del-checkbox-searchbox"
                bind:value={searchQuery}
                on:input={() => {
                  onSearchQueryUpdated();
                }}
                placeholder={searchBoxPlaceholder}
              />
              <div class="search-icon">
                <Icon icon="material-symbols:search" />
              </div>
            </div>
          {/if}
          {#if checkboxTitle}
            <div class="del-checkbox-title">{checkboxTitle}</div>
          {/if}
          {#if !(showSearchBox && searchQuery?.length)}
            <div class="del-checkbox-option select-all-option">
              <label>
                <input
                  type="checkbox"
                  value="select-all"
                  bind:checked={isAllOptionChecked}
                  on:change={onAllOptionChange}
                />All</label
              >
            </div>
          {/if}
          {#each allOptions as option, index (allOptionsIds[index])}
            <div class="del-checkbox-option">
              <label
                ><input
                  type="checkbox"
                  bind:group={selectedOptions}
                  value={option}
                  checkbox-id={option}
                  on:change={() => {
                    onCheckBoxChange();
                  }}
                />{option}</label
              >
            </div>
          {/each}
          {#if !allOptions?.length}
            <div class="del-checkbox-no-data-message">
              {`No matching ${checkboxTitle.toLowerCase() ?? "options"} to show`}
            </div>
          {/if}
        </div>
        {#if showSubmitButton}
          <div class="del-checkbox-submit-button-container">
            <button
              class="del-checkbox-submit-button"
              on:click={onSubmitButtonClick}
              disabled={!options.length}>Submit</button
            >
          </div>
        {/if}
      </div>
    </div>
  {/if}
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-dropdown-checkbox-container {
    position: relative;
  }

  .del-dropdown-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 500 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-dropdown-button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  .del-dropdown-button.opened {
    border: 1px solid #79a4e9;
  }

  .del-dropdown-button.selected {
    border: none;
    background-color: #79a4e9;
    color: white;
  }

  .del-dropdown-button:hover {
    border-color: #79a4e9;
  }

  .del-checkbox-outer-container {
    position: relative;
  }

  .del-checkbox-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    color: #505050;
    padding-bottom: 20px;
  }

  .del-checkbox-container {
    position: absolute;
    z-index: 10;
    top: 2px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    min-width: max-content;
  }

  .del-checkbox-inner-container {
    padding: 15px 10px;
  }

  .del-checkbox-option {
    font: normal normal 500 13px/18px Roboto;
    color: #4d4d4d;
    text-align: left;
  }

  .del-checkbox-option label {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
  }

  .del-checkbox-option:not(:last-child),
  .select-all-option {
    padding-bottom: 15px;
  }

  input[type="checkbox"] {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

  .del-checkbox-submit-button-container {
    padding: 16px;
    border-top: 1px solid #ccd3e7;
    display: flex;
    justify-content: flex-end;
  }

  .del-checkbox-submit-button {
    border: 1px solid #ff6933;
    border-radius: 5px;
    font: normal normal normal 14px/19px Roboto;
    color: #222222;
    background-color: white;
    cursor: pointer;
    padding: 5px 10px;
  }

  .del-checkbox-submit-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .del-checkbox-searchbox-container {
    padding-bottom: 15px;
    position: relative;
  }

  .del-checkbox-searchbox-container .search-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #646060;
    border: none;
    font-size: 16px;
    padding: 0;
    background-color: transparent;
  }

  .del-checkbox-searchbox {
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    min-width: 100px;
    color: #3b3b3b;
    background-color: #fff;
    font-size: 12px;
    padding-right: 25px;
    width: 100%;
    box-sizing: border-box;
  }

  .del-checkbox-no-data-message {
    font: normal normal normal 13px Roboto;
    color: #504c4c;
  }
</style>
