<script>
  import ApexCharts from "apexcharts";
  import Loader from "./Loader.svelte";
  import * as XLSX from "xlsx";
  import moment from "moment";

  export let platformBaseURL = "";
  export let analyticsBaseURL = "";
  export let screenName = "Analytics";
  export let heatMapDetails = null;
  export let defaultColor = null;
  export let exportButtonLabel = null;
  export let onHeatMapClick = null;
  export let onPlotAPISuccess = null;
  export let onPlotAPIError = null;
  export let forceClearChart = null;

  let chartContainer = null;
  let chartElement = null;
  let chartDrawn = false;
  let chartTitle = "";
  let seriesData = null;
  let allRanges = [];
  let rangesList = [];
  let showExportButton = false;
  let disableExportButton = true;
  let rangesAPIAwaiting = false;
  let heatMapAPIAwaiting = false;
  let heatMapData = {};
  let isNoData = false;
  let durationForExport = "";

  const plotEndPoint = "plot";
  const rangeEndPoint = "analyticsapi/api/analyticsconfigdata/heatmapthreshold";

  $: {
    if (platformBaseURL && screenName) {
      invokeGetRangesAPI();
    }
  }

  $: {
    if (analyticsBaseURL && screenName && heatMapDetails) {
      invokeGetHeatMapAPI();
    }
  }

  $: {
    if (forceClearChart) {
      clearChart();
    } else {
      forceDraw();
    }
  }

  const forceDraw = function () {
    if (heatMapData && !heatMapAPIAwaiting) {
      invokeGetHeatMapAPI();
    }
  };

  const invokeGetHeatMapAPI = function () {
    if (!heatMapDetails) return;
    const payload = {
      node: heatMapDetails?.node,
      analysis_type: "Heatmap",
      parameter: heatMapDetails?.parameterId,
      duration: heatMapDetails?.duration,
      resolution: heatMapDetails?.resolution,
      cust_start: heatMapDetails?.customStartTime,
      cust_end: heatMapDetails?.customEndTime,
      param_type: heatMapDetails?.type,
    };
    heatMapData = {};
    heatMapAPIAwaiting = true;
    invokeAPI(
      "POST",
      analyticsBaseURL,
      plotEndPoint,
      onHeatMapAPISuccess,
      onHeatMapAPIError,
      payload,
      `${screenName}/R`,
      {},
      "analytics"
    );
    updateRangesList(allRanges);
    disableExportButton = true;
  };

  const onHeatMapAPISuccess = function (data) {
    heatMapAPIAwaiting = false;
    if (data) {
      heatMapData = { ...data };
    }
    processChartData();
    onPlotAPISuccess && onPlotAPISuccess();
  };

  const onHeatMapAPIError = function (error) {
    chartTitle = "No Data Available";
    isNoData = true;
    heatMapAPIAwaiting = false;
    onPlotAPIError && onPlotAPIError(error);
    durationForExport = "";
    if (chartElement && chartDrawn) {
      chartElement.destroy();
      chartDrawn = false;
    }
  };

  const invokeGetRangesAPI = function () {
    rangesList = [];
    allRanges = [];
    rangesAPIAwaiting = true;
    invokeAPI(
      "GET",
      platformBaseURL,
      rangeEndPoint,
      onRangesAPISuccess,
      onRangesAPIError,
      null,
      `${screenName}/R`
    );
  };

  const onRangesAPISuccess = function (data) {
    rangesAPIAwaiting = false;
    allRanges = [...data];
    updateRangesList(data);
  };

  const onRangesAPIError = function () {
    rangesAPIAwaiting = false;
  };

  const updateRangesList = function (data) {
    let _rangesList = [];
    if (data && heatMapDetails?.parameterId) {
      const parameterId = heatMapDetails?.parameterId;
      const match = data.find((item) => item.ParameterId === parameterId);
      if (match && match.ParameterRanges) {
        _rangesList = match.ParameterRanges.map((item) => ({
          from: item.Min * 1000,
          to: item.Max * 1000,
          color: item.Colour,
          name: `${item.Min}-${item.Max}`,
        }));
      }
    }
    rangesList = [..._rangesList];
    processChartData();
  };

  const processChartData = function () {
    if (!(heatMapAPIAwaiting || rangesAPIAwaiting)) {
      createChart(heatMapData);
    }
  };

  const invokeAPI = function (
    method,
    baseURL,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const noDataChecker = function (item) {
    if (isNaN(parseFloat(item))) {
      return -9999999999998;
    } else {
      return item.toFixed(2) * 1000;
    }
  };

  const convertTZ = function (date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        {
          timeZone: tzString,
        }
      )
    );
  };

  // For setting the default color if the data value doesn't belong to any of the ranges specified
  const findColorsList = function () {
    if (seriesData) {
      const length = seriesData.length;
      const color = defaultColor || "#FFFFFF";
      return Array(length).fill(color);
    }
  };

  const createChart = function (data) {
    if (!data?.data) return;
    let zaxis = [];
    let startdate = null;
    let enddate = null;
    let keys = Object.keys(data.data);
    if (keys.length === 0) {
      if (chartElement && chartDrawn) {
        chartElement.destroy();
        chartDrawn = false;
      }
      chartTitle = "No Data Available";
      isNoData = true;
      return;
    }
    keys.sort();
    let obj = {};
    let obj2 = {};
    let noData = true;
    keys.forEach((item, index) => {
      obj = {};
      obj2 = {};
      let b = "-";
      let day = item;
      let output1 = [day.slice(0, 4), b, day.slice(4)].join("");
      let output2 = [output1.slice(0, 7), b, output1.slice(7)].join("");

      obj.name = moment(new Date(output2)).format("DD MMMM YYYY");
      obj.data = [];
      data.data[item].forEach((item2, index) => {
        obj2 = {};
        obj2.x = `${index + 1}`;
        obj2.y = noDataChecker(item2);
        if (obj2.y !== -9999999999998 && noData) {
          noData = false;
        }
        obj.data.push(obj2);
      });

      zaxis.push(obj);
      if (index === 0) {
        startdate = obj.name;
      }
      if (index === keys.length - 1) {
        enddate = obj.name;
      }
    });

    if (noData) {
      if (chartElement && chartDrawn) {
        chartElement.destroy();
        chartDrawn = false;
      }

      chartTitle = "No Data Available";
      isNoData = true;
      return;
    }

    let range = rangesList;

    let flag = true;

    let titlestartdate = convertTZ(new Date(startdate), "Asia/Kolkata");
    let titlestart =
      (titlestartdate.getDate() > 9
        ? titlestartdate.getDate()
        : `0${titlestartdate.getDate()}`) +
      "-" +
      (titlestartdate.getMonth() + 1 > 9
        ? titlestartdate.getMonth() + 1
        : `0${titlestartdate.getMonth() + 1}`) +
      "-" +
      titlestartdate.getFullYear();

    let titleenddate = convertTZ(new Date(enddate), "Asia/Kolkata");
    let titleend =
      (titleenddate.getDate() > 9
        ? titleenddate.getDate()
        : `0${titleenddate.getDate()}`) +
      "-" +
      (titleenddate.getMonth() + 1 > 9
        ? titleenddate.getMonth() + 1
        : `0${titleenddate.getMonth() + 1}`) +
      "-" +
      titleenddate.getFullYear();
    let titleDuration =
      heatMapDetails?.duration === "Custom"
        ? `${titlestart} to ${titleend}`
        : heatMapDetails?.duration;
    isNoData = false;
    chartTitle = `Heatmap <br> Parameter : ${
      heatMapDetails?.parameter
    } <br> Duration : ${
      titleDuration === "Today" || titleDuration === "Yesterday"
        ? titleDuration +
          ` (${moment(new Date(heatMapDetails?.customStartTime)).format(
            "DD-MM-YYYY"
          )})`
        : titleDuration
    } `;
    durationForExport =
      titleDuration === "Today" || titleDuration === "Yesterday"
        ? titleDuration +
          ` (${moment(new Date(heatMapDetails?.customStartTime)).format(
            "DD-MM-YYYY"
          )})`
        : titleDuration;
    if (flag) {
      seriesData = zaxis;
      let options = {
        noData: {
          text: "No Data Available!",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000",
            fontSize: "14px",
            fontFamily: "Roboto",
          },
        },
        xaxis: {
          type: "category",
          categories: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
          ],
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Roboto",
              fontWeight: 800,
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
        title: {
          align: "center",
          margin: 25,
          show: false,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Roboto",
            color: "#263238",
          },
        },

        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Roboto",
              fontWeight: 800,
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
        colors: findColorsList(),
        series: seriesData,
        stroke: {
          show: true,
          colors: ["#000"],
          width: 2,
          dashArray: 5,
        },
        chart: {
          events: {
            dataPointSelection: function (event, chartContext, config) {
              onHeatMapClick &&
                onHeatMapClick(config.w.config.series[config.seriesIndex]);
            },
          },
          height: 125 + seriesData.length * 23,
          type: "heatmap",
          background: "#fff",
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: range,
            },
            enableShades: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: seriesData.length > 30 ? "right" : "top",
          showForSingleSeries: true,
          offsetY: seriesData.length > 30 ? 225 : 0,
          fontWeight: 800,
          markers: {
            width: 17,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 0,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (item) => {
              if (item === -9999999999998) {
                return "No Data";
              } else return item / 1000 + ` ${heatMapDetails?.unit}`;
            },
            title: {
              formatter: (seriesName) =>
                moment(new Date(seriesName)).format("DD MMM YYYY"),
            },
          },
        },
      };
      disableExportButton = false;
      showExportButton = true;
      if (chartDrawn) {
        chartElement.updateOptions(options, false, false);
      } else {
        chartDrawn = true;
        chartElement = new ApexCharts(chartContainer, options);
        chartElement.render();
      }
    } else {
      durationForExport = "";
    }
  };

  /*
   * To clear the currently drawn chart
   */
  const clearChart = function () {
    if (chartElement && chartDrawn) {
      chartElement.destroy();
      chartDrawn = false;
    }
    durationForExport = "";
    showExportButton = false;
    chartTitle = "";
    isNoData = false;
  };

  /* --------------------------------- Chart related code ends here ----------------------------------- */

  /* ----------------------------- Data Export related code starts here ------------------------------- */

  const exportToCsvExcel = function (filetype) {
    let workbook = XLSX.utils.book_new();
    const fileName = `Heatmap ${heatMapDetails?.parameter} ${durationForExport}.xlsx`;
    let dataArray = [
      [
        "DATE",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
    ];
    let excelColumn = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
    ];
    let headerArray = [
      ["Analysis Type", "Heatmap"],
      ["Company", localStorage.getItem("companyName")],
      ["Parameter", heatMapDetails?.parameter],
      ["Duration", durationForExport],
      ["Resolution", heatMapDetails?.resolution],
      ["Node", heatMapDetails?.node],
      [""],
      [""],
    ];
    const headerStyle = {
      font: {
        name: "Times New Roman",
        sz: 12,
        color: {
          rgb: "FFFFFF",
        },
        bold: true,
      },
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "1F497D",
        },
        fgColor: {
          rgb: "1F497D",
        },
      },
    };
    const contentHeaderStyle = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
    };
    seriesData.forEach((item, index) => {
      dataArray[index + 1] = [];
      dataArray[index + 1][0] = item.name;
      item.data.forEach((item2, index2) => {
        if (item2.y !== -9999999999998)
          dataArray[index + 1][index2 + 1] = item2.y / 1000;
        else dataArray[index + 1][index2 + 1] = "No Data";
      });
    });

    let dataArrayFinal = [...headerArray, ...dataArray];
    let worksheet = XLSX.utils.aoa_to_sheet(dataArrayFinal);
    worksheet["!cols"] = fitToColumn(dataArray);
    for (let i in excelColumn) {
      worksheet[`${excelColumn[i]}9`].s = headerStyle;
    }
    for (let i = 1; i < 7; i++) {
      worksheet[`A${i}`].s = contentHeaderStyle;
      worksheet[`B${i}`].s = contentHeaderStyle;
    }
    let checkIfNotEnd = true;
    let lI = 10;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Heatmap");

    function fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length + 2 : 0))
        ),
      }));
    }
    return XLSX.writeFile(workbook, fileName);
  };

  const onExportDataButtonClick = function ($event, widget) {
    exportToCsvExcel("csv");
  };

  /* -------------------------------- Data Export related code ends here ------------------------------- */
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-heatmap-container">
  {#if heatMapAPIAwaiting || rangesAPIAwaiting}
    <Loader />
  {:else}
    <div
      class={`del-heatmap-title ${
        isNoData ? "del-heatmap-no-data-message" : ""
      }`}
    >
      {@html chartTitle}
    </div>
  {/if}
  <div class="del-heatmap" bind:this={chartContainer} />
  {#if showExportButton}
    <div>
      <button
        class="del-heatmap-export-button"
        on:click={onExportDataButtonClick}
        disabled={disableExportButton}
        >{exportButtonLabel ?? "Export Data"}</button
      >
    </div>
  {/if}
</div>

<style>
  .del-heatmap-container {
    position: relative;
    width: 100%;
    min-height: 300px;
  }

  .del-heatmap-title {
    text-align: center;
    font: normal normal bold 16px Roboto;
    color: #222222;
    opacity: 1;
    padding: 5px;
    line-height: 30px;
  }

  .del-heatmap-no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
    font: normal normal normal 14px Roboto;
    color: #5e5e5e;
  }

  button.del-heatmap-export-button {
    color: #fff;
    background-color: #2c3049;
    border-color: #222639;
    border-radius: 5px;
    padding: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }
  button.del-heatmap-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
</style>
