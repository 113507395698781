<script>
  import Icon from "@iconify/svelte";
  import moment from "moment";
  import Loader from "./Loader.svelte";
  import { exportCSVExcel, generatePDF } from "./exportReports.js";

  export let nodeDetails = null;
  export let startTime = null;
  export let endTime = null;
  export let currentScreen = "Reports";
  export let baseURL = "";
  export let dateTimeFormat = "DD-MM-YYYY hh:mm:ss A";
  export let dateColumnFormat = "DD-MM-YYYY hh:mm:ss A";
  export let columnDetails = [];
  export let showSummary = true;
  export let summaryDetails = [];
  export let entriesPerPage = 10;
  export let showBackButton = true;
  export let onBackButtonClick = null;
  export let groupBy = "none";
  export let includeAllDays = true;
  export let sortInAscending = true;
  export let reportTitle = "Electrical Report";
  export let reportSubTitle = "";
  export let showLoadsTable = false;
  export let excludeStartTime = true;
  export let showColors = false;
  export let showOnlyColumnsWithParameters = false;

  let reportData = [];
  let colorsData = [];
  let paginatedData = [];
  let paginatedColors = [];
  let currentPage = 1;
  let totalPages = 0;
  let reportSummary = {};
  let nodeName = null;
  let nodeId = null;
  let isAwaitingReportDataAPI = false;
  let isAwaitingSummaryDataAPI = false;
  let isAwaitingLoadsDataAPI = false;
  let columnsOrder = [];
  let parametersMap = {};
  let summaryParametersMap = {};
  let cumulativeParametersLength = 0;
  let loadsList = [];

  const telemetryEndPoint = "parameters/telemetry/rawdata/all";
  const aggregationBasicEndPoint = "parameters/telemetry/aggregation";
  const aggregationDerivedEndPoint = "parameters/telemetry/aggregation/derived";
  const aggregationDerivedDailyEndPoint =
    "parameters/telemetry/aggregation/derived/daily";
  const evaluatorEndPoint = "dpe/api/evaluator";

  $: cumulativeEndPoint = `parameters/cumulative?ExcludeStartTime=${excludeStartTime}`;
  $: dailyEndPoint = `parameters/telemetry/aggregation/derived/dailymultiple?includeAllDays=${includeAllDays}`;

  $: {
    nodeName = nodeDetails?.name || "";
    nodeId = nodeDetails?.id || "";
  }

  $: {
    if (
      nodeDetails &&
      startTime &&
      endTime &&
      currentScreen &&
      baseURL &&
      columnDetails &&
      groupBy &&
      (includeAllDays || !includeAllDays)
    ) {
      invokeReportDataAPIs();
    }
  }

  $: {
    if (
      nodeDetails &&
      startTime &&
      endTime &&
      currentScreen &&
      baseURL &&
      summaryDetails &&
      showSummary
    ) {
      invokeGetSummaryAPI();
    }
  }

  $: {
    if (nodeDetails && currentScreen && baseURL && showLoadsTable) {
      invokeGetLoadsDataAPI();
    }
  }

  $: {
    totalPages = Math.ceil(reportData.length / entriesPerPage);
  }

  function findNodeByName(node, targetName) {
    if (!node) {
      return null;
    }

    if (node.name === targetName) {
      return node;
    }

    for (const child of node.children || []) {
      const foundNode = findNodeByName(child, targetName);
      if (foundNode) {
        return foundNode;
      }
    }
    return null;
  }

  const filterParameterId = function (
    nodeDetails,
    parameterCategories,
    deviceCategories,
    type
  ) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return {
                  parameterId: basicParameter.ParameterID,
                  parameterType: "Basic",
                };
              }
            }
          }
          break;

        case "Derived":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return {
                  parameterId: derivedParameter.DerivedParameterId,
                  parameterType: "Derived",
                };
              }
            }
          }
          break;

        case "any":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return {
                  parameterId: basicParameter.ParameterID,
                  parameterType: "Basic",
                };
              }
            }
          }
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return {
                  parameterId: derivedParameter.DerivedParameterId,
                  parameterType: "Derived",
                };
              }
            }
          }
          break;
        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  };

  const getPayloadForReportDataAPI = function (details) {
    columnsOrder = ["Date"].concat(
      details.map(
        (item) => `${item.columnTitle}${item.unit ? ` (${item.unit})` : ""}`
      )
    );
    let telemetryParameters = {};
    let dailyParameters = {};
    let cumulativeParameters = {};
    let parametersFound = [];
    for (const item of details) {
      let parameterIdObject = null;
      if (item.nodeName) {
        const node = findNodeByName(nodeDetails, item.nodeName);
        parameterIdObject = filterParameterId(
          node,
          item.parameterCategories,
          item.deviceCategories,
          item.parameterType
        );
      } else {
        parameterIdObject = filterParameterId(
          nodeDetails,
          item.parameterCategories,
          item.deviceCategories,
          item.parameterType
        );
      }
      const parameterId = parameterIdObject?.parameterId;
      const parameterType = parameterIdObject?.parameterType;
      if (parameterId && parameterType) {
        parametersFound.push({ ...item });
        parametersMap[parameterId] = { ...item };
        switch (item.api) {
          case "telemetry":
            if (
              telemetryParameters[item.aggregation] &&
              telemetryParameters[item.aggregation][parameterType]
            ) {
              telemetryParameters[item.aggregation][parameterType].push(
                parameterId
              );
            } else if (telemetryParameters[item.aggregation]) {
              telemetryParameters[item.aggregation][parameterType] = [
                parameterId,
              ];
            } else {
              telemetryParameters[item.aggregation] = {};
              telemetryParameters[item.aggregation][parameterType] = [
                parameterId,
              ];
            }
            break;
          case "daily":
            if (!dailyParameters[item.aggregation]) {
              dailyParameters[item.aggregation] = [parameterId];
            } else {
              dailyParameters[item.aggregation].push(parameterId);
            }
            break;
          case "cumulative":
            if (cumulativeParameters[parameterType]) {
              cumulativeParameters[parameterType].push(parameterId);
            } else {
              cumulativeParameters[parameterType] = [parameterId];
            }
            break;
          default:
            break;
        }
      }
    }
    let payloads = [];
    for (const item in telemetryParameters) {
      payloads.push({
        endpoint: telemetryEndPoint,
        body: {
          derivedparameters: telemetryParameters[item]["Derived"] ?? [],
          basicParameters: telemetryParameters[item]["Basic"] ?? [],
          starttime: startTime,
          endtime: endTime,
          groupby: groupBy,
          operation: item,
        },
      });
    }
    for (const item in dailyParameters) {
      payloads.push({
        endpoint: dailyEndPoint,
        body: {
          parameters: dailyParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: groupBy,
          operation: item,
        },
      });
    }
    if (Object.keys(cumulativeParameters)?.length) {
      payloads.push({
        endpoint: cumulativeEndPoint,
        body: {
          derivedParameters: cumulativeParameters["Derived"] ?? [],
          basicParameters: cumulativeParameters["Basic"] ?? [],
          endTime: endTime,
          startTime: startTime,
        },
      });
    }

    if (showOnlyColumnsWithParameters) {
      columnsOrder = ["Date"].concat(
        parametersFound.map(
          (item) => `${item.columnTitle}${item.unit ? ` (${item.unit})` : ""}`
        )
      );
    }
    return payloads;
  };

  const invokeReportDataAPIs = function () {
    reportData = [];
    colorsData = [];
    if (
      typeof localStorage === "undefined" ||
      !(
        nodeDetails &&
        startTime &&
        endTime &&
        currentScreen &&
        baseURL &&
        columnDetails &&
        groupBy
      )
    ) {
      return;
    }
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const payloads = getPayloadForReportDataAPI(columnDetails).filter(
      (item) => item?.endpoint
    );

    isAwaitingReportDataAPI = true;
    Promise.all(
      payloads.map((item) => {
        return fetch(`${baseURL}/${item.endpoint}`, {
          method: "POST",
          headers,
          body: JSON.stringify(item.body),
        });
      })
    )
      .then((responses) =>
        Promise.all(
          responses.map((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.warn("API call failed", response);
              return [];
            }
          })
        )
      )
      .then((dataList) => {
        onReportDataReceived(dataList);
        isAwaitingReportDataAPI = false;
      })
      .catch((error) => {
        isAwaitingReportDataAPI = false;
        console.error("API call failed due to Error ", error);
      });
  };

  async function getResponse(endpoint) {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const onGetLoadsDataAPISuccess = function (data) {
    let _loadsList = [];
    if (data.ExternalParameters && nodeId) {
      data.ExternalParameters.forEach((item) => {
        if (item.ParameterDetails.NodeID === nodeId) {
          let count = 0;
          item.ParameterDetails.Processes.forEach((process) => {
            for (const constant of process.Constants) {
              _loadsList.push(`${++count}. ${constant.ConstantName}`);
            }
          });
        }
      });
    }
    loadsList = _loadsList;
  };

  const invokeGetLoadsDataAPI = function () {
    loadsList = [];
    if (baseURL && currentScreen) {
      const endpoint = "parameters/api/external";
      isAwaitingLoadsDataAPI = true;
      getResponse(endpoint)
        .then((data) => {
          isAwaitingLoadsDataAPI = false;
          onGetLoadsDataAPISuccess(data);
        })
        .catch((error) => {
          isAwaitingLoadsDataAPI = false;
          console.error("API call failed", error);
        });
    }
  };

  const formatReportData = function (dataMap, isColor = false) {
    let reportData = [];
    const dates = Object.keys(dataMap);
    if (sortInAscending) {
      dates.sort((a, b) => a - b);
    } else {
      dates.sort((a, b) => b - a);
    }
    for (const date of dates) {
      reportData.push({
        Date: isColor ? null : formatDateTime(date, dateColumnFormat),
        ...dataMap[date],
      });
    }
    return reportData;
  };

  const onReportDataReceived = function (dataList) {
    if (dataList?.length) {
      let reportDataMap = {};
      let colorsDataMap = {};
      for (const data of dataList) {
        for (const item of data) {
          let newReadings = {};
          let colors = {};
          for (const parameter of item?.parameters) {
            const parameterItem = parametersMap[parameter.parameterId];
            if (parameterItem) {
              const reading = parameterItem.divideByThousand
                ? divideByThousand(parameter.reading)
                : convertToTwoDigits(parameter.reading);
              newReadings[
                `${parameterItem.columnTitle}${
                  parameterItem.unit ? ` (${parameterItem.unit})` : ""
                }`
              ] = reading ?? "";
              colors[
                `${parameterItem.columnTitle}${
                  parameterItem.unit ? ` (${parameterItem.unit})` : ""
                }`
              ] = parameter.color ?? null;
            }
          }
          if (reportDataMap[item.unixtime]) {
            reportDataMap[item.unixtime] = {
              ...reportDataMap[item.unixtime],
              ...newReadings,
            };
            colorsDataMap[item.unixtime] = {
              ...colorsDataMap[item.unixtime],
              ...colors,
            };
          } else {
            reportDataMap[item.unixtime] = { ...newReadings };
            colorsDataMap[item.unixtime] = { ...colors };
          }
        }
      }
      reportData = formatReportData(reportDataMap);
      colorsData = formatReportData(colorsDataMap, true);
      updatePagination();
    }
  };

  const getPayloadForSummaryAPI = function (details) {
    let aggregationBasicParameters = {};
    let aggregationDerivedParameters = {};
    let aggregationDerivedDailyParameters = {};
    let evaluatorParameters = [];
    let cumulativeParameters = {};
    cumulativeParametersLength = 0;
    reportSummary = {};
    summaryParametersMap = {};
    for (const item of details) {
      let parameterIdObject = null;
      if (item.nodeName) {
        const node = findNodeByName(nodeDetails, item.nodeName);
        parameterIdObject = filterParameterId(
          node,
          item.parameterCategories,
          item.deviceCategories,
          item.parameterType
        );
      } else {
        parameterIdObject = filterParameterId(
          nodeDetails,
          item.parameterCategories,
          item.deviceCategories,
          item.parameterType
        );
      }
      const parameterId = parameterIdObject?.parameterId;
      const parameterType = parameterIdObject?.parameterType;
      if (parameterId && parameterType) {
        summaryParametersMap[parameterId] = { ...item };
        switch (item.api) {
          case "aggregation-basic":
            if (!aggregationBasicParameters[item.aggregation]) {
              aggregationBasicParameters[item.aggregation] = [parameterId];
            } else {
              aggregationBasicParameters[item.aggregation].push(parameterId);
            }
            break;
          case "aggregation-derived":
            if (!aggregationDerivedParameters[item.aggregation]) {
              aggregationDerivedParameters[item.aggregation] = [parameterId];
            } else {
              aggregationDerivedParameters[item.aggregation].push(parameterId);
            }
            break;
          case "aggregation-any":
            if (parameterType === "Derived") {
              if (!aggregationDerivedParameters[item.aggregation]) {
                aggregationDerivedParameters[item.aggregation] = [parameterId];
              } else {
                aggregationDerivedParameters[item.aggregation].push(
                  parameterId
                );
              }
            } else if (parameterType === "Basic") {
              if (!aggregationBasicParameters[item.aggregation]) {
                aggregationBasicParameters[item.aggregation] = [parameterId];
              } else {
                aggregationBasicParameters[item.aggregation].push(parameterId);
              }
            }
            break;
          case "aggregation-derived-daily":
            if (!aggregationDerivedDailyParameters[item.aggregation]) {
              aggregationDerivedDailyParameters[item.aggregation] = [
                parameterId,
              ];
            } else {
              aggregationDerivedDailyParameters[item.aggregation].push(
                parameterId
              );
            }
            break;
          case "evaluator":
            evaluatorParameters.push(parameterId);
            break;
          case "cumulative":
            if (cumulativeParameters[parameterType]) {
              cumulativeParameters[parameterType].push(parameterId);
            } else {
              cumulativeParameters[parameterType] = [parameterId];
            }
            break;
          default:
            reportSummary[item.columnTitle] = "No Data";
            console.warn("Wrong API given for ", item);
            break;
        }
      } else if (!showOnlyColumnsWithParameters) {
        reportSummary[item.columnTitle] = "No Data";
      }
    }
    let payloads = [];
    for (const item in aggregationBasicParameters) {
      payloads.push({
        endpoint: aggregationBasicEndPoint,
        body: {
          parameters: aggregationBasicParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    for (const item in aggregationDerivedParameters) {
      payloads.push({
        endpoint: aggregationDerivedEndPoint,
        body: {
          parameters: aggregationDerivedParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    for (const item in aggregationDerivedDailyParameters) {
      payloads.push({
        endpoint: aggregationDerivedDailyEndPoint,
        body: {
          parameters: aggregationDerivedDailyParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    if (evaluatorParameters?.length) {
      payloads.push({
        endpoint: evaluatorEndPoint,
        body: {
          parameters: evaluatorParameters,
          starttime: startTime,
          endtime: endTime,
        },
      });
    }
    cumulativeParametersLength = Object.keys(cumulativeParameters)?.length;
    if (cumulativeParametersLength) {
      payloads.push({
        endpoint: cumulativeEndPoint,
        body: {
          derivedParameters: cumulativeParameters["Derived"] ?? [],
          basicParameters: cumulativeParameters["Basic"] ?? [],
          endTime: endTime,
          startTime: startTime,
        },
      });
    }
    return payloads;
  };

  const invokeGetSummaryAPI = function () {
    reportSummary = {};
    if (
      typeof localStorage === "undefined" ||
      !(
        nodeDetails &&
        startTime &&
        endTime &&
        currentScreen &&
        baseURL &&
        summaryDetails &&
        showSummary
      )
    ) {
      return;
    }
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const payloads = getPayloadForSummaryAPI(summaryDetails).filter(
      (item) => item?.endpoint
    );

    isAwaitingSummaryDataAPI = true;
    Promise.all(
      payloads.map((item) => {
        return fetch(`${baseURL}/${item.endpoint}`, {
          method: "POST",
          headers,
          body: JSON.stringify(item.body),
        });
      })
    )
      .then((responses) =>
        Promise.all(
          responses.map((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.warn("API call failed", response);
              return [];
            }
          })
        )
      )
      .then((dataList) => {
        onSummaryDataReceived(dataList);
        isAwaitingSummaryDataAPI = false;
      })
      .catch((error) => {
        isAwaitingSummaryDataAPI = false;
        console.error("API call failed due to Error ", error);
      });
  };

  const findCumulativeReadings = function (data) {
    let readings = {};
    data.reverse();
    for (const item of data) {
      if (Object.keys(readings).length >= cumulativeParametersLength) break;
      for (const parameter of item.parameters) {
        if (!isNaN(parameter?.reading)) {
          readings[parameter.parameterId] = parameter.reading;
        }
      }
    }
    return readings;
  };

  const onSummaryDataReceived = function (dataList) {
    let processedData = {};
    let dataWithOutUnits = {};
    for (const data of dataList) {
      if (data?.length) {
        if (data[0].Id) {
          // Response is of evaluator API
          for (const item of data) {
            if (summaryParametersMap[item.Id]) {
              const parameterItem = summaryParametersMap[item.Id];
              const reading = parameterItem.divideByThousand
                ? divideByThousand(item.Result)
                : convertToTwoDigits(item.Result);
              processedData[parameterItem.columnTitle] =
                reading === null
                  ? "No Data"
                  : `${parameterItem.unit === "Rs" ? "Rs " : ""}${reading}${
                      parameterItem.unit === "Rs"
                        ? ""
                        : ` ${parameterItem.unit}`
                    }`;
              dataWithOutUnits[parameterItem.columnTitle] =
                reading === null ? "No Data" : reading;
            }
          }
        } else if (data[0].parameterid) {
          // Response is of aggregation API
          for (const item of data) {
            if (summaryParametersMap[item.parameterid]) {
              const parameterItem = summaryParametersMap[item.parameterid];
              const reading = parameterItem.divideByThousand
                ? divideByThousand(item.reading)
                : convertToTwoDigits(item.reading);
              processedData[parameterItem.columnTitle] =
                reading === null
                  ? "No Data"
                  : `${parameterItem.unit === "Rs" ? "Rs " : ""}${reading}${
                      parameterItem.unit === "Rs"
                        ? ""
                        : ` ${parameterItem.unit}`
                    }`;
              dataWithOutUnits[parameterItem.columnTitle] =
                reading === null ? "No Data" : reading;
            }
          }
        } else if (data[0].parameters) {
          // Response is of cumulative API
          const cumulativeReadings = findCumulativeReadings(data);
          for (const parameterId in cumulativeReadings) {
            if (summaryParametersMap[parameterId]) {
              const parameterItem = summaryParametersMap[parameterId];
              const reading = parameterItem.divideByThousand
                ? divideByThousand(cumulativeReadings[parameterId])
                : convertToTwoDigits(cumulativeReadings[parameterId]);
              processedData[parameterItem.columnTitle] =
                reading === null
                  ? "No Data"
                  : `${parameterItem.unit === "Rs" ? "Rs " : ""}${reading}${
                      parameterItem.unit === "Rs"
                        ? ""
                        : ` ${parameterItem.unit}`
                    }`;
              dataWithOutUnits[parameterItem.columnTitle] =
                reading === null ? "No Data" : reading;
            }
          }
        }
      }
    }
    let _reportSummary = {};
    // For ensuring the order
    if (showOnlyColumnsWithParameters) {
      const foundParameters = Object.values(summaryParametersMap).map(
        (item) => item.columnTitle
      );
      for (const item of summaryDetails) {
        if (foundParameters.includes(item.columnTitle)) {
          _reportSummary[item.columnTitle] =
            processedData[item.columnTitle] ?? "No Data";
        }
      }
    } else {
      for (const item of summaryDetails) {
        _reportSummary[item.columnTitle] =
          processedData[item.columnTitle] ?? "No Data";
      }
    }
    for (const item of summaryDetails) {
      if (item.isCalculated && item.valuesToAdd?.length) {
        let sum = 0;
        for (const element of item.valuesToAdd) {
          if (dataWithOutUnits[element] && !isNaN(dataWithOutUnits[element])) {
            sum += parseFloat(dataWithOutUnits[element]);
          }
        }
        sum = item.divideByThousand
          ? divideByThousand(sum)
          : convertToTwoDigits(sum);
        _reportSummary[item.columnTitle] = `${
          item.unit === "Rs" ? "Rs " : ""
        }${sum}${item.unit === "Rs" ? "" : ` ${item.unit}`}`;
      }
    }
    reportSummary = { ..._reportSummary };
  };

  const formatDateTime = function (value, format) {
    const dateFormat = format ? format : dateTimeFormat;
    return !value || isNaN(value)
      ? ""
      : moment(new Date(parseInt(value * 1000))).format(dateFormat);
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const divideByThousand = function (value) {
    if (isNaN(value)) return null;
    return convertToTwoDigits(value / 1000);
  };

  const updatePagination = function () {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    paginatedData = reportData.slice(startIndex, endIndex);
    paginatedColors = colorsData.slice(startIndex, endIndex);
  };

  const handleFirstPageClick = function () {
    currentPage = 1;
    updatePagination();
  };
  const handleLastPageClick = function () {
    currentPage = totalPages;
    updatePagination();
  };
  const handleNextPageClick = function () {
    currentPage++;
    updatePagination();
  };
  const handlePreviousPageClick = function () {
    currentPage--;
    updatePagination();
  };

  // Export report related functions
  const handlePDFExportButtonClick = function () {
    handleReportExport("pdf");
  };
  const handleCSVExportButtonClick = function () {
    handleReportExport("csv");
  };
  const handleExcelExportButtonClick = function () {
    handleReportExport("xls");
  };

  const handleReportExport = function (exportType) {
    const startTimeFormatted = formatDateTime(startTime);
    const endTimeFormatted = formatDateTime(endTime);
    const reportDataForDownload = reportData.map((row) => {
      let newRow = {};
      for (const column of columnsOrder) {
        newRow[column] = row[column] ?? "";
      }
      return newRow;
    });

    if (exportType === "pdf") {
      generatePDF(
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        reportSummary,
        reportDataForDownload,
        columnsOrder,
        showLoadsTable,
        loadsList,
        reportTitle,
        reportSubTitle || `Report of ${nodeName}`,
        columnsOrder.length
      );
    } else {
      exportCSVExcel(
        exportType,
        reportTitle,
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        reportSummary,
        reportDataForDownload,
        showLoadsTable,
        loadsList,
        reportTitle,
        reportSubTitle || `Report of ${nodeName}`
      );
    }
  };
</script>

<div class="del-report-container">
  {#if isAwaitingReportDataAPI}
    <Loader />
  {/if}
  <div class="del-report-header-container">
    <div
      class="del-report-header-inner-container del-report-header-container-top"
    >
      <div class="flex-container">
        {#if showBackButton}
          <div>
            <button
              class="del-report-header-back-button"
              on:click={() => {
                onBackButtonClick && onBackButtonClick();
              }}><Icon icon="ep:back" /></button
            >
          </div>{/if}
        <div>
          <div class="del-report-header-main-title">
            {reportTitle || "Electrical Report"}
          </div>
          <div class="del-report-header-sub-title">
            {reportSubTitle || `Report of ${nodeName}`}
          </div>
        </div>
      </div>
      <div class="del-event-report-header-right-container">
        <div>
          <div class="del-report-header-titles">Download</div>
          <div class="flex-container del-report-table-export-buttons-container">
            <button
              name="csvDownloadButton"
              title={reportData?.length ? "Export CSV" : "No data to download"}
              class="del-report-table-export-button csv-export-button"
              on:click={handleCSVExportButtonClick}
              disabled={!reportData?.length}
            />
            <button
              name="excelDownloadButton"
              title={reportData?.length
                ? "Export Excel"
                : "No data to download"}
              class="del-report-table-export-button excel-export-button"
              on:click={handleExcelExportButtonClick}
              disabled={!reportData?.length}
            />
            <button
              name="pdfDownloadButton"
              title={reportData?.length ? "Export PDF" : "No data to download"}
              class="del-report-table-export-button pdf-export-button"
              on:click={handlePDFExportButtonClick}
              disabled={!reportData?.length}
            />
          </div>
        </div>
        <div>
          <div class="del-report-header-titles del-report-summary-title">
            Report Summary
          </div>
          <div
            class="del-report-header-titles del-report-header-small-titles del-report-header-dates"
          >
            {`From : ${formatDateTime(startTime)}`}
          </div>
          <div
            class="del-report-header-titles del-report-header-small-titles del-report-header-dates"
          >
            {`To : ${formatDateTime(endTime)}`}
          </div>
        </div>
      </div>
    </div>
    <div
      class="del-report-header-inner-container del-report-header-container-bottom"
    >
      {#each Object.keys(reportSummary) as item (item)}
        <div class="del-report-summary-item">
          <div class="del-report-summary-label">{item}</div>
          <div class="del-report-summary-separator">:</div>
          <div class="del-report-summary-value">
            {reportSummary[item]}
          </div>
        </div>
      {/each}
    </div>
  </div>
  {#if showLoadsTable}
    <div class="del-electrical-report-loads-table">
      <div class="del-electrical-report-loads-table-header">List of Loads</div>
      <div class="del-electrical-report-loads-table-body">
        {#if loadsList?.length}
          {#each loadsList as load (load)}
            <div class="del-electrical-report-loads-table-item">{load}</div>
          {/each}
        {:else}
          <div class="no-data-message">No Loads Available</div>
        {/if}
      </div>
    </div>
  {/if}
  <div class="del-report-table-container">
    <!-- Report Table -->
    {#if reportData?.length}
      <div class="del-report-table-inner-container">
        <table class="del-report-table">
          <thead>
            {#if columnsOrder?.length}
              <tr class="del-report-table-header-row">
                {#each columnsOrder as item (item)}
                  <th class="del-report-table-cell del-report-table-header-cell"
                    >{item}</th
                  >
                {/each}
              </tr>
            {/if}
          </thead>
          <tbody class="table-body">
            {#each paginatedData as row, index}
              <tr class="del-report-table-row">
                {#each columnsOrder as item (item)}
                  <td class={`del-report-table-cell`}
                    ><span
                      class={`${
                        showColors &&
                        paginatedColors[index] &&
                        paginatedColors[index][item]
                          ? "del-report-table-cell-item"
                          : ""
                      }`}
                      style={`background:${
                        showColors &&
                        paginatedColors[index] &&
                        paginatedColors[index][item]
                          ? paginatedColors[index][item]
                          : "transparent"
                      };`}>{row[item] ?? ""}</span
                    ></td
                  >
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {:else if !isAwaitingReportDataAPI}
      <div class="no-data-message">No Data Available</div>
    {/if}
    <!-- Pagination -->
    {#if reportData.length > entriesPerPage}
      <div class="pagination-container">
        <div class="entries-display">
          {`Showing ${(currentPage - 1) * entriesPerPage + 1} to ${Math.min(
            currentPage * entriesPerPage,
            reportData.length
          )} of ${reportData.length} entries`}
        </div>
        <div class="pagination-buttons">
          <button
            class="first-page"
            disabled={currentPage === 1}
            on:click={handleFirstPageClick}>First</button
          >
          <button
            class="previous-page"
            disabled={currentPage === 1}
            on:click={handlePreviousPageClick}>Previous</button
          >
          <span class="current-page"
            >{`Page ${currentPage} of ${totalPages}`}</span
          >
          <button
            class="next-page"
            disabled={currentPage === totalPages}
            on:click={handleNextPageClick}>Next</button
          >
          <button
            class="last-page"
            disabled={currentPage === totalPages}
            on:click={handleLastPageClick}>Last</button
          >
        </div>
      </div>
    {/if}
  </div>
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-report-container {
    position: relative;
  }
  .del-report-table-container {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    margin: 20px 0;
    padding: 10px;
    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .del-report-header-container {
    background: #6e90d0 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0;
    margin-top: 20px;
  }

  .del-report-header-inner-container {
    display: flex;
    align-content: center;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }

  .del-report-header-container-top {
    background: #4b72b9;
    min-height: 100px;
    border-radius: 10px 10px 0 0;
    background-image: url("./../images/headerBackground.svg");
  }

  .del-report-header-container-bottom {
    justify-content: start;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 20px 3px 0;
    gap: 15px;
  }

  .del-report-summary-item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    padding-left: 0;
    gap: 10px;
  }

  .del-report-summary-item:not(:last-child) {
    border-right: 1px solid #b2b2b2;
  }

  .del-report-summary-label,
  .del-report-summary-separator {
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .del-report-summary-value {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #16558f;
    opacity: 1;
  }

  .del-report-header-back-button {
    background: #7293d1 0 0 no-repeat padding-box;
    border: 1px solid #4565a0;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .del-report-header-back-button:enabled:hover,
  .del-report-header-back-button:enabled:active,
  .del-report-header-back-button:enabled:focus {
    transform: scale(1.05);
  }

  .del-report-header-main-title {
    text-align: left;
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-sub-title {
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-titles {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-small-titles {
    font-size: 14px;
  }

  .del-report-table-export-buttons-container {
    margin-top: 10px;
  }

  .del-report-table-export-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 6px;
    padding: 2px;
  }

  .del-report-table-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .del-report-table-export-button:enabled:hover,
  .del-report-table-export-button:enabled:active,
  .del-report-table-export-button:enabled:focus {
    transform: scale(1.05);
  }

  .csv-export-button {
    background-image: url("./../images/csvLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .pdf-export-button {
    background-image: url("./../images/pdfLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .excel-export-button {
    background-image: url("./../images/excelLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .del-report-summary-title {
    margin-bottom: 10px;
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .del-report-header-dates {
    text-align: right;
  }

  button {
    cursor: pointer;
    border: none;
  }

  .no-data-message {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    font: italic normal normal 16px/21px Roboto;
  }

  .del-electrical-report-loads-table-body .no-data-message {
    margin: 10px 0;
  }

  .del-event-report-header-right-container {
    display: flex;
    gap: 80px;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  /* For Report Table */
  .del-report-table-inner-container {
    overflow-x: auto;
    border: 1px solid #d3d3d3;
    margin: 0;
    padding: 0;
  }
  .del-report-table {
    width: 100%;
    border-spacing: 0;
    position: relative;
  }

  .del-report-table td:first-child,
  .del-report-table th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    border-right: 1px solid #d3d3d3;
    min-width: 300px;
  }

  .del-report-table th:last-child {
    border-right-color: #79919a;
  }

  .del-report-table-cell {
    padding: 10px 15px;
    text-align: center;
    font: normal normal normal 15px/21px Roboto;
    color: #000000;
    opacity: 0.77;
    min-width: 250px;
    border: 1px solid #d3d3d3;
    background-color: #ffffff;
    opacity: 1;
  }

  .del-report-table-no-data-cell {
    text-align: center;
  }

  .del-report-table-header-row {
    border: 1px solid #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }

  .del-report-table-filter-row {
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #beccd1;
    border: 1px solid #d3d3d3;
  }

  .del-report-table-header-cell {
    border-color: #79919a;
    border-right-color: #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }
  .del-report-table-date {
    margin-left: 1ch;
    color: #798b91;
  }

  .del-report-table-cell-item {
    padding: 5px 10px;
    border-radius: 10px;
    color: #000000;
  }

  .del-report-table-event-critical {
    color: #e1464a;
  }

  .del-report-table-event-warning {
    color: #fcb900;
  }

  .del-report-table-event-informational {
    color: #4caf50;
  }

  .del-report-table-event-active {
    color: #ff5555;
  }

  .del-report-table-event-inactive {
    color: #4b72b9;
  }

  .del-report-table-button {
    color: #fff;
    background-color: #2c3049;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
  }

  .del-report-table-button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .del-report-table-button:enabled:hover {
    background: #222639;
  }

  /* For Pagination */
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .pagination-buttons button {
    margin: 0;
    background: #7293d1 0 0 no-repeat padding-box;
    border: none;
    color: #ffffff;
    opacity: 1;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
  }

  .pagination-buttons button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .pagination-buttons button:enabled:hover {
    background: #4b72b9;
  }

  .current-page {
    font-weight: bold;
    margin: 0 10px;
  }

  .entries-display {
    font-style: italic;
    margin-right: 10px;
  }

  /* End of Pagination styles */

  .del-electrical-report-loads-table {
    margin: 15px 0;
  }

  /* For Loads Table */
  .del-electrical-report-loads-table-header {
    background: #79919a;
    border: 2px solid #79919a;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 10px 15px;
  }

  .del-electrical-report-loads-table-body {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;
  }

  .del-electrical-report-loads-table-item {
    width: calc(20% - 32px);
    padding: 10px 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d3d3d3;
    opacity: 1;
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #4b72b9;
  }

  @media (max-width: 1024px) {
    .del-electrical-report-loads-table-item {
      width: calc(33.33% - 32px);
    }
  }
</style>
