<script>
  import { Chart } from "chart.js/auto";
  import moment from "moment";
  import Loader from "./Loader.svelte";
  import { onMount } from "svelte";

  export let baseURL = "";
  export let currentScreen = "Dashboard";
  export let graphConfig = null;
  export let nodeDetails = null;
  export let startTime = null;
  export let endTime = null;
  export let legendPosition = "top";
  export let legendAlignment = "end";
  export let paddingLeft = null;
  export let paddingRight = null;
  export let paddingTop = null;
  export let paddingBottom = null;
  export let groupBy = "day";
  export let operation = "sum";
  export let unit = "kWh";
  export let xAxisFormat = "DD";
  export let includeAllDays = true;
  export let chartTitle = "";
  export let chartHeight;
  export let borderRadius = 3;
  export let xAxisTitle = "";
  export let yAxisTitle = "";
  export let showLegends = true;
  export let barThickness;
  export let hoverDateTimeFormat = "DD MMM YYYY";
  export let showFooter = true;

  let chart = null;
  let chartElement = null;
  let xAxisLabels = [];
  let chartData = [];
  let showNoDataMessage = false;
  let dailyParameters = [];
  let telemetryParameters = [];
  let telemetryDataAwaiting = false;
  let dailyDataAwaiting = false;

  $: {
    if (
      baseURL &&
      groupBy &&
      operation &&
      currentScreen &&
      startTime &&
      endTime
    ) {
      clearChart();
      invokeAPIs();
    }
  }

  $: {
    if (nodeDetails && graphConfig) {
      clearChart();
      getParameters();
    }
  }
  $: {
    updateChartOptions("yaxistitle", yAxisTitle);
  }
  $: {
    updateChartOptions("xaxistitle", xAxisTitle);
  }
  $: {
    updateChartOptions("showlegends", showLegends);
  }
  $: {
    updateChartOptions("legendposition", legendPosition);
  }
  $: {
    updateChartOptions("legendalignment", legendAlignment);
  }
  $: {
    updateChartOptions("barthickness", barThickness);
  }
  $: {
    updateChartOptions("leftpadding", paddingLeft);
  }
  $: {
    updateChartOptions("rightpadding", paddingRight);
  }
  $: {
    updateChartOptions("toppadding", paddingTop);
  }
  $: {
    updateChartOptions("bottompadding", paddingBottom);
  }

  onMount(() => {
    clearChart();
    drawChart();
  });

  async function getResponse(endpoint, payload, method = "POST") {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const checkForNoData = function (value) {
    if (isNaN(value)) return null;
    return convertToTwoDigits(value) ?? null;
  };

  const processData = function (data) {
    if (data?.length) {
      showNoDataMessage = false;
      for (const timeStamp of xAxisLabels) {
        for (const item of data) {
          if (timeStamp === item.unixtime) {
            for (const parameter of item.parameters) {
              const match = chartData.find(
                (element) => element.parameterId === parameter.parameterId
              );
              if (match) {
                match?.data?.push(checkForNoData(parameter.reading));
              }
            }
            break;
          }
        }
      }
    } else {
      showNoDataMessage = true;
    }

    if (!(telemetryDataAwaiting || dailyDataAwaiting)) {
      if (chart) {
        updateChart();
      } else {
        drawChart;
      }
    }
  };

  const invokeAPIs = function () {
    if (
      baseURL &&
      startTime &&
      endTime &&
      groupBy &&
      operation &&
      currentScreen
    ) {
      let endpoint = `parameters/telemetry/aggregation/derived/dailymultiple?includeAllDays=${includeAllDays}`;
      let payload = {};

      xAxisLabels = createTimeDivisions(startTime, endTime);
      if (dailyParameters?.length) {
        payload = {
          operation,
          groupby: groupBy,
          parameters: dailyParameters,
          endtime: endTime,
          starttime: startTime,
        };
        // if (!dailyDataAwaiting) {
        dailyDataAwaiting = true;
        getResponse(endpoint, payload)
          .then((data) => {
            dailyDataAwaiting = false;
            processData(data);
          })
          .catch((error) => {
            dailyDataAwaiting = false;
            console.error("API call failed", error);
          });
        // }
      }
      if (telemetryParameters?.length) {
        const derivedParameters = telemetryParameters.map(
          (item) => item.type === "Derived"
        );
        const basicParameters = telemetryParameters.map(
          (item) => item.type === "Basic"
        );
        endpoint = "parameters/telemetry/rawdata/all";
        payload = {
          basicParameters,
          derivedParameters,
          operation,
          starttime: startTime,
          endtime: endTime,
        };
        // if (!telemetryDataAwaiting) {
        telemetryDataAwaiting = true;
        getResponse(endpoint, payload)
          .then((data) => {
            telemetryDataAwaiting = false;
            processData(data);
          })
          .catch((error) => {
            telemetryDataAwaiting = false;
            console.error("API call failed", error);
          });
        // }
      }
    }
  };

  const filterParameterId = function (
    nodeDetails,
    parameterCategories,
    deviceCategories,
    type
  ) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;

        case "Derived":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          break;

        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  };

  const getParameters = function () {
    chartData = [];
    telemetryParameters = [];
    dailyParameters = [];
    for (const item of graphConfig) {
      const parameterId = filterParameterId(
        nodeDetails,
        item.parameterCategories,
        item.deviceCategories,
        item.type
      );
      if (parameterId) {
        switch (item.apiType) {
          case "telemetry":
            telemetryParameters.push({
              id: parameterId,
              type: item.type,
            });
            break;
          case "daily":
            dailyParameters.push(parameterId);
            break;
          default:
            break;
        }
      } else {
        console.warn("Unable to find parameter id for item, ", item);
      }
      chartData.push({
        label: item.displayName,
        data: [],
        parameterId,
        borderColor: item.color,
        pointBackgroundColor: item.color,
        pointStrokeColor: item.color,
        backgroundColor: item.color,
        unit,
        borderRadius: borderRadius || 3,
      });
    }
    invokeAPIs();
  };

  const createTimeDivisions = function (t1, t2) {
    let start = t1 * 1000;
    let end = t2 * 1000;
    let difference = 86400000;
    let labels = [];
    switch (groupBy) {
      case "day":
        difference = 86400000;
        break;
      case "hour":
        difference = 3600000;
        break;
      case "week":
        difference = 86400000 * 7;
        break;
    }
    for (let i = start; i < end; i = i + difference) {
      labels.push(i / 1000);
    }
    return labels;
  };

  const updateChartOptions = function (key, newVal) {
    if (chart && key) {
      switch (key) {
        case "yaxistitle":
          chart.options.scales.y.title.text = newVal || "";
          chart.update();
          break;
        case "xaxistitle":
          chart.options.scales.x.title.text = newVal || "";
          chart.update();
          break;
        case "showlegends":
          chart.options.plugins.legend.display = newVal;
          chart.update();
          break;
        case "legendposition":
          chart.options.plugins.legend.position = newVal || "top";
          chart.update();
          break;
        case "legendalignment":
          chart.options.plugins.legend.align = newVal || "end";
          chart.update();
          break;
        case "barthickness":
          chart.options.barThickness = newVal || 10;
          chart.update();
          break;
        case "leftpadding":
          chart.options.layout.padding.left = newVal;
          chart.update();
          break;
        case "rightpadding":
          chart.options.layout.padding.right = newVal;
          chart.update();
          break;
        case "toppadding":
          chart.options.layout.padding.top = newVal;
          chart.update();
          break;
        case "bottompadding":
          chart.options.layout.padding.bottom = newVal;
          chart.update();
          break;
        default:
          break;
      }
    }
  };

  const updateChart = function () {
    if (chart) {
      chart.data.labels = xAxisLabels;
      chart.data.datasets = chartData;
      chart.update();
    }
  };

  const clearChart = function () {
    if (chart) {
      chart.data.labels = [];
      chart.data.datasets = [];
      xAxisLabels = [];
      for (const item of chartData) {
        item.data = [];
      }
      chart.update();
    }
  };

  const footer = (tooltipItems) => {
    let sum = 0;
    tooltipItems.forEach(function (tooltipItem) {
      sum += tooltipItem?.parsed?.y;
    });
    return `Total : ${sum.toFixed(2)} ${unit || ""}`;
  };

  const formatTimeStamp = function (timestamp, format) {
    if (timestamp && format) {
      return moment(new Date(timestamp * 1000)).format(format);
    }
    return null;
  };

  const drawTicks = function (val, index) {
    return formatTimeStamp(this.getLabelForValue(val), xAxisFormat);
  };

  const drawChart = function () {
    if (!chartElement) return;
    const options = {
      type: "bar",
      data: {
        labels: xAxisLabels,
        datasets: chartData,
      },
      options: {
        maxBarThickness: 46,
        barThickness: barThickness,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "x",
        },
        layout: {
          padding: {
            left: paddingLeft,
            right: paddingRight,
            top: paddingTop,
            bottom: paddingBottom,
          },
        },
        plugins: {
          legend: {
            display: showNoDataMessage ? false : showLegends,
            align: legendAlignment,
            position: legendPosition,
            labels: {
              pointStyle: "rect",
              boxWidth: 10,
              boxHeight: 10,
              curser: "pointer",
              usePointStyle: true,
              font: {
                size: 12,
                family: "Roboto",
                color: "#3E3E3E",
                weight: 400,
              },
            },
          },
          tooltip: {
            callbacks: {
              footer: showFooter ? footer : undefined,
              title: function (context) {
                const unixtime = context[0].label ?? null;
                const format = hoverDateTimeFormat || "DD MMM YYYY";
                if (unixtime && format) {
                  return formatTimeStamp(unixtime, format);
                }
              },
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            display: !showNoDataMessage,
            title: {
              text: xAxisTitle || "",
            },
            stacked: true,
            ticks: {
              callback: drawTicks,
              color: "#8B8B8B",
              font: {
                size: 14,
                family: "Roboto",
                weight: "400",
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: !showNoDataMessage,
            title: {
              align: "end",
              text: yAxisTitle || "",
              display: true,
              color: "#3C3C3C",
              font: {
                size: 12,
                family: "Roboto",
                weight: "600",
              },
            },
            ticks: {
              color: "#8B8B8B",
              font: {
                size: 14,
                family: "Roboto",
                weight: "400",
              },
              callback: function (value) {
                const sign = value > 0 ? 1 : -1;
                if (value * sign > 1000) {
                  return (value / 1000).toFixed(2) + "k";
                } else if (value * sign > 1000000) {
                  return (value / 1000000).toFixed(2) + "M";
                } else if (value * sign > 100000000) {
                  return (value / 100000000).toFixed(2) + "B";
                } else return value;
              },
            },
            stacked: true,
          },
        },
      },
    };
    chart = new Chart(chartElement, options);
  };
</script>

<div class="del-stacked-bar-chart-container">
  <div class="del-stacked-bar-chart-title">{chartTitle}</div>
  <div style={`${chartHeight ? `height:${chartHeight}` : ""}`}>
    <canvas id="del-stacked-bar-chart" bind:this={chartElement} />
  </div>
  {#if showNoDataMessage && !telemetryDataAwaiting && !dailyDataAwaiting}
    <div class="del-stacked-bar-chart-no-data-message">No Data Available</div>
  {/if}
  {#if telemetryDataAwaiting || dailyDataAwaiting}
    <Loader />
  {/if}
</div>

<style>
  .del-stacked-bar-chart-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    padding: 0 1.5em;
  }

  .del-stacked-bar-chart-no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
  }

  .del-stacked-bar-chart-container {
    position: relative;
  }
</style>
