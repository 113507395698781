<script>
  import Icon from "@iconify/svelte";
  import moment from "moment";
  import Loader from "./../Loader/Loader.svelte";
  import { exportCSVExcel, generatePDF } from "./exportReports.js";
  import DelTable from "../DelTable/DelTable.svelte";

  export let nodeDetails = null;
  export let startTime = null;
  export let endTime = null;
  export let currentScreen = "Reports";
  export let baseURL = "";
  export let dateTimeFormat = "MMMM DD YYYY HH:mm:ss";
  export let columnDetails = [];
  export let showSummary = true;
  export let summaryDetails = [];
  export let showBackButton = true;
  export let onBackButtonClick = null;
  export let reportTitle = "Report";

  export let parentLevelType = "";
  export let excludeStartTime = false;
  export let showChildNodeSummary = false;
  export let endTimeOffSet = null;
  export let groupBy = "none";

  let reportData = {};
  let reportSummary = {};
  let nodeName = null;
  let nodeId = null;
  let isAwaitingReportDataAPI = false;
  let isAwaitingSummaryDataAPI = false;
  let isAwaitingAlertCountAPI = false;
  let isAwaitingAlertsAPI = false;
  let columnsOrder = [];
  let parametersMap = {};
  let summaryParametersMap = {};
  let cumulativeParametersLength = 0;
  let nodesMap = {};
  let reportNodesMap = {};
  let showNoDataMessage = false;
  let nodeAlertsMap = {};
  let showAlertModal = false;
  let alertsList = [];

  const alertsTableColumns = [
    "Alert Name",
    "Occured On",
    "Is Acknowledged",
    "Acknowledged On",
  ];

  const aggregationBasicEndPoint = "parameters/telemetry/aggregation";
  const aggregationDerivedEndPoint = "parameters/telemetry/aggregation/derived";
  const aggregationDerivedDailyEndPoint =
    "parameters/telemetry/aggregation/derived/daily";
  const evaluatorEndPoint = "dpe/api/evaluator";
  const alertCountEndPoint = "alert/api/Configuration/alertcount";
  const alertEndPoint = "alert/api/Configuration/events/details";

  $: cumulativeEndPoint = `parameters/cumulative?ExcludeStartTime=${excludeStartTime}`;

  $: {
    nodeName = nodeDetails?.name || "";
    nodeId = nodeDetails?.id || "";
  }

  $: {
    if (
      nodeDetails &&
      startTime &&
      endTime &&
      currentScreen &&
      baseURL &&
      columnDetails &&
      groupBy &&
      parentLevelType
    ) {
      invokeGetReportDataAPI();
    }
  }

  $: {
    if (
      nodeDetails &&
      startTime &&
      endTime &&
      currentScreen &&
      baseURL &&
      summaryDetails &&
      showSummary
    ) {
      invokeGetSummaryAPI();
    }
  }

  const filterParameterId = function (
    nodeDetails,
    parameterCategories,
    deviceCategories,
    type
  ) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return {
                  parameterId: basicParameter.ParameterID,
                  parameterType: "Basic",
                };
              }
            }
          }
          break;

        case "Derived":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return {
                  parameterId: derivedParameter.DerivedParameterId,
                  parameterType: "Derived",
                };
              }
            }
          }
          break;

        case "any":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return {
                  parameterId: basicParameter.ParameterID,
                  parameterType: "Basic",
                };
              }
            }
          }
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return {
                  parameterId: derivedParameter.DerivedParameterId,
                  parameterType: "Derived",
                };
              }
            }
          }
          break;
        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  };

  const getPayloadForReportDataAPI = function (details) {
    columnsOrder = details.map(
      (item) => `${item.columnTitle}${item.unit ? ` (${item.unit})` : ""}`
    );
    let cumulativeParameters = {};
    let aggregationBasicParameters = {};
    let aggregationDerivedParameters = {};
    let aggregationDerivedDailyParameters = {};
    let evaluatorParameters = [];
    let parametersFound = [];
    reportNodesMap = {};
    parametersMap = {};
    nodeAlertsMap = {};

    const findChildNodes = function (hierarchy) {
      for (const node of hierarchy) {
        if (node?.type === parentLevelType && node?.children?.length) {
          reportNodesMap[node.id] = {
            name: node.name,
          };
          for (const child of node.children) {
            reportNodesMap[node.id][child.id] = {
              name: child.name,
              parentNodeName: node.name,
              id: child.id,
              parentNodeId: node.id,
            };
            nodeAlertsMap[child.id] = child.name;
            for (const item of details) {
              const parameterIdObject = filterParameterId(
                child,
                item.parameterCategories,
                item.deviceCategories,
                item.parameterType
              );
              const parameterId = parameterIdObject?.parameterId;
              const parameterType = parameterIdObject?.parameterType;
              if (parameterId && parameterType) {
                parametersFound.push({ ...item });
                parametersMap[parameterId] = {
                  ...item,
                  nodeId: child.id,
                  parentId: node.id,
                };
                switch (item.api) {
                  case "aggregation-basic":
                    if (!aggregationBasicParameters[item.aggregation]) {
                      aggregationBasicParameters[item.aggregation] = [
                        parameterId,
                      ];
                    } else {
                      aggregationBasicParameters[item.aggregation].push(
                        parameterId
                      );
                    }
                    break;
                  case "aggregation-derived":
                    if (!aggregationDerivedParameters[item.aggregation]) {
                      aggregationDerivedParameters[item.aggregation] = [
                        parameterId,
                      ];
                    } else {
                      aggregationDerivedParameters[item.aggregation].push(
                        parameterId
                      );
                    }
                    break;
                  case "aggregation-any":
                    if (parameterType === "Derived") {
                      if (!aggregationDerivedParameters[item.aggregation]) {
                        aggregationDerivedParameters[item.aggregation] = [
                          parameterId,
                        ];
                      } else {
                        aggregationDerivedParameters[item.aggregation].push(
                          parameterId
                        );
                      }
                    } else if (parameterType === "Basic") {
                      if (!aggregationBasicParameters[item.aggregation]) {
                        aggregationBasicParameters[item.aggregation] = [
                          parameterId,
                        ];
                      } else {
                        aggregationBasicParameters[item.aggregation].push(
                          parameterId
                        );
                      }
                    }
                    break;
                  case "aggregation-derived-daily":
                    if (!aggregationDerivedDailyParameters[item.aggregation]) {
                      aggregationDerivedDailyParameters[item.aggregation] = [
                        parameterId,
                      ];
                    } else {
                      aggregationDerivedDailyParameters[item.aggregation].push(
                        parameterId
                      );
                    }
                    break;
                  case "evaluator":
                    evaluatorParameters.push({
                      parameterId,
                      dayDefinedEndTime: item.dayDefinedEndTime,
                    });
                    break;
                  case "cumulative":
                    if (cumulativeParameters[parameterType]) {
                      cumulativeParameters[parameterType].push(parameterId);
                    } else {
                      cumulativeParameters[parameterType] = [parameterId];
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
        if (node?.children?.length) {
          findChildNodes(node.children);
        }
      }
    };

    findChildNodes([nodeDetails]);
    let payloads = [];
    for (const item in aggregationBasicParameters) {
      payloads.push({
        endpoint: aggregationBasicEndPoint,
        body: {
          parameters: aggregationBasicParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    for (const item in aggregationDerivedParameters) {
      payloads.push({
        endpoint: aggregationDerivedEndPoint,
        body: {
          parameters: aggregationDerivedParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    for (const item in aggregationDerivedDailyParameters) {
      payloads.push({
        endpoint: aggregationDerivedDailyEndPoint,
        body: {
          parameters: aggregationDerivedDailyParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }

    if (evaluatorParameters?.length) {
      const parametersWithDayDefinedEndTime = evaluatorParameters
        .map((item) => (item.dayDefinedEndTime ? item.parameterId : null))
        .filter((item) => item);
      const parameters = evaluatorParameters
        .map((item) => (!item.dayDefinedEndTime ? item.parameterId : null))
        .filter((item) => item);
      if (parameters?.length) {
        payloads.push({
          endpoint: evaluatorEndPoint,
          body: {
            parameters,
            starttime: startTime,
            endtime: endTime,
          },
        });
      }
      if (parametersWithDayDefinedEndTime?.length) {
        payloads.push({
          endpoint: evaluatorEndPoint,
          body: {
            parameters: parametersWithDayDefinedEndTime,
            starttime: startTime,
            endtime: epoch13to10(
              addOffSet(
                moment(new Date(endTime * 1000))
                  .endOf("day")
                  .valueOf(),
                endTimeOffSet,
                true
              )
            ),
          },
        });
      }
    }

    if (Object.keys(cumulativeParameters)?.length) {
      payloads.push({
        endpoint: cumulativeEndPoint,
        body: {
          derivedParameters: cumulativeParameters["Derived"] ?? [],
          basicParameters: cumulativeParameters["Basic"] ?? [],
          endTime: endTime,
          startTime: startTime,
          cumulativeType: true,
        },
      });
    }
    return payloads;
  };

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const invokeGetReportDataAPI = function () {
    reportData = {};
    if (
      typeof localStorage === "undefined" ||
      !(
        nodeDetails &&
        startTime &&
        endTime &&
        currentScreen &&
        baseURL &&
        columnDetails &&
        groupBy
      )
    ) {
      return;
    }
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    showNoDataMessage = false;

    const payloads = getPayloadForReportDataAPI(columnDetails).filter(
      (item) => item?.endpoint
    );

    if (!Object.keys(parametersMap)?.length) showNoDataMessage = true;

    isAwaitingReportDataAPI = true;
    Promise.all(
      payloads.map((item) => {
        return fetch(`${baseURL}/${item.endpoint}`, {
          method: "POST",
          headers,
          body: JSON.stringify(item.body),
        });
      })
    )
      .then((responses) =>
        Promise.all(
          responses.map((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.warn("API call failed", response);
              return [];
            }
          })
        )
      )
      .then((dataList) => {
        onReportDataReceived(dataList);
        isAwaitingReportDataAPI = false;
      })
      .catch((error) => {
        isAwaitingReportDataAPI = false;
        console.error("API call failed due to Error ", error);
      });

    const alertNodeIds = Object.keys(nodeAlertsMap);
    // For getting Alerts Count
    if (alertNodeIds?.length) {
      const alertPayload = {
        StartTime: startTime,
        EndTime: endTime,
        NodeIds: alertNodeIds,
      };
      isAwaitingAlertCountAPI = true;
      invokeAPI(
        "POST",
        alertCountEndPoint,
        onGetAlertCountSuccess,
        onGetAlertCountError,
        alertPayload,
        `${currentScreen}/R`
      );
    }
  };

  const onGetAlertCountSuccess = function (data) {
    let _nodeAlertsMap = {};
    isAwaitingAlertCountAPI = false;
    for (const item of data) {
      if (item?.NodeId && nodeAlertsMap[item.NodeId]) {
        _nodeAlertsMap[nodeAlertsMap[item.NodeId]] = {
          nodeId: item.NodeId,
          count: item.Count,
        };
      }
    }
    nodeAlertsMap = { ..._nodeAlertsMap };
  };

  const onGetAlertCountError = function () {
    isAwaitingAlertCountAPI = false;
  };

  const findCumulativeReadings = function (data) {
    let readings = {};
    let item = null;
    if (data?.length === 2) {
      item = data[1];
    } else if (data?.length === 1) {
      item = data[0];
    }
    if (item) {
      for (const parameter of item.parameters) {
        if (!isNaN(parameter?.reading)) {
          readings[parameter.parameterId] = parameter.reading;
        }
      }
    }
    return readings;
  };

  const addNewReading = function (readingsMap, parameterItem, reading) {
    if (readingsMap[parameterItem.parentId]) {
      if (readingsMap[parameterItem.parentId][parameterItem.nodeId]) {
        readingsMap[parameterItem.parentId][parameterItem.nodeId][
          `${parameterItem.columnTitle}${
            parameterItem.unit ? ` (${parameterItem.unit})` : ""
          }`
        ] = reading;
      } else {
        readingsMap[parameterItem.parentId] = {
          ...readingsMap[parameterItem.parentId],
          [parameterItem.nodeId]: {
            [`${parameterItem.columnTitle}${
              parameterItem.unit ? ` (${parameterItem.unit})` : ""
            }`]: reading,
          },
        };
      }
    } else {
      readingsMap = {
        ...readingsMap,
        [parameterItem.parentId]: {
          [parameterItem.nodeId]: {
            [`${parameterItem.columnTitle}${
              parameterItem.unit ? ` (${parameterItem.unit})` : ""
            }`]: reading,
          },
        },
      };
    }
    return readingsMap;
  };

  const onReportDataReceived = function (dataList) {
    if (dataList?.length) {
      let readingsMap = {};
      for (const data of dataList) {
        if (data?.length) {
          if (data[0].parameterid) {
            // Response is of aggregation API
            for (const item of data) {
              const parameterItem = parametersMap[item.parameterid];
              if (parameterItem) {
                const reading = parameterItem.divideByThousand
                  ? divideByThousand(item.reading)
                  : convertToTwoDigits(item.reading);
                readingsMap = addNewReading(
                  readingsMap,
                  parameterItem,
                  reading
                );
              }
            }
          } else if (data[0].parameters) {
            // Response is of cumulative API
            const cumulativeReadings = findCumulativeReadings(data);
            for (const item in cumulativeReadings) {
              const parameterItem = parametersMap[item];
              if (parameterItem) {
                const reading = parameterItem.divideByThousand
                  ? divideByThousand(cumulativeReadings[item])
                  : convertToTwoDigits(cumulativeReadings[item]);
                readingsMap = addNewReading(
                  readingsMap,
                  parameterItem,
                  reading
                );
              }
            }
          }
        }
      }

      formatReportData(readingsMap);
    }
  };

  const formatReportData = function (readingsMap) {
    let _reportData = {};
    for (const tableId in readingsMap) {
      const tableItem = reportNodesMap[tableId];
      if (tableItem?.name) {
        _reportData[tableItem.name] = [];
        for (const row in readingsMap[tableId]) {
          if (tableItem[row]) {
            const newRow = {
              ...readingsMap[tableId][row],
              [tableItem.name]: tableItem[row].name,
            };
            _reportData[tableItem.name] = [
              newRow,
              ..._reportData[tableItem.name],
            ];
          }
        }
        _reportData[tableItem.name].reverse();
      }
    }
    reportData = { ..._reportData };
  };

  const getAllNodes = function (hierarchy) {
    for (const node of hierarchy) {
      nodesMap[node?.name] = { ...node };
      if (node?.children?.length) {
        getAllNodes(node.children);
      }
    }
  };

  const epoch13to10 = function (date) {
    if (!date) return date;
    return parseInt(date / 1000);
  };

  const addOffSet = function (date, offSet, endTime) {
    if (!(date && parseInt(date))) {
      return date;
    }

    const currentTime = moment().valueOf();

    if (!offSet) {
      offSet = localStorage.getItem("startTimeOffset")
        ? parseInt(localStorage.getItem("startTimeOffset"))
        : 0;
    }

    let updatedDate = date;

    if (moment().startOf("day").valueOf() + offSet <= currentTime) {
      // if as per day definition, today has started
      updatedDate += offSet;
    } else {
      // else, we are still in yesterday, so set the date selected as one day before
      if (endTime) {
        updatedDate =
          moment(date).endOf("day").subtract(1, "days").valueOf() + offSet;
      } else {
        updatedDate =
          moment(date).startOf("day").subtract(1, "days").valueOf() + offSet;
      }
    }

    return updatedDate;
  };

  const getPayloadForSummaryAPI = function (details) {
    let aggregationBasicParameters = {};
    let aggregationDerivedParameters = {};
    let aggregationDerivedDailyParameters = {};
    let evaluatorParameters = [];
    let cumulativeParameters = {};
    cumulativeParametersLength = 0;
    reportSummary = {};
    summaryParametersMap = {};

    if (showChildNodeSummary) {
      nodesMap = {};
      getAllNodes([nodeDetails]);
    }

    for (const item of details) {
      const parameterIdObject = filterParameterId(
        nodesMap[item.nodeName] ?? nodeDetails,
        item.parameterCategories,
        item.deviceCategories,
        item.parameterType
      );
      const parameterId = parameterIdObject?.parameterId;
      const parameterType = parameterIdObject?.parameterType;
      if (parameterId && parameterType) {
        summaryParametersMap[parameterId] = { ...item };
        switch (item.api) {
          case "aggregation-basic":
            if (!aggregationBasicParameters[item.aggregation]) {
              aggregationBasicParameters[item.aggregation] = [parameterId];
            } else {
              aggregationBasicParameters[item.aggregation].push(parameterId);
            }
            break;
          case "aggregation-derived":
            if (!aggregationDerivedParameters[item.aggregation]) {
              aggregationDerivedParameters[item.aggregation] = [parameterId];
            } else {
              aggregationDerivedParameters[item.aggregation].push(parameterId);
            }
            break;
          case "aggregation-any":
            if (parameterType === "Derived") {
              if (!aggregationDerivedParameters[item.aggregation]) {
                aggregationDerivedParameters[item.aggregation] = [parameterId];
              } else {
                aggregationDerivedParameters[item.aggregation].push(
                  parameterId
                );
              }
            } else if (parameterType === "Basic") {
              if (!aggregationBasicParameters[item.aggregation]) {
                aggregationBasicParameters[item.aggregation] = [parameterId];
              } else {
                aggregationBasicParameters[item.aggregation].push(parameterId);
              }
            }
            break;
          case "aggregation-derived-daily":
            if (!aggregationDerivedDailyParameters[item.aggregation]) {
              aggregationDerivedDailyParameters[item.aggregation] = [
                parameterId,
              ];
            } else {
              aggregationDerivedDailyParameters[item.aggregation].push(
                parameterId
              );
            }
            break;
          case "evaluator":
            evaluatorParameters.push({
              parameterId,
              dayDefinedEndTime: item.dayDefinedEndTime,
            });
            break;
          case "cumulative":
            if (cumulativeParameters[parameterType]) {
              cumulativeParameters[parameterType].push(parameterId);
            } else {
              cumulativeParameters[parameterType] = [parameterId];
            }
            break;
          default:
            reportSummary[item.columnTitle] = "No Data";
            console.warn("Wrong API given for ", item);
            break;
        }
      }
    }
    let payloads = [];
    for (const item in aggregationBasicParameters) {
      payloads.push({
        endpoint: aggregationBasicEndPoint,
        body: {
          parameters: aggregationBasicParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    for (const item in aggregationDerivedParameters) {
      payloads.push({
        endpoint: aggregationDerivedEndPoint,
        body: {
          parameters: aggregationDerivedParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    for (const item in aggregationDerivedDailyParameters) {
      payloads.push({
        endpoint: aggregationDerivedDailyEndPoint,
        body: {
          parameters: aggregationDerivedDailyParameters[item],
          starttime: startTime,
          endtime: endTime,
          groupby: "none",
          operation: item,
        },
      });
    }
    if (evaluatorParameters?.length) {
      const parametersWithDayDefinedEndTime = evaluatorParameters
        .map((item) => (item.dayDefinedEndTime ? item.parameterId : null))
        .filter((item) => item);
      const parameters = evaluatorParameters
        .map((item) => (!item.dayDefinedEndTime ? item.parameterId : null))
        .filter((item) => item);
      if (parameters?.length) {
        payloads.push({
          endpoint: evaluatorEndPoint,
          body: {
            parameters,
            starttime: startTime,
            endtime: endTime,
          },
        });
      }
      if (parametersWithDayDefinedEndTime?.length) {
        payloads.push({
          endpoint: evaluatorEndPoint,
          body: {
            parameters: parametersWithDayDefinedEndTime,
            starttime: startTime,
            endtime: epoch13to10(
              addOffSet(
                moment(new Date(endTime * 1000))
                  .endOf("day")
                  .valueOf(),
                endTimeOffSet,
                true
              )
            ),
          },
        });
      }
    }
    cumulativeParametersLength = Object.keys(cumulativeParameters)?.length;
    if (cumulativeParametersLength) {
      payloads.push({
        endpoint: cumulativeEndPoint,
        body: {
          derivedParameters: cumulativeParameters["Derived"] ?? [],
          basicParameters: cumulativeParameters["Basic"] ?? [],
          endTime: endTime,
          startTime: startTime,
        },
      });
    }
    return payloads;
  };

  const invokeGetSummaryAPI = function () {
    if (
      typeof localStorage === "undefined" ||
      !(
        nodeDetails &&
        startTime &&
        endTime &&
        currentScreen &&
        baseURL &&
        showSummary &&
        summaryDetails
      )
    ) {
      return;
    }

    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const payloads = getPayloadForSummaryAPI(summaryDetails).filter(
      (item) => item?.endpoint
    );

    isAwaitingSummaryDataAPI = true;
    Promise.all(
      payloads.map((item) => {
        return fetch(`${baseURL}/${item.endpoint}`, {
          method: "POST",
          headers,
          body: JSON.stringify(item.body),
        });
      })
    )
      .then((responses) =>
        Promise.all(
          responses.map((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.warn("API call failed", response);
              return [];
            }
          })
        )
      )
      .then((dataList) => {
        onSummaryDataReceived(dataList);
        isAwaitingSummaryDataAPI = false;
      })
      .catch((error) => {
        isAwaitingSummaryDataAPI = false;
        console.error("API call failed due to Error ", error);
      });
  };

  const onSummaryDataReceived = function (dataList) {
    let processedData = {};
    let dataWithOutUnits = {};
    for (const data of dataList) {
      if (data?.length) {
        if (data[0].Id) {
          // Response is of evaluator API
          for (const item of data) {
            if (summaryParametersMap[item.Id]) {
              const parameterItem = summaryParametersMap[item.Id];
              const reading = parameterItem.divideByThousand
                ? divideByThousand(item.Result)
                : convertToTwoDigits(item.Result);
              processedData[parameterItem.columnTitle] =
                reading === null
                  ? "No Data"
                  : `${parameterItem.unit === "Rs" ? "Rs " : ""}${reading}${
                      parameterItem.unit === "Rs"
                        ? ""
                        : ` ${parameterItem.unit}`
                    }`;
              dataWithOutUnits[parameterItem.columnTitle] =
                reading === null ? "No Data" : reading;
            }
          }
        } else if (data[0].parameterid) {
          // Response is of aggregation API
          for (const item of data) {
            if (summaryParametersMap[item.parameterid]) {
              const parameterItem = summaryParametersMap[item.parameterid];
              const reading = parameterItem.divideByThousand
                ? divideByThousand(item.reading)
                : convertToTwoDigits(item.reading);
              processedData[parameterItem.columnTitle] =
                reading === null
                  ? "No Data"
                  : `${parameterItem.unit === "Rs" ? "Rs " : ""}${reading}${
                      parameterItem.unit === "Rs"
                        ? ""
                        : ` ${parameterItem.unit}`
                    }`;
              dataWithOutUnits[parameterItem.columnTitle] =
                reading === null ? "No Data" : reading;
            }
          }
        } else if (data[0].parameters) {
          // Response is of cumulative API
          const cumulativeReadings = findCumulativeReadings(data);
          for (const parameterId in cumulativeReadings) {
            if (summaryParametersMap[parameterId]) {
              const parameterItem = summaryParametersMap[parameterId];
              const reading = parameterItem.divideByThousand
                ? divideByThousand(cumulativeReadings[parameterId])
                : convertToTwoDigits(cumulativeReadings[parameterId]);
              processedData[parameterItem.columnTitle] =
                reading === null
                  ? "No Data"
                  : `${parameterItem.unit === "Rs" ? "Rs " : ""}${reading}${
                      parameterItem.unit === "Rs"
                        ? ""
                        : ` ${parameterItem.unit}`
                    }`;
              dataWithOutUnits[parameterItem.columnTitle] =
                reading === null ? "No Data" : reading;
            }
          }
        }
      }
    }
    let _reportSummary = {};
    // For ensuring the order
    for (const item of summaryDetails) {
      _reportSummary[item.columnTitle] =
        processedData[item.columnTitle] ?? "No Data";
    }

    for (const item of summaryDetails) {
      if (item.isCalculated && item.valuesToAdd?.length) {
        let sum = 0;
        for (const element of item.valuesToAdd) {
          if (dataWithOutUnits[element] && !isNaN(dataWithOutUnits[element])) {
            sum += parseFloat(dataWithOutUnits[element]);
          }
        }
        sum = item.divideByThousand
          ? divideByThousand(sum)
          : convertToTwoDigits(sum);
        _reportSummary[item.columnTitle] = `${sum} ${item.unit ?? ""}`;
      }
    }
    reportSummary = { ..._reportSummary };
  };

  const formatDateTime = function (value, format) {
    const dateFormat = format ? format : dateTimeFormat;
    return !value || isNaN(value)
      ? ""
      : moment(new Date(parseInt(value * 1000))).format(dateFormat);
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const divideByThousand = function (value) {
    if (isNaN(value)) return null;
    return convertToTwoDigits(value / 1000);
  };

  // Export report related functions
  const handlePDFExportButtonClick = function () {
    handleReportExport("pdf");
  };
  const handleCSVExportButtonClick = function () {
    handleReportExport("csv");
  };
  const handleExcelExportButtonClick = function () {
    handleReportExport("xls");
  };

  const handleReportExport = function (exportType) {
    const startTimeFormatted = formatDateTime(startTime);
    const endTimeFormatted = formatDateTime(endTime);
    let reportDataForDownload = [];
    let downloadColumnsOrder = [];
    for (const table in reportData) {
      const order = [table].concat(columnsOrder);
      downloadColumnsOrder.push(order);
      reportDataForDownload.push(
        reportData[table].map((row) => {
          let newRow = {};
          for (const column of order) {
            newRow[column] = row[column] ?? "";
          }
          return newRow;
        })
      );
    }

    if (exportType === "pdf") {
      generatePDF(
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        reportSummary,
        reportDataForDownload,
        downloadColumnsOrder,
        reportTitle,
        columnsOrder.length + 1,
        4
      );
    } else {
      exportCSVExcel(
        exportType,
        reportTitle,
        startTimeFormatted,
        endTimeFormatted,
        nodeName,
        reportSummary,
        reportDataForDownload,
        reportTitle
      );
    }
  };

  const onGetAlertsSuccess = function (data) {
    isAwaitingAlertsAPI = false;
    const dateFormat = "HH:mm:ss DD MMM YYYY";
    let _alertsList = [];
    for (const item of data) {
      _alertsList.push({
        "Alert Name": item.alertname,
        "Occured On": formatDateTime(item.createdDate, dateFormat),
        "Is Acknowledged": item.isAcknowledged ? "Yes" : "No",
        "Acknowledged On": formatDateTime(item.acknowledgedDate, dateFormat),
      });
    }
    alertsList = [..._alertsList];
  };

  const onGetAlertsFail = function () {
    isAwaitingAlertsAPI = false;
  };

  const onAlertCountClick = function (nodeId, category) {
    const payload = {
      StartTime: startTime,
      EndTime: endTime,
      NodeId: nodeId,
      EventCategory: category,
    };
    alertsList = [];
    isAwaitingAlertsAPI = true;
    showAlertModal = true;
    invokeAPI(
      "POST",
      alertEndPoint,
      onGetAlertsSuccess,
      onGetAlertsFail,
      payload,
      `${currentScreen}/R`
    );
  };
</script>

<div class="del-report-container">
  {#if isAwaitingReportDataAPI || isAwaitingSummaryDataAPI || isAwaitingAlertCountAPI}
    <Loader />
  {/if}
  <div class="del-report-header-container">
    <div
      class="del-report-header-inner-container del-report-header-container-top"
    >
      <div class="flex-container">
        {#if showBackButton}
          <div>
            <button
              class="del-report-header-back-button"
              on:click={() => {
                onBackButtonClick && onBackButtonClick();
              }}><Icon icon="ep:back" /></button
            >
          </div>{/if}
        <div>
          <div class="del-report-header-main-title">{reportTitle}</div>
          <div class="del-report-header-sub-title">Report of {nodeName}</div>
        </div>
      </div>
      <div class="del-event-report-header-right-container">
        <div>
          <div class="del-report-header-titles">Download</div>
          <div class="flex-container del-report-table-export-buttons-container">
            <button
              name="csvDownloadButton"
              title={Object.keys(reportData)?.length
                ? "Export CSV"
                : "No data to download"}
              class="del-report-table-export-button csv-export-button"
              on:click={handleCSVExportButtonClick}
              disabled={!Object.keys(reportData)?.length}
            />
            <button
              name="excelDownloadButton"
              title={Object.keys(reportData)?.length
                ? "Export Excel"
                : "No data to download"}
              class="del-report-table-export-button excel-export-button"
              on:click={handleExcelExportButtonClick}
              disabled={!Object.keys(reportData)?.length}
            />
            <button
              name="pdfDownloadButton"
              title={Object.keys(reportData)?.length
                ? "Export PDF"
                : "No data to download"}
              class="del-report-table-export-button pdf-export-button"
              on:click={handlePDFExportButtonClick}
              disabled={!Object.keys(reportData)?.length}
            />
          </div>
        </div>
        <div>
          <div class="del-report-header-titles del-report-summary-title">
            Report Summary
          </div>
          <div
            class="del-report-header-titles del-report-header-small-titles del-report-header-dates"
          >
            {`From : ${formatDateTime(startTime)}`}
          </div>
          <div
            class="del-report-header-titles del-report-header-small-titles del-report-header-dates"
          >
            {`To : ${formatDateTime(endTime)}`}
          </div>
        </div>
      </div>
    </div>
    <div
      class="del-report-header-inner-container del-report-header-container-bottom"
    >
      {#each Object.keys(reportSummary) as item (item)}
        <div class="del-report-summary-item">
          <div class="del-report-summary-label">{item}</div>
          <div class="del-report-summary-separator">:</div>
          <div class="del-report-summary-value">
            {reportSummary[item]}
          </div>
        </div>
      {/each}
    </div>
  </div>

  {#each Object.keys(reportData) as table (table)}
    <div class="del-report-table-container">
      <!-- Report Table -->
      {#if reportData[table]?.length}
        <div class="del-report-table-inner-container">
          <table class="del-report-table">
            <thead>
              {#if columnsOrder?.length}
                <tr class="del-report-table-header-row">
                  {#each [table].concat(columnsOrder) as item (item)}
                    <th
                      class="del-report-table-cell del-report-table-header-cell"
                      >{item}</th
                    >
                  {/each}
                  <th
                    class="del-report-table-cell del-report-table-header-cell"
                    colspan={"3"}>Alerts</th
                  >
                </tr>
              {/if}
            </thead>
            <tbody class="table-body">
              {#each reportData[table] as row}
                <tr class="del-report-table-row">
                  {#each [table].concat(columnsOrder) as item (item)}
                    <td class={`del-report-table-cell`}>{row[item] ?? ""}</td>
                  {/each}
                  <td class={`del-report-table-cell`}
                    ><button
                      class="alert-count"
                      on:click={() => {
                        onAlertCountClick(
                          nodeAlertsMap[row[table]]?.nodeId,
                          "Critical"
                        );
                      }}
                    >
                      <div class="count critical">
                        {nodeAlertsMap[row[table]]?.count?.Critical ?? ""}
                      </div>
                      <div class="label">Critical</div>
                    </button></td
                  >
                  <td class={`del-report-table-cell`}>
                    <button
                      class="alert-count"
                      on:click={() => {
                        onAlertCountClick(
                          nodeAlertsMap[row[table]]?.nodeId,
                          "Warning"
                        );
                      }}
                    >
                      <div class="count warning">
                        {nodeAlertsMap[row[table]]?.count?.Warning ?? ""}
                      </div>
                      <div class="label">Warning</div>
                    </button></td
                  >
                  <td class={`del-report-table-cell`}
                    ><button
                      class="alert-count"
                      on:click={() => {
                        onAlertCountClick(
                          nodeAlertsMap[row[table]]?.nodeId,
                          "Informational"
                        );
                      }}
                    >
                      <div class="count informational">
                        {nodeAlertsMap[row[table]]?.count?.Informational ?? ""}
                      </div>
                      <div class="label">Informational</div>
                    </button></td
                  >
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      {:else}
        <div class="no-data-message">No Data Available</div>
      {/if}
    </div>
  {/each}
  {#if showNoDataMessage && !isAwaitingReportDataAPI && !isAwaitingAlertCountAPI}
    <div class="no-data-message">No Data Available</div>
  {/if}

  {#if showAlertModal}
    <div class="del-alert-table-modal">
      <div class="del-alert-table-modal-header">
        <div class="del-alert-table-modal-title">Alerts</div>
        <div>
          <button
            class="del-alert-table-modal-close-button"
            on:click={() => {
              showAlertModal = false;
              alertsList = [];
            }}><Icon icon="iconamoon:close" /></button
          >
        </div>
      </div>
      <div class="del-alert-table-modal-content-container">
        {#if isAwaitingAlertsAPI}
          <Loader />
        {/if}
        <DelTable
          tableColumns={alertsTableColumns}
          tableData={alertsList}
          entriesPerPage={5}
          hideNoDataMessage={isAwaitingAlertsAPI}
        />
      </div>
    </div>
  {/if}
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-report-container {
    position: relative;
    min-height: 600px;
  }
  .del-report-table-container {
    background: #ffffff 0 0 no-repeat padding-box;
    /* border: 1px solid #d3d3d3; */
    border-radius: 6px;
    margin: 20px 0;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .del-report-header-container {
    background: #6e90d0 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0;
    margin-top: 20px;
  }

  .del-report-header-inner-container {
    display: flex;
    align-content: center;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }

  .del-report-header-container-top {
    background: #4b72b9;
    min-height: 100px;
    border-radius: 10px 10px 0 0;
    background-image: url("./../images/headerBackground.svg");
  }

  .del-report-header-container-bottom {
    justify-content: start;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 20px 3px 0;
    gap: 15px;
  }

  .del-report-summary-item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    padding-left: 0;
    gap: 10px;
  }

  .del-report-summary-item:not(:last-child) {
    border-right: 1px solid #b2b2b2;
  }

  .del-report-summary-label,
  .del-report-summary-separator {
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .del-report-summary-value {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #16558f;
    opacity: 1;
  }

  .del-report-header-back-button {
    background: #7293d1 0 0 no-repeat padding-box;
    border: 1px solid #4565a0;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .del-report-header-back-button:enabled:hover,
  .del-report-header-back-button:enabled:active,
  .del-report-header-back-button:enabled:focus {
    transform: scale(1.05);
  }

  .del-report-header-main-title {
    text-align: left;
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-sub-title {
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-titles {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-report-header-small-titles {
    font-size: 14px;
  }

  .del-report-table-export-buttons-container {
    margin-top: 10px;
  }

  .del-report-table-export-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 6px;
    padding: 2px;
  }

  .del-report-table-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .del-report-table-export-button:enabled:hover,
  .del-report-table-export-button:enabled:active,
  .del-report-table-export-button:enabled:focus {
    transform: scale(1.05);
  }

  .csv-export-button {
    background-image: url("./../images/csvLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .pdf-export-button {
    background-image: url("./../images/pdfLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .excel-export-button {
    background-image: url("./../images/excelLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .del-report-summary-title {
    margin-bottom: 10px;
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .del-report-header-dates {
    text-align: right;
  }

  button {
    cursor: pointer;
    border: none;
  }

  .no-data-message {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    font: italic normal normal 16px/21px Roboto;
  }

  .del-event-report-header-right-container {
    display: flex;
    gap: 80px;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  /* For Report Table */
  .del-report-table-inner-container {
    overflow-x: auto;
    border: 1px solid #d3d3d3;
    margin: 0;
    padding: 0;
  }
  .del-report-table {
    width: 100%;
    border-spacing: 0;
    position: relative;
  }

  .del-report-table td:first-child,
  .del-report-table th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    border-right: 1px solid #d3d3d3;
    min-width: 250px;
  }

  .del-report-table th:last-child {
    border-right-color: #79919a;
  }

  .del-report-table-cell {
    padding: 10px 15px;
    text-align: center;
    font: normal normal normal 15px/21px Roboto;
    color: #000000;
    opacity: 0.77;
    min-width: 250px;
    border: 1px solid #d3d3d3;
    background-color: #ffffff;
    opacity: 1;
  }

  .del-report-table-no-data-cell {
    text-align: center;
  }

  .del-report-table-header-row {
    border: 1px solid #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }

  .del-report-table-filter-row {
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #beccd1;
    border: 1px solid #d3d3d3;
  }

  .del-report-table-header-cell {
    border-color: #79919a;
    border-right-color: #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }

  /* For Alerts */

  .alert-count {
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    font: normal normal normal 15px/21px Roboto;
  }

  .alert-count .label {
    color: #101010;
    font-weight: bold;
  }
  .alert-count .count {
    width: 20px;
    height: 20px;
    font-weight: bold;
    border-radius: 50%;
    box-sizing: border-box;
  }
  .alert-count .critical {
    background-color: #e1464a;
    color: #ffffff;
  }
  .alert-count .warning {
    background-color: #fcb900;
    color: #121212;
  }
  .alert-count .informational {
    background-color: #09982a;
    color: #121212;
  }

  /* For Modal */
  .del-alert-table-modal {
    position: fixed;
    display: block;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 600;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 160px;
    top: 10%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  .del-alert-table-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    font: normal normal bold 16px/21px Roboto;
    color: #000000;
  }

  .del-alert-table-modal-content-container {
    font: normal normal bold 15px Roboto;
    color: #000000;
    padding: 15px 0;
    position: relative;
    max-height: 400px;
    overflow-y: auto;
    min-height: 300px;
  }

  .del-alert-table-modal-close-button {
    background: transparent;
    font-size: larger;
    color: #63686d;
  }

  @media screen and (min-width: 700px) {
    .del-alert-table-modal {
      width: 450px;
      left: calc(50% - 225px);
      top: 10%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-alert-table-modal {
      width: 68%;
      left: calc(50% - 34%);
      top: 10%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-alert-table-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-alert-table-modal {
      width: 80%;
      left: calc(50% - 40%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-alert-table-modal {
      width: 800px;
      left: calc(50% - 400px);
      top: 10%;
    }
  }

  /* End of modal styles */
</style>
