<script>
  import SlimSelect from "slim-select";
  import "./../../../../node_modules/slim-select/dist/slimselect.css";
  import { onDestroy } from "svelte";

  export let options = [];
  export let defaultSelection = [];
  export let disabled = false;
  export let maxValuesShown = 1;
  export let allowDeselect = true;
  export let closeOnSelect = true;
  export let showSearch = false;
  export let isMultipleSelect = false;
  export let minSelected = null;
  export let maxSelected = null;
  export let onSelectionChange = null;
  export let onError = null;

  let slimSelectElement = null;
  let selectElement = null;
  let slimSelectOptions = [];

  $: {
    if (options && defaultSelection) {
      setSlimSelectOptions();
    }
  }

  $: {
    if (selectElement) reDrawSlimSelect();
  }

  const reDrawSlimSelect = function () {
    if (slimSelectElement) {
      slimSelectElement.destroy();
    }
    drawSlimSelect();
  };

  const setSlimSelectOptions = function () {
    let _options = [];
    let defaultOptions = [];
    for (const option of options) {
      const value = option.value || option.id || option.name;
      const _selected = defaultSelection.includes(value);
      let newObj = {
        text: option.name,
        value,
        selected: _selected,
        disabled: option.disabled ?? false,
        data: option,
        class: "del-select-option-selected",
        placeholder: option.placeholder ?? false,
      };
      _options.push(newObj);
      if (_selected) defaultOptions.push({ ...option });
    }
    slimSelectOptions = [..._options];
    if (!slimSelectElement) {
      drawSlimSelect();
    } else {
      slimSelectElement.setData(slimSelectOptions);
    }
    if (!isMultipleSelect) defaultOptions = defaultOptions.slice(0, 1);
    onSelectionChange && onSelectionChange(defaultOptions);
  };

  const onChange = function (newVal) {
    if (newVal) {
      const newSelection = newVal.map((item) => item.data);
      onSelectionChange && onSelectionChange(newSelection);
    } else {
      onError && onError("No new values selected");
    }
  };

  const drawSlimSelect = function () {
    if (selectElement) {
      let settings = {
        maxValuesShown,
        allowDeselect,
        closeOnSelect,
        showSearch,
        disabled,
      };
      if (maxSelected !== null) {
        settings.maxSelected = maxSelected;
      }
      if (minSelected !== null) {
        settings.minSelected = minSelected;
      }
      slimSelectElement = new SlimSelect({
        select: selectElement,
        data: slimSelectOptions,
        events: {
          afterChange: (newVal) => {
            if (newVal?.length) {
              onChange(newVal);
            }
          },
          error: function (err) {
            console.error(err);
            onError && onError(err);
          },
        },
        settings,
      });
    }
  };

  onDestroy(() => {
    if (slimSelectElement) slimSelectElement.destroy();
  });
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="slim-select-container" id="slim-select-component">
  {#if isMultipleSelect}
    <select bind:this={selectElement} class="del-select-element" multiple />
  {:else}
    <select bind:this={selectElement} class="del-select-element" />
  {/if}
</div>

<style>
  .del-select-element {
    font: normal normal normal 14px Roboto;
    color: #000000;
  }
  /* Over riding default styles*/

  :global(.ss-option.ss-selected.del-select-option-selected) {
    background-color: #6e90d0 !important;
  }

  :global(.ss-content .ss-list) {
    overflow-x: auto !important;
    font-size: 13px;
  }
  #slim-select-component :global(.ss-main:focus) {
    box-shadow: none;
  }
  #slim-select-component :global(.ss-main.ss-disabled) {
    opacity: 0.8;
  }
  #slim-select-component :global(.ss-deselect) {
    display: none !important;
  }
</style>
