<script>
  import Icon from "@iconify/svelte";
  import Toastify from "toastify-js";
  import "toastify-js/src/toastify.css";
  import { exportCSVExcel, generatePDF } from "./exportReports.js";
  import DelDatePicker from "./DelDatePicker/DelDatePicker.svelte";
  import Loader from "./../Loader/Loader.svelte";

  export let nodeDetails = null;
  export let currentScreen = "Reports";
  export let baseURL = "";
  export let showBackButton = true;
  export let onBackButtonClick = null;
  export let reportTitle = "KSEB Bill Cost Components";
  export let derivedParameters = {};
  export let constantParameters = {};
  export let realTimeParameters = {};
  export let deviceCategories = [];

  let nodeName = null;
  let nodeId = null;
  let startDate = null;
  let endDate = null;
  let selectedDate = null;
  let allConstants = [];
  let constantReadings = {};
  let presentRealTimeReadings = {};
  let previousRealTimeReadings = {};
  let derivedParameterReadings = {};
  let derivedParameterIdsMap = {};
  let realTimeParameterIdsMap = {};
  let constantAPIAwaiting = false;
  let evaluatorAPIAwaiting = false;
  let updateConstantAPIAwaiting = false;
  let previousRealTimeAPIAwaiting = false;
  let presentRealTimeAPIAwaiting = false;
  let roundOffCharge = null;
  let netCharge = null;
  let totalConsumption = null;
  let totalAmount = null;
  let totalStatuatoryCharges = null;
  let initialEmptyConstants = {};
  let hasConstantValueChanged = false;
  let reportGenerated = true;
  const getConstantsEndPoint =
    "derivedparameter/api/constants?pagecount=0&searchtext=";
  const editConstantEndPoint = "derivedparameter/api/constants";
  const evaluatorEndPoint = "dpe/api/evaluator";
  const realTimeDataEndPoint = "realtimedata/api/parameters/latest";

  $: {
    nodeName = nodeDetails?.name || "";
    nodeId = nodeDetails?.id || "";
  }

  $: {
    startDate = selectedDate?.startDateInSeconds;
    endDate = selectedDate?.endDateInSeconds;
    invokeEvaluatorAPI();
    invokePresentRealTimeReadingsAPI();
    invokePreviousRealTimeReadingsAPI();
  }

  $: {
    if (baseURL) invokeGetConstantsAPI();
  }

  $: {
    if (
      baseURL &&
      nodeDetails &&
      derivedParameters &&
      deviceCategories?.length
    ) {
      getDerivedParameterIds();
    }
  }

  $: {
    if (baseURL && nodeDetails && realTimeParameters) {
      getRealTimeParameterIds();
    }
  }

  $: {
    if (constantParameters) {
      filterConstantReadings();
    }
  }

  $: {
    if (derivedParameterReadings) {
      if (
        derivedParameterReadings["total-consumption"] ||
        derivedParameterReadings["total-consumption"] === 0
      ) {
        totalConsumption = `= ${derivedParameterReadings["total-consumption"]}`;
      } else {
        totalConsumption = null;
      }
      if (
        derivedParameterReadings["total-amount"] ||
        derivedParameterReadings["total-amount"] === 0
      ) {
        totalAmount = `= ${derivedParameterReadings["total-amount"]}`;
      } else {
        totalAmount = null;
      }
      if (
        derivedParameterReadings["duty-charge-total-amount"] ||
        derivedParameterReadings["duty-charge-total-amount"] === 0
      ) {
        totalStatuatoryCharges = `= ${derivedParameterReadings["duty-charge-total-amount"]}`;
      } else {
        totalStatuatoryCharges = null;
      }
    }
  }

  $: {
    if (
      derivedParameterReadings &&
      (derivedParameterReadings["total-charges"] ||
        derivedParameterReadings["total-charges"] === 0)
    ) {
      netCharge = Math.round(derivedParameterReadings["total-charges"]);
      roundOffCharge = convertToTwoDigits(
        netCharge - derivedParameterReadings["total-charges"]
      );
    } else {
      netCharge = null;
      roundOffCharge = null;
    }
  }

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const divideByThousand = function (value) {
    if (isNaN(value)) return null;
    return convertToTwoDigits(value / 1000);
  };

  const showToastMessage = function (message, color = "red", duration = 3000) {
    Toastify({
      text: message,
      style: {
        background: color,
      },
      duration: duration,
      close: false,
    }).showToast();
  };

  const filterParameterId = function (
    nodeDetails,
    parameterCategories,
    deviceCategories,
    type
  ) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;

        case "Derived":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          break;

        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  };

  const getRealTimeParameterIds = function () {
    if (realTimeParameters?.levels && nodeDetails) {
      let nodesList = [];
      const getNodeOptions = function (hierarchy) {
        for (const node of hierarchy) {
          if (realTimeParameters?.levels.includes(node.type)) {
            nodesList.push(node);
            continue;
          }
          if (node.children && node.children.length) {
            getNodeOptions(node.children);
          }
        }
      };
      realTimeParameterIdsMap = {};
      getNodeOptions([nodeDetails]);
      const plants = ["eou", "domestic"];
      for (const node of nodesList) {
        for (const plant of plants) {
          if (
            realTimeParameters[plant] &&
            realTimeParameters[plant]["deviceCategories"] &&
            realTimeParameters[plant]["parameterCategories"]
          ) {
            const deviceCategories =
              realTimeParameters[plant]["deviceCategories"];
            const parameterCategories =
              realTimeParameters[plant]["parameterCategories"];
            const type = realTimeParameters[plant]["type"];
            for (const [key, values] of Object.entries(parameterCategories)) {
              const parameterId = filterParameterId(
                node,
                values,
                deviceCategories,
                type
              );
              if (parameterId) {
                realTimeParameterIdsMap[parameterId] = `${plant}-${key}`;
              }
            }
          }
        }
      }
      presentRealTimeReadings = {};
      previousRealTimeReadings = {};
      invokePresentRealTimeReadingsAPI();
      invokePreviousRealTimeReadingsAPI();
    }
  };

  const getDerivedParameterIds = function () {
    let _derivedParameterIdsMap = {};
    for (const parameter in derivedParameters) {
      const parameterId = filterParameterId(
        nodeDetails,
        derivedParameters[parameter],
        deviceCategories,
        "Derived"
      );
      if (parameterId) {
        _derivedParameterIdsMap[parameterId] = parameter;
      }
    }
    derivedParameterIdsMap = { ..._derivedParameterIdsMap };
    invokeEvaluatorAPI();
  };

  const filterConstantReadings = function () {
    let _constantReadings = {};
    if (constantParameters && allConstants?.length) {
      for (const constant in constantParameters) {
        const match = allConstants.find(
          (item) => item.Name === constantParameters[constant]
        );
        if (match) {
          _constantReadings[constant] = { ...match };
        }
      }
    }
    constantReadings = { ..._constantReadings };
  };

  const setDerivedParameterReadings = function (data) {
    let _derivedParameterReadings = {};
    evaluatorAPIAwaiting = false;
    if (data) {
      for (const parameter of data) {
        if (derivedParameterIdsMap[parameter.Id]) {
          switch (derivedParameterIdsMap[parameter.Id]) {
            case "demand-kva-zone1":
            case "demand-kva-zone2":
            case "demand-kva-zone3":
              _derivedParameterReadings[derivedParameterIdsMap[parameter.Id]] =
                divideByThousand(parameter.Result);
              break;
            default:
              _derivedParameterReadings[derivedParameterIdsMap[parameter.Id]] =
                convertToTwoDigits(parameter.Result);
              break;
          }
        }
      }
    }
    derivedParameterReadings = { ..._derivedParameterReadings };
  };

  const setRealTimeParameterReadings = function (data, type) {
    let _realTimeReadings = {};
    if (data) {
      for (const parameter of data) {
        if (realTimeParameterIdsMap[parameter.parameterId]) {
          _realTimeReadings[realTimeParameterIdsMap[parameter.parameterId]] =
            divideByThousand(parameter.reading);
        }
      }
    }
    switch (type) {
      case "present":
        presentRealTimeReadings = { ..._realTimeReadings };
        break;
      case "previous":
        previousRealTimeReadings = { ..._realTimeReadings };
        break;
      default:
        break;
    }
  };

  const clearConstantReadings = function () {
    for (const constant in constantReadings) {
      constantReadings[constant] = null;
    }
  };

  const clearDerivedParameterReadings = function () {
    for (const parameter in derivedParameterReadings) {
      derivedParameterReadings[parameter] = null;
    }
  };

  const clearRealTimeReadings = function (type) {
    switch (type) {
      case "present":
        for (const parameter in presentRealTimeReadings) {
          presentRealTimeReadings[parameter] = null;
        }
        break;
      case "previous":
        for (const parameter in previousRealTimeReadings) {
          previousRealTimeReadings[parameter] = null;
        }
        break;
      default:
        break;
    }
  };

  // API related functions

  const invokeEvaluatorAPI = function () {
    clearDerivedParameterReadings();
    const payload = {
      startTime: startDate,
      endTime: endDate,
      parameters: Object.keys(derivedParameterIdsMap),
    };
    const onError = function () {
      evaluatorAPIAwaiting = false;
    };
    if (payload?.parameters?.length && payload?.startTime && payload?.endTime) {
      evaluatorAPIAwaiting = true;
      invokeAPI(
        "POST",
        evaluatorEndPoint,
        setDerivedParameterReadings,
        onError,
        payload,
        `${currentScreen}/R`
      );
    }
  };

  const invokePresentRealTimeReadingsAPI = function () {
    clearRealTimeReadings("present");
    const onError = function () {
      presentRealTimeAPIAwaiting = false;
    };
    const onSuccess = function (data) {
      presentRealTimeAPIAwaiting = false;
      setRealTimeParameterReadings(data, "present");
    };
    const parameterIds = Object.keys(realTimeParameterIdsMap).filter(
      (item) => item
    );

    if (endDate && parameterIds.length) {
      const payload = {
        timestampLimit: endDate,
        parameterDetails: parameterIds.map((parameterId) => ({
          parameterType: "bp",
          ParameterId: parameterId,
        })),
      };
      presentRealTimeAPIAwaiting = true;
      invokeAPI(
        "POST",
        realTimeDataEndPoint,
        onSuccess,
        onError,
        payload,
        `${currentScreen}/R`
      );
    }
  };

  const invokePreviousRealTimeReadingsAPI = function () {
    clearRealTimeReadings("previous");
    const onError = function () {
      previousRealTimeAPIAwaiting = false;
    };
    const onSuccess = function (data) {
      previousRealTimeAPIAwaiting = false;
      setRealTimeParameterReadings(data, "previous");
    };
    const parameterIds = Object.keys(realTimeParameterIdsMap).filter(
      (item) => item
    );

    if (startDate && parameterIds.length) {
      const payload = {
        timestampLimit: startDate,
        parameterDetails: parameterIds.map((parameterId) => ({
          parameterType: "bp",
          ParameterId: parameterId,
        })),
      };
      previousRealTimeAPIAwaiting = true;
      invokeAPI(
        "POST",
        realTimeDataEndPoint,
        onSuccess,
        onError,
        payload,
        `${currentScreen}/R`
      );
    }
  };

  const invokeGetConstantsAPI = function () {
    clearConstantReadings();
    allConstants = [];
    const onSuccess = function (data) {
      constantAPIAwaiting = false;
      if (data?.Constants) {
        allConstants = data.Constants;
        filterConstantReadings();
      }
    };
    const onError = function () {
      constantAPIAwaiting = false;
    };
    constantAPIAwaiting = true;
    invokeAPI(
      "GET",
      getConstantsEndPoint,
      onSuccess,
      onError,
      null,
      `${currentScreen}/R`
    );
  };

  const invokeUpdateConstantsAPI = function (payload) {
    const onSuccess = function (data) {
      // showToastMessage(data?.message ?? 'Constant updated successfully', 'green');
      updateConstantAPIAwaiting = false;
    };
    const onError = function () {
      showToastMessage("Unable to update constant!");
      invokeGetConstantsAPI();
      updateConstantAPIAwaiting = false;
    };
    updateConstantAPIAwaiting = true;
    invokeAPI(
      "POST",
      editConstantEndPoint,
      onSuccess,
      onError,
      payload,
      `${currentScreen}/R`
    );
  };

  // Export report related functions
  const handlePDFExportButtonClick = function () {
    handleReportExport("pdf");
  };
  const handleCSVExportButtonClick = function () {
    handleReportExport("csv");
  };
  const handleExcelExportButtonClick = function () {
    handleReportExport("xls");
  };

  const handleReportExport = function (exportType) {
    if (exportType === "pdf") {
      generatePDF(
        reportTitle,
        selectedDate?.date,
        derivedParameterReadings,
        presentRealTimeReadings,
        previousRealTimeReadings,
        constantReadings,
        roundOffCharge,
        netCharge,
        totalConsumption,
        totalAmount,
        totalStatuatoryCharges
      );
    } else {
      exportCSVExcel(
        exportType,
        reportTitle,
        selectedDate?.date,
        derivedParameterReadings,
        presentRealTimeReadings,
        previousRealTimeReadings,
        constantReadings,
        roundOffCharge,
        netCharge,
        totalConsumption,
        totalAmount,
        totalStatuatoryCharges
      );
    }
  };

  // For constant updates

  const onConstantUpdated = function (event) {
    const name = event?.target?.id;
    const value = event?.target?.value;
    let _initialEmptyConstants = { ...initialEmptyConstants };
    if (!(value || value === 0) && name !== null) {
      if (!Object.keys(_initialEmptyConstants).includes(name)) {
        event.target.value = constantReadings[name]?.Value ?? "";
      } else {
        _initialEmptyConstants[name] = null;
      }
    } else if (constantReadings[name]) {
      if (Object.keys(_initialEmptyConstants).includes(name)) {
        _initialEmptyConstants[name] = value;
      }
      if (value !== constantReadings[name]?.Value) {
        const payload = {
          ID: constantReadings[name].ID,
          Name: constantReadings[name].Name,
          Value: value,
        };
        constantReadings[name].Value = value;
        reportGenerated = false;
        hasConstantValueChanged = true;
        invokeUpdateConstantsAPI(payload);
      }
    }
    initialEmptyConstants = { ..._initialEmptyConstants };
  };

  // Report sumbit button related functions

  const onGenerateReportButtonClick = function () {
    invokeEvaluatorAPI();
    invokeGetConstantsAPI();
    reportGenerated = true;
    hasConstantValueChanged = false;
  };

  $: isSubmitButtonDisabled =
    !hasConstantValueChanged ||
    !Object.values(initialEmptyConstants).every((item) => item);

  $: isReportDownloadDisabled = !Object.values(initialEmptyConstants).every(
    (item) => item
  );
</script>

<div class="del-reports-main-container">
  {#if evaluatorAPIAwaiting || presentRealTimeAPIAwaiting || previousRealTimeAPIAwaiting}
    <Loader />
  {/if}
  <div class="del-bill-report-header-container">
    <div
      class="del-bill-report-header-inner-container del-bill-report-header-container-top"
    >
      <div class="flex-container">
        {#if showBackButton}
          <div>
            <button
              class="del-bill-report-header-back-button"
              on:click={() => {
                onBackButtonClick && onBackButtonClick();
              }}><Icon icon="ep:back" /></button
            >
          </div>{/if}
        <div>
          <div class="del-bill-report-header-main-title">{reportTitle}</div>
          <div class="del-bill-report-header-sub-title">
            Report of {nodeName}
          </div>
        </div>
      </div>
      <div class="del-event-report-header-right-container">
        <div>
          <div class="del-bill-report-header-titles">Download</div>
          <div class="flex-container del-events-table-export-buttons-container">
            <button
              name="csvDownloadButton"
              title={`${
                updateConstantAPIAwaiting || constantAPIAwaiting
                  ? "Constant is being updated"
                  : !reportGenerated || isReportDownloadDisabled
                    ? "Generate report first to download csv"
                    : "Export CSV"
              }`}
              class="del-events-table-export-button csv-export-button"
              disabled={updateConstantAPIAwaiting ||
                constantAPIAwaiting ||
                !reportGenerated ||
                isReportDownloadDisabled}
              on:click={handleCSVExportButtonClick}
            />
            <button
              name="excelDownloadButton"
              title={`${
                updateConstantAPIAwaiting || constantAPIAwaiting
                  ? "Constant is being updated"
                  : !reportGenerated || isReportDownloadDisabled
                    ? "Generate report first to download excel"
                    : "Export Excel"
              }`}
              class="del-events-table-export-button excel-export-button"
              disabled={updateConstantAPIAwaiting ||
                constantAPIAwaiting ||
                !reportGenerated ||
                isReportDownloadDisabled}
              on:click={handleExcelExportButtonClick}
            />

            <button
              name="pdfDownloadButton"
              title={`${
                updateConstantAPIAwaiting || constantAPIAwaiting
                  ? "Constant is being updated"
                  : !reportGenerated || isReportDownloadDisabled
                    ? "Generate report first to download pdf"
                    : "Export PDF"
              }`}
              class="del-events-table-export-button pdf-export-button"
              disabled={updateConstantAPIAwaiting ||
                constantAPIAwaiting ||
                !reportGenerated ||
                isReportDownloadDisabled}
              on:click={handlePDFExportButtonClick}
            />
          </div>
        </div>
        <div>
          <DelDatePicker
            typeOfPicker={"month"}
            onDateChanged={(date) => {
              selectedDate = date;
            }}
          />
        </div>
      </div>
    </div>
  </div>
  <div class="del-bill-report-summary">
    <div class="del-bill-report-summary-item">
      <div class="del-bill-report-summary-label">Total Charges</div>
      <div class="del-bill-report-summary-separator">:</div>
      <div class="del-bill-report-summary-value">
        {derivedParameterReadings["total-charges"] ?? "No Data"}
      </div>
    </div>
    <div class="del-bill-report-summary-item">
      <div class="del-bill-report-summary-label">Plus/Minus round off</div>
      <div class="del-bill-report-summary-separator">:</div>
      <div class="del-bill-report-summary-value">
        {roundOffCharge ?? "No Data"}
      </div>
    </div>
    <div class="del-bill-report-summary-item">
      <div class="del-bill-report-summary-label">Net payable Amount</div>
      <div class="del-bill-report-summary-separator">:</div>
      <div class="del-bill-report-summary-value">
        {netCharge ?? "No Data"}
      </div>
    </div>
  </div>
  <div class="del-bill-report-table-container">
    <table class="del-bill-report-table" id="del-bill-report-table">
      <thead>
        <tr class="del-bill-report-table-header-row">
          <th />
          <th colspan={"2"} class="first-row-heading">EoU Plant</th>
          <th colspan={"2"} class="first-row-heading">Domestic Plant</th>
          <th />
          <th />
          <th />
        </tr>
        <tr class="del-bill-report-table-header-row">
          <th>kWh</th>
          <th>Previous</th>
          <th>Present</th>
          <th>Previous</th>
          <th>Present</th>
          <th>Consumption</th>
          <th class="flex-cell"
            ><div>Rate</div>
            <div class="icon-border">
              <Icon icon="mdi:pencil-outline" />
            </div></th
          >
          <th>Amount</th>
        </tr></thead
      >
      <tbody>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell top-right-cell">Zone 1</td>
          <td class="dashed-border"
            >{previousRealTimeReadings["eou-zone-1-energy"] ?? "No Data"}</td
          >
          <td class="no-left-border"
            >{presentRealTimeReadings["eou-zone-1-energy"] ?? "No Data"}</td
          >
          <td class="dashed-border"
            >{previousRealTimeReadings["domestic-zone-1-energy"] ??
              "No Data"}</td
          >
          <td class="no-left-border"
            >{presentRealTimeReadings["domestic-zone-1-energy"] ??
              "No Data"}</td
          >
          <td>{derivedParameterReadings["zone-1-consumption"] ?? "No Data"}</td>
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="zone-1-rate"
              value={constantReadings["zone-1-rate"]?.Value ?? ""}
              disabled={!constantReadings["zone-1-rate"]}
            /></td
          >
          <td>{derivedParameterReadings["zone-1-amount"] ?? "No Data"}</td>
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell">Zone 2</td>
          <td class="dashed-border"
            >{previousRealTimeReadings["eou-zone-2-energy"] ?? "No Data"}</td
          >
          <td class="no-left-border"
            >{presentRealTimeReadings["eou-zone-2-energy"] ?? "No Data"}</td
          >
          <td class="dashed-border"
            >{previousRealTimeReadings["domestic-zone-2-energy"] ??
              "No Data"}</td
          >
          <td class="no-left-border"
            >{presentRealTimeReadings["domestic-zone-2-energy"] ??
              "No Data"}</td
          >
          <td>{derivedParameterReadings["zone-2-consumption"] ?? "No Data"}</td>
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="zone-2-rate"
              value={constantReadings["zone-2-rate"]?.Value ?? ""}
              disabled={!constantReadings["zone-2-rate"]}
            /></td
          >
          <td>{derivedParameterReadings["zone-2-amount"] ?? "No Data"}</td>
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell">Zone 3</td>
          <td class="dashed-border"
            >{previousRealTimeReadings["eou-zone-3-energy"] ?? "No Data"}</td
          >
          <td class="no-left-border"
            >{presentRealTimeReadings["eou-zone-3-energy"] ?? "No Data"}</td
          >
          <td class="dashed-border"
            >{previousRealTimeReadings["domestic-zone-3-energy"] ??
              "No Data"}</td
          >
          <td class="no-left-border"
            >{presentRealTimeReadings["domestic-zone-3-energy"] ??
              "No Data"}</td
          >
          <td>{derivedParameterReadings["zone-3-consumption"] ?? "No Data"}</td>
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="zone-3-rate"
              value={constantReadings["zone-3-rate"]?.Value ?? ""}
              disabled={!constantReadings["zone-3-rate"]}
            /></td
          >
          <td>{derivedParameterReadings["zone-3-amount"] ?? "No Data"}</td>
        </tr>
        <tr class="del-bill-report-table-row summary-row">
          <td colspan={"5"} />
          <td>{totalConsumption ?? "No Data"}</td>
          <td />
          <td>{totalAmount ?? "No Data"}</td>
        </tr>

        <div class="del-bill-report-inner-title">Statutory Charges</div>

        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"6"}
            >Electricity Duty</td
          >
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="electric-duty-rate"
              value={constantReadings["electric-duty-rate"]?.Value ?? ""}
              disabled={!constantReadings["electric-duty-rate"]}
            /></td
          >
          <td
            >{derivedParameterReadings["electric-duty-amount"] ?? "No Data"}</td
          >
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"6"}
            >Electricity Surcharge</td
          >

          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="electric-surcharge-rate"
              value={constantReadings["electric-surcharge-rate"]?.Value ?? ""}
              disabled={!constantReadings["electric-surcharge-rate"]}
            /></td
          >
          <td
            >{derivedParameterReadings["electric-surcharge-amount"] ??
              "No Data"}</td
          >
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"6"}
            >Fuel Surcharge</td
          >

          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="fuel-surcharge-rate"
              value={constantReadings["fuel-surcharge-rate"]?.Value ?? ""}
              disabled={!constantReadings["fuel-surcharge-rate"]}
            /></td
          >
          <td
            >{derivedParameterReadings["fuel-surcharge-amount"] ??
              "No Data"}</td
          >
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"6"}
            >Monthly Fuel Surcharge</td
          >
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="monthly-fuel-surcharge-rate"
              value={constantReadings["monthly-fuel-surcharge-rate"]?.Value ??
                ""}
              disabled={!constantReadings["monthly-fuel-surcharge-rate"]}
            /></td
          >
          <td
            >{derivedParameterReadings["monthly-fuel-surcharge-amount"] ??
              "No Data"}</td
          >
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"5"}
            >Duty on Self generated energy</td
          >
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="self-generated-duty-consumption"
              value={constantReadings["self-generated-duty-consumption"]
                ?.Value ?? ""}
              disabled={!constantReadings["self-generated-duty-consumption"]}
            /></td
          >
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="self-generated-duty-rate"
              value={constantReadings["self-generated-duty-rate"]?.Value ?? ""}
              disabled={!constantReadings["self-generated-duty-rate"]}
            /></td
          >
          <td>{derivedParameterReadings["self-generated-duty"] ?? "No Data"}</td
          >
          <!-- <td
            ><input
              type="number" step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="self-generated-duty"
              value={constantReadings["monthly-fuel-surcharge-rate"]?.Value ??
                ""}
              disabled={!constantReadings["self-generated-duty"]}
            /></td
          > -->
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"7"}
            >Belated payment charges</td
          >
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="belated-payment-charges"
              value={constantReadings["belated-payment-charges"]?.Value ?? ""}
              disabled={!constantReadings["belated-payment-charges"]}
            /></td
          >
        </tr>
        <tr class="del-bill-report-table-row summary-row">
          <td colspan={"7"} />
          <td>{totalStatuatoryCharges ?? "No Data"}</td>
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"5"}>PF incentive</td>
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="pf-incentive-consumption"
              value={constantReadings["pf-incentive-consumption"]?.Value ?? ""}
              disabled={!constantReadings["pf-incentive-consumption"]}
            /></td
          >
          <td />
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="pf-incentive-amount"
              value={constantReadings["pf-incentive-amount"]?.Value ?? ""}
              disabled={!constantReadings["pf-incentive-amount"]}
            /></td
          >
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"5"}>Demand KVA Z1</td
          >
          <td>{derivedParameterReadings["demand-kva-zone1"] ?? "No Data"}</td>
          <td />
          <td />
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"5"}>Demand KVA Z2</td
          >
          <td>{derivedParameterReadings["demand-kva-zone2"] ?? "No Data"}</td>
          <td />
          <td />
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"5"}>Demand KVA Z3</td
          >
          <td>{derivedParameterReadings["demand-kva-zone3"] ?? "No Data"}</td>
          <td />
          <td />
        </tr>
        <tr class="del-bill-report-table-row">
          <td class="del-bill-report-title-cell" colspan={"5"}
            >Demand KVA - Normal</td
          >
          <td>{derivedParameterReadings["demand-kva-normal"] ?? "No Data"}</td>
          <td
            ><input
              type="number"
              step="any"
              class="del-bill-report-input"
              on:blur={onConstantUpdated}
              id="demand-kva-normal-rate"
              value={constantReadings["demand-kva-normal-rate"]?.Value ?? ""}
              disabled={!constantReadings["demand-kva-normal-rate"]}
            /></td
          >
          <td
            >{derivedParameterReadings["demand-kva-charge-normal"] ??
              "No Data"}</td
          >
        </tr>
      </tbody>
    </table>

    <div class="del-bill-report-submit-button-container">
      <button
        class="del-bill-report-submit-button"
        title={`${
          updateConstantAPIAwaiting || constantAPIAwaiting
            ? "Constant is being updated"
            : isSubmitButtonDisabled
              ? "Fill in all the fields to generate report"
              : "Generate Report"
        }`}
        on:click={onGenerateReportButtonClick}
        disabled={updateConstantAPIAwaiting ||
          constantAPIAwaiting ||
          isSubmitButtonDisabled}>Generate Report</button
      >
    </div>
  </div>
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz@6..96&family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-reports-main-container {
    position: relative;
  }

  .del-bill-report-header-container {
    background: #6e90d0 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0;
    margin-top: 20px;
  }

  .del-bill-report-header-inner-container {
    display: flex;
    align-content: center;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }

  .del-bill-report-header-container-top {
    background: #4b72b9;
    min-height: 100px;
    border-radius: 10px 10px 0 0;
    background-image: url("./../images/headerBackground.svg");
  }

  .del-bill-report-header-back-button {
    background: #7293d1 0 0 no-repeat padding-box;
    border: 1px solid #4565a0;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .del-bill-report-header-back-button:enabled:hover,
  .del-bill-report-header-back-button:enabled:active,
  .del-bill-report-header-back-button:enabled:focus {
    transform: scale(1.05);
  }

  .del-bill-report-header-main-title {
    text-align: left;
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-bill-report-header-sub-title {
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-bill-report-header-titles {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-events-table-export-buttons-container {
    margin-top: 10px;
  }

  .del-events-table-export-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 6px;
    padding: 2px;
  }

  .del-events-table-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .del-events-table-export-button:enabled:hover,
  .del-events-table-export-button:enabled:active,
  .del-events-table-export-button:enabled:focus {
    transform: scale(1.05);
  }

  .csv-export-button {
    background-image: url("./../images/csvLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .pdf-export-button {
    background-image: url("./../images/pdfLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .excel-export-button {
    background-image: url("./../images/excelLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button {
    cursor: pointer;
    border: none;
  }

  .del-event-report-header-right-container {
    display: flex;
    gap: 80px;
    align-items: end;
    align-content: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .del-bill-report-summary {
    display: flex;
    align-items: center;
    padding: 20px 0;
    gap: 15px;
  }

  .del-bill-report-summary-item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    padding-left: 0;
    gap: 10px;
  }

  .del-bill-report-summary-item:not(:last-child) {
    border-right: 1px solid #b2b2b2;
  }

  .del-bill-report-summary-label,
  .del-bill-report-summary-separator {
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .del-bill-report-summary-value {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #16558f;
    opacity: 1;
  }

  .flex-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .icon-border {
    border: 1px solid #e57628;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    padding: 2px;
  }

  /* Table related styles */
  .del-bill-report-table-container {
    margin: 0;
    padding: 0;
  }
  .del-bill-report-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    position: relative;
    text-align: left;
  }

  .del-bill-report-table-header-row {
    background: #ececec 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: left;
    font: normal normal 500 12px/16px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .del-bill-report-table th,
  .del-bill-report-table td {
    padding: 8px 26px;
    cursor: default;
  }

  .del-bill-report-table tr:first-child th:first-child {
    border-radius: 4px 0 0 0;
  }
  .del-bill-report-table tr:last-child th:first-child {
    border-radius: 0 0 0 4px;
  }
  .del-bill-report-table tr:first-child th:last-child {
    border-radius: 0 4px 0 0;
  }
  .del-bill-report-table tr:last-child th:last-child {
    border-radius: 0 0 4px 0;
  }

  th.first-row-heading {
    text-align: center;
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }

  .del-bill-report-table td,
  .del-bill-report-input {
    text-align: left;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
    border: 1px solid #b2b2b2;
  }

  .del-bill-report-table td.dashed-border {
    border-right: 1px dashed #b2b2b2;
  }

  .del-bill-report-table td.no-left-border {
    border-left: none;
  }
  .del-bill-report-table-row:first-child {
    border-radius: 4px 4px 0 0;
  }

  .del-bill-report-table-row.summary-row {
    position: relative;
  }

  .del-bill-report-table-row.summary-row td {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .del-bill-report-table-row.summary-row td:before,
  .del-bill-report-table-row.summary-row td:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: black;
  }

  .del-bill-report-table-row.summary-row td:before {
    top: 0;
    left: 0;
  }

  .del-bill-report-table-row.summary-row td:after {
    bottom: -1px;
    left: 0;
  }

  .del-bill-report-table-row.summary-row td:first-child {
    border-left: 1px solid black;
  }

  .del-bill-report-table-row.summary-row td:last-child {
    border-right: 1px solid black;
  }

  td .del-bill-report-input {
    height: inherit;
    width: 100%;
    padding: 0;
    border: none;
    outline: transparent;
  }

  td .del-bill-report-input:disabled {
    background: transparent;
    cursor: not-allowed;
  }

  .del-bill-report-inner-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #145aa8;
    opacity: 1;
    padding: 14px 0;
  }

  td.del-bill-report-title-cell {
    text-align: left;
    font: normal normal 600 12px/16px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Table related styles ends here */

  /* Generate Report button styles */

  .del-bill-report-submit-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .del-bill-report-submit-button {
    cursor: pointer;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 6px;
    opacity: 1;
    font: normal normal 600 14px/19px Roboto;
    letter-spacing: 0px;
    color: #f56b26;
    padding: 10px 22px;
  }

  .del-bill-report-submit-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
</style>
