<script>
  import Chart from "chart.js/auto";
  import Loader from "./../Loader/Loader.svelte";
  import moment from "moment";

  export let currentScreen = "Dashboard";
  export let baseURL = null;
  export let nodeDetails = null;
  export let chartDetails = [];
  export let yAxisDetails = [];
  export let startTime = null;
  export let endTime = null;
  export let operation = "sum";
  export let groupBy = "month";
  export let chartTitle = "";
  export let xAxisTitle = "";
  export let dateTimeFormat = "MMM YYYY";
  export let showLegends = true;
  export let legendPosition = "top";
  export let legendAlignment = "center";
  export let chartType = "line";
  export let chartHeight = "500px";
  export let includeAllDays = true;

  let parametersMap = [];
  let isAPIAwaiting = false;
  let xAxisLabels = [];
  let chartElement = null;
  let chartObject = null;
  let showNoDataMessage = false;

  $: endpoint = `parameters/telemetry/aggregation/derived/dailymultiple?includeAllDays=${includeAllDays}`;

  $: {
    if (nodeDetails && chartDetails) getParameters();
  }

  $: {
    if (
      currentScreen &&
      baseURL &&
      startTime &&
      endTime &&
      groupBy &&
      operation
    ) {
      clearChart();
      handleGetDataAPIInvokation();
    }
  }

  $: {
    updateChartOptions("chart-type", chartType);
  }
  $: {
    updateChartOptions("xaxis-title", xAxisTitle);
  }
  $: {
    updateChartOptions("show-legend", showLegends);
  }
  $: {
    updateChartOptions("legend-position", legendPosition);
  }
  $: {
    updateChartOptions("legend-alignment", legendAlignment);
  }

  const filterParameterId = function (
    nodeDetails,
    parameterCategories,
    deviceCategories,
    type = "any"
  ) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;

        case "Derived":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          break;

        case "any":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;
        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  };

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const clearGraphData = function () {
    xAxisLabels = [];
    for (const item in parametersMap) {
      parametersMap[item].data = [];
    }
  };

  const clearChart = function () {
    clearGraphData();
    if (chartObject) {
      updateChart();
    } else {
      drawChart();
    }
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return 0;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const onGetDataAPISuccess = function (data) {
    isAPIAwaiting = false;
    clearGraphData();
    let _parametersMap = { ...parametersMap };
    if (data) {
      for (const item of data) {
        xAxisLabels.push(formatTimeStamp(item.unixtime, dateTimeFormat));
        for (const parameterId in _parametersMap) {
          const match = item.parameters?.find(
            (parameter) => parameter.parameterId === parameterId
          );
          if (match) {
            const reading = convertToTwoDigits(match.reading);
            if (_parametersMap[parameterId].data) {
              _parametersMap[parameterId].data.push(reading);
            }
          } else {
            if (_parametersMap[parameterId].data) {
              _parametersMap[parameterId].data.push(null);
            }
          }
        }
      }
    }
    parametersMap = { ..._parametersMap };
    if (xAxisLabels?.length) showNoDataMessage = false;
    if (chartObject) {
      updateChart();
    } else {
      drawChart();
    }
  };

  const onGetDataAPIError = function () {
    isAPIAwaiting = false;
    showNoDataMessage = true;
  };

  const invokeGetDataAPI = function () {
    const parameters = Object.keys(parametersMap);
    if (
      startTime &&
      endTime &&
      parameters?.length &&
      groupBy &&
      operation &&
      baseURL &&
      currentScreen
    ) {
      const payload = {
        parameters,
        starttime: startTime,
        endtime: endTime,
        groupby: groupBy,
        operation,
      };
      isAPIAwaiting = true;
      invokeAPI(
        "POST",
        endpoint,
        onGetDataAPISuccess,
        onGetDataAPIError,
        payload,
        `${currentScreen}/R`
      );
    }
  };

  const debounce = function (func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(func, timeout);
    };
  };

  const handleGetDataAPIInvokation = debounce(invokeGetDataAPI);

  const getParameters = function () {
    parametersMap = [];
    clearChart();
    for (const item of chartDetails) {
      const parameterId = filterParameterId(
        nodeDetails,
        item.parameterCategories,
        item.deviceCategories,
        item?.parameterType
      );
      if (parameterId) {
        parametersMap[parameterId] = { ...item, parameterId };
      }
    }
    const parameters = Object.keys(parametersMap);
    if (!parameters?.length) showNoDataMessage = true;
    handleGetDataAPIInvokation();
  };

  const formatYAxisObject = function () {
    let yAxisObj = {};
    for (const axis of yAxisDetails) {
      yAxisObj[axis.id] = {
        type: "linear",
        position: axis.position ?? "left",
        display: true,
        title: {
          display: true,
          text: axis.titleText || "",
          color: axis.titleColor,
          align: axis.titleAlign,
        },
        grid: {
          borderColor: axis.borderColor,
          tickColor: axis.tickColor,
        },
        ticks: {
          color: axis.tickColor,
        },
      };
    }
    return yAxisObj;
  };

  const filterYAxisObject = function () {
    let filteredYAxisObject = {};
    let yAxisObj = formatYAxisObject();
    const selectedParameters = Object.values(parametersMap);
    if (selectedParameters?.length) {
      for (const axis in yAxisObj) {
        const match = selectedParameters.find((item) => item.yAxisID === axis);
        if (match) {
          filteredYAxisObject[axis] = { ...yAxisObj[axis] };
        }
      }
    }
    return filteredYAxisObject;
  };

  const formatTimeStamp = function (timestamp, format) {
    if (timestamp && format) {
      return moment(new Date(timestamp * 1000)).format(format);
    }
    return null;
  };

  const drawChart = function () {
    if (chartElement) {
      const seriesData = Object.values(parametersMap);
      const yaxis = filterYAxisObject();
      const options = {
        // The type of chart we want to create
        type: chartType || "bar",
        // The data for our dataset
        data: {
          labels: xAxisLabels,
          datasets: seriesData,
        },
        // configuration options
        options: {
          responsive: true,
          maintainAspectRatio: false,
          maxBarThickness: 46,
          interaction: {
            mode: "index",
            intersect: false,
          },
          plugins: {
            legend: {
              display: showLegends,
              position: legendPosition || "top",
              align: legendAlignment || "end",
              labels: {
                boxHeight: 10,
                boxWidth: 10,
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: xAxisTitle || "",
                color: "#3C3C3C",
              },
              grid: {
                display: false,
              },
            },
            ...yaxis,
          },
        },
      };
      chartObject = new Chart(chartElement, options);
    }
  };

  const updateChart = function () {
    if (chartObject) {
      const seriesData = Object.values(parametersMap);
      const yaxis = filterYAxisObject();
      const scales = {
        x: {
          title: {
            display: true,
            text: xAxisTitle || "",
            color: "#3C3C3C",
          },
          grid: {
            display: false,
          },
        },
        ...yaxis,
      };
      chartObject.data.labels = xAxisLabels;
      chartObject.data.datasets = seriesData;
      chartObject.options.scales = scales;
      chartObject.update();
    }
  };

  const updateChartOptions = function (type, newVal) {
    if (chartObject) {
      switch (type) {
        case "chart-type":
          chartObject.config.type = chartType || "bar";
          chartObject.update();
          break;
        case "xaxis-title":
          chartObject.options.scales.x.title.text = newVal || "";
          chartObject.update();
          break;
        case "show-legend":
          chartObject.options.plugins.legend.display = newVal;
          chartObject.update();
          break;
        case "legend-alignment":
          chartObject.options.plugins.legend.align = newVal || "end";
          chartObject.update();
          break;
        case "legend-position":
          chartObject.options.plugins.legend.position = newVal || "top";
          chartObject.update();
          break;
        default:
          break;
      }
    }
  };
</script>

<div class="del-multi-trend-chart-container">
  <div class="del-multi-trend-chart-title">{chartTitle ?? ""}</div>
  {#if isAPIAwaiting}
    <Loader />
  {/if}
  <div style={`${chartHeight ? `height:${chartHeight}` : ""}`}>
    <canvas id="del-multi-trend-chart" bind:this={chartElement} />
  </div>
  {#if showNoDataMessage && !isAPIAwaiting}
    <div class="del-multi-trend-chart-no-data-message">No Data Available</div>
  {/if}
</div>

<style>
  .del-multi-trend-chart-container {
    position: relative;
  }
  .del-multi-trend-chart-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    padding: 5px;
    padding: 0 1.5em;
  }

  .del-multi-trend-chart-no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
  }
</style>
