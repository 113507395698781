<script>
  import Tree from "./Tree.svelte";
  import Icon from "@iconify/svelte";

  export let withAncestors = true;
  export let currentScreen = "Dashboard";
  export let unClickableNodeTypes = [];
  export let setLeafNodeAsClickable = true;
  export let defaultNodeId = null;
  export let isDisabled = false;
  export let onNodeSelected = null;
  export let baseURL = "";
  export let onNoNodesAssigned = null;
  export let onAPISuccess = null;
  export let onAPIFailed = null;
  export let disabledIfNotAssignedToUser = false;
  export let userId = null;

  let hierarchyDetails = [];
  let showNavigationTree = false;
  let selectedNode = null;
  let isFirstTime = true;

  $: {
    if (baseURL && currentScreen) invokeAPI(withAncestors);
  }

  $: {
    if (onNodeSelected) {
      onNodeSelected(selectedNode);
    }
  }

  $: {
    setDefaultNodeId(defaultNodeId);
  }

  function checkIfNodeIsClickable(type, childrenLength, users) {
    const userIds = users?.map((user) => user.UserId);
    if (
      unClickableNodeTypes.includes(type) ||
      (disabledIfNotAssignedToUser && userId && !userIds.includes(userId))
    ) {
      if (childrenLength) {
        return false;
      } else if (!setLeafNodeAsClickable) {
        return false;
      }
    }
    return true;
  }

  function handleNodeClick(item) {
    if (
      (unClickableNodeTypes || disabledIfNotAssignedToUser) &&
      !checkIfNodeIsClickable(item.type, item?.children?.length, item.users)
    ) {
      return;
    }
    if (selectedNode?.id === item?.id) {
      return;
    }
    selectedNode = item;
    if (onNodeSelected) {
      onNodeSelected(selectedNode);
    }
  }

  const setDefaultNodeId = function (id) {
    let found = null;
    if (selectedNode && !isFirstTime && id && selectedNode?.id !== id) {
      for (const node of hierarchyDetails) {
        found = findNodeItem(node, id);
        if (found) break;
      }
      handleNodeClick({ ...found });
    }
    if (isFirstTime) isFirstTime = false;
  };

  const findNodeItem = function (node, id, path = []) {
    let found = null;
    if (node) {
      path.push({
        id: node.id,
        name: node.name,
        type: node.type,
      });
    }
    if (node?.id === id) {
      node.nodesInPath = path;
      node.path = path.map((item) => item.name).join(" > ");
      return node;
    } else if (node?.children?.length) {
      node.children.some(
        (item) => (found = findNodeItem(item, id, path.slice()))
      );
    }
    return found;
  };

  const processTree = function (node, path = []) {
    node.nodesInPath = path.map((item) => ({ ...item }));
    node.path = path.map((item) => item.name).join(" > ");

    node.showChildren = true;

    if (node.children) {
      node.children.forEach((child) => {
        const newChild = {
          name: child.name,
          id: child.id,
          type: child.type,
          derivedparameters: child.derivedparameters,
          parameters: child.parameters,
        };
        processTree(child, [...path, { ...newChild }]);
      });
    }
  };

  const onHierarchyReceived = function (data) {
    hierarchyDetails = [...data];
    if (data?.length) {
      for (const node of hierarchyDetails) {
        const newChild = {
          name: node.name,
          id: node.id,
          type: node.type,
          derivedparameters: node.derivedparameters,
          parameters: node.parameters,
        };
        processTree(node, [newChild]);
      }

      let defaultNodeItem = data[0];
      defaultNodeItem.path = data[0].name;
      defaultNodeItem.nodesInPath = [
        {
          id: data[0].id,
          name: data[0].name,
          type: data[0].type,
        },
      ];
      let oldNodeItem = null;
      if (defaultNodeId) {
        oldNodeItem = findNodeItem(data[0], defaultNodeId);
      }
      selectedNode = oldNodeItem || defaultNodeItem;
      onAPISuccess && onAPISuccess(data);
    } else {
      onNoNodesAssigned && onNoNodesAssigned();
    }
  };

  const invokeAPI = function (withAncestors) {
    if (typeof localStorage === "undefined" || !baseURL || !currentScreen) {
      return;
    }
    hierarchyDetails = [];
    async function getResponse() {
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const userId = localStorage.getItem("sub");
      const accessToken = localStorage.getItem("access_token");
      let endpoint = `twin/api/user/${userId}/nodes`;

      if (withAncestors) {
        endpoint = `twin/api/User/${userId}/nodehierarchy`;
      }
      let headers = {
        companyid: companyId,
        applicationid: applicationId,
        Authorization: `Bearer ${accessToken}`,
        "access-origin": `${currentScreen}/R`,
      };

      const response = await fetch(`${baseURL}/${endpoint}`, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error(response.status);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onHierarchyReceived(data);
      })
      .catch((error) => {
        console.error("API call failed", error);
        onAPIFailed && onAPIFailed(error);
      });
  };

  const handleNavTreeButtonClick = function () {
    showNavigationTree = !showNavigationTree;
  };
</script>

<div class="tree-cascader-container">
  <button
    class="tree-cascader-button"
    disabled={isDisabled || !hierarchyDetails?.length}
    on:click={handleNavTreeButtonClick}><Icon icon="ic:round-menu" /></button
  >
  <!--old icon : "ic:round-menu" -->
  {#if showNavigationTree}
    <div class="overlay-container" on:click={handleNavTreeButtonClick} />
  {/if}
  <div id="tree-container" class={showNavigationTree ? "show-tree" : ""}>
    <Tree
      data={hierarchyDetails}
      bind:selectedNode
      bind:showNavigationTree
      {setLeafNodeAsClickable}
      {unClickableNodeTypes}
      classNameForRootNode={"root-node"}
      {userId}
      {disabledIfNotAssignedToUser}
    />
  </div>
</div>

<style>
  .tree-cascader-container #tree-container {
    position: fixed;
    left: -100%;
    top: 0;
    width: max-content;
    padding: 10px;
    padding-left: 8px;
    margin: 0;
    background-color: white;
    z-index: 1001;
    height: 100vh;
    overflow-y: auto;
    transition: left 0.5s ease-in-out;
    padding-bottom: 16px;
  }

  .tree-cascader-container #tree-container.show-tree {
    left: 0;
  }
  .tree-cascader-button {
    border: 1px solid #4565a0;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 25px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    width: 40px;
  }
  .tree-cascader-button:disabled {
    border: 1px solid #78788e;
    color: #78788e;
    cursor: not-allowed;
  }

  .overlay-container {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }

  :global(.root-node) {
    padding-bottom: 10px;
  }
</style>
