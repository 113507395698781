import * as XLSX from "xlsx";
import { delpheonLogo } from "./delpheonLogo";
import moment from "moment/moment";

export function exportCSVExcel(
  fileType,
  fileName,
  nodeName,
  summaryArray,
  reportData,
  reportName = "Report"
) {
  let workbook = XLSX.utils.book_new();

  let headerArray = [
    [reportName],
    [""],
    ["Customer", localStorage.getItem("companyName")],
    [`Report of ${nodeName}`],
    [""],
  ];

  summaryArray && headerArray.push(["Report Summary"]);

  let worksheet = XLSX.utils.aoa_to_sheet(headerArray);

  if (summaryArray) {
    XLSX.utils.sheet_add_json(worksheet, [summaryArray, []], {
      origin: -1,
    });
  }
  XLSX.utils.sheet_add_json(worksheet, reportData, {
    origin: -1,
  });

  const merge = [
    {
      s: {
        r: 0,
        c: 0,
      },
      e: {
        r: 0,
        c: 2,
      },
    },
    {
      s: {
        r: 3,
        c: 0,
      },
      e: {
        r: 3,
        c: 4,
      },
    },
    {
      s: {
        r: 2,
        c: 5,
      },
      e: {
        r: 2,
        c: 8,
      },
    },
    {
      s: {
        r: 3,
        c: 1,
      },
      e: {
        r: 3,
        c: 4,
      },
    },
    {
      s: {
        r: 4,
        c: 0,
      },
      e: {
        r: 4,
        c: 1,
      },
    },
  ];

  worksheet["!merges"] = merge;

  const exportFileName = `${fileName}.${fileType}`;
  XLSX.utils.book_append_sheet(workbook, worksheet, reportName.slice(0, 31));
  return XLSX.writeFile(workbook, exportFileName);
}

export function generatePDF(
  nodeName,
  summaryArray,
  reportData,
  columnHeaders,
  reportName = "Report",
  numberOfColumns = 0,
  landscapeNumber = 8
) {
  let applicationName = sessionStorage.getItem("appName");
  applicationName = applicationName ? applicationName.toUpperCase() : "";

  /*------------------------------------ PDF Basic Styling ----------------------------------------------*/
  const htmlForPDFHeader = `<html><head><style>@media print{*{-webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */ color-adjust: exact !important; /* Firefox 48 – 96 */ print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */}
      @page {
    size:  ${
      numberOfColumns > landscapeNumber ? "landscape letter" : "A4 portrait"
    };
  }       
  body{size: ${
    numberOfColumns > landscapeNumber ? "landscape" : "portrait"
  }; width: ${numberOfColumns > landscapeNumber ? "29.7cm" : "21cm"}; height:${
    numberOfColumns > landscapeNumber ? "21cm" : "29.7cm"
  } ; margin:auto; /* change the margins as you want them to be. */}.page-number{  content: counter(page)}.delpheon-header{font-size: 15px;}.t{padding:10px 3px; text-align:center;}.counter:after{counter-increment: page 1;}.pageNumber{content: counter(page);}.t2{padding:10px 3px; text-align:center;}.t:first-child{border-left:1px solid #E7EBED}.t:last-child{border-right:1px solid #E7EBED}table{page-break-after:auto}tr{page-break-inside:avoid; page-break-after:auto}td{page-break-inside:avoid; page-break-after:auto}thead{display:table-header-group}tfoot{display:table-footer-group}.delpheon-band{top: 88px;left: 0px;width: 100%;height: 20px;/* UI Properties */background: #F2F6F8 0% 0% no-repeat padding-box;opacity: 1;font:normal normal 12px/22px Roboto;letter-spacing: 0.4px;color: #5E72B1;text-transform: uppercase;opacity: 1;text-align:center;}h4{overflow:hidden;}h4:after{content:''; display:inline-block; width:100%; margin-right:-100%; border-bottom:3px solid #D5DFE2;}}.delpheon-header{font-size: 15px;}.t{padding:10px 3px; text-align:center;}table{page-break-after:auto;border-collapse:collapse;}tr{page-break-inside:avoid; page-break-after:auto}td{page-break-inside:avoid; page-break-after:auto}thead{display:table-header-group}tfoot{display:table-footer-group}.delpheon-band{top: 88px;left: 0px;width: 100%;height: 20px;/* UI Properties */background: #F2F6F8 0% 0% no-repeat padding-box;opacity: 1;font:normal normal 12px/22px Roboto;letter-spacing: 0.4px;color: #5E72B1;text-transform: uppercase;opacity: 1;text-align:center;}h4{overflow:hidden;}h4:after{content:''; display:inline-block; width:100%; margin-right:-100%; border-bottom:3px solid #D5DFE2;}</style><title>${reportName} </title></head>`;

  /*------------------------------------ PDF Header Styles ----------------------------------------------*/
  const bodyStart = `<body><div class='temp'><div class='delpheon-header'> <div style='position:relative; width: 100%;height: 88px;background: transparent linear-gradient(111deg, #FE7E24 0%, #203F9B 100%) 0% 0% no-repeat padding-box;'> <div style='top: 33px;position: absolute;left: 40px;width: 71px;height: 21px;background: transparent url(${delpheonLogo}) 0% 0% no-repeat padding-box;opacity: 1;'></div><div style='position: absolute;top:34px;left:120px;color:white;'>|</div><div style='position: absolute;top: 36px;left: 132px;height: 16px;text-align: left;font: italic normal 300 12px/16px Roboto;letter-spacing: 0.6px;color: #FFFFFF;text-transform: uppercase;opacity: 1;'>${reportName}</div><div style='position: absolute;right: 4%;top: 26%;'> <p style='text-align: right;font: normal normal normal 10px/13px Roboto;letter-spacing: 0px;color: #FFFFFF;'>Report Date : ${moment().format(
    "DD-MM-YYYY"
  )} </p><p style='text-align: right;font: normal normal normal 10px/13px Roboto; letter-spacing: 0px;color: #FFFFFF;'>India</p></div></div></div>${
    applicationName ? `<div class='delpheon-band'>${applicationName}</div>` : ""
  }<div style='background-color: #F2F6F8;margin-top:10px;border-radius:10px'> <div style='margin: 0 auto;text-align:center;font: normal normal medium 8px/10px Roboto;letter-spacing: 0.4px;color: #5E72B1;padding: 14px 10px 7px 10px;'>${localStorage.getItem(
    "companyName"
  )} ${reportName} <br>Report of ${nodeName}</div>
	</div>
	<div id='headerline'> `;

  /*------------------------------------ Report Summary ----------------------------------------------*/
  let summaryContent = "";
  if (summaryArray) {
    summaryContent =
      "<h4 style='color: #3B478D;font-weight: 700;'>SUMMARY</h4></div><div style='display: inline-flex;flex-wrap:wrap;'>";
    for (const [key, value] of Object.entries(summaryArray)) {
      summaryContent += `<div style='display:inline-flex;padding: 5px 10px 5px 10px;background-color:#F2F6F8;border-radius: 10px;margin-left: 10px;/* width: 335px; */margin-top:10px;'> <p style='font-size:12px;'>${key}</p><p style='margin-left: 20px; color: black; font-size:12px'>: ${value}</p></div>`;
    }
    summaryContent = summaryContent + "</div>";
  }

  /*------------------------------- Line Separating table from summary -----------------------------------*/
  const textReportsWithLine =
    "<div style='margin-top:10px'> <h4 style='color: #3B478D;/* margin-left: 55px; */font-weight: 700;text-transform: uppercase;'>Reports</h4></div>";

  /*------------------------------------ Table Body ----------------------------------------------*/
  let tableContent = `<div style='margin-left: auto;margin-right: auto; ' class='wrapper' ><table style="border-collapse:collapse;width:100%"><thead><tr style='border: 1px solid #ddd;color: black;background-color: #F2F6F8;height: 30px;font-family:Roboto;'>`;

  for (const column of columnHeaders) {
    tableContent += `<th class='t2' style="border: 1px solid #ddd;">${column}</th>`;
  }

  tableContent += "</tr></thead><tbody>";
  for (const row of reportData) {
    tableContent += "<tr style='border-bottom:1px solid #E7EBED'>";
    for (const column of columnHeaders) {
      let cssStyles = "";
      if (column === "Status") {
        cssStyles = `color:${
          row[column] === "Removed"
            ? "#ff0000"
            : row[column] === "Active"
            ? "#0000ff"
            : "#999"
        };`;
      }
      tableContent += `<td class='t' style="font-size:10px;border: 1px solid #ddd;${cssStyles}">${row[column]}</td>`;
    }
    tableContent += `</tr>`;
  }
  tableContent += `</tbody></table></div></div></body></html>`;

  /*------------------------------------ Combining all to get pdf report -------------------------------*/
  const pdfContent =
    htmlForPDFHeader +
    bodyStart +
    summaryContent +
    textReportsWithLine +
    tableContent;
  printDiv(pdfContent);
}

function printDiv(contents) {
  let frame1 = document.createElement("iframe");
  frame1.name = "frame1";
  frame1.style.position = "absolute";
  frame1.style.top = "-1000000px";
  document.body.appendChild(frame1);
  let frameDoc = frame1.contentWindow
    ? frame1.contentWindow
    : frame1.contentDocument.document
    ? frame1.contentDocument.document
    : frame1.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write(contents);
  frameDoc.document.close();
  setTimeout(function () {
    window.frames["frame1"].focus();
    window.frames["frame1"].print();
    document.body.removeChild(frame1);
  }, 500);
  return false;
}
