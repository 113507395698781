<script>
  import Chart from "chart.js/auto";
  import moment from "moment";
  import Loader from "./Loader.svelte";
  import { onMount } from "svelte";

  export let nodeDetails;
  export let currentScreen = "Dashboard";
  export let baseURL = "";
  export let chartDetails;
  export let showOnlyCurrentData = false;
  export let showTarget = false;
  export let targetConfigurations;
  export let currentStartTime;
  export let currentEndTime;
  export let previousStartTime;
  export let previousEndTime;
  export let operation = "sum";
  export let groupBy = "month";
  export let chartTitle = "";
  export let chartHeight;
  export let hideSecondXLabel = false;
  export let xAxisTitle;
  export let yAxisTitle;
  export let dateTimeFormat = "MMM YY";
  export let hoverDateTimeFormat = "MMM YYYY";
  export let legendPosition = "top";
  export let legendAlignment = "MMM YYYY";
  export let chartType = "bar";
  export let showLegends = true;

  let chartElement = null;
  let chart = null;
  let currentDataObject = {};
  let previousDataObject = {};
  let xAxisData = [];
  let trendsParameters = {};
  let showNoDataMessage = false;
  let previousDataAwaiting = false;
  let currentDataAwaiting = false;

  $: {
    if (nodeDetails && chartDetails) {
      clearChartData();
      invokeAPIs();
    }
  }

  $: {
    if (baseURL && operation && groupBy && currentScreen) {
      clearChartData();
      handleCurrentDataAPIInvokation();
      handlePreviousDataAPIInvokation();
    }
  }

  $: {
    if (currentStartTime && currentEndTime) {
      clearCurrentData();
      updateChart();
      handleCurrentDataAPIInvokation();
    }
  }

  $: {
    if (previousStartTime && previousEndTime) {
      clearPreviousData();
      updateChart();
      handlePreviousDataAPIInvokation();
    }
  }

  const debounce = function (func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(func, timeout);
    };
  };

  $: {
    updateChartOptions("type", chartType);
  }
  $: {
    updateChartOptions("yaxistitle", yAxisTitle);
  }
  $: {
    updateChartOptions("xaxistitle", xAxisTitle);
  }
  $: {
    updateChartOptions("showlegends", showLegends);
  }
  $: {
    updateChartOptions("legendposition", legendPosition);
  }
  $: {
    updateChartOptions("legendalignment", legendAlignment);
  }
  $: {
    updateChartOptions("hidesecondxlabel", hideSecondXLabel);
  }

  onMount(() => {
    drawChart();
  });

  async function getResponse(endpoint, payload, method = "POST") {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const filterParameterId = function (
    parameterCategories,
    deviceCategories,
    type
  ) {
    if (parameterCategories && deviceCategories && nodeDetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails?.parameters?.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                return basicParameter.ParameterID;
              }
            }
          }
          break;

        case "Derived":
          if (nodeDetails?.derivedparameters?.length) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                return derivedParameter.DerivedParameterId;
              }
            }
          }
          break;

        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  };

  const onPreviousDataReceived = function (data) {
    previousDataObject = {
      label: chartDetails?.previousDisplayName ?? "Previous",
      data: [],
      xAxisData: [],
      dataMap: {},
      backgroundColor: chartDetails?.previousChartColor ?? "#808080",
    };
    const format = dateTimeFormat || "MMM YY";

    if (chartDetails?.previousBorderColor) {
      previousDataObject.borderColor = chartDetails.previousBorderColor;
    }

    if (chartDetails?.pointRadius) {
      previousDataObject.pointRadius = chartDetails.pointRadius;
      previousDataObject.pointHoverRadius = chartDetails.pointRadius + 1;
    }
    previousDataAwaiting = false;

    if (data) {
      for (const item of data) {
        if (item.parameters) {
          for (const parameter of item.parameters) {
            if (trendsParameters[parameter.parameterId]) {
              const formattedTimeStamp = formatTimeStamp(item.unixtime, format);
              previousDataObject.xAxisData.push(item.unixtime);
              previousDataObject.dataMap[formattedTimeStamp] =
                convertToTwoDigits(parameter.reading);
              previousDataObject.data.push(
                convertToTwoDigits(parameter.reading)
              );
            }
          }
        }
      }
    }

    if (!currentDataAwaiting) {
      if (chart) {
        updateChartData();
      } else {
        drawChart();
      }
    }
  };

  const onCurrentDataReceived = function (data) {
    currentDataObject = {
      label: chartDetails?.currentDisplayName ?? "Current",
      data: [],
      xAxisData: [],
      dataMap: {},
      backgroundColor: chartDetails?.currentChartColor ?? "#808080",
    };

    if (chartDetails.currentBorderColor) {
      currentDataObject.borderColor = chartDetails.currentBorderColor;
    }

    if (chartDetails.pointRadius) {
      currentDataObject.pointRadius = chartDetails.pointRadius;
      currentDataObject.pointHoverRadius = chartDetails.pointRadius + 1;
    }

    xAxisData = [];
    currentDataAwaiting = false;
    const format = dateTimeFormat || "MMM YY";

    if (data) {
      for (const item of data) {
        if (item.parameters) {
          for (const parameter of item.parameters) {
            if (trendsParameters[parameter.parameterId]) {
              const formattedTimeStamp = formatTimeStamp(item.unixtime, format);
              xAxisData.push(formattedTimeStamp);
              currentDataObject.xAxisData.push(item.unixtime);
              currentDataObject.dataMap[formattedTimeStamp] =
                convertToTwoDigits(parameter.reading);
              currentDataObject.data.push(
                convertToTwoDigits(parameter.reading)
              );
            }
          }
        }
      }
    }

    if (!xAxisData.length) {
      showNoDataMessage = true;
    } else {
      showNoDataMessage = false;
    }

    if (!previousDataAwaiting) {
      if (chart) {
        updateChartData();
      } else {
        drawChart();
      }
    }
  };

  const invokeGetPreviousDataAPI = function () {
    if (showOnlyCurrentData) return;
    const endpoint = `parameters/telemetry/aggregation/derived/dailymultiple?includeAllDays=true`;

    const parameterIds = Object.keys(trendsParameters);
    if (
      baseURL &&
      previousStartTime &&
      previousEndTime &&
      parameterIds?.length &&
      groupBy &&
      operation
    ) {
      const payload = {
        parameters: parameterIds,
        groupby: groupBy,
        operation,
        starttime: parseInt(previousStartTime),
        endtime: parseInt(previousEndTime),
      };
      previousDataAwaiting = true;
      getResponse(endpoint, payload)
        .then((data) => {
          onPreviousDataReceived(data);
        })
        .catch((error) => {
          previousDataAwaiting = false;
          clearPreviousData();
          updateChart();
          console.error("API call failed", error);
        });
    }
  };

  const invokeGetCurrentDataAPI = function () {
    const endpoint = `parameters/telemetry/aggregation/derived/dailymultiple?includeAllDays=true`;
    const parameterIds = Object.keys(trendsParameters);
    if (
      baseURL &&
      currentStartTime &&
      currentEndTime &&
      parameterIds?.length &&
      groupBy &&
      operation
    ) {
      const payload = {
        parameters: parameterIds,
        groupby: groupBy,
        operation,
        starttime: parseInt(currentStartTime),
        endtime: parseInt(currentEndTime),
      };
      currentDataAwaiting = true;
      getResponse(endpoint, payload)
        .then((data) => {
          onCurrentDataReceived(data);
        })
        .catch((error) => {
          currentDataAwaiting = false;
          clearCurrentData();
          updateChart();
          console.error("API call failed", error);
        });
    }
  };

  const invokeAPIs = function () {
    trendsParameters = {};
    clearChartData();
    if (nodeDetails && chartDetails) {
      const parameterId = filterParameterId(
        chartDetails.parameterCategories,
        chartDetails.deviceCategories,
        chartDetails.parameterType
      );
      if (parameterId) {
        showNoDataMessage = false;
        trendsParameters = {
          [parameterId]: chartDetails,
        };
        handlePreviousDataAPIInvokation();
        handleCurrentDataAPIInvokation();
      } else {
        showNoDataMessage = true;
      }
    }
  };

  const handleCurrentDataAPIInvokation = debounce(invokeGetCurrentDataAPI);
  const handlePreviousDataAPIInvokation = debounce(invokeGetPreviousDataAPI);

  const updateChart = function () {
    if (chart) {
      updateChartData();
    } else {
      drawChart();
    }
  };

  const updateChartOptions = function (key, newVal) {
    if (chart && key) {
      switch (key) {
        case "type":
          chart.config.type = newVal || "bar";
          chart.update();
          break;
        case "yaxistitle":
          chart.options.scales.y.title.text = newVal || "";
          chart.update();
          break;
        case "xaxistitle":
          chart.options.scales.x.title.text = newVal || "";
          chart.update();
          break;
        case "showlegends":
          chart.options.plugins.legend.display = newVal;
          chart.update();
          break;
        case "legendposition":
          chart.options.plugins.legend.position = newVal || "top";
          chart.update();
          break;
        case "legendalignment":
          chart.options.plugins.legend.align = newVal || "end";
          chart.update();
          break;
        case "hidesecondxlabel":
          chart.update();
          break;
        default:
          break;
      }
    }
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const formatTimeStamp = function (timestamp, format) {
    if (timestamp && format) {
      return moment(new Date(timestamp * 1000)).format(format);
    }
    return null;
  };

  const drawTicks = function (val, index) {
    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
    // Hide every 2nd tick label
    if (hideSecondXLabel) {
      return index % 2 === 0 ? this.getLabelForValue(val) : "";
    } else {
      return this.getLabelForValue(val);
    }
  };

  const drawChart = function () {
    if (chartElement) {
      let dataset = [];
      if (showOnlyCurrentData) {
        dataset = [currentDataObject];
      } else {
        dataset = combineCurrentAndPreviousDataSets(dataset);
      }

      chart = new Chart(chartElement, {
        // The type of chart we want to create
        type: chartType || "bar",
        // The data for our dataset
        data: {
          labels: xAxisData,
          datasets: dataset,
        },
        // configuration options
        options: {
          responsive: true,
          maintainAspectRatio: false,
          maxBarThickness: 46,
          plugins: {
            legend: {
              display: showLegends,
              position: legendPosition || "top",
              align: legendAlignment || "end",
              labels: {
                boxHeight: 10,
                boxWidth: 10,
              },
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  const unixtime = context[0].dataset.xAxisData
                    ? context[0].dataset.xAxisData[context[0].dataIndex]
                    : null;
                  const format = hoverDateTimeFormat || "MMM YYYY";
                  if (unixtime && format) {
                    return formatTimeStamp(unixtime, format);
                  }
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                callback: drawTicks,
              },
              title: {
                display: true,
                text: xAxisTitle || "",
                color: "#3C3C3C",
              },
              grid: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
                text: yAxisTitle || "",
                color: "#3C3C3C",
                align: "end",
              },
            },
          },
        },
      });
    }
  };

  const combineCurrentAndPreviousDataSets = function (datasets) {
    const format = dateTimeFormat || "MMM YY";
    const set1 = new Set(
      currentDataObject.xAxisData?.map((timestamp) =>
        formatTimeStamp(timestamp, format)
      ) ?? []
    );
    const set2 = new Set(
      previousDataObject.xAxisData?.map((timestamp) =>
        formatTimeStamp(timestamp, format)
      ) ?? []
    );
    const unionSet = new Set([...set1, ...set2]);
    xAxisData = [...unionSet];
    currentDataObject.data = [];
    previousDataObject.data = [];
    if (currentDataObject.dataMap && previousDataObject.dataMap) {
      for (const timestamp of xAxisData) {
        previousDataObject.data.push(
          previousDataObject.dataMap[timestamp] ?? null
        );
        currentDataObject.data.push(
          currentDataObject.dataMap[timestamp] ?? null
        );
      }
    }
    return datasets.concat([currentDataObject, previousDataObject]);
  };

  const updateChartData = function () {
    if (chart) {
      let dataset = [];
      let scatterData = [];
      if (showTarget && targetConfigurations) {
        // Random data for testing purposes

        // for (let i = 0; i < xAxisData.length; i++) {
        // 	scatterData.push({
        // 		x: xAxisData[i],
        // 		y: (i + 1) * 10000
        // 	});
        // }

        const scatterPlotConfig = {
          type: "line",
          label: targetConfigurations.label || "Target",
          data: scatterData,
          pointStyle: targetConfigurations.pointStyle || "rectRot",
          backgroundColor: targetConfigurations.backgroundColor || "#0081C9",
          borderColor: targetConfigurations.backgroundColor || "#0081C9",
          pointRadius: targetConfigurations.pointRadius || 5,
          pointHoverRadius: targetConfigurations.pointHoverRadius || 10,
        };

        dataset = [scatterPlotConfig];
      }

      if (showOnlyCurrentData) {
        dataset = dataset.concat([currentDataObject]);
      } else {
        dataset = combineCurrentAndPreviousDataSets(dataset);
      }

      if (!xAxisData.length) {
        showNoDataMessage = true;
      } else {
        showNoDataMessage = false;
      }

      chart.data.labels = xAxisData;
      chart.data.datasets = dataset;
      chart.update();
    }
  };

  const clearCurrentData = function () {
    currentDataObject = {
      label: chartDetails?.currentDisplayName ?? "Current",
      data: [],
      backgroundColor: chartDetails?.currentChartColor ?? "#808080",
    };
  };

  const clearPreviousData = function () {
    previousDataObject = {
      label: chartDetails?.previousDisplayName ?? "Previous",
      data: [],
      backgroundColor: chartDetails?.previousChartColor ?? "#808080",
    };
  };

  const clearChartData = function () {
    clearCurrentData();
    clearPreviousData();
    xAxisData = [];

    if (chart) {
      updateChartData();
    } else {
      drawChart();
    }
  };
</script>

<div class="del-consumption-trend-chart-container">
  <div class="del-consumption-trend-chart-title">{chartTitle}</div>
  <div style={`${chartHeight ? `height:${chartHeight}` : ""}`}>
    <canvas id="del-consumption-trend-chart" bind:this={chartElement} />
  </div>
  {#if showNoDataMessage && !currentDataAwaiting && !previousDataAwaiting}
    <div class="del-consumption-trend-chart-no-data-message">
      No Data Available
    </div>
  {/if}
  {#if currentDataAwaiting || previousDataAwaiting}
    <Loader />
  {/if}
</div>

<style>
  .del-consumption-trend-chart-container {
    position: relative;
  }
  .del-consumption-trend-chart-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0;
    color: #222222;
    opacity: 1;
    padding: 5px;
  }

  .del-consumption-trend-chart-no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
  }
</style>
