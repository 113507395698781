import moment from "moment/moment";

export const filterParameterId = function (
  nodeDetails,
  parameterCategories,
  deviceCategories,
  type
) {
  if (parameterCategories && deviceCategories && nodeDetails && type) {
    switch (type) {
      case "Basic":
        if (nodeDetails?.parameters?.length) {
          for (let basicParameter of nodeDetails.parameters) {
            if (
              deviceCategories.includes(basicParameter.DeviceCategory) &&
              parameterCategories.includes(basicParameter.ParameterCategory)
            ) {
              return basicParameter.ParameterID;
            }
          }
        }
        break;

      case "Derived":
        if (nodeDetails?.derivedparameters?.length) {
          for (let derivedParameter of nodeDetails.derivedparameters) {
            if (
              deviceCategories.includes(derivedParameter.DeviceCategory) &&
              parameterCategories.includes(derivedParameter.ParameterCategory)
            ) {
              return derivedParameter.DerivedParameterId;
            }
          }
        }
        break;

      default:
        console.error("Unspecified Parameter Type!!!");
        break;
    }
  }
  return null;
};

export const checkForNoData = function (value) {
  if (isNaN(value)) return null;
  return value ?? null;
};

export const convertToTwoDigits = function (value) {
  if (isNaN(value)) return null;
  return (value + "").indexOf(".") > -1
    ? parseFloat(value).toFixed(2)
    : parseInt(value);
};

export const formatTimeStamp = function (timestamp, format) {
  if (timestamp && format) {
    return moment(new Date(timestamp * 1000)).format(format);
  }
  return null;
};

export const addOffSet = function (date, offSet, endTime) {
  if (!(date && parseInt(date))) {
    return date;
  }

  const currentTime = moment().valueOf();

  if (!offSet) {
    const offSetFromLocalStorage = endTime
      ? localStorage.getItem("endTimeOffset")
      : localStorage.getItem("startTimeOffset");
    offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
  }

  let updatedDate = date;

  if (moment().startOf("day").valueOf() + offSet <= currentTime) {
    // if as per day definition, today has started
    updatedDate += offSet;
  } else {
    // else, we are still in yesterday, so set the date selected as one day before
    if (endTime) {
      updatedDate =
        moment(date).endOf("day").subtract(1, "days").valueOf() + offSet;
    } else {
      updatedDate =
        moment(date).startOf("day").subtract(1, "days").valueOf() + offSet;
    }
  }

  return updatedDate;
};

export const checkIfGreaterThanNow = function (date) {
  if (!date || isNaN(date)) return;

  date = parseInt(date);
  const currentTime = moment().valueOf();
  if (date > currentTime) {
    return currentTime;
  }
  return date;
};

export const epoch13to10 = function (date) {
  if (!date) return date;
  return parseInt(date / 1000);
};

export const isSameAsToday = function (date) {
  return moment(new Date(date)).isSame(new Date(), "day");
};
