// Common Configurations
export const PREVIEW_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const DEV_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const QA_DOMAIN_URL = "http://onpremplatformapi.avtserver.local";
export const PRODUCTION_DOMAIN_URL = "http://avtplatformapi.delpheon.local";

export const PREVIEW_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const DEV_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const QA_ANALYTICS_DOMAIN_URL =
  "http://analytics-onpremise.avtserver.local";
export const PRODUCTION_ANALYTICS_DOMAIN_URL =
  "http://onpremanalytics.delpheon.local";

export const PREVIEW_DEPLOYED_URL = "http://localhost:3000";
export const DEV_DEPLOYED_URL = "https://epm-devnew.delpheon.io";
export const QA_DEPLOYED_URL = "http://epm-onpremise.avtserver.local";
export const PRODUCTION_DEPLOYED_URL = "http://avtepm.delpheon.local";

export const APP_VERSION = "2.0.13";

// Configurations for Header

export const pathNameSSPA = "/epm";
export const infoboardScreenDetails = {
  show: true,
  screenName: "Infoboard",
  displayName: "Infoboard",
};
export const pagesWithDatePicker = ["Dashboard", "Reports", "Plan Input"]; //should be the corresponding displayName
export const screensToShow = {
  "/Infoboard": { screen: "Infoboard", displayName: "Infoboard" },
  "/": { screen: "Dashboard", displayName: "Dashboard" },
  "/Reports": { screen: "Reports", displayName: "Reports" },
  "/Events": { screen: "Events", displayName: "Events" },
  "/DigitalTwin": { screen: "Digital Twin", displayName: "Digital Twin" },
  "/PlanInput": { screen: "Plan Input", displayName: "Plan Input" },
  "/Analytics": { screen: "Analytics", displayName: "Analytics" },
  "/Error": { screen: "Dashboard", displayName: "Error" },
};

// Configurations for Dashboard

// Highest Consumption Feeders Graph

export const highestConsumptionFeedersGraph = {
  title: "Highest Consumption Feeders",
  parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
  deviceCategories: ["Feeder Meter", "Utility Meter"],
  chartConfigurations: {
    currentChartColor: "#3F4C84",
    previousChartColor: "#BFDDE5",
    currentDisplayName: "Current",
    previousDisplayName: "Previous",
  },
  xAxisTitle: "kWh",
  chartHeight: "250px",
};

// Daily Energy Consumption Chart
export const dailyEnergyConsumptionChart = {
  title: "Daily Energy Consumption",
  chartHeight: "220px",
  dateTimeFormatOnHover: "DD MMM YY",
  dateTimeFormat: "DD",
  yAxisTitle: "kWh",
  operation: "sum",
  groupBy: "day",
  chartDetails: {
    parameterCategories: [
      "dailyActiveEnergy_Total_kWH",
      "dailyActiveEnergy_Imp_Total_kWH",
    ],
    deviceCategories: [
      "Utility Meter",
      "Feeder Meter",
      "Total Meter",
      "ToD Meter",
    ],
    parameterType: "Derived",
    currentChartColor: "#FFA375",
    currentDisplayName: "Energy",
  },
  showLegends: false,
};

// Specific Consumption Chart
export const specificConsumptionChart = {
  title: "Specific Consumption",
  chartHeight: "220px",
  dateTimeFormatOnHover: "DD MMM YY",
  dateTimeFormat: "DD",
  yAxisTitle: "kwh/Unit",
  operation: "sum",
  groupBy: "day",
  chartDetails: {
    parameterCategories: [
      "Daily Sp cons(EoU ProdFeeder MCC1ATS-1)",
      "Daily Sp cons(EoU ProdFeeder MCC2ATS-2)",
      "Daily Sp cons(EoU ProdFeeder MCC3ATS-3)",
      "Daily Sp cons(EoU ProdFeeder CP1)",
      "Daily Sp cons(EoU ProdFeeder CP2)",
      "Daily Sp cons(EoU ProdFeeder COSO2)",
      "Daily Sp cons(EoU ProdFeeder MCC4-HM)",
      "Daily Sp cons(EoU ProdFeeder MCC5)",
      "Daily Sp cons(EoU ProdFeeder MCC6)",
      "Daily Sp cons(EoU ProdFeeder MCC7)",
      "Daily Sp cons(EoU ProdFeeder MCC11ATS-4)",
      "Daily Sp cons(EoU ProdFeeder MCC12)",
      "Daily Sp cons(EoU ProdFeeder PCC3)",
      "Daily Sp cons(EoU ProdFeeder PCC12)",
      "Daily Sp cons(EoU ProdFeeder PCC13)",
      "Daily Sp cons(EoU ProdFeeder PCC14)",
      "Daily Sp cons(EoU UtilFeeder  ETP STP)",
      "Daily Sp cons(EoU UtilFeeder Chiller Units)",
      "Daily Sp cons(EoU UtilFeeder Air compressor)",
      "Daily Sp cons(EoU UtilFeeder AHU)",
      "Daily Sp cons(EoU UtilFeeder Boiler)",
      "Daily Sp cons(EoU UtilFeeder WPH)",
      "Daily Sp cons(EoU UtilFeeder CleanRooms)",
      "Daily Sp cons Domestic ProdFeeder Domestic BU",
      "Daily Sp cons Domestic ProdFeeder APFC",
      "Daily Sp cons Domestic ProdFeeder Color sorter-2",
      "Daily Sp cons Domestic ProdFeeder MCC1A",
      "Daily Sp cons(EoU UtilFeeder Chiller)",
      "Daily Sp cons(EoU UtilFeeder Comp)",
      "Daily Sp cons(EoU UtilFeeder PCC3)",
      "Daily Sp cons(EoU ProdFeeder Sterlilizer)",
      "Daily Sp cons(EoU UtilFeeder UtilityDB1)",
      "Daily Sp cons(EoU UtilFeeder UtilityDB2)",
      "Daily Sp cons EoU UtilFeeder FireDB",
      "Daily Sp cons EoU ProdFeeder MCC1A",
      "Daily Sp cons Domestic ProdFeeder Process1",
      "Daily Sp cons Domestic UtilFeeder Process2",
      "Daily Sp consumption(EoU Production)",
      "Daily Sp consumption(EoU Utility)",
      "Daily Sp cons DomBU",
    ],
    deviceCategories: ["Enterprise"],
    parameterType: "Derived",
    currentChartColor: "#AEDFFF",
    currentDisplayName: "Energy",
  },
  showLegends: false,
};

// Energy Consumption Monthly Trends Chart
export const monthlyEnergyConsumptionChart = {
  title: "Energy Consumption Monthly Trends (12 Months)",
  chartHeight: "220px",
  dateTimeFormatOnHover: "MMM YYYY",
  dateTimeFormat: "MMM YY",
  yAxisTitle: "kWh",
  operation: "sum",
  groupBy: "month",
  chartType: "line",
  chartDetails: {
    parameterCategories: [
      "dailyActiveEnergy_Total_kWH",
      "dailyActiveEnergy_Imp_Total_kWH",
    ],
    deviceCategories: [
      "Utility Meter",
      "Feeder Meter",
      "Total Meter",
      "ToD Meter",
    ],
    parameterType: "Derived",
    currentChartColor: "#D20000",
    currentDisplayName: "Energy",
    currentBorderColor: "#707070",
    pointRadius: 5,
  },
  showLegends: false,
};

// Real Time Alert Count Widget
export const realTimeAlertCount = {
  title: "Real-time Events",
  uniqueId: "real-time-alert-count",
};

// Real Time Alert Count Widget
export const historyAlertCount = {
  title: "Event History",
  uniqueId: "alert-history-count",
};

// For the energy consumption trend cards and cost
export const energyConsumptionDetails = {
  mainTitle: "Energy Consumption",
  totalTrendCardTitle: "Total",
  totalParameterCategories: [
    "ondemandActiveEnergy_Imp_total_kWH",
    "ondemandActiveEnergy_Imp_Total_kWH",
    "ondemandActiveEnergy_Total_kWH",
    "ondemandActiveEnergy",
  ],
  totalDeviceCategoriesForSubDashboard: [
    "ToD Meter",
    "Total Meter",
    "Utility Meter",
    "Feeder Meter",
  ],
  totalDeviceCategories: ["Total Meter", "Utility Meter", "Feeder Meter"],
  totalParameterType: "Derived",
  totalTrendType: "Consumption",
  totalTrendRequired: true,
  totalUnit: "kWh",
  specificTrendCardTitle: "Specific",
  specificParameterCategories: [
    "ondemand Sp consumption EoU Production",
    "ondemand Sp consumption EoU Utility",
    "od Sp cons EoU ProdFeeder MCC1ATS-1",
    "od Sp cons EoU ProdFeeder MCC2ATS-2",
    "od Sp cons EoU ProdFeeder MCC3ATS-3",
    "od Sp cons EoU ProdFeeder CP1",
    "od Sp cons EoU ProdFeeder CP2",
    "od Sp cons EoU ProdFeeder COSO2",
    "od Sp cons EoU ProdFeeder MCC4-HM",
    "od Sp cons EoU ProdFeeder MCC5",
    "od Sp cons EoU ProdFeeder MCC6",
    "od Sp cons EoU ProdFeeder MCC7",
    "od Sp cons EoU ProdFeeder MCC11ATS-4",
    "od Sp con EoU ProdFeeder MCC12",
    "od Sp cons EoU ProdFeeder PCC3",
    "od Sp cons EoU ProdFeeder PCC12",
    "od Sp cons EoU ProdFeeder PCC13",
    "od Sp cons EoU ProdFeeder PCC14",
    "od Sp cons EoU UtilFeeder ETP STP",
    "od Sp cons EoU UtilFeeder Chiller Units",
    "od Sp cons EoU UtilFeeder Air compressor",
    "od Sp cons EoU UtilFeeder AHU",
    "od Sp cons EoU UtilFeeder Boiler",
    "od Sp cons EoU UtilFeeder WPH",
    "od Sp cons EoU UtilFeeder CleanRooms",
    "od Sp cons Domestic Production",
    "od Sp cons Domestic Utility",
    "od Sp cons Domestic ProdFeeder Domestic BU",
    "od Sp cons Domestic ProdFeeder APFC",
    "od Sp cons Domestic ProdFeeder Color sorter-2",
    "od Sp cons Domestic ProdFeeder MCC1A",
    "od Sp cons EoU UtilFeeder PCC3",
    "od Sp cons EoU UtilFeeder Chiller",
    "od Sp cons EoU UtilFeeder Comp",
    "od Sp cons EoU ProdFeeder Spicesterilizer",
    "od Sp cons EoU UtilFeeder UtilityDB1",
    "od Sp cons EoU UtilFeeder UtilityDB2",
    "od Sp cons DomBU",
    "od Sp cons EoU UtilFeeder FireDB",
    "od Sp cons EoU ProdFeeder MCC1A",
    "od Sp cons Domestic ProdFeeder Process1",
    "od Sp cons Domestic UtilFeeder Process2",
    "ondemand Sp consumption AVT",
    "ondemand Sp consumption EoU",
    "ondemand Sp consumption Domestic",
  ],
  specificDeviceCategories: ["Enterprise"],
  specificParameterType: "Derived",
  specificTrendType: "Consumption",
  specificTrendRequired: true,
  specificUnit: "kWh/Unit",
  costTitle: "cost",
  costDeviceCategories: ["Total Meter"],
  costParameterCategories: ["Energy Cost"],
  costParameterType: "Derived",
  costUnit: "₹",
  costFormat: "en-IN",
};

// For Donut Chart
export const donutChartConfig = {
  chartConfig: [
    {
      Name: "DG Meter",
      DeviceCategory: "Total Meter",
      ParameterCategory: "DG Percentage",
      Color: "#EB5353",
    },
    {
      Name: "DISCOM",
      DeviceCategory: "Total Meter",
      ParameterCategory: "KSEB Percentage",
      Color: "#1C82AD",
    },
  ],
  chartHeight: "200px",
  chartCutOut: "60%",
  chartLabelOffSet: 40,
};

export const horizontalChartConfigurations = {
  chartTitle: "Energy Cost",
  requiredParameters: [
    {
      parameterCategories: ["Energy Cost"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      name: "KSEB",
      color: "#1C82AD",
      type: "Derived",
    },
    {
      parameterCategories: ["Energy Cost"],
      deviceCategories: ["DG Meter", "DG3 Meter"],
      name: "DG",
      color: "#EB5353",
      type: "Derived",
    },
    {
      parameterCategories: ["ondemand Briquet cost"],
      deviceCategories: ["Enterprise"],
      name: "Briquette",
      color: "#505050",
      type: "Derived",
    },
  ],
  legendPosition: "bottom",
  chartHeight: "230px",
  showYLabels: false,
};

// Daily Total Energy Consumption Chart
export const dailyConsumptionChartConfig = {
  title: "Daily Total Energy Consumption",
  chartConfig: [
    {
      displayName: "DG",
      deviceCategories: ["DG Meter", "DG3 Meter"],
      parameterCategories: [
        "dailyActiveEnergy_Total_DG_kWH",
        "dailyActiveEnergy_Total_kWH",
      ],
      color: "#EB5353",
      apiType: "daily",
      type: "Derived",
    },
    {
      displayName: "KSEB",
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterCategories: ["dailyActiveEnergy_Imp_Total_kWH"],
      color: "#1C82AD",
      apiType: "daily",
      type: "Derived",
    },
  ],
  showLegends: true,
  yAxisTitle: "kWh",
  xAxisTitle: "",
  chartHeight: "180px",
  includeAllDays: true,
  xAxisFormat: "DD",
  unit: "kWh",
  operation: "sum",
  groupBy: "day",
  legendAlignment: "end",
  legendPosition: "top",
  showFooter: true,
};

// Monthly Consumption Chart

export const monthlyConsumptionChartConfig = {
  title: "Energy Consumption Monthly Trends",
  chartConfig: [
    {
      label: "KSEB",
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterCategories: ["dailyActiveEnergy_Imp_Total_kWH"],
      borderColor: "#1C82AD",
      backgroundColor: "#1C82AD",
      parameterType: "Derived",
      yAxisID: "Energy",
    },
    {
      label: "DG",
      deviceCategories: ["DG Meter", "DG3 Meter"],
      parameterCategories: [
        "dailyActiveEnergy_Total_DG_kWH",
        "dailyActiveEnergy_Total_kWH",
      ],
      borderColor: "#EB5353",
      backgroundColor: "#EB5353",
      parameterType: "Derived",
      yAxisID: "Energy",
    },
    {
      label: "Total",
      deviceCategories: ["Total Meter"],
      parameterCategories: ["dailyActiveEnergy"],
      borderColor: "#03C988",
      backgroundColor: "#03C988",
      parameterType: "Derived",
      yAxisID: "Energy",
    },
    {
      label: "Briquette",
      deviceCategories: ["Enterprise"],
      parameterCategories: ["Daily Briquet"],
      borderColor: "#3e2d41",
      backgroundColor: "#3e2d41",
      parameterType: "Derived",
      yAxisID: "Briquette",
    },
  ],
  yAxisDetails: [
    {
      id: "Energy",
      position: "left",
      titleText: "Energy (in kWh)",
      titleAlign: "center",
    },
    {
      id: "Briquette",
      position: "left",
      titleText: "Briquette (in kg)",
      titleAlign: "center",
      titleColor: "#3e2d41",
      borderColor: "#3e2d41",
      tickColor: "#3e2d41",
    },
  ],
  operation: "sum",
  groupBy: "month",
  legendPosition: "top",
  includeAllDays: true,
  chartHeight: "250px",
  legendAlignment: "end",
};

// Daily Specific Energy Consumption Chart
export const specificConsumptionChartConfig = {
  title: "Daily Specific Energy Consumption",
  chartConfig: [
    {
      displayName: "Plan",
      deviceCategories: ["Enterprise"],
      parameterCategories: [
        "Daily Plan Sp consumption(AVT)",
        "Daily Plan Sp consumption(EoU)",
        "Daily Plan Sp consumption(Domestic)",
      ],
      color: "#EB5353",
      apiType: "daily",
      type: "Derived",
    },
    {
      displayName: "Run Hour",
      deviceCategories: ["Enterprise"],
      parameterCategories: [
        "Daily RH Sp consumption(AVT)",
        "Daily RH Sp consumption(EoU)",
        "Daily RH Sp consumption(Domestic)",
      ],
      color: "#03C988",
      apiType: "daily",
      type: "Derived",
    },
    {
      displayName: "Actual",
      deviceCategories: ["Enterprise"],
      parameterCategories: [
        "Daily Actual Sp consumption(AVT)",
        "Daily Actual Sp consumption(EoU)",
        "Daily Actual Sp consumption(Domestic)",
      ],
      color: "#1C82AD",
      apiType: "daily",
      type: "Derived",
    },
  ],
  showLegends: true,
  yAxisTitle: "kWh/MT",
  xAxisTitle: "",
  chartHeight: "180px",
  includeAllDays: true,
  xAxisFormat: "DD",
  unit: "kWh/MT",
  operation: "sum",
  groupBy: "day",
  legendAlignment: "end",
  legendPosition: "top",
  showFooter: false,
};

export const feederBreakUpConfig = {
  title: "Feeder BreakUp (kWh)",
  parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
  deviceCategories: ["Utility Meter", "Feeder Meter"],
  unit: "kWh",
};

// Box Plot Power Configurations
export const boxPlotPower = {
  title: "Power",
  deviceCategory: ["Feeder Meter", "Utility Meter", "Total Meter", "ToD Meter"],
  parameterCategory: "Active Power",
  legendPosition: "top",
  legendAlignment: "center",
  currentColor: "#8FE8ED",
  previousColor: "#C3C8D6",
  currentLabel: "Current",
  previousLabel: "Previous",
  unit: "W",
  uniqueId: "box-plot-power",
  chartHeight: "140px",
};

// Box Plot PF Configurations
export const boxPlotPF = {
  title: "PF",
  deviceCategory: ["Feeder Meter", "Utility Meter", "Total Meter", "ToD Meter"],
  parameterCategory: "Power Factor",
  legendPosition: "top",
  legendAlignment: "center",
  currentColor: "#8FE8ED",
  previousColor: "#C3C8D6",
  currentLabel: "Current",
  previousLabel: "Previous",
  unit: "W",
  uniqueId: "box-plot-pf",
  chartHeight: "140px",
};

// Box Plot Current Configurations
export const boxPlotCurrent = {
  title: "Current",
  deviceCategory: ["Feeder Meter", "Utility Meter", "Total Meter", "ToD Meter"],
  parameterCategory: "Current",
  legendPosition: "top",
  legendAlignment: "center",
  currentColor: "#8FE8ED",
  previousColor: "#C3C8D6",
  currentLabel: "Current",
  previousLabel: "Previous",
  unit: "W",
  uniqueId: "box-plot-current",
  chartHeight: "140px",
};

// Infoboard Configurations

export const infoBoardIntervalDuration = 60000; // Default duration is 60000

// For the energy consumption trend cards and cost
export const infoboardEnergyConsumptionDetails = {
  mainTitle: "Energy Consumption",
  totalTrendCardTitle: "Total",
  totalParameterCategories: ["ondemandActiveEnergy"],
  totalDeviceCategories: ["Total Meter"],
  totalParameterType: "Derived",
  totalTrendType: "Consumption",
  totalTrendRequired: true,
  totalUnit: "kWh",
  specificTrendCardTitle: "Specific",
  specificParameterCategories: ["ondemand Sp consumption AVT"],
  specificDeviceCategories: ["Enterprise"],
  specificParameterType: "Derived",
  specificTrendType: "Consumption",
  specificTrendRequired: true,
  specificUnit: "kWh/Unit",
  costTitle: "cost",
  costDeviceCategories: ["Total Meter"],
  costParameterCategories: ["Energy Cost"],
  costParameterType: "Derived",
  costUnit: "₹",
  costFormat: "en-IN",
};

// For Donut Chart
export const infoboardDonutChartConfig = {
  chartConfig: [
    {
      Name: "DG Meter",
      DeviceCategory: "Total Meter",
      ParameterCategory: "DG Percentage",
      Color: "#EB5353",
    },
    {
      Name: "KSEB",
      DeviceCategory: "Total Meter",
      ParameterCategory: "KSEB Percentage",
      Color: "#1C82AD",
    },
  ],
  chartHeight: "172px",
  chartCutOut: "60%",
  chartLabelOffSet: 32,
};

// For Carbon FootPrint Widget
export const infoboardCarbonFootPrintConfig = {
  mainTitle: "Carbon Footprint",
  emissionTrendCardTitle: "Actual Emission",
  emissionParameterCategories: ["ondemand_Carbon_FP"],
  emissionDeviceCategories: ["Total Meter"],
  emissionParameterType: "Derived",
  emissionTrendType: "Consumption",
  emissionTrendRequired: false,
  emissionUnit: "kg CO2",
  savingsTrendCardTitle: "Carbon Savings",
  savingsParameterCategories: ["ondemand_CFP_Saving"],
  savingsDeviceCategories: ["Total Meter"],
  savingsParameterType: "Derived",
  savingsTrendType: "Production",
  savingsTrendRequired: true,
  savingsUnit: "kg CO2",
};

// For Export Widget
export const infoboardExportConfig = {
  mainTitle: "Export",
  trendCardTitle: "Total",
  parameterCategories: ["ondemandActiveEnergy_Exp_total_kWH"],
  deviceCategories: ["ToD Meter"],
  parameterType: "Derived",
  trendType: "Consumption",
  trendRequired: true,
  unit: "kWh",
};

// For Energy Consumption Trend Chart
export const consumptionTrendChartConfig = {
  title: "Energy Consumption Trend",
  chartDetails: {
    parameterCategories: ["dailyActiveEnergy"],
    deviceCategories: ["Total Meter"],
    parameterType: "Derived",
    currentChartColor: "#86E5FF",
    currentDisplayName: "Current",
    currentBorderColor: "#86E5FF",
    previousDisplayName: "Previous",
    previousChartColor: "#FFC93C",
  },
  operation: "sum",
  yAxisTitle: "kWh",
  legendAlignment: "end",
  legendPosition: "top",
  chartType: "bar",
  showLegends: true,
  showOnlyCurrentData: false,
  showTarget: false,
  targetConfigurations: {},
  chartHeight: "175px",
  hideSecondXLabel: true,
};

// For Energy Consumption Cumulative Graph
export const cumulativeGraphConfig = {
  title: "Energy Consumption Cumulative Graph",
  parameterDetails: [
    {
      Name: "DISCOM",
      DeviceCategory: "Total Meter",
      ParameterCategory: "dailyActiveEnergy",
      Color: "red",
      ParameterType: "Derived",
    },
  ],
  chartDetails: {
    currGraphColor: "#1746A2",
    prevGraphColor: "#A4B7DE",
    targGraphColor: "#EB1D36",
  },
  operation: "sum",
  yAxisTitle: "kWh",
  xAxisTitle: "",
  chartHeight: "545px",
};

// Reports Configurations

// For Event Report
export const eventReportConfigurations = {
  buttonLabel: "Event Report",
  showSubmitButton: false,
  options: [
    { name: "Critical", id: "Critical", value: "Critical" },
    { name: "Informational", id: "Informational", value: "Informational" },
    { name: "Warning", id: "Warning", value: "Warning" },
  ],
  columnsToShow: [
    "EventName",
    "EventCategory",
    "Source",
    "OccuredOn",
    "CurrentStatus",
    "RemovedOn",
    "IsAcknowledged",
    "AcknowledgedBy",
    "AlertValue",
    "Comment",
  ],
  columnDetails: {
    EventName: {
      show: true,
      displayName: "Event Name",
    },
    EventCategory: {
      show: true,
      displayName: "Event Class",
    },
    Source: {
      show: true,
      displayName: "Source",
    },
    AlertValue: {
      show: true,
      displayName: "Alert Value",
    },
    OccuredOn: {
      show: true,
      displayName: "Occured On",
      isDateTime: true,
    },
    CurrentStatus: {
      show: true,
      displayName: "Status",
    },
    RemovedOn: {
      show: true,
      displayName: "Removed On",
      isDateTime: true,
    },
    IsAcknowledged: {
      show: true,
      displayName: "Ack Status",
      isBoolean: true,
    },
    AcknowledgedBy: {
      show: true,
      displayName: "Acked By",
    },
    Comment: {
      show: true,
      displayName: "Comment",
    },
  },
};

// For User Report
export const userReportConfigurations = {
  buttonLabel: "User Report",
  columnsOrder: [
    "Username",
    "RoleName",
    "AssignedNode",
    "CreateDate",
    "EditDate",
    "Status",
    "RemovedDate",
  ],
  columnDetails: {
    Username: {
      show: true,
      displayName: "User",
    },
    RoleName: {
      show: true,
      displayName: "Role",
    },
    AssignedNode: {
      show: true,
      displayName: "Assigned Node",
    },
    CreateDate: {
      show: true,
      displayName: "Created Date",
    },
    EditDate: {
      show: true,
      displayName: "Edited Date",
    },
    Status: {
      show: true,
      displayName: "Status",
    },
    RemovedDate: {
      show: true,
      displayName: "Removed Date",
    },
  },
};

// For Electrical Report
export const electricalReportConfigurations = {
  buttonLabel: "Daily Electrical Report",
  hourlyButtonLabel: "Hourly Electrical Report",
  showSubmitButton: false,
  groupBy: "day",
  groupByForHourly: "hour",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  electricalColumnDateFormat: "MMM D, YYYY",
  dropDownOptions: [
    {
      columnTitle: "Consumption",
      name: "kWh",
      api: "daily",
      aggregation: "avg",
      parameterCategories: ["dailyActiveEnergy", "dailyActiveEnergy_Total_kWH"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Derived",
      parameterType2: "Derived",
      extraParameterCategories: ["dailyActiveEnergy_Imp_Total_kWH"],
      extraDeviceCategories: ["ToD Meter"],
      unit: "Kwh",
    },
    {
      columnTitle: "Power",
      name: "kW",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Basic",
      parameterType2: "any",
      extraDeviceCategories: ["ToD Meter"],
      unit: "kW",
      divideByThousand: true,
    },
    {
      columnTitle: "Current",
      name: "Current",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Basic",
      parameterType2: "any",
      extraDeviceCategories: ["ToD Meter"],
      unit: "A",
    },
    {
      columnTitle: "Power Factor",
      name: "Power Factor",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Basic",
      parameterType2: "any",
      extraDeviceCategories: ["ToD Meter"],
      unit: "",
    },
  ],
  reportSummaryConfig: [
    {
      columnTitle: "Consumption",
      name: "kWh",
      api1: "aggregation-derived-daily",
      api2: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy", "dailyActiveEnergy_Total_kWH"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Derived",
      parameterType2: "Derived",
      extraParameterCategories: ["dailyActiveEnergy_Imp_Total_kWH"],
      extraDeviceCategories: ["ToD Meter"],
      unit: "kWh",
    },
    {
      columnTitle: "Power",
      name: "kW",
      api1: "aggregation-basic",
      api2: "aggregation-any",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Basic",
      parameterType2: "any",
      extraDeviceCategories: ["ToD Meter"],
      unit: "kW",
      divideByThousand: true,
    },
    {
      columnTitle: "Current",
      name: "Current",
      api1: "aggregation-basic",
      api2: "aggregation-any",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Basic",
      parameterType2: "any",
      extraDeviceCategories: ["ToD Meter"],
      unit: "A",
    },
    {
      columnTitle: "Power Factor",
      name: "Power Factor",
      api1: "aggregation-basic",
      api2: "aggregation-any",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Total Meter", "Feeder Meter", "Utility Meter"],
      parameterType1: "Basic",
      parameterType2: "any",
      extraDeviceCategories: ["ToD Meter"],
      unit: "",
    },
  ],
};

export const costReportConfigurations = {
  buttonLabel: "Daily Energy Cost Report",
  showSubmitButton: false,
  groupBy: "day",
  includeAllDays: true,
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  columnDateFormat: "MMM D, YYYY",
  dropDownOptions: [
    {
      name: "Energy KSEB",
      columnTitle: "Energy KSEB",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy_Imp_Total_kWH"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Energy DG",
      columnTitle: "Energy DG",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "dailyActiveEnergy_Total_DG_kWH",
        "dailyActiveEnergy_Total_kWH",
      ],
      deviceCategories: ["DG Meter", "DG3 Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Briquette Cost",
      columnTitle: "Briquette Cost",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["daily Briquet cost"],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "Rs",
    },
  ],
  summaryOptions: [
    {
      name: "Energy KSEB",
      columnTitle: "KSEB Total Energy",
      api: "evaluator",
      aggregation: "sum",
      parameterCategories: [
        "ondemandActiveEnergy_Imp_total_kWH",
        "ondemandActiveEnergy_Imp_Total_kWH",
      ],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Energy DG",
      columnTitle: "DG Total Energy",
      api: "evaluator",
      aggregation: "sum",
      parameterCategories: [
        "dailyActiveEnergy_Total_DG_kWH",
        "dailyActiveEnergy_Total_kWH",
      ],
      deviceCategories: ["DG Meter", "DG3 Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Briquette Cost",
      columnTitle: "Briquette Cost",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["daily Briquet cost"],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "Rs",
    },
  ],
  discomOptions: [
    {
      name: "Active Energy ToD1",
      columnTitle: "Active Energy ToD1",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy_Imp_ToD1_kWH"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Active Energy ToD2",
      columnTitle: "Active Energy ToD2",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy_Imp_ToD2_kWH"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Active Energy ToD3",
      columnTitle: "Active Energy ToD3",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy_Imp_ToD3_kWH"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Energy Cost ToD1",
      columnTitle: "Energy Cost ToD1",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily ToD1"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "Energy Cost ToD2",
      columnTitle: "Energy Cost ToD2",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily ToD2"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "Energy Cost ToD3",
      columnTitle: "Energy Cost ToD3",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily ToD3"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "Energy Cost KSEB",
      columnTitle: "Energy Cost KSEB",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
  ],
  dgOptions: [
    {
      name: "Energy Cost DG",
      columnTitle: "Energy Cost DG",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily"],
      deviceCategories: ["DG Meter", "DG3 Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
  ],
  discomSummaryOptions: [
    {
      name: "Active Energy ToD1",
      columnTitle: "Active Energy ToD1",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy_Imp_ToD1_kWH"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Active Energy ToD2",
      columnTitle: "Active Energy ToD2",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy_Imp_ToD2_kWH"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Active Energy ToD3",
      columnTitle: "Active Energy ToD3",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["dailyActiveEnergy_Imp_ToD3_kWH"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      name: "Energy Cost ToD1",
      columnTitle: "Energy Cost ToD1",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily ToD1"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "Energy Cost ToD2",
      columnTitle: "Energy Cost ToD2",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily ToD2"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "Energy Cost ToD3",
      columnTitle: "Energy Cost ToD3",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily ToD3"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "KSEB Average Cost",
      columnTitle: "KSEB Average Cost",
      api: "evaluator",
      aggregation: "sum",
      parameterCategories: ["Unit Cost KSEB"],
      deviceCategories: ["Total Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "KSEB Cost",
      columnTitle: "KSEB Cost",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily"],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
  ],
  dgSummaryOptions: [
    {
      name: "DG Average Cost",
      columnTitle: "DG Average Cost",
      api: "evaluator",
      aggregation: "sum",
      parameterCategories: ["Unit Cost DG"],
      deviceCategories: ["Total Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
    {
      name: "DG Cost",
      columnTitle: "DG Cost",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily"],
      deviceCategories: ["DG Meter", "DG3 Meter"],
      parameterType: "Derived",
      unit: "Rs",
    },
  ],
  commonSummaryOptions: [
    {
      name: "Total Cost",
      columnTitle: "Total Cost",
      api: "evaluator",
      aggregation: "sum",
      parameterCategories: [""],
      deviceCategories: [""],
      parameterType: "Derived",
      unit: "Rs",
      isCalculated: true,
      valuesToAdd: ["DG Cost", "KSEB Cost", "Briquette Cost"],
    },
  ],
  discomDetails: {
    currentTitle: "Energy KSEB",
  },

  dgDetails: {
    currentTitle: "Energy DG",
  },
  includeAllDays: true,
};

export const varianceReportConfigurations = {
  reportTitle: "Daily Energy Variance Report",
  buttonLabel: "Daily Energy Variance Report",
  showSubmitButton: false,
  groupBy: "day",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  columnDateFormat: "MMM D, YYYY",
  includeAllDays: true,
  dropDownOptions: [
    {
      columnTitle: "Plan",
      name: "Plan",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "Daily Total Planned KWH(AVT)",
        "Daily Total Planned KWH(EoU)",
        "Daily Total Planned KWH(Domestic)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Run Hour",
      name: "Run Hour",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "Daily Total Run Hour KWH(AVT)",
        "Daily Total Run Hour KWH(EoU)",
        "Daily Total Run Hour KWH(Domestic)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Actual",
      name: "Actual",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "Daily Actual KWH(AVT)",
        "Daily Actual KWH(EoU)",
        "Daily Actual KWH(Dom)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Plan Variance",
      name: "Plan Variance",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "Daily Variance planned",
        "Daily Variance planned(EoU)",
        "Daily Variance planned(Dom)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "%",
    },
    {
      columnTitle: "Run Hour Variance",
      name: "Run Hour Variance",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "Daily Variance Run Hour",
        "Daily Variance Run Hour(EoU)",
        "Daily Variance Run Hour(Dom)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "%",
    },
    {
      columnTitle: "Briquette",
      name: "Briquette",
      api: "daily",
      aggregation: "avg",
      parameterCategories: ["Daily Briquet"],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kg",
    },
  ],
  reportSummaryConfig: [
    {
      columnTitle: "Plan",
      name: "Plan",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "Daily Total Planned KWH(AVT)",
        "Daily Total Planned KWH(EoU)",
        "Daily Total Planned KWH(Domestic)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Run Hour",
      name: "Run Hour",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "Daily Total Run Hour KWH(AVT)",
        "Daily Total Run Hour KWH(EoU)",
        "Daily Total Run Hour KWH(Domestic)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Actual",
      name: "Actual",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "Daily Actual KWH(AVT)",
        "Daily Actual KWH(EoU)",
        "Daily Actual KWH(Dom)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Plan Variance",
      name: "Plan Variance",
      api: "aggregation-derived-daily",
      aggregation: "avg",
      parameterCategories: [
        "Daily Variance planned",
        "Daily Variance planned(EoU)",
        "Daily Variance planned(Dom)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "%",
    },
    {
      columnTitle: "Run Hour Variance",
      name: "Run Hour Variance",
      api: "aggregation-derived-daily",
      aggregation: "avg",
      parameterCategories: [
        "Daily Variance Run Hour",
        "Daily Variance Run Hour(EoU)",
        "Daily Variance Run Hour(Dom)",
      ],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "%",
    },
    {
      columnTitle: "Briquette",
      name: "Briquette",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Daily Briquet"],
      deviceCategories: ["Enterprise"],
      parameterType: "Derived",
      unit: "kg",
    },
  ],
};

export const energyReportConfigurations = {
  reportTitle: "Daily Energy Report",
  buttonLabel: "Daily Energy Report",
  showSubmitButton: false,
  groupBy: "day",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  columnDateFormat: "MMM D, YYYY",
  includeAllDays: true,
  columnOptions: [
    {
      columnTitle: "Active Energy ToD1",
      name: "Active Energy ToD1",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "dailyActiveEnergy_Import Active Energy Daily ToD1",
      ],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Active Energy ToD2",
      name: "Active Energy ToD2",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "dailyActiveEnergy_Import Active Energy Daily ToD2",
      ],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Active Energy ToD3",
      name: "Active Energy ToD3",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "dailyActiveEnergy_Import Active Energy Daily ToD3",
      ],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Energy KSEB",
      name: "Energy KSEB",
      api: "daily",
      aggregation: "avg",
      parameterCategories: [
        "dailyActiveEnergy_Imp_Total_kWH",
        "dailyActiveEnergy_Total_kWH",
      ],
      deviceCategories: [
        "ToD Meter",
        "Total Meter",
        "Feeder Meter",
        "Utility Meter",
        "EoU ToD Meter",
        "Domestic ToD Meter",
      ],
      parameterType: "Derived",
      unit: "kWh",
    },
  ],
  reportSummaryConfig: [
    {
      columnTitle: "Active Energy ToD1",
      name: "Active Energy ToD1",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "dailyActiveEnergy_Import Active Energy Daily ToD1",
      ],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Active Energy ToD2",
      name: "Active Energy ToD2",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "dailyActiveEnergy_Import Active Energy Daily ToD2",
      ],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Active Energy ToD3",
      name: "Active Energy ToD3",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "dailyActiveEnergy_Import Active Energy Daily ToD3",
      ],
      deviceCategories: ["ToD Meter", "EoU ToD Meter", "Domestic ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Energy KSEB",
      name: "Energy KSEB",
      api: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: [
        "dailyActiveEnergy_Imp_Total_kWH",
        "dailyActiveEnergy_Total_kWH",
      ],
      deviceCategories: [
        "ToD Meter",
        "Total Meter",
        "Feeder Meter",
        "Utility Meter",
        "EoU ToD Meter",
        "Domestic ToD Meter",
      ],
      parameterType: "Derived",
      unit: "kWh",
    },
  ],
};

export const feederReportConfigurations = {
  showSubmitButton: false,
  buttonLabel: "Report of Individual Feeders",
  reportTitle: "Report of Individual Feeders",
  groupBy: "none",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  columnDateFormat: "MMM D, YYYY HH:mm:ss",
  dropDownOptions: [
    {
      columnTitle: "kW",
      name: "kW",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      unit: "kW",
      divideByThousand: true,
    },
    {
      columnTitle: "Amps",
      name: "Amps",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      unit: "A",
    },
    {
      columnTitle: "PF",
      name: "PF",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      unit: "",
    },
    {
      columnTitle: "kWh Cumulative",
      name: "kWh Cumulative",
      api: "cumulative",
      aggregation: "avg",
      parameterCategories: ["Total_kWH_R"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
  ],
  reportSummaryConfig: [
    {
      columnTitle: "Total kW",
      name: "kW",
      api: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      unit: "kW",
      divideByThousand: true,
    },
    {
      columnTitle: "Total Amps",
      name: "Amps",
      api: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      unit: "A",
    },
    {
      columnTitle: "Total PF",
      name: "PF",
      api: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      unit: "",
    },
    {
      columnTitle: "Total kWh Cumulative",
      name: "kWh Cumulative",
      api: "cumulative",
      aggregation: "avg",
      parameterCategories: ["Total_kWH_R"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
  ],
};

export const rawReportConfigurations = {
  showSubmitButton: false,
  buttonLabel: "Electrical Energy Report",
  reportTitle: "Electrical Energy Report",
  groupBy: "none",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  columnDateFormat: "DD-MM-YYYY hh:mm:ss A",
  dropDownOptions: [
    {
      columnTitle: "ToD1 kWh",
      name: "ToD1 kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Imp_ToD1_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "ToD2 kWh",
      name: "ToD2 kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Imp_ToD2_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "ToD3 kWh",
      name: "ToD3 kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Imp_ToD3_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "Total kWh",
      name: "Total kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Imp_Total_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "DG1 kWh",
      name: "DG1 kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Total_kWH"],
      deviceCategories: ["DG1 Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
      nodeName: "EoU Plant",
    },
    {
      columnTitle: "DG2 kWh",
      name: "DG2 kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Total_kWH"],
      deviceCategories: ["DG2 Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
      nodeName: "EoU Plant",
    },
    {
      columnTitle: "DG3 kWh",
      name: "DG3 kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Total_kWH"],
      deviceCategories: ["DG3 Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
      nodeName: "Domestic Plant",
    },
    {
      columnTitle: "Total DG kWh",
      name: "Total DG kWh",
      api: "telemetry",
      aggregation: "avg",
      parameterCategories: ["deltaActiveEnergy_Total_DG_kWH"],
      deviceCategories: ["DG Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
  ],
  summaryOptions: [
    {
      columnTitle: "ToD1 kWh",
      name: "ToD1 kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Imp_ToD1_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "ToD2 kWh",
      name: "ToD2 kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Imp_ToD2_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "ToD3 kWh",
      name: "ToD3 kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Imp_ToD3_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "Total kWh",
      name: "Total kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Imp_Total_kWH"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
    {
      columnTitle: "DG1 kWh",
      name: "DG1 kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Total_kWH"],
      deviceCategories: ["DG1 Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
      nodeName: "EoU Plant",
    },
    {
      columnTitle: "DG2 kWh",
      name: "DG2 kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Total_kWH"],
      deviceCategories: ["DG2 Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
      nodeName: "EoU Plant",
    },
    {
      columnTitle: "DG3 kWh",
      name: "DG3 kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Total_kWH"],
      deviceCategories: ["DG3 Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
      nodeName: "Domestic Plant",
    },
    {
      columnTitle: "Total DG kWh",
      name: "Total DG kWh",
      api: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Total_DG_kWH"],
      deviceCategories: ["DG Meter"],
      parameterType: "Derived",
      unit: "kWh",
      divideByThousand: false,
    },
  ],
};

export const ksebBillConfigurations = {
  reportTitle: "KSEB Bill Cost Components",
  buttonLabel: "KSEB Bill Cost Components",
  derivedParameters: {
    "zone-1-consumption": ["bill_monthly_Imp_ToD1_kWH"],
    "zone-2-consumption": ["bill_monthly_Imp_ToD2_kWH"],
    "zone-3-consumption": ["bill_monthly_Imp_ToD3_kWH"],
    "total-consumption": ["bill_monthly_Imp_Total_kWH"],
    "zone-1-amount": ["bill_monthly_imp_ToD1_Cost"],
    "zone-2-amount": ["bill_monthly_imp_ToD2_Cost"],
    "zone-3-amount": ["bill_monthly_imp_ToD3_Cost"],
    "total-amount": ["bill_monthly_imp_Total_Cost"],
    "electric-duty-amount": ["Electricity_Duty"],
    "electric-surcharge-amount": ["Electricity_surcharge"],
    "fuel-surcharge-amount": ["Fuel_surcharge"],
    "monthly-fuel-surcharge-amount": ["Monthly_fuel_surcharge"],
    "duty-charge-total-amount": ["Duty_charges_Total"],
    "self-generated-duty": ["Self_gen_duty"],
    "monthly-md-total-amount": ["monthly_MD_charge_normal"],
    "total-charges": ["Net_Monthly_charge"],
    "demand-kva-zone1": ["monthlyMD_Z1"],
    "demand-kva-zone2": ["monthlyMD_Z2"],
    "demand-kva-zone3": ["monthlyMD_Z3"],
    "demand-kva-normal": ["monthly_MD"],
    "demand-kva-charge-normal": ["monthly_MD_charge_normal"],
  },
  deviceCategories: ["ToD Meter"],
  constantParameters: {
    "zone-1-rate": "Virtual AVT ToD Meter_ToD1_Unit cost",
    "zone-2-rate": "Virtual AVT ToD Meter_ToD2_Unit cost",
    "zone-3-rate": "Virtual AVT ToD Meter_ToD3_Unit cost",
    "electric-duty-rate": "Virtual AVT ToD Meter_Electricity_Duty_Mult",
    "electric-surcharge-rate": "Virtual AVT ToD Meter_Elect_sur_Mult",
    "fuel-surcharge-rate": "Virtual AVT ToD Meter_Fuel_sur_Mult",
    "monthly-fuel-surcharge-rate": "Virtual AVT ToD Meter_Month_fuel_sur_Mult",
    "self-generated-duty-consumption":
      "Virtual AVT ToD Meter_Self_gen_duty_KWh",
    "self-generated-duty-rate":
      "Virtual AVT ToD Meter_Self_gen_duty_multiplier",
    "belated-payment-charges": "Virtual AVT ToD Meter_Belated_payment",
    "pf-incentive-consumption": "Virtual AVT ToD Meter_bill_monthlyPF",
    "pf-incentive-amount": "Virtual AVT ToD Meter_PF_Incentive",
    "demand-kva-normal-rate": "Virtual AVT ToD Meter_MD_charge_normal",
  },
  realTimeParameters: {
    eou: {
      deviceCategories: ["EoU ToD Meter"],
      parameterCategories: {
        "zone-1-energy": ["Import Active Energy ToD1 RealTime"],
        "zone-2-energy": ["Import Active Energy ToD2 RealTime"],
        "zone-3-energy": ["Import Active Energy ToD3 RealTime"],
      },
      type: "Basic",
    },
    domestic: {
      deviceCategories: ["Domestic ToD Meter"],
      parameterCategories: {
        "zone-1-energy": ["Import Active Energy ToD1 RealTime"],
        "zone-2-energy": ["Import Active Energy ToD2 RealTime"],
        "zone-3-energy": ["Import Active Energy ToD3 RealTime"],
      },
      type: "Basic",
    },
  },
};

export const summaryReportConfigurations = {
  reportTitle: "Power Cosumption Summary Report",
  buttonLabel: "Power Cosumption Summary Report",
  dropDownOptions: [
    {
      columnTitle: "kW",
      name: "kW",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      api: "aggregation-basic",
      aggregation: "avg",
      divideByThousand: true,
      unit: "kW",
    },
    {
      columnTitle: "Amps",
      name: "Amps",
      parameterCategories: ["Current"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Basic",
      aggregation: "avg",
      api: "aggregation-basic",
      unit: "A",
    },
    {
      columnTitle: "PF",
      name: "PF",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      api: "aggregation-basic",
      aggregation: "avg",
      parameterType: "Basic",
    },
    {
      columnTitle: "kWh Cumulative",
      name: "kWh Cumulative",
      parameterCategories: ["Total_kWH_R"],
      deviceCategories: ["Feeder Meter", "Utility Meter"],
      parameterType: "Derived",
      api: "cumulative",
      unit: "kWh",
    },
  ],
  summaryOptions: [
    {
      columnTitle: "Energy Consumption",
      parameterCategories: ["ondemandActiveEnergy"],
      deviceCategories: ["Total Meter"],
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "EoU Process",
      parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
      deviceCategories: ["Total Meter"],
      nodeName: "EoU Production",
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "EoU Utility",
      parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
      deviceCategories: ["Total Meter"],
      nodeName: "EoU Utility",
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DP_DomBU Process",
      parameterCategories: ["ondemandActiveEnergy_Imp_Total_kWH"],
      deviceCategories: ["ToD Meter"],
      nodeName: "DP_DomBU",
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DG 1",
      parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
      deviceCategories: ["DG1 Meter"],
      nodeName: "EoU Plant",
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DG 2",
      parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
      deviceCategories: ["DG2 Meter"],
      nodeName: "EoU Plant",
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DG 3",
      parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
      deviceCategories: ["DG3 Meter"],
      nodeName: "Domestic Plant",
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DG Total",
      unit: "kWh",
      isCalculated: true,
      valuesToAdd: ["DG 1", "DG 2", "DG 3"],
    },
    {
      columnTitle: "Lighting",
      parameterCategories: ["ondemandActiveEnergy_Total_kWH"],
      deviceCategories: ["Utility Meter"],
      nodeName: "EU_PCC3Lighting",
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "Total Specific Consumption",
      parameterCategories: ["ondemand Sp consumption AVT"],
      deviceCategories: ["Enterprise"],
      unit: "kWh/MT",
      api: "evaluator",
      parameterType: "Derived",
    },
  ],
  excludeStartTime: false,
  showChildNodeSummary: true,
  groupBy: "none",
};

export const connectedEquipmentDetails = [
  {
    title: "KSEB",
    subTitle: "Tod Meter 1",
    content: ["KSEB Power Incomer", "LT SIDE ACB 2500 AMPS"],
  },
  {
    title: "APFC NO 2",
    content: [
      "POWER CAPACITOR PANEL NO: 1 - 300 KVAR",
      "12 NOS  25 KVAR Capacitors",
    ],
  },
  {
    title: "Colour Sorter - No 2",
    subTitle: "Mf Meter No 1",
    content: ["COLOUR SORTER NO: 2"],
  },
  {
    title: "MCC - 11",
    subTitle: "Meter No 2",
    content: ["CHILLEY DRYER", "RM DUST COLLECTOR FANS, BATTERY CHARGERS"],
  },
  {
    title: "MCC - 4",
    subTitle: "Meter No 3",
    content: ["HAMMER MILL - 4TH", "HAMMER MILL DRIVE - 75 HP"],
  },
  {
    title: "MCC 9",
    subTitle: "Meter No 4",
    content: [
      "UTILITIES - AIR COMPRESSORS, II FLOOR OFFICE AC , CHEMICAL LAB EQUIPMENTS & AC , CANTEEN , LOCKER ROOMS,SECURITY GATE, SERVER ROOM",
    ],
  },
  {
    title: "MCC - 2",
    subTitle: "Meter No 5",
    content: [
      "BOKFARD STERILIZER , AHU FANS, CHILLER PUMPS , DRIVES OF BLENDER 2A, NATURAL MILL AND TROPICAL CLEANING (4TH FLOOR)",
    ],
  },
  {
    title: "MCC - 3",
    subTitle: "Meter No 6",
    content: [
      "BLENDER - 2 , DRIVES OF SEED CLEANING LINE, HAMMER MILL , ROLLER MILL, TROPICAL CLEANING LINE MRC AND CC , ELECTRIC ROASTER",
    ],
  },
  {
    title: "Spare Feeder 1",
    subTitle: "Meter No 7",
    content: ["SPARE FEEDER"],
  },
  {
    title: "MCC - 1",
    subTitle: "Meter No 8",
    content: [
      "NATURAL HAMMER MILL , DRIVES OF TROPICAL AND SEED CLEANING LINES",
    ],
  },
  {
    title: "ACB - Bus Couplers",
    content: [],
  },
  {
    title: "MCC - 5",
    subTitle: "Meter No 9",
    content: [
      "BLENDER - 1 , BLENDER -2B, DRIVES OF ROLLER MILL AND 3TH MILL , SPICE STERILIZER ZONING AC&AHU, EFFLUENT PUMPS",
    ],
  },
  {
    title: "MCC - 8",
    subTitle: "Meter No 10",
    content: ["CHILLER PLANTS NO1 & 2 , BATTERY CHARGERS"],
  },
  {
    title: "PCC - 12",
    subTitle: "Meter No 11",
    content: [
      "PIN MILL FRONT & BACK DRIVE, COLOR SORTER NO 1 ,SEED MRC, AIR COMPRESSOR-4, NEW MILL ZONING AC&AHU",
    ],
  },
  {
    title: "PCC - 14",
    subTitle: "Meter No 12",
    content: [
      "NPD LAB, BLENDER -3 , NEW HAMMER MILL ,GROUND FLOOR ZONING AC&AHUs",
    ],
  },
  {
    title: "PCC - 13",
    subTitle: "Meter No 13",
    content: [
      "SPICE STERILIZER, ELEVATOR , BOILER, ETP, MECH:WORKSHOP, SUBSTATION, FIFTH FLOOR AC&AHU",
    ],
  },
  {
    title: "PCC - 3",
    subTitle: "Meter No 14",
    content: ["LIGHTING FEEDER"],
  },
  {
    title: "APFC NO 3",
    content: [
      "POWER CAPACITOR PANEL NO: 2 - 300 KVAR",
      "12 NOS  25 KVAR Capacitors",
    ],
  },
  {
    title: "DG NO 1",
    subTitle: "Meter No 15",
    content: ["STAND BY POWER - DIESEL GENERATOR NO 1", "750 KVA"],
  },
  {
    title: "DG NO 2",
    subTitle: "Meter No 16",
    content: ["STAND BY POWER - DIESEL GENERATOR NO 2", "750 KVA"],
  },
];

// For Trends
export const monthlyPeakConfigurations = {
  chartTitle: "Monthly Peak Identification",
  chartConfig: [
    {
      DisplayName: "Total Meter",
      DeviceCategory: "Total Meter",
      ParameterCategory: "deltaActiveEnergy_Total_kWH",
      Node: "Total Meter",
      Type: "line",
      selectable: true,
      inverse: false,
      color: "grad-#96B6C5>1|#ADC4CE>0",
    },
  ],
  chartHeight: "380px",
  datePickerType: "month",
  groupBy: "day",
  operation: "max",
  dayFormat: "DD",
  monthFormat: "MMM YYYY",
  xAxisLabel: "Days",
  monthHoverFormat: "MMM YYYY",
  dayHoverFormat: "DD MMMM YYYY",
  showRangePicker: false,
};

export const yearlyPeakConfigurations = {
  chartTitle: "Yearly Peak Identification",
  chartConfig: [
    {
      DisplayName: "Total Meter",
      DeviceCategory: "Total Meter",
      ParameterCategory: "deltaActiveEnergy_Total_kWH",
      Node: "NettoMeterNode",
      Type: "line",
      selectable: true,
      inverse: false,
      color: "grad-#A76F6F>1|#EAB2A0>0",
    },
  ],
  chartHeight: "380px",
  datePickerType: "year",
  groupBy: "month",
  operation: "max",
  dayFormat: "DD MMM yyyy",
  monthFormat: "MMM",
  xAxisLabel: "Months",
  monthHoverFormat: "MMMM YYYY",
  dayHoverFormat: "DD MMMM YYYY",
  showRangePicker: false,
};

export const dayAggregationConfigurations = {
  chartTitle: "Day Aggregation",
  chartConfig: [
    {
      DeviceCategory: "Load DB Meter",
      ParameterCategory: "deltaActiveEnergy_Total_kWH",
      Type: "line",
    },
  ],
  chartHeight: "380px",
  datePickerType: "day",
  groupBy: "day",
  operation: "sum",
  dayFormat: "DD MMM YYYY",
  monthFormat: "MMM YYYY",
  xAxisLabel: "Days",
  monthHoverFormat: "MMM YYYY",
  dayHoverFormat: "DD MMMM YYYY",
  showRangePicker: true,
};

export const monthAggregationConfigurations = {
  chartTitle: "Monthly Aggregation",
  chartConfig: [
    {
      DeviceCategory: "Load DB Meter",
      ParameterCategory: "deltaActiveEnergy_Total_kWH",
      Type: "line",
    },
  ],
  chartHeight: "380px",
  datePickerType: "manual",
  groupBy: "month",
  operation: "sum",
  dayFormat: "DD MMM YYYY",
  monthFormat: "MMM YYYY",
  xAxisLabel: "",
  monthHoverFormat: "MMMM YYYY",
  dayHoverFormat: "DD MMMM YYYY",
  showRangePicker: false,
};

export const generationAndConsumptionChart = {
  chartTitle: "Generation vs Consumption",
  staticConfig: [
    {
      DisplayName: "Production",
      DeviceCategory: "Solar Meter",
      ParameterCategory: "deltaActiveEnergy_Total_kWH",
      Node: "NettoMeterNode",
      Type: "bar",
      selectable: false,
      color: "#537C78",
      inverse: true,
    },
    {
      DisplayName: "Consumption",
      DeviceCategory: "ToD Meter",
      ParameterCategory: "deltaActiveEnergy_Imp_Total_kWH",
      Node: "NettoMeterNode",
      Type: "bar",
      selectable: false,
      color: "#FFD45B",
      inverse: false,
    },
  ],
  dynamicConfig: [],
  annotationConfig: [],
  uniqueId: "generation-vs-consumption-chart",
  datePickerType: "day",
  groupBy: "",
  chartHeight: "380px",
};

export const dayPeakIdentificationConfigurations = {
  chartTitle: "Day Peak Identification",
  staticConfig: [
    {
      DisplayName: "Production",
      DeviceCategory: "Solar Meter",
      ParameterCategory: "deltaActiveEnergy_Total_kWH",
      Type: "bar",
      selectable: true,
      color: "#537C78",
      inverse: true,
    },
  ],
  dynamicConfig: [
    {
      DeviceCategory: "Load DB Meter",
      ParameterCategory: "deltaActiveEnergy_Total_kWH",
      Type: "bar",
    },
  ],
  annotationConfig: [],
  uniqueId: "dayPeakIdentificationChart",
  datePickerType: "day",
  groupBy: "",
  chartHeight: "380px",
};
