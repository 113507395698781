<script>
  import DelAnalytics from "./../components/DelAnalytics/DelAnalytics.svelte";
  import {
    selectedNode,
    analyticsSelectedTab,
    currentPage,
    availableScreens,
    allNodes,
    defaultNodeId,
    parentNodeType,
  } from "./../stores";
  import { getDomainURL } from "./../invokeAPI";
  import { screensToShow } from "./../../config";

  const parameterMatches = [
    "deltaActiveEnergy",
    "dailyActiveEnergy",
    "monthlyActiveEnergy",
    "ondemandActiveEnergy",
    "Frequency",
    "Power Factor",
    "Current",
    "Voltage",
    "Apparent Power",
    "Active Power",
    "Apparent Energy",
    "Active Energy",
    "Power Demand",
  ];

  const onTabChanged = function (tab) {
    analyticsSelectedTab.set(tab);
  };

  const updateSelectedNode = function (id) {
    defaultNodeId.set(id);
  };

  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);
</script>

<div class="del-page-container del-analytics-page-container">
  <DelAnalytics
    platformBaseURL={getDomainURL()}
    analyticsBaseURL={getDomainURL("analytics")}
    currentScreen={"Analytics"}
    selectedNode={$selectedNode}
    {parameterMatches}
    {onTabChanged}
    isWritePermission={screenPermission === "RW"}
    allNodesList={$allNodes}
    {updateSelectedNode}
    nodeTypeToUpdate={$parentNodeType}
  ></DelAnalytics>
</div>

<style>
  .del-analytics-page-container {
    padding: 20px;
  }
</style>
