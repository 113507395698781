<script>
  import PlanInput from "./../components/DelInputScreen/PlanInput.svelte";
  import DelDatePicker from "./../components/DelDatePicker/DelDatePicker.svelte";
  import {
    rootNode,
    parentNodeType,
    startTimeOffSet,
    currentPage,
    availableScreens,
  } from "./../stores";
  import { screensToShow } from "./../../config.js";
  import { getDomainURL } from "./../invokeAPI";
  import moment from "moment";

  let selectedNodeId = null;
  let selectedDate = null;
  const fututeMaxDate = moment().add(5000, "day").valueOf();
  const dateRangesToShow = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
    "Current Year",
  ];
  let defaultStartDatePlanInput = sessionStorage.getItem(
    "plan-input-saved-start-date"
  )
    ? sessionStorage.getItem("plan-input-saved-start-date")
    : new Date(moment().subtract(6, "day").startOf("day"));
  let defaultEndDatePlanInput = sessionStorage.getItem(
    "plan-input-saved-end-date"
  )
    ? sessionStorage.getItem("plan-input-saved-end-date")
    : new Date(moment().endOf("day"));
  let dateFromSessionStorage =
    sessionStorage.getItem("plan-input-saved-start-date") ?? null;

  const checkIfTodayHasStarted = function (offSet, endTime) {
    const currentTime = moment().valueOf();
    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }
    return moment().startOf("day").valueOf() + offSet <= currentTime;
  };

  if (!checkIfTodayHasStarted($startTimeOffSet)) {
    if (!dateFromSessionStorage) {
      defaultStartDatePlanInput = new Date(
        moment(defaultStartDatePlanInput).subtract(1, "day")
      );
      defaultEndDatePlanInput = new Date(
        moment(defaultEndDatePlanInput).subtract(1, "day")
      );
    }
  }

  const onDateChange = function (newDate) {
    if (newDate) {
      selectedDate = { ...newDate };
      sessionStorage.setItem("plan-input-saved-start-date", newDate?.start);
      sessionStorage.setItem("plan-input-saved-end-date", newDate?.end);
    }
  };

  const setSelectedNodeId = function () {
    selectedNodeId = $rootNode.id;
    console.log("selected node id", selectedNodeId);
  };

  const deviceCategory = "Enterprise";
  const parameterCategories = [
    "Daily Total Planned KWH(AVT)",
    "Daily Total Run Hour KWH(AVT)",
    "Daily Actual KWH(AVT)",
    "Daily Variance planned",
    "Daily Variance Run Hour",
    "Daily Sp cons Total(EoU)",
    "Daily Sp cons FG(EoU)",
    "Daily Sp cons Total(Dom)",
    "Daily Sp cons FG(Dom)",
  ];

  const parametersToDisable = ["Run Hour PU"];

  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);

  $: {
    if ($parentNodeType && $rootNode) {
      if ($rootNode.type === $parentNodeType) {
        setSelectedNodeId();
      }
    }
  }
</script>

<div class="del-page-container del-plan-input-page-container">
  <div class="del-plan-input-date-picker">
    <DelDatePicker
      customRangesToShow={dateRangesToShow}
      onDateChanged={onDateChange}
      autoApply={true}
      defaultStartDate={defaultStartDatePlanInput}
      defaultEndDate={defaultEndDatePlanInput}
      zIndex={11}
      firstDay={1}
      maxDate={fututeMaxDate}
    ></DelDatePicker>
  </div>
  <PlanInput
    parentNodeID={selectedNodeId}
    baseURL={getDomainURL()}
    currentScreen={"Plan Input"}
    startTime={selectedDate?.dayDefinedStartDateInSeconds}
    endTime={selectedDate?.dayDefinedEndDateInSeconds}
    offsetStartTime={$startTimeOffSet}
    {deviceCategory}
    parameterCategory={parameterCategories}
    isWritePermission={screenPermission === "RW"}
    {parametersToDisable}
    startTimeWithoutOffSet={selectedDate?.startDateInSeconds}
    endTimeWithoutOffset={selectedDate?.endDateInSeconds}
  ></PlanInput>
</div>

<style>
  .del-plan-input-page-container {
    padding: 20px;
  }

  .del-plan-input-date-picker {
    padding-bottom: 10px;
  }
</style>
