<script>
  import { onMount } from "svelte";

  export let baseURL = "";
  export let nodeDetails;
  export let parameterCategory = [];
  export let deviceCategory = [];
  export let parameterType = "";
  export let currentScreen = "Events";
  export let currStartTime;
  export let currEndTime;
  export let prevStartTime;
  export let prevEndTime;
  export let trendType = "Consumption";
  export let widgetTitle = "Specific";
  export let unit = "kWh";
  export let isTrendRequired = true;
  export let titlePadding = "";
  export let contentPadding = "";

  let currentValue = "Loading...";
  let trendValue = "";
  let data = [];
  let parameterIDList = [];
  let isNoData = true;
  let trendRealValue = "";

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  $: {
    if (
      nodeDetails &&
      parameterCategory &&
      deviceCategory &&
      parameterType &&
      currStartTime &&
      currEndTime &&
      prevStartTime &&
      prevEndTime
    ) {
      clearData();
      assignPayloadToAPIs();
    } else {
      setNoData();
    }
  }

  async function fetchData() {
    try {
      const payload = {
        timespan: "Custom",
        C_EndTime: currEndTime,
        endTime: currEndTime,
        C_StartTime: currStartTime,
        p_EndTime: prevEndTime,
        p_StartTime: prevStartTime,
        parameters: parameterIDList,
      };

      const url = `${baseURL}/dpe/api/trend`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(payload),
      };
      if (
        !(
          currStartTime &&
          currEndTime &&
          prevStartTime &&
          prevEndTime &&
          parameterIDList &&
          parameterIDList.length &&
          nodeDetails
        )
      ) {
        setNoData();
        return;
      }
      const response = await fetch(url, options);
      if (response.status !== 200) setNoData();
      if (!response.ok) {
        let error = await response.json();
        setNoData();
        throw new Error(error);
      }
      data = await response.json();
    } catch (error) {
      console.error(error);
    }

    if (!data.length) {
      setNoData();
      return;
    }

    isNoData = isNaN(data[0].Current.Value);

    currentValue = isNaN(data[0].Current.Value)
      ? "No Data"
      : Math.abs(Math.round(data[0].Current.Value)).toLocaleString("en-IN");
    trendValue = isNaN(data[0].Trend)
      ? "No Data"
      : Math.abs(Math.round(data[0].Trend)).toLocaleString("en-IN") + " %";
    trendRealValue = isNaN(data[0].Trend) ? 0 : data[0].Trend;
  }

  // Fetch data when the component is mounted
  onMount(() => {
    // fetchData();
  });

  function setNoData() {
    isNoData = true;
    currentValue = "No Data";
    trendValue = "No Data";
    trendRealValue = 0;
  }

  function getClassForTrendType(trendtype, trendvalue) {
    let trendValue = isNaN(trendvalue) ? trendvalue : Math.round(trendvalue);
    switch (trendtype) {
      case "Production":
        if (trendValue > 0) return "positive-class trends-percentage-arrow-up";
        else if (trendValue < 0)
          return "negative-class trends-percentage-arrow-down";
        else return "no-data-class";
      case "Consumption":
        if (trendValue < 0)
          return "positive-class trends-percentage-arrow-down";
        else if (trendValue > 0)
          return "negative-class trends-percentage-arrow-up";
        else return "no-data-class";
      default:
        return "no-data-class";
    }
  }

  function filterParameterId(
    parameterCategories,
    deviceCategories,
    type,
    parameterdetails
  ) {
    if (parameterCategories && deviceCategories && parameterdetails && type) {
      switch (type) {
        case "Basic":
          if (nodeDetails.parameters && nodeDetails.parameters.length) {
            for (let basicParameter of nodeDetails.parameters) {
              if (
                deviceCategories.includes(basicParameter.DeviceCategory) &&
                parameterCategories.includes(basicParameter.ParameterCategory)
              ) {
                // console.log("ParameterID", basicParameter.ParameterID);
                return basicParameter.ParameterID;
              }
            }
          }

          break;

        case "Derived":
          if (
            nodeDetails.derivedparameters &&
            nodeDetails.derivedparameters.length
          ) {
            for (let derivedParameter of nodeDetails.derivedparameters) {
              if (
                derivedParameter.DerivedParameterName ===
                "GSSHQ Meter_ondemand_Total_kWH"
              ) {
              }
              if (
                deviceCategories.includes(derivedParameter.DeviceCategory) &&
                parameterCategories.includes(derivedParameter.ParameterCategory)
              ) {
                // console.log("ParameterID", derivedParameter.DerivedParameterId);
                return derivedParameter.DerivedParameterId;
              }
            }
          }

          break;

        default:
          console.error("Unspecified Parameter Type!!!");
          break;
      }
    }
    return null;
  }

  function assignPayloadToAPIs() {
    if (nodeDetails && parameterCategory && deviceCategory && parameterType) {
      const parameterID = filterParameterId(
        parameterCategory,
        deviceCategory,
        parameterType,
        nodeDetails
      );
      if (parameterID) {
        parameterIDList = [parameterID];
        fetchData();
      } else {
        setNoData();
      }
    }
  }

  function clearData() {
    currentValue = "Loading...";
    trendValue = "";
    data = [];
    parameterIDList = [];
    trendRealValue = "";
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="trend-card">
  <div class="title-div" style={titlePadding ? `padding:${titlePadding};` : ""}>
    {widgetTitle}
  </div>
  <div
    class="content-div"
    style={contentPadding ? `padding:${contentPadding};` : ""}
  >
    <div class="value-div">
      <div class="current-value">{currentValue}</div>
      {#if !isNoData && currentValue !== "Loading..."}
        <div class="unit-div">{unit}</div>
      {/if}
    </div>
    {#if isTrendRequired}
      <div
        class="percentage-value {getClassForTrendType(
          trendType,
          trendRealValue
        )}"
      >
        {trendValue}
      </div>
    {/if}
  </div>
</div>

<style>
  .trend-card {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .title-div {
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    /* text-transform: capitalize; */
    opacity: 1;
    padding: 16px 22px;
    padding-top: 0;
  }

  .content-div {
    display: flex;
    justify-content: space-between;
    /* gap: 10px; */
    padding: 16px 22px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .value-div {
    display: flex;
    gap: 5px;
    align-items: baseline;
  }

  .current-value {
    text-align: left;
    font: normal normal bold 16px Roboto;
    letter-spacing: 0px;
    color: #003955;
  }

  .unit-div {
    text-align: left;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    color: #003955;
  }

  .percentage-value {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .positive-class {
    float: right;
    text-align: left;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    color: #1b9e58;
    opacity: 1;
  }

  .negative-class {
    float: right;
    text-align: left;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    color: #d20000;
    opacity: 1;
  }

  .trends-percentage-arrow-up:before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #f51842;
    margin-right: 2px;
  }

  .trends-percentage-arrow-down:before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #3ab45a;
    margin-right: 2px;
  }

  .positive-class.trends-percentage-arrow-up:before {
    border-bottom: 9px solid #3ab45a;
  }

  .negative-class.trends-percentage-arrow-down:before {
    border-top: 9px solid #f51842;
  }
  .no-data-class {
    float: right;
    text-align: left;
    margin-left: auto;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    color: grey;
    opacity: 1;
  }
</style>
