<script>
  import Icon from "@iconify/svelte";
  import moment from "moment";
  import { exportCSVExcel, generatePDF } from "./exportReports.js";
  import Loader from "./Loader.svelte";

  export let nodeDetails = null;
  export let currentScreen = "Reports";
  export let baseURL = "";
  export let dateTimeFormat = "DD-MMM-YYYY";
  export let columnsOrder = [
    "Username",
    "RoleName",
    "AssignedNode",
    "CreateDate",
    "EditDate",
    "Status",
    "RemovedDate",
  ];
  export let columnDetails = {
    Username: {
      show: true,
      displayName: "User",
    },
    RoleName: {
      show: true,
      displayName: "Role",
    },
    AssignedNode: {
      show: true,
      displayName: "Assigned Node",
    },
    CreateDate: {
      show: true,
      displayName: "Created Date",
    },
    EditDate: {
      show: true,
      displayName: "Edited Date",
    },
    Status: {
      show: true,
      displayName: "Status",
    },
    RemovedDate: {
      show: true,
      displayName: "Removed Date",
    },
  };
  export let showSummary = true;
  export let summaryDetails = [];
  export let entriesPerPage = 10;
  export let showBackButton = true;
  export let onBackButtonClick = null;

  let reportData = [];
  let sortedAndFilteredData = [];
  let paginatedData = [];
  let currentPage = 1;
  let totalPages = 0;
  let reportSummary = {};
  let nodeName = null;
  let nodeId = null;
  let isAwaitingReportDataAPI = false;

  $: {
    nodeName = nodeDetails?.name || "";
    nodeId = nodeDetails?.id || "";
  }

  $: {
    if (nodeDetails && currentScreen && baseURL) {
      invokeGetReportDataAPI();
    }
  }

  $: {
    totalPages = Math.ceil(sortedAndFilteredData.length / entriesPerPage);
  }

  async function getResponse(endpoint, method = "GET") {
    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${baseURL}/${endpoint}`, {
      method,
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  }

  const invokeGetReportDataAPI = function () {
    if (
      typeof localStorage === "undefined" ||
      !(nodeDetails && currentScreen && baseURL)
    ) {
      return;
    }
    reportData = [];

    const endpoint = `user/api/user/userreport`; // add the required endpoint
    isAwaitingReportDataAPI = true;
    getResponse(endpoint)
      .then((data) => {
        isAwaitingReportDataAPI = false;
        onReportDataReceived(data);
      })
      .catch((error) => {
        isAwaitingReportDataAPI = false;
        console.error("API call failed", error);
      });
  };

  const onReportDataReceived = function (data) {
    if (data) {
      // Process the received data and save it in the required format here
      reportData = data["Users"];
      reportData.forEach((user) => {
        user.CreateDate = formatDateTime(user.CreateDate);
        user.EditDate = formatDateTime(user.EditDate);
        user.RemovedDate = formatDateTime(user.RemovedDate);
        user.AssignedNode = user.AssignedNode?.length
          ? user.AssignedNode.join(", ").trim()
          : "";
      });
      summaryDetails = data["ActiveUsers"];
      sortedAndFilteredData = reportData;
      updatePagination();
    }
  };

  const formatDateTime = function (value, format) {
    const dateFormat = format ? format : dateTimeFormat;
    return !value || isNaN(value)
      ? ""
      : moment(new Date(parseInt(value * 1000))).format(dateFormat);
  };

  const convertToTwoDigits = function (value) {
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  const getValueWithUnit = function (value, unit) {
    if (isNaN(value) || value === null) {
      return "";
    }
    if (unit) {
      return `${convertToTwoDigits(value)} ${unit}`;
    } else {
      return convertToTwoDigits(value);
    }
  };

  const updatePagination = function () {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    paginatedData = sortedAndFilteredData.slice(startIndex, endIndex);
  };

  const handleFirstPageClick = function () {
    currentPage = 1;
    updatePagination();
  };
  const handleLastPageClick = function () {
    currentPage = totalPages;
    updatePagination();
  };
  const handleNextPageClick = function () {
    currentPage++;
    updatePagination();
  };
  const handlePreviousPageClick = function () {
    currentPage--;
    updatePagination();
  };

  // Export report related functions
  const handlePDFExportButtonClick = function () {
    handleReportExport("pdf");
  };
  const handleCSVExportButtonClick = function () {
    handleReportExport("csv");
  };
  const handleExcelExportButtonClick = function () {
    handleReportExport("xls");
  };

  const getDataToDownload = function () {
    const dataToDownload = [];
    let newRow = {};
    if (reportData?.length && columnsOrder?.length && columnDetails) {
      for (const row of reportData) {
        newRow = {};
        for (const column of columnsOrder) {
          if (columnDetails[column] && columnDetails[column].show) {
            const columnName = columnDetails[column].displayName || column;
            newRow[columnName] = row[column] ?? "";
          }
        }
        dataToDownload.push(newRow);
      }
    }
    return dataToDownload;
  };

  const handleReportExport = function (exportType) {
    const dataToDownload = getDataToDownload();
    const columnsToShow = columnsOrder.map(
      (column) => columnDetails[column].displayName || column
    );
    if (showSummary) {
      reportSummary = {
        "Active User Count": summaryDetails,
      };
    } else {
      reportSummary = null;
    }
    if (exportType === "pdf") {
      generatePDF(
        nodeName,
        reportSummary,
        dataToDownload,
        columnsToShow,
        "User Report",
        columnsToShow.length
      );
    } else {
      exportCSVExcel(
        exportType,
        "User Report",
        nodeName,
        reportSummary,
        dataToDownload,
        "User Report"
      );
    }
  };
</script>

<div class="del-user-report-container">
  <div class="del-user-report-header-container">
    {#if isAwaitingReportDataAPI}
      <Loader />
    {/if}
    <div
      class="del-user-report-header-inner-container del-user-report-header-container-top"
    >
      <div class="flex-container">
        {#if showBackButton}
          <div>
            <button
              class="del-user-report-header-back-button"
              on:click={() => {
                onBackButtonClick && onBackButtonClick();
              }}><Icon icon="ep:back" /></button
            >
          </div>{/if}
        <div>
          <div class="del-user-report-header-main-title">User Report</div>
          <div class="del-user-report-header-sub-title">
            Report of {nodeName}
          </div>
        </div>
      </div>
      <div>
        <div class="del-user-report-header-titles">Download</div>
        <div
          class="flex-container del-user-report-table-export-buttons-container"
        >
          <button
            name="csvDownloadButton"
            title={sortedAndFilteredData?.length
              ? "Export CSV"
              : "No data to download"}
            class="del-user-report-table-export-button csv-export-button"
            on:click={handleCSVExportButtonClick}
            disabled={!sortedAndFilteredData?.length}
          />
          <button
            name="excelDownloadButton"
            title={sortedAndFilteredData?.length
              ? "Export Excel"
              : "No data to download"}
            class="del-user-report-table-export-button excel-export-button"
            on:click={handleExcelExportButtonClick}
            disabled={!sortedAndFilteredData?.length}
          />
          <button
            name="pdfDownloadButton"
            title={sortedAndFilteredData?.length
              ? "Export PDF"
              : "No data to download"}
            class="del-user-report-table-export-button pdf-export-button"
            on:click={handlePDFExportButtonClick}
            disabled={!sortedAndFilteredData?.length}
          />
        </div>
      </div>
    </div>
    <div
      class="del-user-report-header-inner-container del-user-report-header-container-bottom"
    >
      {#if showSummary}
        <div class="del-user-report-summary-item">
          <div class="del-user-report-summary-label">Active User Count</div>
          <div class="del-user-report-summary-separator">:</div>
          <div class="del-user-report-summary-value">
            {summaryDetails}
          </div>
        </div>
      {/if}
    </div>
  </div>
  <div class="del-user-report-table-container">
    <!-- Report Table -->
    {#if sortedAndFilteredData?.length}
      <div class="del-user-report-table-inner-container">
        <table class="del-user-report-table">
          <thead>
            {#if columnsOrder?.length}
              <tr class="del-user-report-table-header-row">
                {#each columnsOrder as item (item)}
                  <th
                    class="del-user-report-table-cell del-user-report-table-header-cell"
                    >{columnDetails[item].displayName}</th
                  >
                {/each}
              </tr>
            {/if}
          </thead>
          <tbody class="table-body">
            {#each paginatedData as row}
              <tr class="del-user-report-table-row">
                {#each columnsOrder as item (item)}
                  <td
                    class={`del-user-report-table-cell ${
                      item === "Status"
                        ? row[item] === "Removed"
                          ? "del-user-report-table-user-inactive"
                          : row[item] === "Active"
                            ? "del-user-report-table-user-active"
                            : "del-user-report-table-user-unassigned"
                        : ""
                    }`}>{row[item]}</td
                  >
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {:else}
      <div class="no-data-message">No Data Available</div>
    {/if}
    <!-- Pagination -->
    {#if sortedAndFilteredData.length > entriesPerPage}
      <div class="pagination-container">
        <div class="entries-display">
          {`Showing ${(currentPage - 1) * entriesPerPage + 1} to ${Math.min(
            currentPage * entriesPerPage,
            sortedAndFilteredData.length
          )} of ${sortedAndFilteredData.length} entries`}
        </div>
        <div class="pagination-buttons">
          <button
            class="first-page"
            disabled={currentPage === 1}
            on:click={handleFirstPageClick}>First</button
          >
          <button
            class="previous-page"
            disabled={currentPage === 1}
            on:click={handlePreviousPageClick}>Previous</button
          >
          <span class="current-page"
            >{`Page ${currentPage} of ${totalPages}`}</span
          >
          <button
            class="next-page"
            disabled={currentPage === totalPages}
            on:click={handleNextPageClick}>Next</button
          >
          <button
            class="last-page"
            disabled={currentPage === totalPages}
            on:click={handleLastPageClick}>Last</button
          >
        </div>
      </div>
    {/if}
  </div>
</div>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<style>
  .del-user-report-container {
    position: relative;
  }
  .del-user-report-table-container {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    margin: 20px 0;
    padding: 10px;
    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .del-user-report-header-container {
    background: #6e90d0 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 0;
    margin-top: 20px;
  }

  .del-user-report-header-inner-container {
    display: flex;
    align-content: center;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }

  .del-user-report-header-container-top {
    background: #4b72b9;
    min-height: 100px;
    border-radius: 10px 10px 0 0;
    background-image: url("./../images/headerBackground.svg");
  }

  .del-user-report-header-container-bottom {
    justify-content: start;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 20px 3px 0;
    gap: 15px;
  }

  .del-user-report-summary-item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    padding-left: 0;
    gap: 10px;
  }

  .del-user-report-summary-item:not(:last-child) {
    border-right: 1px solid #b2b2b2;
  }

  .del-user-report-summary-label,
  .del-user-report-summary-separator {
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .del-user-report-summary-value {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #16558f;
    opacity: 1;
  }

  .del-user-report-header-back-button {
    background: #7293d1 0 0 no-repeat padding-box;
    border: 1px solid #4565a0;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .del-user-report-header-back-button:enabled:hover,
  .del-user-report-header-back-button:enabled:active,
  .del-user-report-header-back-button:enabled:focus {
    transform: scale(1.05);
  }

  .del-user-report-header-main-title {
    text-align: left;
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-user-report-header-sub-title {
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-user-report-header-titles {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }

  .del-user-report-header-small-titles {
    font-size: 14px;
  }

  .del-user-report-table-export-buttons-container {
    margin-top: 10px;
  }

  .del-user-report-table-export-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 6px;
    padding: 2px;
  }

  .del-user-report-table-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .del-user-report-table-export-button:enabled:hover,
  .del-user-report-table-export-button:enabled:active,
  .del-user-report-table-export-button:enabled:focus {
    transform: scale(1.05);
  }

  .csv-export-button {
    background-image: url("./../images/csvLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .pdf-export-button {
    background-image: url("./../images/pdfLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .excel-export-button {
    background-image: url("./../images/excelLogo.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .del-user-report-summary-title {
    margin-bottom: 10px;
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .del-user-report-header-dates {
    text-align: right;
  }

  button {
    cursor: pointer;
    border: none;
  }

  .no-data-message {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    font: italic normal normal 16px/21px Roboto;
  }

  /* For Report Table */
  .del-user-report-table-inner-container {
    overflow-x: auto;
    border: 1px solid #d3d3d3;
    margin: 0;
    padding: 0;
  }
  .del-user-report-table {
    width: 100%;
    border-spacing: 0;
    position: relative;
  }

  .del-user-report-table td:first-child,
  .del-user-report-table th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    border-right: 1px solid #d3d3d3;
    min-width: 300px;
  }

  .del-user-report-table th:last-child {
    border-right-color: #79919a;
  }

  .del-user-report-table-cell {
    padding: 10px 15px;
    text-align: center;
    font: normal normal normal 15px/21px Roboto;
    color: #000000;
    opacity: 0.77;
    min-width: 250px;
    border: 1px solid #d3d3d3;
    background-color: #ffffff;
    opacity: 1;
  }

  .del-user-report-table-no-data-cell {
    text-align: center;
  }

  .del-user-report-table-header-row {
    border: 1px solid #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }

  .del-user-report-table-filter-row {
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #beccd1;
    border: 1px solid #d3d3d3;
  }

  .del-user-report-table-header-cell {
    border-color: #79919a;
    border-right-color: #d3d3d3;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }
  .del-user-report-table-date {
    margin-left: 1ch;
    color: #798b91;
  }

  .del-user-report-table-user-critical {
    color: #e1464a;
  }

  .del-user-report-table-user-warning {
    color: #fcb900;
  }

  .del-user-report-table-user-informational {
    color: #4caf50;
  }

  .del-user-report-table-user-active {
    color: #0000ff;
  }

  .del-user-report-table-user-inactive {
    color: #ff0000;
  }

  .del-user-report-table-user-unassigned {
    color: #999;
  }

  .del-user-report-table-button {
    color: #fff;
    background-color: #2c3049;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
  }

  .del-user-report-table-button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .del-user-report-table-button:enabled:hover {
    background: #222639;
  }

  /* For Pagination */
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .pagination-buttons button {
    margin: 0;
    background: #7293d1 0 0 no-repeat padding-box;
    border: none;
    color: #ffffff;
    opacity: 1;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
  }

  .pagination-buttons button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .pagination-buttons button:enabled:hover {
    background: #4b72b9;
  }

  .current-page {
    font-weight: bold;
    margin: 0 10px;
  }

  .entries-display {
    font-style: italic;
    margin-right: 10px;
  }

  /* End of Pagination styles */
</style>
