<script>
  import DelDatePicker from "./../DelDatePicker/DelDatePicker.svelte";
  import moment from "moment/moment";
  export let defaultStartTime = null;
  export let defaultEndTime = null;
  export let extraValidations = null;
  export let onCancel = null;
  export let onSubmit = null;
  export let submitButtonLabel = "";
  export let showLatestTimeAsEndTimeForToday = false;

  let error = {};
  let dateTimeFormat = "D MMMM YYYY HH:mm:ss";
  let startTime = null;
  let endTime = null;

  const validateDates = function (start, end) {
    error.status = false;
    if (!(start && end)) {
      error = {
        status: true,
        message: "From/To time cannot be empty",
      };
    } else if (start > end) {
      error = {
        status: true,
        message: "From time cannot be greater than To time",
      };
    } else if (extraValidations) {
      error = extraValidations(start, end);
    }
    return !error?.status;
  };

  const onSubmitClick = function () {
    if (startTime && endTime) {
      if (validateDates(startTime?.dateInSeconds, endTime?.dateInSeconds)) {
        onSubmit(startTime?.dateInSeconds, endTime?.dateInSeconds);
      }
    } else {
      error = {
        status: true,
        message: "Invalid date",
      };
    }
  };

  const epoch13to10 = function (date) {
    if (!date) return date;
    return parseInt(date / 1000);
  };

  const isGreaterThanStartOfToday = function (date) {
    const startOfToday = epoch13to10(
      addOffSet(moment(new Date()).startOf("day").valueOf())
    );
    return epoch13to10(date) > startOfToday;
  };

  const addOffSet = function (date, offSet, endTime) {
    if (!(date && parseInt(date))) {
      return date;
    }

    const currentTime = moment().valueOf();

    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }

    let updatedDate = date;

    if (moment().startOf("day").valueOf() + offSet <= currentTime) {
      // if as per day definition, today has started
      updatedDate += offSet;
    } else {
      // else, we are still in yesterday, so set the date selected as one day before
      if (endTime) {
        updatedDate =
          moment(date).endOf("day").subtract(1, "days").valueOf() + offSet;
      } else {
        updatedDate =
          moment(date).startOf("day").subtract(1, "days").valueOf() + offSet;
      }
    }

    return updatedDate;
  };

  const getEndTime = function () {
    if (isGreaterThanStartOfToday(defaultEndTime)) {
      return new Date();
    } else {
      return defaultEndTime;
    }
  };
</script>

<div class="del-time-picker-modal">
  <div class="del-time-picker-modal-header">
    <div class="del-time-picker-modal-title">Select Time</div>
  </div>
  <div class="del-time-picker-modal-ignore-container">
    <div class="time-picker-container">
      <div class="time-picker-label">From :</div>
      <DelDatePicker
        showRangePicker={false}
        showTimePicker={true}
        format={dateTimeFormat}
        defaultDate={defaultStartTime}
        autoApply={true}
        onDateChanged={(date) => {
          startTime = date;
          error = {};
        }}
      />
    </div>
    <div class="time-picker-container">
      <div class="time-picker-label">To :</div>
      <DelDatePicker
        showRangePicker={false}
        showTimePicker={true}
        format={dateTimeFormat}
        defaultDate={showLatestTimeAsEndTimeForToday
          ? getEndTime(defaultEndTime)
          : defaultEndTime}
        autoApply={true}
        onDateChanged={(date) => {
          endTime = date;
          error = {};
        }}
      />
    </div>
    {#if error?.status}
      <div class="error-message">{error?.message || ""}</div>
    {/if}
  </div>
  <div class="del-time-picker-modal-buttons-container">
    <button
      class="del-time-picker-modal-cancel-button"
      id="del-time-picker-modal-cancel-button"
      on:click={() => {
        onCancel && onCancel();
      }}>Cancel</button
    >
    <button
      class="del-time-picker-modal-submit-button"
      id="del-time-picker-modal-submit-button"
      on:click={onSubmitClick}
      disabled={error.status}>{submitButtonLabel || "Submit"}</button
    >
  </div>
</div>

<style>
  /* For Modal */
  .del-time-picker-modal {
    position: fixed;
    display: block;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 600;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 160px;
    top: 18%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  .del-time-picker-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    font: normal normal bold 16px/21px Roboto;
    color: #000000;
  }
  .del-time-picker-modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
    margin-top: 10px;
  }

  .del-time-picker-modal-ignore-container {
    font: normal normal normal 15px Roboto;
    color: #000000;
    padding: 30px 0;
  }

  button.del-time-picker-modal-submit-button {
    color: #fff;
    background-color: #2c3049;
    border-color: #222639;
    border-radius: 5px;
    padding: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }

  button.del-time-picker-modal-cancel-button {
    color: #e51f1f;
    background: transparent;
    border: none;
    font: normal normal bold 14px Roboto;
    cursor: pointer;
  }

  button.del-time-picker-modal-submit-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .time-picker-container {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 25px;
    margin-bottom: 20px;
  }

  .time-picker-label {
    flex-grow: 1;
  }

  .error-message {
    color: #ff0000;
    font: normal normal normal 14px Roboto;
  }

  @media screen and (min-width: 700px) {
    .del-time-picker-modal {
      width: 450px;
      left: calc(50% - 225px);
      top: 25%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-time-picker-modal {
      width: 68%;
      left: calc(50% - 34%);
      top: 25%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-time-picker-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-time-picker-modal {
      width: 80%;
      left: calc(50% - 40%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-time-picker-modal {
      top: 18%;
    }
  }

  /* End of modal styles */
</style>
