<script>
  import { onMount } from "svelte";
  import moment from "moment";
  import { Chart, LinearScale, CategoryScale } from "chart.js";
  import {
    BoxPlotController,
    BoxAndWiskers,
  } from "@sgratzl/chartjs-chart-boxplot";
  import Loader from "./Loader.svelte";
  import { onDestroy } from "svelte";

  // register controller in chart.js and ensure the defaults are set
  Chart.register(BoxPlotController, BoxAndWiskers, LinearScale, CategoryScale);
  import * as signalR from "@microsoft/signalr";

  export let baseURL = "";
  export let currentScreen = "Dashboard";
  export let nodeDetails;
  export let legendPosition = "top";
  export let legendAlignment = "end";
  export let title = "";
  export let deviceCategory = "";
  export let parameterCategory = "";
  export let parameterName = "Power";
  // export let isPreviousRequired;
  export let currentStartTime = 0;
  export let currentEndTime = 0;
  export let previousStartTime = 0;
  export let previousEndTime = 0;
  export let currentColor = "#8FE8ED";
  export let previousColor = "#C3C8D6";
  export let currentLabel = "Current";
  export let previousLabel = "Previous";
  export let unit = "";
  export let isVertical = false;
  export let minPadding = 0.05;
  export let maxPadding = 0.05;
  export let uniqueId = "";
  export let chartHeight = "";

  let currData = [];
  let prevData = [];
  let wsData = [];
  let latestReading;
  let lastUpdatedTime = "";
  let presentValue;
  let filteredParameter;
  let apiExecutedOnce = false;
  let currBP = false;
  let prevBP = false;
  let myBoxPlot = null;
  let chartElement = null;
  let realTimeReadingsWS = null;
  let prevAwaiting = false;
  let currAwaiting = false;
  let latestAwating = false;
  let showNoDataMessage = false;

  const companyId = localStorage.getItem("companyId");
  const appId = sessionStorage.getItem("appId");

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyId: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  let currPayload = {
    EndTime: currentEndTime,
    StartTime: currentStartTime,
    BasicParameters: [],
    DerivedParameters: [],
  };

  let prevPayload = {
    EndTime: previousEndTime,
    StartTime: previousStartTime,
    BasicParameters: [],
    DerivedParameters: [],
  };

  let latestPayload = [];

  let WSPayload;

  onDestroy(() => {
    if (realTimeReadingsWS) realTimeReadingsWS.stop();
  });

  $: {
    if (
      currentStartTime &&
      currentEndTime &&
      previousStartTime &&
      previousEndTime
    ) {
      invokeAPIs();
      destroyChart();
    }
  }

  $: {
    if (parameterCategory && deviceCategory && nodeDetails) {
      parseParameterFromConfig();
      destroyChart();
    }
  }

  async function fetchCurrentData() {
    try {
      if (currAwaiting || !baseURL) return;
      const url = `${baseURL}/parameters/boxplot`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(currPayload),
      };
      currAwaiting = true;
      const response = await fetch(url, options);
      currAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      currData = await response.json();
      currBP = true;
      if (currBP && prevBP) {
        drawChartChartJs(prevData, currData);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchPreviousData() {
    try {
      if (prevAwaiting || !baseURL) return;
      const url = `${baseURL}/parameters/boxplot`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(prevPayload),
      };
      prevAwaiting = true;
      const response = await fetch(url, options);
      prevAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      prevData = await response.json();
      prevBP = true;
      if (currBP && prevBP) {
        drawChartChartJs(prevData, currData);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchLatestData() {
    try {
      if (!filteredParameter || !baseURL || latestAwating) return;
      const url = `${baseURL}/realtimedata/api/Parameters/all`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify([
          {
            parameterId: filteredParameter.id,
            type: filteredParameter.type,
          },
        ]),
      };
      latestAwating = true;
      const response = await fetch(url, options);
      latestAwating = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }

      latestReading = await response.json();
      lastUpdatedTime =
        "Last Updated : " +
        (latestReading[0]
          ? moment(latestReading[0].timestamp * 1000).format(
              "DD-MM-YYYY HH:mm:ss"
            )
          : "Not Available");
      presentValue =
        parseFloat(latestReading[0].reading) ||
        parseFloat(latestReading[0].reading) === 0
          ? parseFloat(latestReading[0].reading).toFixed(2)
          : "";
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchWS() {
    try {
      const url = `${baseURL}/realtimedata/api/parameters/all/live`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(WSPayload),
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      wsData = await response.json();
      WebSocketAPIonSuccess(wsData);
    } catch (error) {
      console.error(error);
    }
  }

  onMount(() => {
    if (!myBoxPlot) {
      drawChartChartJs();
    }
  });

  function destroyChart() {
    if (myBoxPlot) myBoxPlot.destroy();
  }

  function parameterIdResolver(dc, pc) {
    if (nodeDetails) {
      if (nodeDetails.parameters) {
        let id = nodeDetails.parameters.find((item) => {
          if (
            dc.includes(item.DeviceCategory) &&
            item.ParameterCategory === pc
          ) {
            return true;
          }
        });

        if (id) {
          return {
            type: "Basic",
            id: id.ParameterID,
            name: id.ParameterAliasName
              ? id.ParameterAliasName
              : id.ParameterFriendlyName,
            unit: id.Unit,
          };
        }
      }
      if (nodeDetails.derivedparameters) {
        let id = nodeDetails.derivedparameters.find((item) => {
          if (
            dc.includes(item.DeviceCategory) &&
            item.ParameterCategory === pc
          ) {
            return true;
          }
        });

        if (id) {
          return {
            type: "Derived",
            id: id.DerivedParameterId,
            name: id.AliasName ? id.AliasName : id.DerivedParameterName,
            unit: id.Unit,
          };
        }
      }
    }
  }

  function parseParameterFromConfig() {
    currPayload = {
      EndTime: parseInt(currentEndTime),
      StartTime: parseInt(currentStartTime),
      BasicParameters: [],
      DerivedParameters: [],
    };
    prevPayload = {
      EndTime: parseInt(previousEndTime),
      StartTime: parseInt(previousStartTime),
      BasicParameters: [],
      DerivedParameters: [],
    };
    WSPayload = [];
    let resolvedParameterObj = parameterIdResolver(
      deviceCategory,
      parameterCategory
    );

    if (resolvedParameterObj) {
      unit =
        resolvedParameterObj?.unit === "Number" ||
        resolvedParameterObj?.unit === "count"
          ? ""
          : resolvedParameterObj.unit ?? "";
      parameterName = resolvedParameterObj?.name || "";
      if (resolvedParameterObj.type === "Basic") {
        //check If the parameter is basic or derived
        currPayload.BasicParameters.push(resolvedParameterObj.id);
        prevPayload.BasicParameters.push(resolvedParameterObj.id);
        WSPayload.push({
          parameterId: resolvedParameterObj.id,
          type: "BasicParameter",
          uniqueId: uniqueId,
        });
      } else {
        currPayload.DerivedParameters.push(resolvedParameterObj.id);
        prevPayload.DerivedParameters.push(resolvedParameterObj.id);
        WSPayload.push({
          parameterId: resolvedParameterObj.id,
          type: "DerivedParameter",
          uniqueId: uniqueId,
        });
      }
      latestPayload = [resolvedParameterObj.id];
      filteredParameter = resolvedParameterObj;
    } else {
      filteredParameter = null;
    }

    if (realTimeReadingsWS) realTimeReadingsWS.stop();
    fetchWS();
    fetchLatestData();
    invokeAPIs();
  }

  const invokeAPIs = function () {
    if (
      !(
        currentEndTime &&
        currentStartTime &&
        previousEndTime &&
        previousStartTime &&
        filteredParameter
      )
    )
      return;
    currPayload.EndTime = parseInt(currentEndTime);
    currPayload.StartTime = parseInt(currentStartTime);
    prevPayload.EndTime = parseInt(previousEndTime);
    prevPayload.StartTime = parseInt(previousStartTime);
    fetchCurrentData();
    fetchPreviousData();
  };

  function drawChartChartJs(dataPrev, dataCurr) {
    if (!(dataCurr?.length && dataPrev?.length)) {
      showNoDataMessage = true;
      return;
    } else {
      showNoDataMessage = false;
    }
    let cur = Object.values(dataCurr[0].BoxPlotData);
    let pre = Object.values(dataPrev[0].BoxPlotData);
    if (!(cur?.length || pre?.length)) {
      showNoDataMessage = true;
    } else {
      showNoDataMessage = false;
    }
    cur.sort((a, b) => {
      if (a > b) {
        return 1;
      } else if (b > a) {
        return -1;
      } else {
        return 0;
      }
    });
    pre.sort((a, b) => {
      if (a > b) {
        return 1;
      } else if (b > a) {
        return -1;
      } else {
        return 0;
      }
    });
    const boxplotData = {
      // define label tree
      labels: [parameterName],
      datasets: [
        {
          label: previousLabel,
          backgroundColor: previousColor,
          borderColor: "#707070",
          borderWidth: 1,
          outlierColor: "#999999",

          //padding: 60,
          itemRadius: 0,

          data: [pre],
        },
        {
          label: currentLabel,
          backgroundColor: currentColor,
          borderColor: "#707070",
          borderWidth: 1,
          outlierColor: "#999999",
          // padding: 60,
          itemRadius: 0,
          data: [cur],
        },
      ],
    };

    // Prefab.ctx = $('div[widget-id=' + Prefab.Widgets.html2.widgetId + '] #myCanvas')[0];
    let arr1 = Object.values([
      ...Object.values(dataPrev[0].BoxPlotData),
      ...Object.values(dataCurr[0].BoxPlotData),
    ]);
    let config = {
      type: "boxplot",
      data: boxplotData,
      options: {
        interaction: "y",
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        legend: {
          position: legendPosition,
          align: legendAlignment,
        },
        title: {
          display: true,
          text: title,
        },
        scales: {
          // y: {
          //     min: Math.min(...arr1) === 0 ? 0 : Math.min(...arr1) - (Math.min(...arr1) * minPadding),
          //     max: Math.max(...arr1) === 0 ? 0 : Math.max(...arr1) + (Math.max(...arr1) * maxPadding)
          // },
        },
        plugins: {
          legend: {
            position: legendPosition,
            align: legendAlignment,

            labels: {
              boxHeight: 15,
              boxWidth: 15,
            },
          },
          tooltip: {
            callbacks: {
              title: (a) => {
                return "";
              },
              label: (a) => {
                return [
                  `${a.dataset.label}`,
                  `Min ${a.dataset.data[0][0]}`,
                  `Q1 ${a.dataset.data[0][1]}`,
                  `Median ${a.dataset.data[0][2]}`,
                  `Q3 ${a.dataset.data[0][3]}`,
                  `Max ${a.dataset.data[0][4]}`,
                ];
              },
            },
          },
        },
      },
    };
    if (isVertical) {
      config.options.scales.y = {
        title: {
          display: false,
        },
        min:
          Math.min(...arr1) === 0
            ? 0
            : Math.min(...arr1) - Math.min(...arr1) * minPadding,
        max:
          Math.max(...arr1) === 0
            ? 0
            : Math.max(...arr1) + Math.max(...arr1) * maxPadding,
        callback: function (val, index) {
          // Hide every 2nd tick label
          return val + " " + unit;
        },
      };
      config.options.scales.x = {
        title: {
          display: false,
        },
        ticks: {
          display: false,
        },
      };
    } else {
      config.options.scales.x = {
        min:
          Math.min(...arr1) === 0
            ? 0
            : Math.min(...arr1) - Math.min(...arr1) * minPadding,
        max:
          Math.max(...arr1) === 0
            ? 0
            : Math.max(...arr1) + Math.max(...arr1) * maxPadding,
        title: {
          display: false,
        },
        callback: function (val, index) {
          // Hide every 2nd tick label
          return val + " " + unit;
        },
      };
      config.options.scales.y = {
        title: {
          display: false,
        },
        ticks: {
          display: false,
        },
      };
    }

    if (!myBoxPlot) {
      // apiExecutedOnce = true;
      myBoxPlot = new Chart(chartElement, config);
    } else {
      myBoxPlot.destroy();
      myBoxPlot = new Chart(chartElement, config);
    }
  }

  function WebSocketAPIonSuccess(data) {
    if (realTimeReadingsWS) realTimeReadingsWS.stop();
    if (data.length)
      customWebSocketConnectionRealTime(
        data.map((item) => item.webSocketMethod),
        data[0].webSocketUrl
      );
  }
  function customWebSocketConnectionRealTime(webSocketMethods, webSocketUrl) {
    if (!webSocketMethods.length || !webSocketUrl) return false;

    realTimeReadingsWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    webSocketMethods.forEach((method) => {
      realTimeReadingsWS.on(method, function (_data) {
        let data = JSON.parse(_data);
        WSVFormatRealTimeData(data);
      });
    });

    realTimeReadingsWS
      .start()
      .then(function () {
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueId + "_BasicParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueId + "_DerivedParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    realTimeReadingsWS.onreconnected(function () {
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueId + "_BasicParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueId + "_DerivedParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  }

  function validateWebSocketData(data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed) {
      return false;
    } else {
      return true;
    }
  }

  function WSVFormatRealTimeData(data) {
    if (!validateWebSocketData(data)) {
      console.error("WebSocket update failed, calling WebSocket API!!!");
      fetchWS();
      return;
    }
    console.log("Websocket data", data);
    try {
      lastUpdatedTime =
        "Last Updated : " +
        (data.unixtime
          ? moment(data.unixtime * 1000).format("DD-MM-YYYY HH:mm:ss")
          : "Not Available");
      const match = data.parameters.find(
        (item) => item.parameterId === filteredParameter.id
      );
      console.log("Match for ", title, match);
      if (match && !isNaN(match.reading)) {
        presentValue =
          parseFloat(match.reading) || parseFloat(match.reading) === 0
            ? parseFloat(match.reading).toFixed(2)
            : "";
      } else {
        presentValue = "";
      }
    } catch (e) {
      console.error(e);
    }
  }
</script>

<div class="del-boxplot-main-container">
  <div class="del-boxplot-title">{title}</div>
  <div class="del-boxplot-inner-container">
    {#if latestAwating || prevAwaiting || currAwaiting}
      <Loader />
    {/if}
    <div
      class="del-boxplot-container"
      style={`${chartHeight ? `height:${chartHeight}` : ""}`}
    >
      <canvas bind:this={chartElement} />
      {#if showNoDataMessage && !prevAwaiting && !currAwaiting}
        <div class="del-boxplot-no-data-message">No Data Available</div>
      {/if}
    </div>
  </div>

  <div class="del-boxplot-current-reading-container">
    <div class="present-label">{`Present : `}</div>
    <div title={lastUpdatedTime || ""}>
      <span class="present-value">{presentValue || "No Data"}</span><span
        >{presentValue ? ` ${unit}` || "" : ""}</span
      >
    </div>
  </div>
</div>

<style>
  .del-boxplot-title {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
    padding-bottom: 24px;
  }
  .del-boxplot-inner-container {
    position: relative;
    /* display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between; */
  }
  .del-boxplot-current-reading-container {
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #003955;
    white-space: nowrap;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 22px 0;
  }
  .present-label {
    color: #2d3860;
    padding-right: 1ch;
  }
  .present-value {
    font: normal normal bold 18px/19px Roboto;
  }
  .del-boxplot-container {
    flex-grow: 1;
    position: relative;
  }
  .del-boxplot-no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
  }
</style>
