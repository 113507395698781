<script>
  import { Chart } from "chart.js";
  import "chartjs-adapter-moment";
  import moment from "moment";
  import DelDatePicker from "./DelDatePicker/DelDatePicker.svelte";
  import * as signalR from "@microsoft/signalr";
  import { tick } from "svelte";
  import Loader from "./Loader.svelte";

  export let nodeDetails;
  export let baseURL = "";
  export let currentScreen = "Dashboard";
  export let chartTitle = "Day Peak Identification";
  export let config = [];
  export let dynamicConfig = [];
  export let annotationConfig = [];
  export let uniqueId = "monthlyPeakChart";
  export let chartHeight = "380px";
  export let datePickerType = "day";
  export let groupBy = "";
  export let unit = "kWh";

  const companyId = localStorage.getItem("companyId");
  const appId = sessionStorage.getItem("appId");

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  let telemetryPayload = {
    derivedParameters: [],
    starttime: "",
    endtime: "",
    groupby: groupBy,
  };

  let evaluatorPayload = {
    parameters: [],
    startTime: "",
    endTime: "",
  };

  let WSPayload = [];

  let chartData = [];
  let constantData = [];
  let annotationsObject = [];
  let chartCanvas;
  let chart = null;
  let chartTemplate = [];
  let realTimeReadingsWS = null;
  let staticParameterDetails = [];
  let dynamicParameterDetails = [];
  let constantParameterDetails = [];
  let colors = [
    "#537C78",
    "#7BA591",
    "#CC222B",
    "#F15B4C",
    "#FAA41B",
    "#FFD45B",
    "#5AC04B",
    "#714DD3",
    "#51B3C8",
    "#417CA3",
    "#EF9C5F",
    "#8B3B25",
    "#B2CDD9",
    "#64BAA1",
    "#4F97F2",
    "#E56025",
    "#4AF2E5",
    "#FD9AF2",
    "#467C63",
    "#DA936D",
    "#FF8933",
    "#5C2B1D",
    "#AE8BFF",
    "#4AA7EE",
    "#E5AE19",
  ];
  let prevNodeDetails = null;
  let initialLoad = true;
  let annotationFlag = false;
  let telemetryFlag = false;
  let isNoData = false;
  let dataAwaiting = false;
  let datePickerStartTime;
  let datePickerEndTime;
  let datePickerDate;

  function avoidreactive() {
    if (JSON.stringify(prevNodeDetails) === JSON.stringify(nodeDetails)) {
      return false;
    }
    prevNodeDetails = nodeDetails;
    return true;
  }

  $: {
    if (nodeDetails) {
      if (avoidreactive()) {
        chartTemplate = [];
        staticParameterDetails = [];
        dynamicParameterDetails = [];
        constantParameterDetails = [];
        findStaticParameters();
        findDynamicParameters();
        findConstantParameters();
        destroyChart();
        fetchData();
        fetchConstantData();
      }
    }
  }

  $: {
    if (chartCanvas) {
      renderChart(chartData);
    }
  }

  function destroyChart() {
    if (chart) {
      chart.destroy();
    }
  }

  async function fetchData() {
    if (
      !(
        nodeDetails &&
        (config.length || annotationConfig.length || dynamicConfig.length)
      )
    ) {
      return;
    }
    try {
      telemetryPayload.starttime = datePickerStartTime;
      telemetryPayload.endtime = datePickerEndTime;
      telemetryPayload.derivedParameters = [];
      if (staticParameterDetails?.length) {
        staticParameterDetails.forEach((item) => {
          if (item.PType === "Basic") {
            telemetryPayload.basicParameters.push(item.ParameterID);
            WSPayload.push({
              parameterId: item.ParameterID,
              type: "BasicParameter",
              uniqueId: uniqueId,
            });
          } else {
            telemetryPayload.derivedParameters.push(item.DerivedParameterId);
            WSPayload.push({
              parameterId: item.DerivedParameterId,
              type: "DerivedParameter",
              uniqueId: uniqueId,
            });
          }
        });
      }

      if (dynamicParameterDetails?.length) {
        dynamicParameterDetails.forEach((item) => {
          if (item.PType === "Basic") {
            telemetryPayload.basicParameters.push(item.ParameterID);
            WSPayload.push({
              parameterId: item.ParameterID,
              type: "BasicParameter",
              uniqueId: uniqueId,
            });
          } else {
            telemetryPayload.derivedParameters.push(item.DerivedParameterId);
            WSPayload.push({
              parameterId: item.DerivedParameterId,
              type: "DerivedParameter",
              uniqueId: uniqueId,
            });
          }
        });
      }

      if (
        !(
          telemetryPayload.derivedParameters &&
          telemetryPayload.endtime &&
          telemetryPayload.starttime &&
          telemetryPayload.derivedParameters.length
        )
      ) {
        isNoData = true;
        return;
      }

      const url = `${baseURL}/parameters/telemetry/rawdata/all`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(telemetryPayload),
      };
      dataAwaiting = true;
      const response = await fetch(url, options);
      dataAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      chartData = await response.json();
      if (chartData.length === 0) isNoData = true;
      getWS();
      destroyChart();
      createChartTemplate();
      if (!initialLoad) updateChart(chartData);
      telemetryFlag = true;
      if (initialLoad && annotationFlag && telemetryFlag) {
        renderChart(chartData);
        initialLoad = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchConstantData() {
    try {
      evaluatorPayload.startTime = datePickerStartTime;
      evaluatorPayload.endTime = datePickerEndTime;
      if (constantParameterDetails?.length) {
        constantParameterDetails.forEach((item) => {
          evaluatorPayload.parameters.push(item.DerivedParameterId);
        });
      }
      const url = `${baseURL}/dpe/api/evaluator`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(evaluatorPayload),
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      constantData = await response.json();
      destroyChart();
      createAnnotationsTemplate();
      addConstantToTemplate(constantData);
    } catch (error) {
      console.error(error);
    }
  }

  async function getWS() {
    try {
      const response = await fetch(
        `${baseURL}/realtimedata/api/parameters/all/live`,
        {
          method: "POST", // Use 'GET' or 'POST' based on your API requirements
          headers: getHeader(),
          body: JSON.stringify(WSPayload), // Convert payload to JSON if needed
        }
      );

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      let wsData = await response.json();
      getWSOnSuccess(wsData);
    } catch (error) {
      console.error(error);
    }
  }

  function getWSOnSuccess(data) {
    if (data.length) {
      customWebSocketConnectionRealTime(
        data.map((item) => item.webSocketMethod),
        data[0].webSocketUrl
      );
    }
  }

  function customWebSocketConnectionRealTime(webSocketMethods, webSocketUrl) {
    if (!webSocketMethods.length || !webSocketUrl) return false;

    realTimeReadingsWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    webSocketMethods.forEach((method) => {
      realTimeReadingsWS.on(method, function (_data) {
        let data = JSON.parse(_data);
        WSUFormatRealTimeData(data);
      });
    });
    realTimeReadingsWS
      .start()
      .then(function () {
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueId + "_BasicParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueId + "_DerivedParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    realTimeReadingsWS.onreconnected(function () {
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueId + "_BasicParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueId + "_DerivedParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  }

  function validateWebSocketData(data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed) {
      return false;
    } else {
      return true;
    }
  }

  function WSUFormatRealTimeData(data) {
    if (!validateWebSocketData(data)) {
      console.error(
        "WebSocket update failed, calling GetData API from delMultiAxisChart!!!"
      );

      telemetryPayload.starttime = datePickerStartTime;
      telemetryPayload.endtime = datePickerEndTime;
      fetchData();
      return;
    }
    const boolerman = moment(datePickerDate).isSame(new Date(), "day");
    console.log(
      boolerman,
      "websocket condition..........................................................."
    );
    if (moment(datePickerDate).isSame(new Date(), "day")) {
      data.parameters.forEach((item) => {
        let itemFound = chart.config.data.datasets.find((item2) => {
          if (item2.paramId === item.parameterId) return true;
        });

        if (itemFound) {
          let pi = itemFound.PInverse ? -1 : 1;
          itemFound.data.push({
            x: data.unixtime * 1000,
            y: isNaN(parseFloat(item.reading))
              ? null
              : parseFloat(item.reading * pi).toFixed(2),
          });
        }
      });
    }
    let noData = true;
    chart.config.data.datasets.forEach((item) => {
      if (item.data.length > 0 && noData) {
        noData = false;
      }
    });
    if (!noData) {
      chart.config.options.scales.x.display = true;
      chart.config.options.scales.y.display = true;
      chart.config.options.plugins.annotation.annotations.nodata.display =
        !true;
      Object.values(annotationsObject).forEach((item, key) => {
        if (item.type !== "label") item.display = true;
      });
      chart.config.options.plugins.legend.display = true;
      chart.update();
    }

    if (noData) {
      chart.config.options.scales.x.display = !true;
      chart.config.options.scales.y.display = !true;
      chart.config.options.plugins.annotation.annotations.nodata.display = true;
      Object.values(annotationsObject).forEach((item, key) => {
        if (item.type !== "label") item.display = false;
      });
      chart.config.options.plugins.legend.display = !true;
      chart.update();
    }
  }

  function findStaticParameters() {
    if (config) {
      config.forEach((item) => {
        recursiveStaticFinder(item, nodeDetails);
      });
    }
  }

  function findDynamicParameters() {
    // [{DeviceCategory:"NettoMeter",ParameterCategory:"Current"}]
    if (dynamicConfig) {
      dynamicConfig.forEach((item) => {
        recursiveDynamicFinder(item, nodeDetails);
      });
    }
  }

  function findConstantParameters() {
    // [{DeviceCategory:"NettoMeter",ParameterCategory:"Current"}]
    if (annotationConfig) {
      annotationConfig.forEach((item) => {
        recursiveConstantFinder(item, nodeDetails);
      });
    }
  }

  function recursiveStaticFinder(parameter, nodes) {
    let parameterIdentified = parameterIdResolver(
      parameter.DeviceCategory,
      parameter.ParameterCategory,
      nodes,
      false
    );
    if (parameterIdentified) {
      parameterIdentified.GType = parameter.Type;
      if (parameter.DisplayName)
        parameterIdentified.displayName = parameter.DisplayName;
      else {
        let deviceIdentified = "";
        if (nodes.devices) {
          deviceIdentified = nodes.devices.find((device) => {
            if (device.DeviceCategory === parameter.DeviceCategory) return true;
          });
        }
        parameterIdentified.displayName = deviceIdentified
          ? deviceIdentified.DeviceFriendlyName
          : "name not found!";
      }
      parameterIdentified.displayName2 = parameterIdentified.displayName;
      parameterIdentified["Select Dimensions"] = "Select Dimensions";
      if (parameter.inverse) {
        parameterIdentified.inverse = true;
      } else {
        parameterIdentified.inverse = false;
      }
      if (parameter.color) parameterIdentified.PColor = parameter.color;
      staticParameterDetails.push(parameterIdentified);
    } else {
      if (nodes.children) {
        nodes.children.forEach((item) => {
          recursiveStaticFinder(parameter, item);
        });
      }
    }
  }

  function recursiveDynamicFinder(parameter, nodes) {
    let parameterIdentified = parameterIdResolver(
      parameter.DeviceCategory,
      parameter.ParameterCategory,
      nodes,
      true
    );
    if (parameterIdentified) {
      parameterIdentified.GType = parameter.Type;
      //
      let deviceIdentified = "";
      if (nodes.devices)
        deviceIdentified = nodes.devices.find((device) => {
          if (device.DeviceCategory === parameter.DeviceCategory) return true;
        });
      parameterIdentified.displayName = deviceIdentified
        ? deviceIdentified.DeviceFriendlyName
        : nodes.name;
      parameterIdentified.displayName2 = parameterIdentified.displayName;
      parameterIdentified["Select Dimensions"] = "Select Dimensions";
      dynamicParameterDetails.push({ ...parameterIdentified });
    } else {
      if (nodes.children) {
        nodes.children.forEach((item) => {
          recursiveDynamicFinder(parameter, item);
        });
      }
    }
  }

  function recursiveConstantFinder(parameter, nodes) {
    let parameterIdentified = parameterIdResolver(
      parameter.DeviceCategory,
      parameter.ParameterCategory,
      nodes,
      false
    );
    if (parameterIdentified) {
      parameterIdentified.label = parameter.label;
      if (parameter.color) parameterIdentified.PColor = parameter.color;
      constantParameterDetails.push(parameterIdentified);
    } else {
      if (nodes.children) {
        nodes.children.forEach((item) => {
          recursiveConstantFinder(parameter, item);
        });
      }
    }
  }

  function parameterIdResolver(dc, pc, node, dynamic = false) {
    let found = false;
    let founditem = {};
    if (node.parameters) {
      node.parameters.forEach((item) => {
        if (item.DeviceCategory === dc && item.ParameterCategory === pc) {
          item.PType = "Basic";
          found = true;
          founditem = item;
        }
      });
    }
    if (node.derivedparameters && !found) {
      node.derivedparameters.forEach((item) => {
        if (item.DeviceCategory === dc && item.ParameterCategory === pc) {
          item.PType = "Derived";
          found = true;
          founditem = item;
        }
      });
    }
    if (!found) {
      if (dynamic)
        console.warn(
          `Parameter with DeviceCategory:"${dc}" and ParameterCategory:"${pc}" not found in node:"${node.name}"`
        );
      else
        console.error(
          `Parameter not found with DeviceCategory:"${dc}" and ParameterCategory:"${pc}" not found in node:"${node.name}"`
        );
      return null;
    } else {
      return founditem;
    }
  }

  function DataToTemplate(datas, template) {
    template.forEach((temp) => {
      temp.data = [];
    });
    datas.forEach((time) => {
      time.parameters.forEach((param, i) => {
        let matchedTemplate = template.find((item) => {
          if (item.paramId === param.parameterId) return true;
        });

        let pi = matchedTemplate?.PInverse ? -1 : 1;
        matchedTemplate?.data.push({
          x: time.unixtime * 1000,
          y: isNaN(parseFloat(param.reading))
            ? null
            : parseFloat(param.reading * pi).toFixed(2),
        });
        //
      });
    });
  }

  function addConstantToTemplate(data) {
    data.forEach((item) => {
      let itemFound = Object.values(annotationsObject).find((obj) => {
        if (obj.paramId === item.Id) return true;
      });
      itemFound.yMin = item.Result;
      itemFound.yMax = item.Result;
    });
    annotationFlag = true;
    if (initialLoad && annotationFlag && telemetryFlag) {
      renderChart(chartData);
      initialLoad = false;
    }
  }

  async function renderChart(data) {
    console.log(isNoData, "no data");
    if (!chartCanvas) return;
    DataToTemplate(data, chartTemplate);
    let noData = true;
    chartTemplate.forEach((item) => {
      if (item.data.length > 0 && noData) {
        noData = false;
      }
    });
    if (noData) {
      annotationsObject.nodata.display = !false;
      Object.values(annotationsObject).forEach((item, key) => {
        if (item.type !== "label") item.display = false;
      });
    } else {
      annotationsObject.nodata.display = !true;
      Object.values(annotationsObject).forEach((item, key) => {
        if (item.type !== "label") item.display = !false;
      });
    }
    isNoData = noData;
    await tick();
    chart = new Chart(chartCanvas, {
      type: "bar",
      responsive: true,
      maintainAspectRatio: false,
      data: {
        datasets: chartTemplate,
      },
      options: {
        animation: false,
        // parsing: false,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "x",
        },
        scales: {
          x: {
            type: "time",
            stacked: true,
            display: noData ? false : true,
            // hidden: true,
            time: {
              unit: "hour",
              stepSize: 1, // I'm using 3 hour intervals here
              // tooltipFormat: 'DD MMMM YYYY HH:mm  ',
              displayFormats: {
                millisecond: "hh:mm a",
                second: "hh:mm a",
                minute: "hh:mm a",
                hour: "hh:mm a",
                day: "hh:mm a",
                week: "hh:mm a",
                month: "hh:mm a",
                quarter: "hh:mm a",
                year: "hh:mm a",
              },
            },
            ticks: {
              maxTicksLimit: 12,
              // maxRotation: 70,
              // minRotation: 70,
              font: {
                size: 10,
                weight: "600",
                color: "#8B8B8B",
                family: "Roboto",
              },
            },
          },
          y: {
            display: noData ? false : true,
            title: {
              text: unit,
              display: true,
              color: "#8B8B8B",
              font: {
                size: 10,
                family: "Roboto",
                weight: "700",
              },
            },
            ticks: {
              // maxTicksLimit: 25,
              // maxRotation: 70,
              // minRotation: 70,
              callback: function (value, index, ticks) {
                // console.log(typeof value, "value!@")
                let m = value > 0 ? 1 : -1;
                if (value * m > 1000) {
                  return (value / 1000).toFixed(2) + "k";
                } else if (value * m > 1000000) {
                  return (value / 1000000).toFixed(2) + "M";
                } else if (value * m > 100000000) {
                  return (value / 100000000).toFixed(2) + "B";
                } else return value;
                // return '$' + value;
              },
              font: {
                size: 10,
                weight: "600",
                color: "#566064",
                family: "Roboto",
              },
            },
            stacked: true,
          },
        },
        plugins: {
          legend: {
            display: noData ? false : true,
            position: "bottom",
            labels: {
              usePointStyle: true,
            },
            // onClick: (e) => e.stopPropagation(),
          },
          annotation: {
            annotations: annotationsObject,
          },

          tooltip: {
            callbacks: {
              label: function (context) {
                // console.log("Context", context)
                return (
                  context.dataset.label +
                  ": " +
                  context.formattedValue +
                  " " +
                  context.dataset.PUnit
                );
              },
            },
          },

          zoom: {
            pan: {
              mode: "x",
              enabled: true,
              modifierKey: "shift",
            },
            limits: {
              // axis limits
            },
            zoom: {
              mode: "x",
              drag: {
                enabled: true,
                modifierKey: "alt",
              },
            },
            // zoom options and/or events
          },
        },
      },
    });
  }

  function updateChart(data, websocket = false) {
    if (!chart) return;
    DataToTemplate(data, chart.config.data.datasets);
    let noData = true;
    chart.config.data.datasets.forEach((item) => {
      if (item.data.length > 0 && noData) {
        noData = false;
      }
    });

    if (!noData) {
      chart.config.options.scales.x.display = true;
      chart.config.options.scales.y.display = true;
      chart.config.options.plugins.annotation.annotations.nodata.display =
        !true;
      Object.values(annotationsObject).forEach((item, key) => {
        if (item.type !== "label") item.display = !false;
      });
      chart.config.options.plugins.legend.display = true;
      chart.update();
    }

    if (noData) {
      chart.config.options.scales.x.display = !true;
      chart.config.options.scales.y.display = !true;
      chart.config.options.plugins.annotation.annotations.nodata.display = true;
      Object.values(annotationsObject).forEach((item, key) => {
        if (item.type !== "label") item.display = false;
      });
      chart.config.options.plugins.legend.display = !true;
      chart.update();
    }
    isNoData = noData;
  }

  function createChartTemplate() {
    let colorIndex = 0;
    staticParameterDetails.forEach((param) => {
      let obj = {
        label: param.displayName,
        data: [],
        paramId: param.ParameterID
          ? param.ParameterID
          : param.DerivedParameterId,
        borderColor: param.PColor ? param.PColor : colors[colorIndex],
        backgroundColor: param.PColor ? param.PColor : colors[colorIndex],
        hidden: false,
      };
      obj.PInverse = param.inverse;
      obj.PUnit = param.Unit;
      unit = param.Unit;
      if (param.GType === "line") {
        obj.type = "line";
        obj.borderWidth = "2";
        obj.pointStyle = "line";
      } else if (param.GType === "bar") {
        obj.pointStyle = "rectRounded";
        obj.borderRadius = 3;
      }

      chartTemplate.push(obj);
      if (!param.PColor) colorIndex++;
    });
    dynamicParameterDetails.forEach((param, i) => {
      let obj = {
        label: param.displayName,
        data: [],
        paramId: param.ParameterID
          ? param.ParameterID
          : param.DerivedParameterId,
        borderColor: param.PColor ? param.PColor : colors[colorIndex],
        backgroundColor: param.PColor ? param.PColor : colors[colorIndex],
        hidden: false,
        // hidden: i === 1 ? true : false
      };
      obj.PUnit = param.Unit;
      unit = param.Unit;
      if (param.GType === "line") {
        obj.type = "line";
        obj.borderWidth = "2";
        obj.pointStyle = "line";
        obj.tension = 0;
      } else if (param.GType === "bar") {
        obj.pointStyle = "rectRounded";
        obj.borderRadius = 3;
      }

      chartTemplate.push(obj);
      if (!param.PColor) colorIndex++;
    });

    chartTemplate.sort((a, b) => {
      if (a.type && b.type) return 0;
      if (!a.type && b.type) return 1;
      if (a.type && !b.type) return -1;
      if (!a.type && !b.type) return 0;
    });
  }

  function createAnnotationsTemplate() {
    constantParameterDetails.forEach((item, i) => {
      annotationsObject[`line${i + 1}`] = {
        type: "line",
        drawTime: "afterDatasetsDraw",
        yMin: 0,
        yMax: 0,
        borderColor: item.PColor,
        borderWidth: 2,
        borderDash: [6, 6],
        label: {
          display: false,
          content: item.label,
          position: "end",
          backgroundColor: "rgba(0,0,0,0.3)",
          PContent: item.label,
        },
        paramId: item.DerivedParameterId,
        enter: (ctx, event, test = `line${i + 1}`) => {
          toggleLabel(ctx, event, test);
        },
        leave: (ctx, event, test = `line${i + 1}`) => {
          toggleLabel(ctx, event, test);
        },
      };
    });
    annotationsObject["0liner"] = {
      type: "line",
      drawTime: "afterDatasetsDraw",
      yMin: 0,
      yMax: 0,
      borderColor: "rgba(0,0,0,0.1)",

      borderWidth: 2,
      label: {
        content: "Zero",
        position: "end",
        PContent: "Zero",
        backgroundColor: "rgba(0,0,0,0.3)",
      },
      enter: (ctx, event, test = "0liner") => {
        toggleLabel(ctx, event, test);
      },
      leave: (ctx, event, test = `0liner`) => {
        toggleLabel(ctx, event, test);
      },
    };
    annotationsObject["nodata"] = {
      type: "label",
      //   xValue: 2.5,
      //   yValue: 60,
      display: !true,
      backgroundColor: "yellow",
      content: ["No Data Available!"],
      font: {
        size: 18,
      },
    };
  }

  function onDateChange(date) {
    datePickerStartTime = date.dayDefinedStartDateInSeconds;
    datePickerEndTime = date.dayDefinedEndDateInSeconds;
    datePickerDate = date.date;
    chartTemplate = [];
    fetchConstantData();
    fetchData();
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-stacked-columns-container">
  <div class="stacked-columns-header">
    <div class="del-stacked-columns-title">{chartTitle}</div>
    <div class="date-picker">
      <DelDatePicker
        onDateChanged={onDateChange}
        showRangePicker={false}
        typeOfPicker={datePickerType}
        autoApply={true}
      />
    </div>
  </div>
  <div style={`${chartHeight ? `height:${chartHeight}` : ""}`}>
    {#if dataAwaiting}
      <Loader />
    {:else if isNoData}
      <div class="no-data-class">No data available</div>
    {/if}
    <canvas style={isNoData ? `display:none;` : ""} bind:this={chartCanvas} />
  </div>
</div>

<style>
  .del-stacked-columns-container {
    position: relative;
  }
  .stacked-columns-header {
    display: flex;
    justify-content: space-between;
  }

  .del-stacked-columns-title {
    font: normal normal bold 14px/26px Roboto;
  }

  .no-data-class {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    font: italic normal normal 16px/21px Roboto;
  }
</style>
