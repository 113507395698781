<script>
  import { Chart } from "chart.js";
  import "chartjs-adapter-moment";
  import DelDatePicker from "./DelDatePicker.svelte";
  import Loader from "./Loader.svelte";
  import moment from "moment";
  import { tick } from "svelte";

  export let nodeDetails;
  export let baseURL = "";
  export let currentScreen = "Dashboard";
  export let chartTitle = "Monthly Peak Identification";
  export let config = [];
  export let chartHeight = "380px";
  export let showRangePicker = false;
  export let datePickerType = "month";
  export let groupBy = "day";
  export let operation = "max";
  export let dayFormat = "DD MMM";
  export let monthFormat = "MMM YYYY";
  export let xAxisLabel = "Days";
  export let monthHoverFormat = "MMM YYYY";
  export let dayHoverFormat = "DD MM YYYY";
  export let unit = "kWh";
  export let manualStartTime;
  export let manualEndTime;

  const defaultStartDate = new Date(moment().subtract(6, "day").startOf("day"));
  const defaultEndDate = new Date(moment().endOf("day"));

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  let telemetryPayload = {
    derivedParameters: [],
    starttime: "",
    endtime: "",
    groupby: groupBy,
    operation: operation,
    basicParameters: [],
  };

  let chartData = [];
  let chartCanvas;
  let chart = null;
  let chartTemplate = [];
  let staticParameterDetails = [];
  let colors = [
    "#537C78",
    "#7BA591",
    "#CC222B",
    "#F15B4C",
    "#FAA41B",
    "#FFD45B",
    "#5AC04B",
    "#714DD3",
    "#51B3C8",
    "#417CA3",
    "#EF9C5F",
    "#8B3B25",
    "#B2CDD9",
    "#64BAA1",
    "#4F97F2",
    "#E56025",
    "#4AF2E5",
    "#FD9AF2",
    "#467C63",
    "#DA936D",
    "#FF8933",
    "#5C2B1D",
    "#AE8BFF",
    "#4AA7EE",
    "#E5AE19",
  ];
  let isNoData = false;
  let apiExecutedOnce = false;
  let dataAwaiting = false;
  let prevNodeDetails = null;

  function avoidreactive() {
    if (JSON.stringify(prevNodeDetails) === JSON.stringify(nodeDetails)) {
      return false;
    }
    prevNodeDetails = nodeDetails;
    return true;
  }

  $: {
    if (baseURL && nodeDetails && config) {
      if (avoidreactive()) {
        chartTemplate = [];
        staticParameterDetails = [];
        findStaticParameters();
        fetchData();
        destroyChart();
      }
    }
  }

  function destroyChart() {
    if (chart) {
      isNoData = false;
      // apiExecutedOnce = false;
      chart.destroy();
    }
  }

  async function fetchData() {
    try {
      telemetryPayload.basicParameters = [];
      telemetryPayload.derivedParameters = [];
      if (staticParameterDetails?.length) {
        staticParameterDetails.forEach((item) => {
          if (item.PType === "Basic") {
            telemetryPayload.basicParameters.push(item.ParameterID);
          } else {
            telemetryPayload.derivedParameters.push(item.DerivedParameterId);
          }
        });
      }
      if (datePickerType === "manual") {
        telemetryPayload.starttime = manualStartTime;
        telemetryPayload.endtime = manualEndTime;
      }
      const url = `${baseURL}/parameters/telemetry/rawdata/all`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(telemetryPayload),
      };
      if (
        !(
          telemetryPayload.basicParameters &&
          telemetryPayload.derivedParameters &&
          telemetryPayload.groupby &&
          telemetryPayload.operation &&
          telemetryPayload.starttime &&
          telemetryPayload.endtime &&
          (telemetryPayload.basicParameters.length ||
            telemetryPayload.derivedParameters.length)
        )
      ) {
        isNoData = true;
        return;
      }
      dataAwaiting = true;
      const response = await fetch(url, options);
      dataAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        isNoData = true;
        throw new Error(error);
      }
      chartData = await response.json();
      destroyChart();
      if (chartData.length === 0) isNoData = true;
      if (!apiExecutedOnce) createChartTemplate();
      renderChart(chartData);
    } catch (error) {
      console.error(error);
    }
  }

  function findStaticParameters() {
    if (config) {
      config.forEach((item) => {
        recursiveStaticFinder(item, nodeDetails);
      });
    }
  }

  function recursiveStaticFinder(parameter, nodes) {
    let parameterIdentified = parameterIdResolver(
      parameter.DeviceCategory,
      parameter.ParameterCategory,
      nodes,
      false
    );
    if (parameterIdentified) {
      parameterIdentified.GType = parameter.Type;
      if (parameter.DisplayName)
        parameterIdentified.displayName = parameter.DisplayName;
      else {
        let deviceIdentified = "";
        if (nodes.devices) {
          deviceIdentified = nodes.devices.find((device) => {
            if (device.DeviceCategory === parameter.DeviceCategory) return true;
          });
        }
        parameterIdentified.displayName = deviceIdentified
          ? deviceIdentified.DeviceFriendlyName
          : "name not found!";
      }
      parameterIdentified.displayName2 = parameterIdentified.displayName;
      parameterIdentified["Select Dimensions"] = "Select Dimensions";
      if (parameter.inverse) {
        parameterIdentified.inverse = true;
      } else {
        parameterIdentified.inverse = false;
      }
      if (parameter.color) parameterIdentified.PColor = parameter.color;
      staticParameterDetails.push({ ...parameterIdentified });
    } else {
      if (nodes.children) {
        nodes.children.forEach((item) => {
          recursiveStaticFinder(parameter, item);
        });
      }
    }
  }

  function parameterIdResolver(dc, pc, node, dynamic = false) {
    let found = false;
    let founditem = {};
    if (node.parameters) {
      node.parameters.forEach((item) => {
        if (item.DeviceCategory === dc && item.ParameterCategory === pc) {
          item.PType = "Basic";
          found = true;
          founditem = item;
        }
      });
    }
    if (node.derivedparameters && !found) {
      node.derivedparameters.forEach((item) => {
        if (item.DeviceCategory === dc && item.ParameterCategory === pc) {
          item.PType = "Derived";
          found = true;
          founditem = item;
        }
      });
    }
    if (!found) {
      if (dynamic)
        console.warn(
          `Parameter with DeviceCategory:"${dc}" and ParameterCategory:"${pc}"" not found in node:"${node.name}"`
        );
      else
        console.error(
          `Parameter not found with DeviceCategory:"${dc}" and ParameterCategory:"${pc}"" not found in node:"${node.name}"`
        );
      return null;
    } else {
      return founditem;
    }
  }

  function DataToTemplate(datas, template) {
    template.forEach((temp) => {
      temp.data = [];
    });
    datas.forEach((time) => {
      time.parameters.forEach((param, i) => {
        let matchedTemplate = template.find((item) => {
          if (item.paramId === param.parameterId) return true;
        });
        if (matchedTemplate) {
          let pi = matchedTemplate.PInverse ? -1 : 1;
          matchedTemplate.data.push({
            x: time.unixtime * 1000,
            y: isNaN(parseFloat(param.reading))
              ? null
              : parseFloat(param.reading * pi).toFixed(2),
          });
        }

        //
      });
    });
  }

  async function renderChart(data) {
    DataToTemplate(data, chartTemplate);
    chartTemplate.forEach((item) => {
      if (item.data.length > 0 && isNoData) {
        isNoData = false;
      }
    });
    let displayf =
      groupBy === "month"
        ? monthFormat
        : groupBy === "day"
          ? dayFormat
          : "hh:mm:ss";
    let tooltipf =
      groupBy === "month"
        ? monthHoverFormat
        : groupBy === "day"
          ? dayHoverFormat
          : "hh:mm:ss";

    await tick();
    chart = new Chart(chartCanvas, {
      type: "line",
      responsive: true,
      maintainAspectRatio: false,
      data: {
        datasets: chartTemplate,
      },
      options: {
        animation: false,
        // parsing: false,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "x",
        },
        scales: {
          x: {
            title: {
              display: true,
              text: xAxisLabel,
              display: true,
              color: "#161519",
              font: {
                size: 10,
                family: "Roboto",
                weight: "700",
              },
            },
            type: "time",
            // stacked: !Prefab.singlemode ? "single" : false,
            display: isNoData ? false : true,
            // hidden: true,
            time: {
              tooltipFormat: tooltipf,
              unit: groupBy,
              stepSize: 1, // I'm using 3 hour intervals here
              // tooltipFormat: 'DD MMMM YYYY HH:mm  ',
              displayFormats: {
                millisecond: displayf,
                second: displayf,
                minute: displayf,
                hour: displayf,
                day: displayf,
                week: displayf,
                month: displayf,
                quarter: displayf,
                year: displayf,
              },
            },
            ticks: {
              maxTicksLimit: 12,
              // maxRotation: 70,
              // minRotation: 70,
              font: {
                // size: 10,
                weight: "600",
                color: "#566064",
                family: "Roboto",
              },
            },
          },
          y: {
            display: isNoData ? false : true,
            title: {
              text: unit,
              display: true,
              color: "#161519",
              font: {
                size: 10,
                family: "Roboto",
                weight: "600",
              },
            },
            ticks: {
              callback: function (value, index, ticks) {
                let m = value > 0 ? 1 : -1;
                if (value * m > 1000) {
                  return (value / 1000).toFixed(2) + "k";
                } else if (value * m > 1000000) {
                  return (value / 1000000).toFixed(2) + "M";
                } else if (value * m > 100000000) {
                  return (value / 100000000).toFixed(2) + "B";
                } else return value;
              },
              font: {
                size: 10,
                weight: "600",
                color: "#566064",
                family: "Roboto",
              },
            },
            stacked: true,
          },
        },
        plugins: {
          legend: {
            display: isNoData ? false : true,
            position: "bottom",
            labels: {
              usePointStyle: true,
            },
            // onClick: (e) => e.stopPropagation(),
          },

          tooltip: {
            callbacks: {
              label: function (context) {
                return (
                  context.dataset.label +
                  ": " +
                  context.formattedValue +
                  " " +
                  context.dataset.PUnit
                );
              },
            },
          },

          zoom: {
            pan: {
              mode: "x",
              enabled: true,
              modifierKey: "shift",
              // pan options and/or events
            },
            limits: {
              // axis limits
            },
            zoom: {
              mode: "x",
              drag: {
                enabled: true,
                modifierKey: "alt",
              },
            },
            // zoom options and/or events
          },
        },
      },
    });
  }

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function createChartTemplate() {
    apiExecutedOnce = true;
    chartTemplate = [];
    let colorIndex = 0;
    staticParameterDetails.forEach((param) => {
      let linecolor = null;
      let bgcolor = null;
      if (param.PColor) {
        if (param.PColor.split("-")[0] === "grad") {
          let colorinfo = param.PColor.split("-")[1];
          let c1c = hexToRgb(colorinfo.split("|")[0].split(">")[0]);
          let c1i = colorinfo.split("|")[0].split(">")[1];
          let c2c = hexToRgb(colorinfo.split("|")[1].split(">")[0]);
          let c2i = colorinfo.split("|")[1].split(">")[1];
          let gradientctx = chartCanvas.getContext("2d");
          let gradient = gradientctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, `rgba(${c1c.r},${c1c.g},${c1c.b},${c1i})`);
          gradient.addColorStop(1, `rgba(${c2c.r},${c2c.g},${c2c.b},${c2i})`);
          bgcolor = gradient;
          linecolor = colorinfo.split("|")[0].split(">")[0];
        } else if (param.PColor.split("-")[0] === "color") {
          linecolor = param.PColor.split("-")[1];
          bgcolor = param.PColor.split("-")[1];
        }
      } else {
        linecolor = colors[colorIndex];
        bgcolor = colors[colorIndex];
      }
      let obj = {
        label: param.displayName,
        data: [],
        paramId: param.ParameterID
          ? param.ParameterID
          : param.DerivedParameterId,
        borderColor: linecolor,
        pointBackgroundColor: linecolor,
        pointStrokeColor: linecolor,
        backgroundColor: bgcolor,
        hidden: false,
      };
      obj.PInverse = param.inverse;
      obj.PUnit = param.Unit;
      unit = param.Unit;
      if (param.GType === "line") {
        obj.type = "line";
        obj.borderWidth = "2";
        obj.pointRadius = 1;
        obj.hitRadius = 8;
        obj.hoverRadius = 8;
        obj.fill = true;
      } else if (param.GType === "bar") {
        obj.pointStyle = "rectRounded";
        obj.borderRadius = 3;
      }
      chartTemplate.push(obj);
      if (!param.PColor) colorIndex++;
    });

    chartTemplate.sort((a, b) => {
      if (a.type && b.type) return 0;
      if (!a.type && b.type) return 1;
      if (a.type && !b.type) return -1;
      if (!a.type && !b.type) return 0;
    });
  }

  function onDateChange(date) {
    telemetryPayload.starttime = date.dayDefinedStartDateInSeconds;
    telemetryPayload.endtime = date.dayDefinedEndDateInSeconds;
    fetchData();
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-fill-chart-container">
  <div class="fill-chart-header">
    <div class="del-fill-chart-title">{chartTitle}</div>
    {#if datePickerType !== "manual"}
      <div class="date-picker">
        <DelDatePicker
          onDateChanged={onDateChange}
          {showRangePicker}
          typeOfPicker={datePickerType}
          defaultStartDate={showRangePicker ? defaultStartDate : null}
          defaultEndDate={showRangePicker ? defaultEndDate : null}
          autoApply={true}
        />
      </div>{/if}
  </div>

  <div
    style={`${chartHeight ? `height:${chartHeight}` : ""}; position:relative;`}
  >
    {#if dataAwaiting}
      <Loader />
    {:else if isNoData}
      <div class="no-data-class">No data available</div>
    {/if}
    <canvas style={isNoData ? `display:none;` : ""} bind:this={chartCanvas} />
  </div>
</div>

<style>
  .fill-chart-header {
    display: flex;
    justify-content: space-between;
  }

  .del-fill-chart-title {
    font: normal normal bold 15px Roboto;
  }

  .no-data-class {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    font: italic normal normal 16px/21px Roboto;
  }
</style>
