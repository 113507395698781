<script>
  import Plotly from "plotly.js-dist-min";
  import Loader from "./Loader.svelte";
  import * as XLSX from "xlsx";
  import moment from "moment";

  export let baseURL = "";
  export let screenName = "Analytics";
  export let anomalyDetectionDetails = null;
  export let exportButtonLabel = null;
  export let onAnomalyDetectionClick = null;
  export let onPlotAPISuccess = null;
  export let onPlotAPIError = null;
  export let forceClearChart = null;
  export let onTrainingPeriodUpdated = null;

  const plotEndPoint = "plot";

  let chartContainer = null;
  let chartDrawn = false;
  let showExportButton = false;
  let disableExportButton = true;
  let anomalyDetectionAPIAwaiting = false;
  let isNoData = false;
  let figure = {
    data: [
      {
        fill: "none",
        line: {
          color: "rgb(200, 207, 210)",
          width: 3,
        },
        meta: {
          columnNames: {
            x: "xaxis",
            y: "env_ub",
          },
        },
        mode: "lines",
        name: "Upper Envelope",
        type: "scatter",
        x: [],
        y: [],
        hovertemplate: "Upper Envelope:  %{y}<br>" + "<extra></extra>",
        opacity: 1,
        visible: true,
      },
      {
        fill: "tonexty",
        line: {
          color: "rgb(200, 207, 210)",
          width: 3,
        },
        meta: {
          columnNames: {
            x: "xaxis",
            y: "env_lb",
          },
        },
        mode: "lines",
        name: "Lower Envelope",
        type: "scatter",
        x: [],
        y: [],
        xaxis: "x",
        yaxis: "y",
        fillcolor: "rgb(232, 237, 239)",
        hovertemplate: "Lower Envelope:  %{y}<br>" + "<extra></extra>",
        stackgroup: null,
      },
      {
        line: {
          color: "rgb(51, 47, 208)",
          width: 2,
        },
        meta: {
          columnNames: {
            x: "xaxis",
            y: "data",
            marker: {
              color: "env_ub",
            },
          },
        },
        mode: "lines",
        name: "Data",
        hovertemplate: "Data:  %{y}<br>" + "<extra></extra>",
        type: "scatter",
        x: [],
        y: [],
        marker: {
          meta: {
            columnNames: {
              color: "env_ub",
            },
          },
          color: "#00cc96",
        },
        stackgroup: null,
      },
      {
        meta: {
          columnNames: {
            x: "anon_px",
            y: "anon_py",
          },
        },
        mode: "markers",
        name: "Positive Anomaly",
        hovertemplate: "Positive Anomaly:  %{y}<br>" + "<extra></extra>",
        type: "scatter",
        x: [],
        y: [],
        marker: {
          size: 8,
          color: "rgb(250, 72, 72)",
        },
      },
      {
        meta: {
          columnNames: {
            x: "anon_nx",
            y: "anon_ny",
          },
        },
        hovertemplate: "Negative Anomaly: %{y}<br>" + "<extra></extra>",
        mode: "markers",
        name: "Negative Anomaly",
        type: "scatter",
        x: [],
        y: [],
        marker: {
          size: 8,
          color: "rgb(148, 103, 189)",
        },
        stackgroup: null,
      },
    ],
    layout: {
      height: 600,
      title: {
        x: 0.5,
        font: {
          size: 16,
          family: "Roboto",
        },
        text: "<b>Anomaly Detection</b>",
      },
      xaxis: {
        type: "date",
        range: ["2022-04-18 17:34:08.2072", "2022-04-19 19:55:51.7928"],
        ticks: "",
        title: {
          font: {
            size: 14,
            family: "Roboto",
          },
          text: "<b>Time Duration</b>",
        },
        nticks: 16,
        tickfont: {
          size: 14,
        },
        tickmode: "auto",
        autorange: false,
        automargin: true,
        showspikes: false,
        rangeslider: {
          range: ["2022-04-18 17:34:18.8925", "2022-04-19 19:55:41.1075"],
          yaxis: {},
          visible: false,
          autorange: true,
        },
        rangeselector: {
          visible: false,
        },
      },
      yaxis: {
        side: "left",
        type: "linear",
        dtick: 30,
        range: [650.4, 1162.3999999999999],
        tick0: 8,
        title: {
          font: {
            size: 14,
            family: "Roboto",
          },
          text: "<b>Insert Unit here</b>",
        },
        tickfont: {
          size: 14,
          family: "Roboto",
        },
        tickmode: "auto",
        autorange: true,
        tickangle: "auto",
        automargin: true,
        showspikes: false,
        ticksuffix: "  ",
        showticksuffix: "all",
        separatethousands: false,
      },
      legend: {
        x: 1.02,
        y: 0.7,
        font: {
          size: 14,
          family: "Roboto",
        },

        valign: "middle",
        xanchor: "left",
        traceorder: "reversed",
        bordercolor: "rgb(178, 196, 201)",
        borderwidth: 1,
        orientation: "v",
      },
      margin: {
        b: 80,
      },
      modebar: {
        orientation: "h",
      },
      autosize: true,
      hovermode: "x",
      showlegend: true,
    },
  };
  let durationForExport = "";

  $: {
    if (baseURL && screenName && anomalyDetectionDetails) {
      invokeAnomalyDetectionAPI();
    }
  }

  $: {
    if (forceClearChart) {
      clearChart();
    } else {
      forceDraw();
    }
  }

  $: {
    if (chartContainer) {
      forceDraw();
    }
  }

  const forceDraw = function () {
    if (
      baseURL &&
      screenName &&
      anomalyDetectionDetails &&
      !anomalyDetectionAPIAwaiting
    ) {
      invokeAnomalyDetectionAPI();
    }
  };

  const invokeAnomalyDetectionAPI = function () {
    const payload = {
      node: anomalyDetectionDetails?.node,
      analysis_type: "Anomaly Detection",
      parameter: anomalyDetectionDetails?.parameterId,
      duration: anomalyDetectionDetails?.duration,
      resolution: anomalyDetectionDetails?.resolution,
      cust_start: anomalyDetectionDetails?.customStartTime,
      cust_end: anomalyDetectionDetails?.customEndTime,
      param_type: anomalyDetectionDetails?.type,
    };
    clearChart();
    anomalyDetectionAPIAwaiting = true;
    invokeAPI(
      "POST",
      baseURL,
      plotEndPoint,
      onAnomalyDetectionAPISuccess,
      onAnomalyDetectionAPIError,
      payload,
      `${screenName}/R`,
      {},
      "analytics"
    );
    disableExportButton = true;
  };

  const onAnomalyDetectionAPISuccess = function (data) {
    anomalyDetectionAPIAwaiting = false;
    let trainingStartTime = "-";
    let trainingEndTime = "-";

    if (data && data.training_dates && data.training_dates.length === 2) {
      trainingStartTime = moment(
        new Date(data.training_dates[0] * 1000)
      ).format("DD-MM-YYYY");
      trainingEndTime = moment(new Date(data.training_dates[1] * 1000)).format(
        "DD-MM-YYYY"
      );
    }
    const trainingDates = `Last computation period : ${trainingStartTime} to ${trainingEndTime}`;
    onTrainingPeriodUpdated && onTrainingPeriodUpdated(trainingDates);
    if (data) {
      processChartData(data);
    }
    onPlotAPISuccess && onPlotAPISuccess();
  };

  const onAnomalyDetectionAPIError = function (error) {
    anomalyDetectionAPIAwaiting = false;
    onPlotAPIError && onPlotAPIError(error);
    clearChart();
    isNoData = true;
  };

  const invokeAPI = function (
    method,
    baseURL,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const convertTZ = function (date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        {
          timeZone: tzString,
        }
      )
    );
  };

  const noDataChecker = function (num) {
    if (isNaN(num)) {
      return null;
    } else {
      return parseFloat(num).toFixed(3);
    }
  };
  const processChartData = function (data) {
    if (
      chartContainer &&
      anomalyDetectionDetails &&
      data &&
      ((data.data && data.data.length) || data.env_lb || data.env_ub)
    ) {
      isNoData = false;
      let actualData = data.data;
      let lowerEnvelope = data.env_lb;
      let upperEnvelope = data.env_ub;
      if (
        !noDataChecker(actualData) ||
        !noDataChecker(lowerEnvelope) ||
        !noDataChecker(upperEnvelope)
      ) {
        let xaxis = [];
        if (data.xaxis)
          xaxis = data.xaxis.map((item) =>
            convertTZ(new Date(item), "Asia/Kolkata")
          );
        let actualDataLength = actualData.length;
        let positiveAnomaly = [];
        let negativeAnomaly = [];
        xaxis = xaxis.slice(0, actualDataLength);
        let positiveAnomalyXAxis = [];
        let negativeAnomalyXAxis = [];
        for (let i = 0; i < actualData.length; i++) {
          if (actualData[i] > upperEnvelope[i]) {
            positiveAnomaly.push(actualData[i]);
            positiveAnomalyXAxis.push(xaxis[i]);
          } else if (actualData[i] < lowerEnvelope[i]) {
            negativeAnomaly.push(actualData[i]);
            negativeAnomalyXAxis.push(xaxis[i]);
          }
        }
        figure.data.forEach((item) => {
          if (item.name === "Upper Envelope") {
            item.x = xaxis;
            item.y = upperEnvelope;
          } else if (item.name === "Lower Envelope") {
            item.x = xaxis;
            item.y = lowerEnvelope;
          } else if (item.name === "Data") {
            item.x = xaxis;
            item.y = actualData;
          } else if (item.name === "Positive Anomaly") {
            item.x = positiveAnomalyXAxis;
            item.y = positiveAnomaly;
          } else if (item.name === "Negative Anomaly") {
            item.x = negativeAnomalyXAxis;
            item.y = negativeAnomaly;
          }
        });
        let lengthminus =
          anomalyDetectionDetails.resolution === "Hourly" ||
          anomalyDetectionDetails.resolution === "Base Frequency"
            ? 2
            : 1;
        let titlestartdate = convertTZ(new Date(xaxis[0]), "Asia/Kolkata");
        let titlestart =
          (titlestartdate.getDate() > 9
            ? titlestartdate.getDate()
            : `0${titlestartdate.getDate()}`) +
          "-" +
          (titlestartdate.getMonth() + 1 > 9
            ? titlestartdate.getMonth() + 1
            : `0${titlestartdate.getMonth() + 1}`) +
          "-" +
          titlestartdate.getFullYear();
        let titleenddate = convertTZ(
          new Date(xaxis[xaxis.length - lengthminus]),
          "Asia/Kolkata"
        );
        let titleend =
          (titleenddate.getDate() > 9
            ? titleenddate.getDate()
            : `0${titleenddate.getDate()}`) +
          "-" +
          (titleenddate.getMonth() + 1 > 9
            ? titleenddate.getMonth() + 1
            : `0${titleenddate.getMonth() + 1}`) +
          "-" +
          titleenddate.getFullYear();
        let titleDuration =
          anomalyDetectionDetails.duration === "Custom"
            ? `${titlestart} to ${titleend}`
            : anomalyDetectionDetails.duration;

        let title = `<b> <span>Anomaly Detection</span> <span style="text-align:left"><br>Parameter : ${
          anomalyDetectionDetails.parameter
        }<br>Duration : ${
          titleDuration === "Today" || titleDuration === "Yesterday"
            ? titleDuration +
              ` (${moment(anomalyDetectionDetails.customStartTime).format(
                "DD-MM-YYYY"
              )})`
            : titleDuration
        }</span></b>`;
        durationForExport =
          titleDuration === "Today" || titleDuration === "Yesterday"
            ? titleDuration +
              ` (${moment(anomalyDetectionDetails.customStartTime).format(
                "DD-MM-YYYY"
              )})`
            : titleDuration;
        figure.layout.title.text = title;
        figure.layout.xaxis.range = [
          xaxis[0],
          xaxis[xaxis.length - lengthminus],
        ];
        figure.layout.xaxis.autorange = true;
        figure.layout.yaxis.title.text = `<b> ${anomalyDetectionDetails.unit} </b>`;
        figure.layout.yaxis.autorange = true;
        figure.data.forEach((item) => {
          item.visible = "true";
        });
        const pngFileName = `Anomaly Detection ${anomalyDetectionDetails.parameter} ${durationForExport})`;
        Plotly.react(chartContainer, figure.data, figure.layout, {
          displaylogo: false,
          toImageButtonOptions: {
            filename: pngFileName,
          },
        });
        chartContainer.on("plotly_click", function (data) {
          if (data) {
            onAnomalyDetectionClick(data.points);
          }
        });
        // setTimeout(function () {
        //   document.querySelector('[data-title="Autoscale"]').click();
        // }, 1000);
        disableExportButton = false;
        showExportButton = true;
      } else {
        isNoData = true;
      }
    } else if (chartContainer) {
      isNoData = true;
      durationForExport = "";
    }
  };
  const clearChart = function () {
    isNoData = false;
    if (chartContainer) {
      Plotly.purge(chartContainer);
    }
    durationForExport = "";
    showExportButton = false;
    chartDrawn = false;
  };

  const exportToCsvExcel = function () {
    let workbook = XLSX.utils.book_new();
    const fileName = `Anomaly Detection ${anomalyDetectionDetails.parameter} ${durationForExport}.xlsx`;
    let headerArray = [
      ["Analysis Type", "Anomaly Detection"],
      ["Company", localStorage.getItem("companyName")],
      ["Parameter", anomalyDetectionDetails.parameter],
      ["Duration", durationForExport],
      ["Resolution", anomalyDetectionDetails.resolution],
      ["Node", anomalyDetectionDetails.node],
      [""],
      [""],
    ];

    let dataArray = [["Time", "Upper Envelope", "Data", "Lower Envelope"]];
    const headerStyle = {
      font: {
        name: "Times New Roman",
        sz: 12,
        color: {
          rgb: "FFFFFF",
        },
        bold: true,
      },
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "1F497D",
        },
        fgColor: {
          rgb: "1F497D",
        },
      },
    };
    const noDataStyle = {
      alignment: {
        horizontal: "center",
      },
      font: {
        color: {
          rgb: "FFFFFF",
        },
      },
      fill: {
        patternType: "solid",
        fgColor: {
          rgb: "000000",
        },
      },
    };
    const anomalyPositiveStyle = {
      alignment: {
        horizontal: "center",
      },
      font: {
        color: {
          rgb: "FFFFFF",
        },
      },
      fill: {
        patternType: "solid",
        fgColor: {
          rgb: "D2686E",
        },
      },
    };
    const anomalyNegativeStyle = {
      alignment: {
        horizontal: "center",
      },
      font: {
        color: {
          rgb: "FFFFFF",
        },
      },
      fill: {
        patternType: "solid",
        fgColor: {
          rgb: "799cb9",
        },
      },
    };
    const normalStyle = {
      alignment: {
        horizontal: "center",
      },
    };
    const contentHeaderStyle = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
    };

    for (let i = 1, j = 0; j < figure.data[0].x.length; i++, j++) {
      if (dataArray[i] === undefined) dataArray[i] = [];
      if (anomalyDetectionDetails.resolution !== "Daily")
        dataArray[i][0] = moment(figure.data[0].x[j]).format(
          "DD MMMM YYYY hh:mm:ss A"
        );
      else dataArray[i][0] = moment(figure.data[0].x[j]).format("DD MMMM YYYY");
    }
    for (let i = 1, j = 0; j < figure.data[2].x.length; j++, i++) {
      if (dataArray[i] === undefined) dataArray[i] = [];
      dataArray[i][1] = figure.data[0].y[j];
    }
    for (let i = 1, j = 0; j < figure.data[2].x.length; j++, i++) {
      if (dataArray[i] === undefined) {
        dataArray[i] = [];
      }
      dataArray[i][2] = figure.data[2].y[j];
    }
    for (let i = 1, j = 0; j < figure.data[2].x.length; j++, i++) {
      if (dataArray[i] === undefined) {
        dataArray[i] = [];
      }
      dataArray[i][3] = figure.data[1].y[j];
    }
    let dataArrayFinal = [...headerArray, ...dataArray];
    let worksheet = XLSX.utils.aoa_to_sheet(dataArrayFinal);
    worksheet["!cols"] = fitToColumn(dataArray);

    worksheet["A9"].s = headerStyle;
    worksheet["B9"].s = headerStyle;
    worksheet["C9"].s = headerStyle;
    worksheet["D9"].s = headerStyle;
    for (let i = 1; i < 7; i++) {
      worksheet[`A${i}`].s = contentHeaderStyle;
      worksheet[`B${i}`].s = contentHeaderStyle;
    }
    let checkIfNotEnd = true;
    let lI = 10;
    console.time("looper");
    do {
      if (worksheet[`A${lI}`]) {
        if (worksheet[`C${lI}`].v === "nan") {
          worksheet[`C${lI}`].v = "No Data";
          worksheet[`C${lI}`].s = noDataStyle;
          worksheet[`B${lI}`].s = normalStyle;
          worksheet[`D${lI}`].s = normalStyle;
        } else if (
          parseFloat(worksheet[`C${lI}`].v) < parseFloat(worksheet[`D${lI}`].v)
        ) {
          worksheet[`C${lI}`].s = anomalyNegativeStyle;
          worksheet[`B${lI}`].s = normalStyle;
          worksheet[`D${lI}`].s = normalStyle;
        } else if (
          parseFloat(worksheet[`C${lI}`].v) > parseFloat(worksheet[`B${lI}`].v)
        ) {
          worksheet[`C${lI}`].s = anomalyPositiveStyle;
          worksheet[`B${lI}`].s = normalStyle;
          worksheet[`D${lI}`].s = normalStyle;
        } else {
          worksheet[`C${lI}`].s = normalStyle;
          worksheet[`B${lI}`].s = normalStyle;
          worksheet[`D${lI}`].s = normalStyle;
        }
        lI = lI + 1;
      } else {
        checkIfNotEnd = false;
      }
    } while (checkIfNotEnd);
    console.timeEnd("looper");

    XLSX.utils.book_append_sheet(workbook, worksheet, "Anomaly Detection");

    function fitToColumn(arrayOfArray) {
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length + 2 : 0))
        ),
      }));
    }
    return XLSX.writeFile(workbook, fileName);
  };
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-anomaly-detection-container">
  {#if anomalyDetectionAPIAwaiting}
    <Loader />
  {:else if isNoData}
    <div class="no-data-message">No Data Available</div>
  {/if}
  <div class="del-anomaly-detection-chart" bind:this={chartContainer} />
  {#if showExportButton}
    <div>
      <button
        class="del-anomaly-detection-export-button"
        on:click={() => exportToCsvExcel()}
        disabled={disableExportButton}
        >{exportButtonLabel ?? "Export Data"}</button
      >
    </div>
  {/if}
</div>

<style>
  .del-anomaly-detection-container {
    position: relative;
    width: 100%;
    min-height: 500px;
  }

  .del-anomaly-detection-chart {
    min-height: 300px;
  }
  button.del-anomaly-detection-export-button {
    color: #fff;
    background-color: #2c3049;
    border-color: #222639;
    border-radius: 5px;
    padding: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }
  button.del-anomaly-detection-export-button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  .no-data-message {
    position: absolute;
    top: 40%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
    font: normal normal normal 14px Roboto;
    color: #5e5e5e;
  }
</style>
