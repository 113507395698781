<script>
  import DelEventTable from "../components/DelEventTable/DelEventTable.svelte";
  import {
    selectedNode,
    defaultNodeId,
    currentPage,
    availableScreens,
  } from "./../stores";
  import { screensToShow } from "./../../config.js";
  import { getDomainURL } from "./../invokeAPI";

  $: selectedNodeId = $selectedNode?.id;

  const eventTableColumns = {
    alertname: "Alert",
    nodeName: "Source",
    category: "Category",
    alertvalue: "Alert Value",
    currentstatus: "Status",
    createdDate: "Occured On",
    acknowledgedDate: "Acked On",
    acknowledgedBy: "Acked By",
    isAcknowledged: "Ack Status",
    action: "Action",
    ignore: "Disable",
  };

  const columnsToFilter = {
    category: ["Critical", "Warning", "Informational"],
    nodeName: [],
    isAcknowledged: ["YES", "NO"],
  };

  const columnsToSort = [
    "alertname",
    "category",
    "createdDate",
    "acknowledgedDate",
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const nodeIdFromURL = urlParams.get("nodeId");

  if (nodeIdFromURL) {
    defaultNodeId.set(nodeIdFromURL);
  }

  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);
</script>

<div class="del-event-viewer-container">
  <DelEventTable
    currentNodeId={selectedNodeId}
    selectedNodeDetails={$selectedNode}
    baseURL={getDomainURL()}
    tableHeadings={eventTableColumns}
    isWritePermission={screenPermission === "RW"}
    filterColumns={columnsToFilter}
    sortColumns={columnsToSort}
  />
</div>

<style>
  .del-event-viewer-container {
    padding: 32px 28px;
  }
</style>
