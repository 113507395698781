<div class="loader-container">
	<div class="loader" />
</div>

<style>
	.loader-container {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		background-color: rgba(180, 184, 189, 0.363);
	}

	.loader {
		border: 6px solid #f3f3f3;
		border-radius: 50%;
		border-top: 6px solid #6c6c8a;
		width: 30px;
		height: 30px;
		z-index: 11;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
	}

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
