<script>
  export let data;
</script>

<div class="del-card-container">
  <div class="del-card-header">
    <div>{data?.title ?? ""}</div>
    <div>{data?.subTitle ?? ""}</div>
  </div>
  <div class="del-card-content">
    {#if data?.content}
      {#each data?.content as item (item)}
        <div>{item ?? ""}</div>
      {/each}
    {/if}
  </div>
</div>

<style>
  .del-card-container {
    border-radius: 10px;
    min-height: 181px;
    border: 1px solid #79919a;
  }
  .del-card-header {
    background-color: #79919a;
    color: #ffffff;
    font: normal normal bold 16px/21px Roboto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px 10px 0 0;
  }
  .del-card-content {
    min-height: 110px;
    font: normal normal normal 16px/24px Roboto;
    color: #101010;
    padding: 10px;
    background-color: #ffffff;
  }
</style>
