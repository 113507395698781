<script>
  import Icon from "@iconify/svelte";
  import DelCard from "../DelCard/DelCard.svelte";

  export let dataList = [];
  export let title = "";
  export let onBackButtonClick = null;
</script>

<div class="del-cards-list-container">
  <div class="del-cards-list-header">
    <button
      class="del-cards-list-back-button"
      on:click={() => {
        onBackButtonClick && onBackButtonClick();
      }}><Icon icon="ep:back" /></button
    >
    <div class="del-cards-list-title">{title ?? ""}</div>
    <div />
  </div>
  <div class="del-cards-list">
    {#each dataList as data (data?.title)}
      <div class="del-card">
        <DelCard {data} />
      </div>
    {/each}
  </div>
</div>

<style>
  .del-cards-list-container {
    width: 100%;
    background: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    box-shadow: 5px 1px 7px #00000014;
    margin: 10px auto;
    box-sizing: border-box;
    padding-bottom: 20px;
    border: 1px solid #ccd3e7;
  }

  .del-cards-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 0;
    margin: 10px 0 20px;
  }

  .del-cards-list-back-button {
    background: #79919a 0 0 no-repeat padding-box;
    border: 1px solid transparent;
    color: #ffffff;
    opacity: 1;
    border-radius: 64%;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 30px;
    align-items: center;
    cursor: pointer;
  }

  .del-cards-list-back-button:enabled:hover,
  .del-cards-list-back-button:enabled:active,
  .del-cards-list-back-button:enabled:focus {
    transform: scale(1.05);
  }

  .del-cards-list-title {
    font: normal normal bold 18px/24px Roboto;
    color: #101010;
  }

  .del-cards-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
  }

  .del-card {
    width: calc(25% - 30px);
    box-sizing: border-box;
    flex-grow: 1;
  }

  @media screen and (min-width: 700px) {
    .del-card {
      width: calc(33.33% - 30px);
    }
  }
  @media screen and (min-width: 900px) {
    .del-card {
      width: calc(25% - 30px);
    }
  }
  @media screen and (max-width: 700px) {
    .del-card {
      width: 100%;
    }
  }
</style>
