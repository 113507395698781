<script>
  import {
    AmpPlugin,
    LockPlugin,
    RangePlugin,
    PresetPlugin,
    TimePlugin,
    easepick,
  } from "@easepick/bundle";
  import moment from "moment";
  import { onMount, onDestroy } from "svelte";
  import AirDatepicker from "air-datepicker";
  import "air-datepicker/air-datepicker.css";
  import localeEn from "air-datepicker/locale/en";
  import Icon from "@iconify/svelte";

  export let showRangePicker = true;
  export let customRangesToShow = [];
  export let showTimePicker = false;
  export let defaultDate = new Date();
  export let defaultStartDate = null;
  export let defaultEndDate = null;
  export let autoApply = false;
  export let maxDate = new Date();
  export let minDate = null;
  export let format = "D MMMM YYYY";
  export let minDays = null;
  export let maxDays = null;
  export let firstDay = 0;
  export let typeOfPicker = "day";
  export let startTimeOffSet = 0;
  export let endTimeOffSet = 0;
  export let zIndex = 1;
  export let format12 = false;
  export let isDisabled = false;

  export let onDateChanged = null;
  export let validateSelectedDate = null;

  let delDatePickerElement = null;
  let datePickerElement = null;
  let monthWeekPickerElement = null;
  let yearPickerElement = null;
  let selectedDate = null;
  let formattedDefaultDate = "";
  let formattedMaxDate = "";
  let validationResult = false;
  let offSetInterval = null;

  $: {
    updateDefaultDate(defaultDate);
  }

  $: {
    if (defaultStartDate || defaultEndDate) {
      updateDefaultDates();
    }
  }

  $: {
    handlePluginChange("showRanges", showRangePicker);
  }

  $: {
    handlePluginChange("rangesToShow", customRangesToShow);
  }

  $: {
    handlePluginChange("showTimePicker", showTimePicker);
  }

  $: {
    handlePluginChange("autoApply", autoApply);
  }

  $: {
    handlePluginChange("maxDate", maxDate);
    checkIfGreater();
  }
  $: {
    handlePluginChange("minDate", minDate);
  }
  $: {
    handlePluginChange("format", format);
  }
  $: {
    handlePluginChange("minDays", minDays);
  }
  $: {
    handlePluginChange("maxDays", maxDays);
  }
  $: {
    handlePluginChange("firstDay", firstDay);
  }
  $: {
    handlePluginChange("zIndex", zIndex);
  }
  $: {
    handlePluginChange("format12", format12);
  }

  $: {
    handlePickerChange(typeOfPicker);
  }

  $: {
    if (startTimeOffSet || endTimeOffSet) {
      handleOffSetChange();
    }
  }

  const checkIfDayChanged = function () {
    const currentTime = moment().valueOf();
    const previousTime = moment().valueOf() - 60000;

    const offSetFromLocalStorage = localStorage.getItem("startTimeOffset");
    const offSet = offSetFromLocalStorage
      ? parseInt(offSetFromLocalStorage)
      : 0;
    const dayDefinedStartTime = moment().startOf("day").valueOf() + offSet;
    if (
      dayDefinedStartTime <= currentTime &&
      dayDefinedStartTime > previousTime
    ) {
      handleOffSetChange(true);
    }
  };

  const checkIfTodayHasStarted = function (offSet, endTime) {
    const currentTime = moment().valueOf();
    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }
    return moment().startOf("day").valueOf() + offSet <= currentTime;
  };

  const handleOffSetChange = function (auto) {
    if (!checkIfTodayHasStarted(startTimeOffSet) && showRangePicker) {
      handleCustomRangesChange(customRangesToShow);
      const newMaxDate = new Date(moment(maxDate).subtract(1, "day"));
      handlePluginChange("maxDate", newMaxDate);
      checkIfGreater();
    } else if (auto && showRangePicker) {
      handleCustomRangesChange(customRangesToShow);
      handlePluginChange("maxDate", maxDate);
      checkIfGreater();
    }
    if (selectedDate?.start && selectedDate?.end && selectedDate?.date) {
      selectedDate = getSelectedDateObject(
        selectedDate?.date,
        selectedDate?.start,
        selectedDate?.end
      );
      onDateChanged && onDateChanged(selectedDate);
    }
  };

  const addOffSet = function (date, offSet, endTime) {
    if (!(date && parseInt(date))) {
      return date;
    }

    const currentTime = moment().valueOf();

    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }

    let updatedDate = date;
    const endOfToday = moment().endOf("day").valueOf();

    if (
      moment(updatedDate).startOf("day").valueOf() + offSet <= currentTime ||
      moment(updatedDate).valueOf() > endOfToday
    ) {
      // if as per day definition, today has started
      updatedDate += offSet;
    } else {
      // else, we are still in yesterday, so set the date selected as one day before
      if (endTime) {
        updatedDate =
          moment(date).endOf("day").subtract(1, "days").valueOf() + offSet;
      } else {
        updatedDate =
          moment(date).startOf("day").subtract(1, "days").valueOf() + offSet;
      }
    }

    return updatedDate;
  };

  const checkIfGreaterThanNow = function (date) {
    if (!date || isNaN(date)) return;

    date = parseInt(date);
    const currentTime = moment().valueOf();
    if (date > currentTime) {
      return currentTime;
    }
    return date;
  };

  const epoch13to10 = function (date) {
    if (!date) return date;
    return parseInt(date / 1000);
  };

  const updateDefaultDate = function (defaultDate) {
    if (delDatePickerElement && defaultDate && !showRangePicker) {
      delDatePickerElement.setDate(defaultDate);
      if (showTimePicker) {
        delDatePickerElement.setTime(moment(defaultDate).format("HH:mm:ss"));
      }
    }
  };

  const updateDefaultDates = function (start, end) {
    let currentDate = defaultDate ? new Date(defaultDate) : new Date();
    let currentStartDate = start
      ? new Date(start)
      : defaultStartDate
        ? new Date(defaultStartDate)
        : defaultDate
          ? new Date(moment(defaultDate).startOf("day"))
          : new Date(moment().startOf("day"));
    let currentEndDate = end
      ? new Date(end)
      : defaultEndDate
        ? new Date(defaultEndDate)
        : defaultDate
          ? new Date(moment(defaultDate).endOf("day"))
          : new Date(moment().endOf("day"));
    if (delDatePickerElement) {
      if (showRangePicker) {
        delDatePickerElement.setDateRange(
          currentStartDate || null,
          currentEndDate || null
        );
      } else {
        delDatePickerElement.setDate(currentDate || null);
      }
    }
    if (!(start && end)) {
      handlePickerChange();
    } else {
      let result = null;
      if (currentDate && validateSelectedDate) {
        result = validateSelectedDate(currentDate);
      }
      validationResult = handleValidation(result);
      if (result?.error) return;
      selectedDate = getSelectedDateObject(
        currentDate,
        currentStartDate,
        currentEndDate
      );
      onDateChanged && onDateChanged(selectedDate);
    }
  };

  const handlePickerChange = function (type) {
    if (!(delDatePickerElement && monthWeekPickerElement)) return;
    let currentDate = defaultDate ? new Date(defaultDate) : new Date();
    let currentStartDate = defaultStartDate
      ? new Date(defaultStartDate)
      : defaultDate
        ? new Date(moment(defaultDate).startOf("day"))
        : new Date(moment().startOf("day"));
    let currentEndDate = defaultEndDate
      ? new Date(defaultEndDate)
      : defaultDate
        ? new Date(moment(defaultDate).endOf("day"))
        : new Date(moment().endOf("day"));
    const newMaxDate = checkIfTodayHasStarted(startTimeOffSet)
      ? maxDate
      : new Date(moment(maxDate).subtract(1, "day"));

    switch (type) {
      case "month":
        formattedDefaultDate = moment(new Date(defaultDate)).format("YYYY-MM");
        formattedMaxDate = moment(new Date(newMaxDate)).format("YYYY-MM");
        currentStartDate = new Date(moment(currentStartDate).startOf("month"));
        currentEndDate = new Date(moment(currentEndDate).endOf("month"));
        break;
      case "week":
        formattedDefaultDate =
          moment(new Date(defaultDate)).format("YYYY") +
          "-W" +
          moment(new Date(defaultDate)).format("WW");
        formattedMaxDate =
          moment(new Date(newMaxDate)).format("YYYY") +
          "-W" +
          moment(new Date(newMaxDate)).format("WW");
        currentStartDate = new Date(
          moment(currentStartDate).isoWeekday(1).startOf("day")
        );
        currentEndDate = new Date(
          moment(currentEndDate).isoWeekday(7).endOf("day")
        );
        break;
      case "year":
        currentStartDate = defaultStartDate
          ? new Date(defaultStartDate)
          : defaultDate
            ? new Date(moment(defaultDate).startOf("year"))
            : new Date(moment().startOf("year"));
        currentEndDate = defaultEndDate
          ? new Date(defaultEndDate)
          : defaultDate
            ? new Date(moment(defaultDate).endOf("year"))
            : new Date(moment().endOf("year"));
        break;
      default:
        break;
    }
    let result = null;
    if (currentDate && validateSelectedDate) {
      result = validateSelectedDate(currentDate);
    }
    validationResult = handleValidation(result);
    if (result?.error) return;
    selectedDate = getSelectedDateObject(
      currentDate,
      currentStartDate,
      currentEndDate
    );
    onDateChanged && onDateChanged(selectedDate);
  };

  const setDates = function () {
    if (delDatePickerElement) {
      if (showRangePicker) {
        delDatePickerElement.setDateRange(
          selectedDate?.start || null,
          selectedDate?.end || null
        );
      } else {
        delDatePickerElement.setDate(selectedDate?.date || null);
      }
    }
  };

  const setTime = function () {
    if (delDatePickerElement && showTimePicker && selectedDate) {
      delDatePickerElement.setTime(
        selectedDate.date ? moment(selectedDate.date).format("HH:mm:ss") : null
      );
      delDatePickerElement.setStartTime(
        selectedDate.start
          ? moment(selectedDate.start).format("HH:mm:ss")
          : null
      );
      delDatePickerElement.setEndTime(
        selectedDate.end ? moment(selectedDate.end).format("HH:mm:ss") : null
      );
    }
  };

  const checkIfGreater = function () {
    if (selectedDate) {
      const newMaxDate = checkIfTodayHasStarted(startTimeOffSet)
        ? maxDate
        : new Date(moment(maxDate).subtract(1, "day"));
      const start =
        moment(new Date(selectedDate.start)).valueOf() >
        moment(new Date(newMaxDate)).valueOf()
          ? newMaxDate
          : selectedDate.start;
      const end =
        moment(new Date(selectedDate.end)).valueOf() >
        moment(new Date(newMaxDate)).valueOf()
          ? newMaxDate
          : selectedDate.end;
      const isGreater =
        moment(new Date(selectedDate.end)).valueOf() >
          moment(new Date(newMaxDate)).valueOf() ||
        moment(new Date(selectedDate.start)).valueOf() >
          moment(new Date(newMaxDate)).valueOf();
      if (isGreater) {
        updateDefaultDates(start, end);
      }
    }
  };

  const handlePluginChange = function (type, value) {
    if (delDatePickerElement) {
      let currentRanges = [];
      switch (type) {
        case "showTimePicker":
          currentRanges = Object.keys(
            delDatePickerElement.PluginManager.instances
          );
          if (value) {
            if (!currentRanges.includes("TimePlugin")) {
              const plugins = getPluginList();
              delDatePickerElement.options.plugins = plugins;
              delDatePickerElement.PluginManager.addInstance("TimePlugin");
            }
          } else {
            if (currentRanges.includes("TimePlugin")) {
              const plugins = getPluginList();
              delDatePickerElement.options.plugins = plugins;
              delDatePickerElement.PluginManager.removeInstance("TimePlugin");
            }
          }
          break;
        case "showRanges":
          currentRanges = Object.keys(
            delDatePickerElement.PluginManager.instances
          );
          if (value) {
            const plugins = getPluginList();
            delDatePickerElement.options.plugins = plugins;
            if (!currentRanges.includes("RangePlugin")) {
              const rangePluginInstance =
                delDatePickerElement.PluginManager.addInstance("RangePlugin");
              rangePluginInstance.options.startDate =
                selectedDate?.start || null;
              rangePluginInstance.options.endDate = selectedDate?.end || null;
              handleCustomRangesChange(customRangesToShow);
            } else {
              delDatePickerElement.PluginManager.reloadInstance("RangePlugin");
            }
          } else {
            if (currentRanges.includes("RangePlugin")) {
              const plugins = getPluginList();
              delDatePickerElement.options.plugins = plugins;
              delDatePickerElement.PluginManager.removeInstance("RangePlugin");
            }
          }
          setDates();
          break;
        case "rangesToShow":
          handleCustomRangesChange(value);
          break;
        case "maxDate":
        case "minDate":
        case "minDays":
        case "maxDays":
          delDatePickerElement.options.LockPlugin[type] = value;
          delDatePickerElement.PluginManager.reloadInstance("LockPlugin");
          break;
        default:
          delDatePickerElement.options[type] = value;
          break;
      }
      delDatePickerElement.renderAll();
    }
  };

  const handleCustomRangesChange = function (value) {
    if (delDatePickerElement && showRangePicker) {
      const currentRanges = Object.keys(
        delDatePickerElement.PluginManager.instances
      );
      delDatePickerElement.options.PresetPlugin.customPreset =
        getCustomPresetRanges();
      if (value?.length && !currentRanges.includes("PresetPlugin")) {
        delDatePickerElement.PluginManager.addInstance("PresetPlugin");
      } else if (!value?.length && currentRanges.includes("PresetPlugin")) {
        delDatePickerElement.PluginManager.removeInstance("PresetPlugin");
      } else if (value?.length && currentRanges.includes("PresetPlugin")) {
        delDatePickerElement.PluginManager.reloadInstance("PresetPlugin");
      }
    }
  };

  const getAllRanges = function () {
    const lastDayOfWeek = firstDay + 6 > 6 ? firstDay + 6 : (firstDay + 6) % 7;
    let maxDateEndOfDay = new Date(moment(maxDate).endOf("day"));
    const hasTodayStarted = checkIfTodayHasStarted(startTimeOffSet);
    let endOfToday =
      maxDateEndOfDay < new Date(moment().endOf("day"))
        ? maxDateEndOfDay
        : new Date(moment().endOf("day"));
    let endOfYesterday =
      maxDateEndOfDay < new Date(moment().subtract(1, "day").endOf("day"))
        ? maxDateEndOfDay
        : new Date(moment().subtract(1, "day").endOf("day"));
    let endOfLastWeek =
      maxDateEndOfDay <
      new Date(
        moment()
          .startOf("week")
          .weekday(firstDay)
          .subtract(1, "week")
          .endOf("week")
          .weekday(lastDayOfWeek)
      )
        ? maxDateEndOfDay
        : new Date(
            moment()
              .startOf("week")
              .weekday(firstDay)
              .subtract(1, "week")
              .endOf("week")
              .weekday(lastDayOfWeek)
          );
    let endOfWeek =
      maxDateEndOfDay < new Date(moment().endOf("week").weekday(lastDayOfWeek))
        ? maxDateEndOfDay
        : new Date(moment().endOf("week").weekday(lastDayOfWeek));
    let endOfMonth =
      maxDateEndOfDay < new Date(moment().endOf("month"))
        ? maxDateEndOfDay
        : new Date(moment().endOf("month"));
    let endOfLastMonth =
      maxDateEndOfDay <
      new Date(moment().startOf("month").subtract(1, "month").endOf("month"))
        ? maxDateEndOfDay
        : new Date(
            moment().startOf("month").subtract(1, "month").endOf("month")
          );
    let endOfYear =
      maxDateEndOfDay < new Date(moment().endOf("year"))
        ? maxDateEndOfDay
        : new Date(moment().endOf("year"));

    if (!hasTodayStarted) {
      endOfToday = new Date(moment(endOfToday).subtract(1, "day"));
      endOfYesterday = new Date(moment(endOfYesterday).subtract(1, "day"));
      endOfWeek = new Date(moment(endOfWeek).subtract(1, "day"));
      endOfLastWeek = new Date(moment(endOfLastWeek).subtract(1, "day"));
      endOfMonth = new Date(moment(endOfMonth).subtract(1, "day"));
      endOfLastMonth = new Date(moment(endOfLastMonth).subtract(1, "day"));
      endOfYear = new Date(moment(endOfYear).subtract(1, "day"));
    }

    let customRanges = {
      Today: hasTodayStarted
        ? [new Date(moment().startOf("day")), endOfToday]
        : [new Date(moment().subtract(1, "day").startOf("day")), endOfToday],
      Yesterday: hasTodayStarted
        ? [new Date(moment().subtract(1, "day").startOf("day")), endOfYesterday]
        : [
            new Date(moment().subtract(2, "day").startOf("day")),
            endOfYesterday,
          ],
      "Last 7 days": hasTodayStarted
        ? [new Date(moment().subtract(6, "day").startOf("day")), endOfToday]
        : [new Date(moment().subtract(7, "day").startOf("day")), endOfToday],
      "Last 30 days": hasTodayStarted
        ? [new Date(moment().subtract(29, "day").startOf("day")), endOfToday]
        : [new Date(moment().subtract(30, "day").startOf("day")), endOfToday],
      "Current Week": [
        new Date(moment().startOf("week").weekday(firstDay)),
        endOfWeek,
      ],
      "Last Week": [
        new Date(
          moment().startOf("week").weekday(firstDay).subtract(1, "week")
        ),
        endOfLastWeek,
      ],
      "Current Month": [new Date(moment().startOf("month")), endOfMonth],
      "Last Month": [
        new Date(moment().startOf("month").subtract(1, "month")),
        endOfLastMonth,
      ],
      "Current Year": [new Date(moment().startOf("year")), endOfYear],
    };
    return customRanges;
  };

  const getPluginList = function () {
    let plugins = [AmpPlugin, LockPlugin];
    showRangePicker && plugins.push(RangePlugin);
    showRangePicker && customRangesToShow?.length && plugins.push(PresetPlugin);
    showTimePicker && plugins.push(TimePlugin);
    return plugins;
  };

  const getCustomPresetRanges = function () {
    const possibleRanges = getAllRanges();
    let customPresetRanges = {};
    if (customRangesToShow?.length) {
      const rangesToShow = customRangesToShow.map((item) => item.toLowerCase());
      for (const range of Object.keys(possibleRanges)) {
        if (rangesToShow.includes(range.toLowerCase())) {
          customPresetRanges[range] = possibleRanges[range];
        }
      }
    }
    return customPresetRanges;
  };

  const initializePlugins = function () {
    window.easepick = easepick;
    window.RangePlugin = RangePlugin;
    window.LockPlugin = LockPlugin;
    window.PresetPlugin = PresetPlugin;
    window.TimePlugin = TimePlugin;
    window.AmpPlugin = AmpPlugin;
  };

  const getSelectedDateObject = function (date, start, end) {
    const dayDefinedStartDateInEpoch = addOffSet(
      moment(start).valueOf(),
      startTimeOffSet
    );
    const dayDefinedEndDateInEpoch = addOffSet(
      moment(end).valueOf(),
      endTimeOffSet,
      true
    );
    const selectedDateObject = {
      isRange: showRangePicker,
      date,
      start,
      end,
      dayDefinedStartDate: new Date(dayDefinedStartDateInEpoch),
      dayDefinedEndDate: new Date(dayDefinedEndDateInEpoch),
      dateInEpoch: moment(date).valueOf(),
      startDateInEpoch: moment(start).valueOf(),
      endDateInEpoch: moment(end).valueOf(),
      dayDefinedStartDateInEpoch,
      dayDefinedEndDateInEpoch,
      dateInSeconds: epoch13to10(moment(date).valueOf()),
      startDateInSeconds: epoch13to10(moment(start).valueOf()),
      endDateInSeconds: epoch13to10(moment(end).valueOf()),
      dayDefinedStartDateInSeconds: epoch13to10(dayDefinedStartDateInEpoch),
      dayDefinedEndDateInSeconds: epoch13to10(dayDefinedEndDateInEpoch),
      dayDefinedEndDateInEpochNotGreaterThanNow: checkIfGreaterThanNow(
        dayDefinedEndDateInEpoch
      ),
      dayDefinedEndDateInSecondsNotGreaterThanNow: epoch13to10(
        checkIfGreaterThanNow(dayDefinedEndDateInEpoch)
      ),
    };
    return selectedDateObject;
  };

  const setSelectedDate = function (detail) {
    let date = null;
    let start = null;
    let end = null;
    if (detail) {
      date = detail?.date || detail?.start || null;
      start = detail?.start
        ? new Date(moment(detail?.start).startOf("day"))
        : new Date(moment(detail?.date).startOf("day"));
      end = detail?.end
        ? new Date(moment(detail?.end).endOf("day"))
        : new Date(moment(detail?.date).endOf("day"));
    } else {
      date = delDatePickerElement.getDate();
      start = new Date(moment(date).startOf("day"));
      end = new Date(moment(date).endOf("day"));
      if (showRangePicker) {
        start = delDatePickerElement.getStartDate();
        end = delDatePickerElement.getEndDate();
        date = start;
      }
    }
    let result = null;
    if (date && validateSelectedDate) {
      result = validateSelectedDate(date);
    }
    validationResult = handleValidation(result);
    if (result?.error) return;
    selectedDate = getSelectedDateObject(date, start, end);
    onDateChanged && onDateChanged(selectedDate);
  };

  const handleValidation = function (result) {
    if (result?.error) {
      datePickerElement?.setCustomValidity(result.message);
      monthWeekPickerElement?.setCustomValidity(result.message);
      yearPickerElement?.setCustomValidity(result.message);
    } else {
      datePickerElement?.setCustomValidity("");
      monthWeekPickerElement?.setCustomValidity("");
      yearPickerElement?.setCustomValidity("");
    }
    datePickerElement?.reportValidity();
    monthWeekPickerElement?.reportValidity();
    yearPickerElement?.reportValidity();
    return result?.error;
  };

  onMount(() => {
    offSetInterval = setInterval(checkIfDayChanged, 60000);
    initializePlugins();
    const plugins = getPluginList();
    let customPresetRanges = getCustomPresetRanges();
    let currentDate = defaultDate ? new Date(defaultDate) : new Date();
    let currentStartDate = defaultStartDate
      ? new Date(defaultStartDate)
      : defaultDate
        ? new Date(moment(defaultDate).startOf("day"))
        : new Date(moment().startOf("day"));
    let currentEndDate = defaultEndDate
      ? new Date(defaultEndDate)
      : defaultDate
        ? new Date(moment(defaultDate).endOf("day"))
        : new Date(moment().endOf("day"));
    const newMaxDate = checkIfTodayHasStarted(startTimeOffSet)
      ? maxDate
      : new Date(moment(maxDate).subtract(1, "day"));
    delDatePickerElement = new easepick.create({
      element: datePickerElement,
      date: currentDate,
      autoApply,
      format,
      firstDay,
      zIndex,
      css: [
        "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css",
        "/del-datepicker.css",
      ],
      plugins,
      AmpPlugin: {
        dropdown: {
          months: true,
          years: true,
        },
      },
      LockPlugin: {
        maxDate: newMaxDate,
        minDate,
        minDays,
        maxDays,
      },
      RangePlugin: {
        startDate: currentStartDate,
        endDate: currentEndDate,
      },
      PresetPlugin: {
        customPreset: customPresetRanges,
      },
      TimePlugin: {
        seconds: true,
        stepMinutes: 1,
        stepSeconds: 1,
        format12,
      },
      setup(delDatePickerElement) {
        delDatePickerElement.on("select", (event, options) => {
          setSelectedDate(event?.detail);
        });

        delDatePickerElement.on("change", (e, options) => {
          if (
            e.target &&
            e.target.classList.contains("time-plugin-custom-input")
          ) {
            const btn = delDatePickerElement.ui.container.querySelector(
              "footer .apply-button"
            );
            if (btn) {
              btn.disabled = false;
            } else {
              setSelectedDate();
            }
          }
        });

        delDatePickerElement.on("clear", () => {
          selectedDate = null;
          onDateChanged && onDateChanged(selectedDate);
        });
      },
    });

    const handleYearChange = function ({ date }) {
      const start = new Date(moment(date).startOf("year"));
      const end = new Date(moment(date).endOf("year"));
      const newDateObject = { date, start, end };
      setSelectedDate(newDateObject);
    };

    new AirDatepicker(yearPickerElement, {
      dateFormat: "yyyy",
      locale: localeEn,
      view: "years",
      minView: "years",
      maxDate: new Date(newMaxDate),
      selectedDates: [new Date(defaultDate)],
      onSelect: handleYearChange,
      autoClose: true,
    });

    handlePickerChange(typeOfPicker);
    setTime();
  });

  onDestroy(() => {
    if (delDatePickerElement) {
      delDatePickerElement.off("select", () => {});
      delDatePickerElement.off("clear", () => {});
    }
    if (offSetInterval) {
      clearInterval(offSetInterval);
      offSetInterval = null;
    }
  });

  const handleCalendarIconClick = function () {
    if (delDatePickerElement) {
      if (!delDatePickerElement.isShown() && typeOfPicker === "day") {
        delDatePickerElement.show();
      }
    }
    if (yearPickerElement && typeOfPicker === "year") {
      yearPickerElement.datepicker("show");
    }
  };

  const handleDateChange = function (event) {
    let newDate = event.target.value;
    if (!newDate) return false;
    let date = new Date(newDate);
    let start = null;
    let end = null;

    switch (typeOfPicker) {
      case "month":
        start = new Date(moment(newDate).startOf("month"));
        end = new Date(moment(newDate).endOf("month"));
        break;
      case "week":
        newDate = event.target.valueAsNumber;
        date = new Date(newDate);
        start = new Date(moment(newDate).isoWeekday(1).startOf("day"));
        end = new Date(moment(newDate).isoWeekday(7).endOf("day"));
        break;
      default:
        break;
    }
    const newDateObject = { date, start, end };
    setSelectedDate(newDateObject);
    monthWeekPickerElement?.blur();
  };
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div
  class={`del-date-picker-container ${typeOfPicker !== "day" ? "hidden" : ""}`}
>
  <input
    id="del-date-picker"
    bind:this={datePickerElement}
    class={`${validationResult ? "invalid-input" : ""}`}
    disabled={isDisabled}
  />
  <button class="date-picker-icon-container" on:click={handleCalendarIconClick}
    ><Icon icon="mdi-light:calendar" /></button
  >
</div>
<div
  class={`del-date-picker-container del-month-week-picker-container ${
    typeOfPicker !== "month" && typeOfPicker !== "week" ? "hidden" : ""
  }`}
>
  <input
    type={typeOfPicker}
    class="del-month-week-picker"
    value={formattedDefaultDate}
    max={formattedMaxDate}
    on:change={handleDateChange}
    bind:this={monthWeekPickerElement}
    onmousedown="return false;"
    disabled={isDisabled}
    required
  />
  <button class="date-picker-icon-container" on:click={handleCalendarIconClick}
    ><Icon icon="mdi-light:calendar" /></button
  >
</div>
<div
  class={`del-date-picker-container del-year-picker-container ${
    typeOfPicker !== "year" ? "hidden" : ""
  }`}
>
  <input
    class={`del-year-picker ${validationResult ? "invalid-input" : ""}`}
    bind:this={yearPickerElement}
    disabled={isDisabled}
    readonly
  />
  <button class="date-picker-icon-container" on:click={handleCalendarIconClick}
    ><Icon icon="mdi-light:calendar" /></button
  >
</div>

<style>
  .del-date-picker-container {
    position: relative;
  }

  .del-date-picker-container.hidden {
    display: none;
  }

  .del-month-week-picker,
  .del-year-picker {
    background: no-repeat padding-box #fff;
    border: 1px solid #d2d5d9;
    border-radius: 5px;
    min-width: 150px;
    font: normal normal normal 12px Roboto;
    padding: 5px;
    padding-left: 20px;
  }

  .del-year-picker {
    font-size: 15px;
    min-width: 0;
    width: 80px;
  }

  .date-picker-icon-container {
    position: absolute;
    left: 20px;
    top: 10px;
    font-size: 16px;
  }

  .del-month-week-picker {
    user-select: none;
  }

  .del-month-week-picker-container .date-picker-icon-container,
  .del-year-picker-container .date-picker-icon-container {
    left: 5px;
    top: 5px;
  }

  .del-year-picker-container .date-picker-icon-container {
    top: 6px;
  }

  #del-date-picker {
    min-width: 150px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid black;
    width: 260px;
    text-align: left;
    padding-left: 40px;
    padding-right: 20px;
    color: #585859;
    font: normal normal normal 14px Roboto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    margin: 0;
  }
  button {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  input:invalid,
  .invalid-input {
    box-shadow: 0 0 5px 1px red;
    border-color: red;
    outline: none;
  }

  .del-date-picker-container input {
    cursor: pointer;
  }

  /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
  input[type="month"]::-webkit-calendar-picker-indicator,
  input[type="week"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    z-index: 2;
    cursor: pointer;
  }

  input:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
</style>
