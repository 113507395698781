<script>
  import Plotly from "plotly.js-dist-min";
  import moment from "moment";
  import Loader from "./Loader.svelte";
  import * as XLSX from "xlsx";
  import { tick } from "svelte";

  export let baseURL = "";
  export let forecastDetails = null;

  export let currentScreen = "Analytics";
  export let onPlotAPISuccess = null;
  export let onPlotAPIError = null;
  export let onTrainingPeriodUpdated = null;
  export let forceClearChart = true;
  export let exportButtonLabel = "Export Data";

  $: {
    if (forecastDetails) getForecast();
  }

  $: {
    if (forceClearChart) destroyChart();
    else forceDraw();
  }

  const getHeader = function () {
    const companyId = localStorage.getItem("companyId");
    const appId = sessionStorage.getItem("appId");
    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      cid: companyId,
      aid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };
    return headers;
  };

  let forecastData = [];
  let isNoData = false;
  let dataAwaiting = false;
  let chartCanvas;
  let durationForExport = "";

  async function getForecast() {
    if (!forecastDetails) {
      isNoData = true;
      return;
    }
    try {
      const payload = {
        node: forecastDetails?.node,
        analysis_type: "Forecast",
        parameter: forecastDetails?.parameterId,
        duration: forecastDetails?.duration,
        resolution: forecastDetails?.resolution,
        cust_start: forecastDetails?.customStartTime,
        cust_end: forecastDetails?.customEndTime,
        param_type: forecastDetails?.type,
      };
      const url = `${baseURL}/plot`;
      const options = {
        method: "POST",
        headers: getHeader(),
        body: JSON.stringify(payload),
      };
      dataAwaiting = true;
      const response = await fetch(url, options);
      dataAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        getForecastonError(error);
        throw new Error(error);
      }
      forecastData = await response.json();
      if (forecastData.length === 0) isNoData = true;
      getForecastonSuccess(forecastData);
    } catch (error) {
      isNoData = true;
      console.error(error);
    }
  }

  function checkNaN(arr) {
    let flag = true;
    if (arr.length) {
      let i = 0;
      for (i = 0; i < arr.length; i++) {
        if (!isNaN(parseFloat(arr[i]))) {
          flag = false;
          break;
        }
      }
    }
    return flag;
  }

  function forceDraw() {
    if (forecastData && !dataAwaiting) {
      getForecast();
    }
  }

  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        {
          timeZone: tzString,
        }
      )
    );
  }

  async function destroyChart() {
    await tick();
    Plotly.purge("forecast-div");
    durationForExport = "";
  }

  function getForecastonSuccess(data) {
    let trainingStartTime = "-";
    let trainingEndTime = "-";
    onPlotAPISuccess && onPlotAPISuccess("success", data);
    if (data && data.training_dates && data.training_dates.length === 2) {
      trainingStartTime = moment(
        new Date(data.training_dates[0] * 1000)
      ).format("DD-MM-YYYY");
      trainingEndTime = moment(new Date(data.training_dates[1] * 1000)).format(
        "DD-MM-YYYY"
      );
    }
    const trainingDates = `Last training period : ${trainingStartTime} to ${trainingEndTime}`;
    onTrainingPeriodUpdated && onTrainingPeriodUpdated(trainingDates);
    createChart(data);
  }

  async function createChart(data) {
    if (data.data.length || data.forecast_data.length) {
      isNoData = false;
      let actualData = data.data;
      let forecastedData = data.forecast_data;

      if (!checkNaN(actualData) || !checkNaN(forecastedData)) {
        let xaxis = [];
        if (data.xaxis)
          xaxis = data.xaxis.map((item) =>
            convertTZ(new Date(item), "Asia/Kolkata")
          );
        figure.data.forEach((item) => {
          if (item.name === "<b>Forecasted Data</b>") {
            item.x = xaxis;
            item.y = forecastedData;
          } else if (item.name === "<b>Actual Data</b>") {
            item.x = xaxis;
            item.y = actualData;
          }
        });

        let lengthminus =
          forecastDetails.resolution === "Hourly" ||
          forecastDetails.resolution === "Base Frequency"
            ? 2
            : 1;
        let titlestartdate = convertTZ(new Date(xaxis[0]), "Asia/Kolkata");
        let titlestart =
          (titlestartdate.getDate() > 9
            ? titlestartdate.getDate()
            : `0${titlestartdate.getDate()}`) +
          "-" +
          (titlestartdate.getMonth() + 1 > 9
            ? titlestartdate.getMonth() + 1
            : `0${titlestartdate.getMonth() + 1}`) +
          "-" +
          titlestartdate.getFullYear();

        let titleenddate = convertTZ(
          new Date(xaxis[xaxis.length - lengthminus]),
          "Asia/Kolkata"
        );
        let titleend =
          (titleenddate.getDate() > 9
            ? titleenddate.getDate()
            : `0${titleenddate.getDate()}`) +
          "-" +
          (titleenddate.getMonth() + 1 > 9
            ? titleenddate.getMonth() + 1
            : `0${titleenddate.getMonth() + 1}`) +
          "-" +
          titleenddate.getFullYear();
        let titleDuration =
          forecastDetails.duration === "Custom"
            ? `${titlestart} to ${titleend}`
            : forecastDetails.duration;
        let title = `<b> <span style="font-size:18;">Actual and Forecasted Data</span> <span style="text-align:left"><br>Parameter:${
          forecastDetails.parameter
        }<br>Duration:${
          titleDuration === "Today" || titleDuration === "Yesterday"
            ? titleDuration +
              ` (${moment(forecastDetails.customStartTime).format(
                "DD-MM-YYYY"
              )})`
            : titleDuration
        } </span></b>`;
        durationForExport =
          titleDuration === "Today" || titleDuration === "Yesterday"
            ? titleDuration +
              ` (${moment(forecastDetails.customStartTime).format(
                "DD-MM-YYYY"
              )})`
            : titleDuration;

        figure.layout.title.text = title;
        figure.layout.yaxis.title.text = `<b>${forecastDetails.unit}</b>`;

        figure.data.forEach((item) => {
          item.visible = "true";
        });
        await tick();
        const pngFileName = `Forecast ${forecastDetails.parameter} ${durationForExport}`;
        Plotly.react("forecast-div", figure.data, figure.layout, {
          displaylogo: false,
          toImageButtonOptions: {
            filename: pngFileName,
          },
        });
        setTimeout(function () {
          document.querySelector('[data-title="Autoscale"]').click();
        }, 1000);
      } else {
        isNoData = true;
      }
    } else {
      isNoData = true;
      durationForExport = "";
    }
  }

  let figure = {
    data: [
      {
        line: {
          color: "rgb(51, 47, 208)",
        },
        mode: "lines",
        name: "<b>Actual Data</b>",
        type: "scatter",
        x: [],
        y: [],
        hovertemplate: "Actual Data:  %{y}<br>" + "<extra></extra>",
      },
      {
        line: {
          dash: "longdashdot",
          color: "rgb(239, 35, 0)",
          shape: "linear",
          width: 2,
        },
        mode: "lines",
        name: "<b>Forecasted Data</b>",
        type: "scatter",
        x: [],
        y: [],
        stackgroup: null,
        connectgaps: false,
        hovertemplate: "Forecasted Data:  %{y}<br>" + "<extra></extra>",
      },
    ],
    layout: {
      height: 600,
      title: {
        x: 0.5,
        font: {
          family: "Roboto",
        },
        text: "<b>Actual and Forecasted Data&nbsp;</b>",
      },
      xaxis: {
        type: "date",
        range: ["2022-04-18 18:45", "2022-04-19 18:30"],
        title: {
          font: {
            size: 16,
            family: "Roboto",
          },
          text: "<b>TIME DURATION</b>",
        },
        nticks: 12,
        tickfont: {
          size: 14,
          family: "Roboto",
        },
        tickmode: "auto",
        autorange: true,
      },
      yaxis: {
        type: "linear",
        range: [789.7333333333333, 1155.0666666666666],
        title: {
          font: {
            size: 16,
            family: "Roboto",
          },
          text: "<b>Insert Unit here</b>",
        },
        tickfont: {
          size: 14,
          family: "Roboto",
        },
        autorange: true,
        ticksuffix: "   ",
      },
      legend: {
        x: 1.02,
        y: 0.7000000000000002,
        bordercolor: "rgb(178, 196, 201)",
        borderwidth: 2,
      },
      autosize: true,
      hovermode: "x",
    },
  };

  function exportToCsvExcel() {
    // make_xlsx_lib(XLSX);
    let workbook = XLSX.utils.book_new();
    const fileName = `Forecast ${forecastDetails.parameter} ${durationForExport}.xlsx`;

    let headerArray = [
      ["Analysis Type", "Forecast"],
      ["Company", localStorage.getItem("companyName")],
      ["Parameter", forecastDetails.parameter],
      ["Duration", durationForExport],
      ["Resolution", forecastDetails.resolution],
      ["Node", forecastDetails.node],
      [""],
      [""],
    ];
    let dataArray = [["Time", "Forecasted Data", "Actual Data"]];
    const headerStyle = {
      font: {
        name: "Times New Roman",
        sz: 12,
        color: {
          rgb: "FFFFFF",
        },
        bold: true,
      },
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "1F497D",
        },
        fgColor: {
          rgb: "1F497D",
        },
      },
    };
    const contentHeaderStyle = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
    };
    const normalStyle = {
      alignment: {
        horizontal: "center",
      },
    };
    const noDataStyle = {
      alignment: {
        horizontal: "center",
      },
      font: {
        color: {
          rgb: "FFFFFF",
        },
      },
      fill: {
        patternType: "solid",
        fgColor: {
          rgb: "000000",
        },
      },
    };
    for (let i = 1, j = 0; j < figure.data[0].x.length; i++, j++) {
      if (dataArray[i] === undefined) dataArray[i] = [];
      if (forecastDetails.resolution !== "Daily")
        dataArray[i][0] = moment(figure.data[0].x[j]).format(
          "DD MMMM YYYY hh:mm:ss A"
        );
      else dataArray[i][0] = moment(figure.data[0].x[j]).format("DD MMMM YYYY");
    }

    for (let i = 1, j = 0; j < figure.data[1].y.length; j++, i++) {
      if (dataArray[i] === undefined) {
        dataArray[i] = [];
      }
      dataArray[i][1] =
        figure.data[1].y[j] === "nan" ? "No Data" : figure.data[1].y[j];
    }
    for (let i = 1, j = 0; j < figure.data[0].y.length; j++, i++) {
      if (dataArray[i] === undefined) dataArray[i] = [];
      dataArray[i][2] =
        figure.data[0].y[j] === "nan" ? "No Data" : figure.data[0].y[j];
    }
    let dataArrayFinal = [...headerArray, ...dataArray];
    let worksheet = XLSX.utils.aoa_to_sheet(dataArrayFinal);
    worksheet["!cols"] = fitToColumn(dataArray);
    worksheet["A9"].s = headerStyle;
    worksheet["B9"].s = headerStyle;
    worksheet["C9"].s = headerStyle;
    for (let i = 1; i < 7; i++) {
      worksheet[`A${i}`].s = contentHeaderStyle;
      worksheet[`B${i}`].s = contentHeaderStyle;
    }
    let checkIfNotEnd = true;
    let lI = 10; //leter iterator
    console.time("looper forecast");

    do {
      if (worksheet[`A${lI}`]) {
        if (worksheet[`C${lI}`]) worksheet[`C${lI}`].s = normalStyle;
        if (worksheet[`B${lI}`]) worksheet[`B${lI}`].s = normalStyle;
        lI = lI + 1;
      } else {
        checkIfNotEnd = false;
      }
    } while (checkIfNotEnd);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Forecast");

    function fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length + 2 : 0))
        ),
      }));
    }
    // console.log(workbook);
    return XLSX.writeFile(workbook, fileName);
  }

  function getForecastonError(options) {
    onPlotAPIError && onPlotAPIError("error", options);
    isNoData = true;
    durationForExport = "";
  }
</script>

<div class="chart-container">
  <div>
    {#if dataAwaiting}
      <Loader />
    {:else if isNoData}
      <div class="no-data-class">No data available</div>
    {/if}
    <div
      style={isNoData ? `display:none;` : ""}
      bind:this={chartCanvas}
      id="forecast-div"
    />
  </div>

  <div class="btn-div">
    {#if !isNoData}
      <button class="export-button" on:click={() => exportToCsvExcel()}
        >{exportButtonLabel}</button
      >
    {/if}
  </div>
</div>

<style>
  .no-data-class {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    font: italic normal normal 16px/21px Roboto;
  }

  .chart-container {
    position: relative;
    width: 100%;
    min-height: 300px;
  }

  #forecast-div {
    min-height: 300px;
  }

  .export-button {
    color: #fff;
    background-color: #2c3049;
    border-color: #222639;
    border-radius: 5px;
    padding: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }
</style>
